import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    hideMessage,
    resetPasswordCancel,
    setInitUrl,
    showAuthLoader,
    userFacebookSignIn,
    userGithubSignIn,
    userGoogleSignIn,
    userSignIn,
    userTwitterSignIn
} from 'actions/Auth';
import MainLoader from 'components/Loader/MainLoader';
import { AppLoaderFull } from 'components/Loader/AppLoaderFull';
import logo from "assets/images/logo.svg";
import HomeNavBar from './explore/HomeNavBar';
import { closeNetworkVisualizer } from 'components/networkVisualizer/networkVisualizerState/store/general/actions';
import TypeForm from './explore/TypeForm';
import * as typeformEmbed from '@typeform/embed'

const SignIn = (props) => {

    const [email, setEmail] = useState('');
    const [errorEmail, setErrorEmail] = useState(false);
    const [helperEmail, setHelperEmail] = useState('');

    const [password, setPassword] = useState('');
    const [errorPassword, setErrorPassword] = useState(false);
    const [helperPassword, setHelperPassword] = useState('');
    const [showContainer, setShowContainer] = useState(false);

    const dispatch = useDispatch();
    const {loader, alertMessage, showMessage, authUser, initURL} = useSelector(({auth}) => auth);
    let timeout;
    let showContainerTimer;
    useEffect(() => {
        if (showMessage) {
            timeout = setTimeout(() => {
                dispatch(hideMessage());
            }, 100);
        }
        showContainerTimer = setTimeout(() => {
            setShowContainer(true)
        }, 200);
        
        if (authUser !== null && authUser.code && authUser.code === "UserNotConfirmedException" && initURL === "/verifyaccount") {
            props.history.push('/verifyaccount');
        }

        if (initURL !== "/signin") {
            dispatch(setInitUrl("/signin"));
        }

        dispatch(closeNetworkVisualizer());

        dispatch(resetPasswordCancel());

        // Typeform
        let formPopup = typeformEmbed.makePopup('https://hymedallug.typeform.com/to/cIIZLd', {
            mode: 'drawer_left',          
            hideHeaders: true,
            hideFooter: true
        });

        

        let timer2;
        timer2 = setTimeout(()=>{
            document.getElementById('apply').addEventListener('click', function () {
                formPopup.open();
            });
        }, 500)
        // end Typeform

        return () => {
            clearTimeout(timeout);
            clearTimeout(showContainerTimer);
            clearTimeout(timer2);
        }

    }, [showMessage, authUser, props.history, dispatch, /*initURL*/]);


    const handleLogin = (event) => {
            event.preventDefault();
            if (password === '') {
                setErrorPassword(true);
                setHelperPassword('Please enter your password')
            } else if (email === '') {
                setErrorEmail(true);
                setHelperEmail("Please enter a Username or eMail address");
            } else {
                dispatch(userSignIn({email, password}));
            }       
        }
    
    return (
        <div className="lock-screen">
            {<HomeNavBar />}
            <AppLoaderFull/>
            <div
                className="app-login-container justify-content-center align-items-center pt-3">
                <div className="row justify-content-center mt-5 mb-4">
                    <Link className="logo-lg" to="/" title="Jambo">
                        <img src={logo} alt="jambo" title="jambo"/>
                    </Link>
                </div>
            
                <div className="app-login-main-content">
                    <div className="app-login-content">
                        <div className="app-login-header mb-4">
                            <h1>Login to Utilify</h1>
                        </div>

                        <div className="app-login-form">
                            <form>
                                <fieldset>
                                    <TextField
                                        textfieldprops={{ style: { background: "#2daeee" } }}
                                        label={"Username / e-Mail"}
                                        variant="outlined"
                                        fullWidth
                                        error={errorEmail} 
                                        helperText={helperEmail}
                                        onChange={(event) => {
                                            setEmail(event.target.value);
                                            setErrorEmail(false);
                                            setHelperEmail('');
                                        }}
                                        defaultValue={email}
                                        margin="normal"
                                        className="mt-1 my-sm-3"
                                    />
                                    <TextField
                                        type="password"
                                        label={<IntlMessages id="appModule.password"/>}
                                        variant="outlined"
                                        fullWidth
                                        error={errorPassword} 
                                        helperText={helperPassword}
                                        onChange={(event) => {
                                            setPassword(event.target.value);
                                            setErrorPassword(false);
                                            setHelperPassword('');
                                        }}
                                        defaultValue={password}
                                        margin="normal"
                                        className="mt-1 my-sm-3"
                                    />


                                    <div className="mt-3 mb-3 d-flex align-items-center justify-content-between">
                                        <Button 
                                            type="submit"
                                            className="contained"
                                                onClick={handleLogin} variant="contained" className="jr-btn bg-indigo text-white mr-2">
                                                Login
                                        </Button>

                                        <Button id="apply" className="">
                                            JOIN PRE-ALPHA
                                        </Button>

                                        {/*<Link to="/signup">
                                            <IntlMessages id="signIn.signUp"/>
                                        </Link>*/}
                                    </div>

                                    {/*<div className="app-social-block my-1 my-sm-3">
                                        <IntlMessages
                                            id="signIn.connectWith"/>
                                        <ul className="social-link">
                                            <li>
                                                <IconButton className="icon"
                                                            onClick={() => {
                                                                dispatch(showAuthLoader());
                                                                dispatch(userFacebookSignIn());
                                                            }}>
                                                    <i className="zmdi zmdi-facebook"/>
                                                </IconButton>
                                            </li>

                                            <li>
                                                <IconButton className="icon"
                                                            onClick={() => {
                                                                dispatch(showAuthLoader());
                                                                dispatch(userTwitterSignIn());
                                                            }}>
                                                    <i className="zmdi zmdi-twitter"/>
                                                </IconButton>
                                            </li>

                                            <li>
                                                <IconButton className="icon"
                                                            onClick={() => {
                                                                dispatch(showAuthLoader());
                                                                dispatch(userGoogleSignIn());

                                                            }}>
                                                    <i className="zmdi zmdi-google-plus"/>
                                                </IconButton>
                                            </li>

                                            <li>
                                                <IconButton className="icon"
                                                            onClick={() => {
                                                                dispatch(showAuthLoader());
                                                                dispatch(userGithubSignIn());
                                                            }}>
                                                    <i className="zmdi zmdi-github"/>
                                                </IconButton>
                                            </li>
                                        </ul>
                                                        </div>*/}
                                    <div>
                                        <Link to="/forgotpassword">
                                            <IntlMessages id="appModule.forgotPassword"/>
                                        </Link>
                                    </div>

                                </fieldset>
                            </form>
                        </div>
                    </div>

                </div>
                {showMessage && NotificationManager.error(alertMessage)}
            </div>
            {showContainer && (
                <NotificationContainer/>
            )}
        </div>

    );
};


export default SignIn;
