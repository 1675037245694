import {all, call, delay, fork, put, select, takeEvery} from "redux-saga/effects";

import {
    GET_ALL_RESOURCES,
    GET_ALL_RESOURCES_SUCCESS,
    CREATE_RESOURCE,
    CREATE_RESOURCE_SUCCESS,
    GET_RESOURCE,
    GET_RESOURCE_SUCCESS,
    UPDATE_RESOURCE,
    UPDATE_RESOURCE_SUCCESS,
    DELETE_RESOURCE,
    DELETE_RESOURCE_SUCCESS
    
} from "constants/ActionTypes";

import {
    getAllResources_,
    getAllResourcesSuccess_,
    createResource_,
    createResourceSuccess_,
    getResource_,
    getResourceSuccess_,
    updateResource_,
    updateResourceSuccess_,
    deleteResource_,
    deleteResourceSuccess_,
    loadMoreResourcesSuccess_,
    resourcesRefreshed_,
    resourceRefreshed_

} from "actions/Resource";

import {
    getAllResources,
    createResource,
    getResource,
    updateResource,
    deleteResource

} from "../api";

import { Auth } from 'aws-amplify';
import { createNewImage } from "./Img";
import { updateImageSuccess_ } from "actions/Img";
import { hideDashbaordLoader_, hideDialogueLoader_, hideIsFetching_, hideMainLoader_, hideScrollLoader_, hideTransparentLoader_, isLoadingMore_, showDashbaordLoader_, showDialogueLoader_, showIsFetching_, showLoaderCreateSuccess_, showLoaderDeleteSuccess_, showLoaderUpdateSuccess_, showMainLoader_, showScrollLoader_, showTransparentLoader_ } from "actions/IsFetching";
import { formatAssetData } from "util/formatAssetData";
import { getAssetSuccess_, userSignOut } from "actions";
import { viewAlertMessage_ } from "actions/Alert";
import { errorHandler, successHandler } from "util/messageHandler";

// Get JWToken from Local Storage

function* getAllResourcesByFilter({payload}) {
    const {JWToken, querryParams, refresh, loadMore, noLoader} = payload;
    const resourceList = yield select(state => state.resource.resourceList)
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDashbaordLoader_());
            } 
        }

        if (loadMore) {
            yield put(showScrollLoader_());
            yield put(isLoadingMore_(true));
        }
        
        const apiResponse = yield call(getAllResources, JWToken, querryParams);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            if (loadMore) {
                yield put(getAllResourcesSuccess_([...resourceList, ...apiResponse.data.resources])); 
            } else {
                yield put(getAllResourcesSuccess_(apiResponse.data.resources));
            }
              
        }
    } catch (error) {
        // console.log("catchError: ", error);
        yield put(hideIsFetching_());
        // yield put(errorAction(error));
    } finally {
        yield put(hideDashbaordLoader_());
        yield put(hideTransparentLoader_());
        yield put(hideScrollLoader_());
        yield put(isLoadingMore_(false));
    }

}

function* createNewResourceWithData({payload}) {
    const {JWToken, resourceData, imgData, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDashbaordLoader_());
            } 
        }
        let fetchingResult = {};
        const apiResponse = yield call(createResource, JWToken, resourceData);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            fetchingResult.error = "Error";
            yield put(showLoaderCreateSuccess_(fetchingResult));
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            if (imgData !== "") {
                // console.log("apiResponse: ", apiResponse.data);
                imgData.resourceId = apiResponse.data.resourceId;
                // console.log("imgData in Saga (after): ", imgData);
                const downloadUrl = yield call(createNewImage, JWToken, imgData);
                yield put(updateImageSuccess_(downloadUrl));
            }
            yield delay(3000);
            yield put(resourcesRefreshed_(true));
            fetchingResult.success = "Success";
            yield put(showLoaderCreateSuccess_(fetchingResult));
            yield put(createResourceSuccess_());

            // alert message   
            const successMessage = yield call(successHandler,"Resource", "", "created");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideDashbaordLoader_());
    }

}

function* getResourceById({payload}) {
    const {JWToken, resourceId, dashboard, refresh, noLoader} = payload;
    let formattedAsset;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                if (dashboard) {
                    yield put(showDashbaordLoader_());
                } else {
                    yield put(showMainLoader_());
                }
            }
        }
        
        
        
        const apiResponse = yield call(getResource, JWToken, resourceId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }

            
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            let assetData = apiResponse.data;
            let assetType = "resources";
            formattedAsset = yield call(formatAssetData, assetData, assetType);

            // console.log("FORMATTED: ", formattedAsset);
            yield put(getAssetSuccess_(formattedAsset));   
            yield put(getResourceSuccess_(apiResponse.data));    
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideMainLoader_());
        yield put(hideDashbaordLoader_());
        yield put(hideTransparentLoader_());
    }

}

function* updateResourceById({payload}) {
    const {JWToken, resourceId, resourceData, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDashbaordLoader_());
            } 
        }
        let fetchingResult = {};

        const apiResponse = yield call(updateResource, JWToken, resourceId, resourceData);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            // alert message
            

            fetchingResult.error = "Update Error";
            yield put(showLoaderUpdateSuccess_(fetchingResult));

            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);

            // alert message   
            
            yield put(resourcesRefreshed_(true));
            yield put(resourceRefreshed_(true));
            fetchingResult.success = "Success";
            yield put(showLoaderUpdateSuccess_(fetchingResult));
            yield put(updateResourceSuccess_());   
            
            const successMessage = yield call(successHandler,"Resource", "", "updated");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideDashbaordLoader_());
    }

}

function* deleteResourceById({payload}) {
    const {JWToken, resourceId, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        let fetchingResult={};
        const apiResponse = yield call(deleteResource, JWToken, resourceId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            fetchingResult.error = "Error";
            yield put(showLoaderDeleteSuccess_(fetchingResult));
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(resourcesRefreshed_(true));
            fetchingResult.error = "Success";
            yield put(showLoaderDeleteSuccess_(fetchingResult));
            yield put(deleteResourceSuccess_());  
            
            // alert message   
            const successMessage = yield call(successHandler,"Resource", "", "deleted");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideDialogueLoader_());
        yield put(hideTransparentLoader_());
    }
}

export function* readAllResources() {
    yield takeEvery(GET_ALL_RESOURCES, 
        getAllResourcesByFilter);
}

export function* createOneResource() {
    yield takeEvery(CREATE_RESOURCE, 
        createNewResourceWithData);
}

export function* readOneResource() {
    yield takeEvery(GET_RESOURCE, 
        getResourceById);
}

export function* updateOneResource() {
    yield takeEvery(UPDATE_RESOURCE, 
        updateResourceById);
}
export function* removeOneResource() {
    yield takeEvery(DELETE_RESOURCE, 
        deleteResourceById);
}

export default function* rootSaga() {
    yield all([fork(readAllResources),
        fork(createOneResource),
        fork(readOneResource),
        fork(updateOneResource),
        fork(removeOneResource)
    ]);
}