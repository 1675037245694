import { CategoryTypes } from 'components/networkVisualizer/networkVisualizerState/sharedTypes'
import { changeSelectedNode } from 'components/networkVisualizer/networkVisualizerState/store/graph/actions'
import { hideVisualizerNavMenu } from 'components/networkVisualizer/networkVisualizerState/store/navMenu/actions'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AutoSizer from 'react-virtualized-auto-sizer'
import { FixedSizeList } from 'react-window'
import { AppState } from 'store'
import { SearchResultItem } from './components/searchResultItem/searchResultItem'

export const Results = () => {
  const dispatch = useDispatch()

  const selectedNode = useSelector(
    (state: AppState) => state.networkVisualizerState.graph.selectedNode
  )

  const resultsData = useSelector(
    (state: AppState) => state.networkVisualizerState.navMenu.searchResults
  )

  const onPress = useCallback(
    (
      id: number | string,
      type: CategoryTypes,
      platformId?: string | number
    ) => {
      dispatch(hideVisualizerNavMenu())
      dispatch(changeSelectedNode(id, type, platformId))
    },
    [dispatch]
  )

  const RenderItem = ({
    data,
    index,
    style,
  }: {
    data: typeof resultsData
    index: number
    style: any
  }) => {
    let { id, type, name, image, projectName, platformId, platformName } = data[
      index
    ]
    return (
      <div style={style} className=''>
        <SearchResultItem
          key={type + '-' + id}
          active={id === selectedNode.id && type === selectedNode.type}
          {...{
            id,
            type,
            name,
            image,
            projectName,
            platformId,
            platformName,
            onPress,
          }}
        />
      </div>
    )
  }

  return (
    // <div className='results-wrapper'>
    <AutoSizer className='results-wrapper'>
      {({ height, width }) => (
        <FixedSizeList
          itemCount={resultsData.length}
          itemData={resultsData}
          itemSize={60} //Value must match height value specified in css
          height={height}
          width={width}
        >
          {RenderItem}
        </FixedSizeList>
      )}
    </AutoSizer>
    // </div>
  )
}
