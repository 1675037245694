import { fork } from 'redux-saga/effects'
import { generalSagas } from './store/general/sagas'
import { graphSagas } from './store/graph/sagas'
import { itemDetailsSagas } from './store/itemDetails/sagas'
import { navMenuSagas } from './store/navMenu/sagas'
import { walletInputSagas } from './store/walletInput/sagas'

export function* networkVisualizerSagas() {
  yield fork(generalSagas)
  yield fork(graphSagas)
  yield fork(itemDetailsSagas)
  yield fork(navMenuSagas)
  yield fork(walletInputSagas)
}
