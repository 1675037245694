import {
    EVENTS_REFRESHED,
    EVENT_REFRESHED,
    GET_ALL_EVENTS_SUCCESS
} from "constants/ActionTypes";

const INIT_STATE = {
    eventList: [],
    eventsRefreshed: false,
    eventRefreshed: false
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_EVENTS_SUCCESS: {
            return {
                ...state,
                eventList: action.payload
            }
        }
        case EVENTS_REFRESHED: {
            return {
                ...state,
                eventsRefreshed: action.payload
            }
        }
        case EVENT_REFRESHED: {
            return {
                ...state,
                eventRefreshed: action.payload
            }
        }
        default:
            return state;
    }
}