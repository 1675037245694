import {
    GET_ALL_ORDERS_SUCCESS,
    PLACE_ORDER_SUCCESS,
    GET_ORDER_SUCCESS,
    DELETE_ORDER_SUCCESS,
    UPDATE_ORDER_SUCCESS,
    GET_ORDER_QUEUE_ITEMS_SUCCESS,
    FORCE_SEND_SUCCESS,
    CLEAR_ORDER_STAGE,
    SET_ORDER_ID,
    SET_SELECTED_ORDER,
    ORDERS_REFRESHED,
    ORDER_REFRESHED
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    orderList: '',
    orderId: '',
    selectedOrder: '',
    order: '',
    queueItemList: [],
    orderStage: 1,
    orderRefreshed: false,
    ordersRefreshed: false
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_ORDERS_SUCCESS: {
            return {
                ...state,
                orderList: action.payload
            }
        }
        case PLACE_ORDER_SUCCESS: {
            return {
                ...state,
                orderStage: 2
            }
        }
        case GET_ORDER_SUCCESS: {
            return {
                ...state,
                order: action.payload
            }
        }
        case DELETE_ORDER_SUCCESS: {
            return {
                ...state,
                loader: false
            }
        }
        case UPDATE_ORDER_SUCCESS: {
            return {
                ...state,
                loader: false
            }
        }
        case GET_ORDER_QUEUE_ITEMS_SUCCESS: {
            return {
                ...state,
                loader: false,
                queueItemList: action.payload
            }
        }
        case FORCE_SEND_SUCCESS: {
            return {
                ...state,
                loader: false
            }
        }
        case CLEAR_ORDER_STAGE: {
            return {
                ...state,
                orderStage: 1
            }
        }
        case SET_ORDER_ID: {
            return {
                ...state,
                orderId: action.payload
            }
        }
        case SET_SELECTED_ORDER: {
            return {
                ...state,
                selectedOrder: action.payload
            }
        }
        case ORDERS_REFRESHED: {
            return {
                ...state,
                ordersRefreshed: action.payload
            }
        }
        case ORDER_REFRESHED: {
            return {
                ...state,
                orderRefreshed: action.payload
            }
        }
        default:
            return state;
    }
}