import { CategoryTypes } from 'components/networkVisualizer/networkVisualizerState/sharedTypes'
import React, { CSSProperties } from 'react'

interface IsProps {
  categories: CategoryTypes[]
  activeTabIndex: number
  setCategory: (newI: number) => void
}

export const Header = ({
  categories,
  activeTabIndex,
  setCategory,
}: IsProps) => {
  return (
    <div
      className='categories-header-container'
      style={
        { '--l': categories.length, '--i': activeTabIndex } as CSSProperties
      }
    >
      {categories.map((category, i) => (
        <div
          key={category}
          className={`category-header-container  ${i === activeTabIndex &&
            'active'}`}
          onClick={() => setCategory(i)}
        >
          <h2 className='category-text'>{category}</h2>
        </div>
      ))}
    </div>
  )
}
