import { GetCooperationsResponse } from 'components/networkVisualizer/API/types/getCooperationsResponse'
import { GetCooperationsResourcesResponse } from 'components/networkVisualizer/API/types/getCooperationsResponse/resource'
import { GetCooperationsTokensResponse } from 'components/networkVisualizer/API/types/getCooperationsResponse/token'
import { GetCooperationsUtilitiesResponse } from 'components/networkVisualizer/API/types/getCooperationsResponse/utility'
import {
  Connections,
  Resources,
  SetVisualizerDataPayload,
  Tokens,
  Utilities,
} from 'components/networkVisualizer/networkVisualizerState/store/general/types'
import { tokenIdCreator } from './../../tokenIdCreator'

export interface ToGeneralDataOutput {
  data: SetVisualizerDataPayload
  connections: Connections
}

export function toGeneralData(
  response: GetCooperationsResponse
): ToGeneralDataOutput {
  if (response.cooperations.length > 0) {
    let { cooperations } = response
    //Is this a reliable conditional? Property names are not consistent.
    if ('resource' in cooperations[0]) {
      //@ts-ignore
      return resourcesFormatter(response)
    } else if ('utility' in cooperations[0]) {
      //@ts-ignore
      return utilitiesFormatter(response)
    } else if ('token' in cooperations[0]) {
      //'token' attribute is now being returned with data even if not a token. Keep the token check as the last of the if/else statements.
      //@ts-ignore
      return tokensFormatter(response)
    } else {
      console.log(
        'cooperations to general data formatter failed. Check if property exists in response.'
      )
    }
  }
  return { data: {}, connections: {} }
}

export function tokensFormatter(
  response: GetCooperationsTokensResponse
): ToGeneralDataOutput {
  let tokens: Tokens = {}
  let connections: Connections = { tokens: [] }

  // console.log({ response })

  response.cooperations.forEach((token) => {
    let {
      tokenId,
      name,
      nonFungible,
      supplyModel,
      totalSupply,
      circulatingSupply,
      meltValue,
      meltFeeRatio,
      transferable,
      // transferFeeSettings,
      appName,
      // cooperations,
      project,
      metadata,
      cooperationCounts,
      platformId,
      platform,
    } = token.token

    let referenceId = tokenIdCreator(tokenId, platformId)

    connections.tokens.push(referenceId)

    tokens[referenceId] = {
      tokenId,
      name,
      image: metadata?.image ?? null,
      // connections,
      nonFungible,
      supplyModel,
      totalSupply,
      circulatingSupply,
      meltValue,
      meltFeeRatio: meltFeeRatio ?? undefined,
      transferable,
      // transferFee: transferFeeSettings.value ?? undefined,
      appName,
      // collaborationsCount,
      projectName: project?.name ?? undefined,
      cooperationsCount: cooperationCounts,
      platformId,
      platformName: platform?.name ?? undefined,
    }
  })

  return { data: { tokens }, connections }
}

function utilitiesFormatter(
  response: GetCooperationsUtilitiesResponse
): ToGeneralDataOutput {
  let utilities: Utilities = {}
  let connections: Connections = { utilities: [] }

  response.cooperations.forEach((utility) => {
    let {
      utilityId,
      name,
      avatarImage,
      project,
      type,
      cooperationCounts,
    } = utility.utility

    connections.utilities.push(utilityId)

    utilities[utilityId] = {
      name,
      image: avatarImage?.thumbnail,
      // connections,
      projectName: project?.name ?? undefined,
      // expiry,
      type,
      // rating,
      cooperationsCount: cooperationCounts,
    }
  })

  return { data: { utilities }, connections }
}

function resourcesFormatter(
  response: GetCooperationsResourcesResponse
): ToGeneralDataOutput {
  let resources: Resources = {}
  let connections: Connections = { resources: [] }

  response.cooperations.forEach((resource) => {
    let {
      resourceId,
      name,
      avatarImage,
      project,
      //Commented out properties are missing and are required. Check if they will be sent or not.
      // category,
      // modelType,
      // platform,
      license,
      // fileType,
      cooperationCounts,
    } = resource.resource

    connections.resources.push(resourceId)

    resources[resourceId] = {
      name,
      image: avatarImage?.thumbnail ?? undefined,
      projectName: project?.name ?? undefined,
      // category,
      // modelType,
      // platform,
      license,
      // fileType,
      cooperationsCount: cooperationCounts,
    }
  })

  return { data: { resources }, connections }
}
