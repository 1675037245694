import React from 'react';
import Avatar from '@material-ui/core/Avatar'
import unknownIcon from 'assets/images/unknown_icon.png';
import moment from 'moment';
import { CardActionArea } from '@material-ui/core';


const NotificationItem = (props) => {

    const {data} = props;

    const calcTimeSinceCreated = () => {
        let hoursSinceCreated = Math.round((Date.now() - Date.parse(data.createdAt))/(1000*60*60));
        let tempTime = moment().subtract((hoursSinceCreated), 'hours');

        let result = moment(tempTime).format('MMMM Do YYYY, h:mm:ss a');
        
        return result;
    }

    let message;
    let userName;
    let userAvatar;
    let link;
    let projectId;

    switch(data.type) {
        case "request": {
            message = " has sent you a coop request";
            userName = data.request.requester.displayName;
            userAvatar = data.request.requester.avatarImage ? data.request.requester.avatarImage.thumbnail : unknownIcon;
            
            if (data.request.cooperationType === "resource") {
                if (data.request.offeringType === "token") {
                    projectId = data.request.resource.project.projectId;
                } else {
                    projectId = data.request.token.project?.projectId || 0;
                }
            } else {
                if (data.request.offeringType === "token") {
                    projectId = data.request.utility.project.projectId;
                } else {
                    projectId = data.request.token.project?.projectId || 0;
                }
            }

            link = projectId === 0 ? `/app/projects/${projectId}/dashboard/requests/received` : "/app/Home";
        }
        break;
        case "requestResponse": {
            message = data.request.status === ("accepted" || "declined") ? `has ${data.request.status} your Request` : `has answered your Request`;
            userName = data.request.responder.displayName;
            userAvatar = data.request.responder.avatarImage ? data.request.responder.avatarImage.thumbnail : unknownIcon;
            
            if (data.request.cooperationType === "resource") {
                if (data.request.offeringType === "token") {
                    projectId = data.request.resource.project.projectId;
                } else {
                    projectId = data.request.token.project?.projectId || 0;
                }
            } else {
                if (data.request.offeringType === "token") {
                    projectId = data.request.utility.project.projectId;
                } else {
                    projectId = data.request.token.project?.projectId || 0;
                }
            }

            link = projectId === 0 ? `/app/projects/${projectId}/dashboard/requests/sent` : "/app/Home";
        }
        break;
        case "newFollower": {
            message = " has added you as a friend";
            userName = data.user.userId || "Unknown User";
            userAvatar = data.user.avatarImage ? data.user.avatarImage.thumbnail : unknownIcon;
            link = `/app/home`;
        }
        break;
        default: ;

    }


    
    const handleClick = () => {
        props.history.push(link);
    }

    return (

        <li className="media">
            <CardActionArea onClick={handleClick}>
            <div className="row">
                <div className="col-2">
                    <Avatar
                        alt={userName}
                        src={userAvatar}
                        className=" mr-2"
                    />
                </div>
                <div className="col-10">
                    <div className="media-body align-self-center">
                        <h6 className="mb-0 d-inline">{`${userName} `}</h6>
                        <p className="sub-heading mb-0 d-inline">{`${message}`}</p>
                        <span
                        className="meta-date"><small>{calcTimeSinceCreated()}</small></span>
                    </div>
                </div>
                
                
            </div>
            </CardActionArea>
        </li>
    );
};

export default NotificationItem;
