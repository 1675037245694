import {
  closeIcon,
  searchIcon,
} from 'components/networkVisualizer/assets/icons'
import {
  changeVisualizerSearchValue,
  showVisualizerNavMenu,
} from 'components/networkVisualizer/networkVisualizerState/store/navMenu/actions'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

export const SearchBar = () => {
  const dispatch = useDispatch()

  // let autoSearchTimeout = useRef<NodeJS.Timeout>()

  const searchValue = useSelector(
    (state: AppState) => state.networkVisualizerState.navMenu.searchValue
  )

  const onFocus = useCallback(() => {
    dispatch(showVisualizerNavMenu())
  }, [])

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let newValue = e.target.value
      dispatch(changeVisualizerSearchValue(newValue))
    },
    [dispatch]
  )

  const clearInput = useCallback(() => {
    dispatch(changeVisualizerSearchValue(''))
  }, [dispatch])

  return (
    <div className='search-bar-wrapper'>
      <div
        className={`search-button${searchValue ? ' show-clear' : ''}`}
        onClick={clearInput}
      >
        <div className='search-slider'>
          <div className='image-container'>
            <img src={searchIcon} alt='search icon' />
          </div>
          <div className='image-container'>
            <img src={closeIcon} alt='clear icon' />
          </div>
        </div>
      </div>
      <input
        type='text'
        value={searchValue}
        onChange={onInputChange}
        className='search-input'
        placeholder='Search...'
        onFocus={onFocus}
      />
    </div>
  )
}
