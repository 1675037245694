import {
    HIDE_ALERT_MSG,
    VIEW_ALERT_MSG
} from 'constants/ActionTypes'

export const viewAlertMessage_ = (msg) => {
    return {
        type: VIEW_ALERT_MSG,
        payload: msg
    };
};

export const hideAlertMessage_ = () => {
    return {
        type: HIDE_ALERT_MSG,
    };
};