import Amplify, { API } from 'aws-amplify';

const apiName = 'utilify-api-dev';

const axios = require('axios');

////////////////////////////////////////////////////////////////////////////////////////////
//                                        ACCOUNTS                                        //
////////////////////////////////////////////////////////////////////////////////////////////

////// GET All Accounts
export const getAllUserAccounts = (JWToken, querryParams) => {
  const path = `/accounts`;
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: querryParams,
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    // (//console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    // //console.log("API error: ", error.response);
    return error.response;
  });
};

////// GET User Account
export const getUserAccount = (JWToken, userId) => {

    const path = `/accounts/${userId}`;
    const myInit = { // OPTIONAL
        headers: {
            // Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${JWToken}`
        }, // OPTIONAL
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        // queryStringParameters: {  // OPTIONAL
        //     name: 'param',
        // },
    };

    return API
      .get(apiName, path, myInit)
      .then(response => {
        //console.log("API response: ", response);
        return response;
      })
      .catch(error => {
        //console.log("API error: ", error.response);
        return error.response;
      });
};

////// PUT User Account
export const updateUserAccount = (JWToken, userId, accountData) => {

  const path = `/accounts/${userId}`;
  const myInit = { // OPTIONAL
      body: accountData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param',
      // },
  };

  return API
  .put(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// DELETE User Account
export const deleteUserAccount = (JWToken, userName) => {

  const path = `/accounts/${userName}`;
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param',
      // },
  };

  return API
  .del(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////////////////////////////////////////////////////////////////////////////////////////////
//                                        COOPERATIONS                                  //
////////////////////////////////////////////////////////////////////////////////////////////

////// GET All Cooperations
export const getAllCooperations = (JWToken, assetType, querryParams) => {
  
  const path = `/cooperations/${assetType}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: querryParams,
  };
  
  ////console.log("API CALLED!")
  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};


////// POST new Cooperation
export const createCooperation = (JWToken, cooperationData) => {

  const path = '/cooperations'
  const myInit = { // OPTIONAL
      body: cooperationData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .post(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// POST new Cooperation Rating
export const rateCooperation = (JWToken, ratingData) => {

  const path = '/cooperations/rating'
  const myInit = { // OPTIONAL
      body: ratingData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .post(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// UPDATE Cooperation with Utility
export const updateCooperationWithUtility = (JWToken, tokenId, platform, utilityId, coopData) => {

  const path = `/cooperations/tokens/${platform}/${tokenId}/utilities/${utilityId}`;
  const myInit = { // OPTIONAL
      body: coopData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param',
      // },
  };

  return API
  .put(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// UPDATE Cooperation with Resource
export const updateCooperationWithResource = (JWToken, tokenId, platform, resourceId, coopData) => {

  const path = `/cooperations/tokens/${platform}/${tokenId}/resources/${resourceId}`;
  const myInit = { // OPTIONAL
      body: coopData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param',
      // },
  };

  return API
  .put(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// DELETE Cooperation with Utility
export const deleteCooperationWithUtility = (JWToken, tokenId, platform, utilityId) => {

  const path = `/cooperations/tokens/${platform}/${tokenId}/utilities/${utilityId}`;
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param',
      // },
  };

  return API
  .del(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// DELETE Cooperation with Resource
export const deleteCooperationWithResource = (JWToken, tokenId, platform, resourceId) => {

  const path = `/cooperations/tokens/${platform}/${tokenId}/resources/${resourceId}`;
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      // queryStringParameters: {  // OPTIONAL
      //     name: 'param',
      // },
  };

  return API
  .del(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////////////////////////////////////////////////////////////////////////////////////////////
//                                        EVENTS                                          //
////////////////////////////////////////////////////////////////////////////////////////////

////// GET All Events
export const getAllEvents = (JWToken, querryParams) => {

  const path = '/events'
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: querryParams,
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};



////////////////////////////////////////////////////////////////////////////////////////////
//                                        IMGS                                            //
////////////////////////////////////////////////////////////////////////////////////////////

////// POST new Image
export const getImgUrl = (JWToken, imgData) => {

  const path = '/files/images/getUploadUrl'
  const myInit = { // OPTIONAL
      body: imgData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL  (return the entire Axios response object instead of only response.data)
  };

  return API
  .post(apiName, path, myInit)
  .then(response => {
    //console.log("API response (post image): ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

export const uploadImg = async (uploadUrl, file, contentType) => {
  //console.log("Upload Image: ", uploadUrl, "File: ", file, "contentType: ", contentType);
  const response = await fetch(uploadUrl, {
    method: "PUT",
    headers: {
      "Content-Type": contentType
    },
    body: file
  });

  return response;
}

////// DELETE Image
export const deleteImg = (JWToken, imageId) => {

  const path = `/files/images/${imageId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL  (return the entire Axios response object instead of only response.data)
  };

  return API
  .del(apiName, path, myInit)
  .then(response => {
    //console.log("API response (delete image): ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////////////////////////////////////////////////////////////////////////////////////////////
//                                    NOTIFICATIONS                                       //
////////////////////////////////////////////////////////////////////////////////////////////

////// GET All Notifications
export const getAllNotifications = (JWToken, querryParams) => {

  const path = '/notifications'
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: querryParams,
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};


////// POST Notification
export const updateNotificationStatus = (JWToken, isRead) => {

  const path = `/notifications`
  const myInit = { // OPTIONAL
      body: {"isRead": isRead},
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .put(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};



////////////////////////////////////////////////////////////////////////////////////////////
//                                        ORDERS                                          //
////////////////////////////////////////////////////////////////////////////////////////////


////// GET All Orders
export const getAllOrders = (JWToken, querryParams) => {

  const path = '/orders'
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: querryParams,
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};


////// POST new Order
export const placeOrder = (JWToken, orderData) => {

  const path = '/orders'
  const myInit = { // OPTIONAL
      body: orderData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .post(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// GET Order by Id
export const getOrder = (JWToken, orderId) => {

  const path = `/orders/${orderId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// UPDATE Order by Id
export const updateOrder = (JWToken, orderId, orderData) => {

  const path = `/orders/${orderId}`
  const myInit = { // OPTIONAL
      body: orderData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .put(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// DELETE Order by Id
export const deleteOrder = (JWToken, orderId) => {

  const path = `/orders/${orderId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .del(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// GET Order Queue Items
export const getOrderQueueItems = (JWToken, querryParams) => {

  const path = '/orders/queueitems'
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: querryParams,
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// ForceSend Items
export const forceSend = (JWToken, orderData) => {

  const path = '/orders/queue/ForceSend'
  const myInit = { // OPTIONAL
      body: orderData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true // OPTIONAL (return the entire Axios response object instead of only response.data)

  };

  return API
  .post(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};


////////////////////////////////////////////////////////////////////////////////////////////
//                                       PROJECTS                                         //
////////////////////////////////////////////////////////////////////////////////////////////


////// GET All Projects by user ID
export const getAllProjects = (JWToken, querryParams) => {

  const path = '/projects'
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: querryParams,
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    // console.log("API error get all projects: ", error.response);
    return error.response;
  });
};


////// POST new Project
export const postProject = (JWToken, projectData) => {

  const path = '/projects'
  const myInit = { // OPTIONAL
      body: projectData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      
  };

  return API
  .post(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    // console.log("API error get project: ", error);
    return error;
  });
};

////// GET Project by Id
export const getProject = (JWToken, projectId) => {

  const path = `/projects/${projectId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// UPDATE Project by Id
export const updateProject = (JWToken, projectId, projectData) => {

  const path = `/projects/${projectId}`
  const myInit = { // OPTIONAL
      body: projectData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .put(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};


////// DELETE Project by Id
export const deleteProject = (JWToken, projectId) => {

  const path = `/projects/${projectId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .del(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};


////// POST new User to Project
export const addProjectEditor = (JWToken, projectId, userData) => {

  const path = `/projects/${projectId}/users`
  const myInit = { // OPTIONAL
      body: userData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .post(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// GET All Project Editors
export const getAllProjectEditors = (JWToken, projectId, querryParams) => {

  const path = `/projects/${projectId}/users`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: querryParams,
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// GET All Project BP
export const getAllProjectBlueprints = (JWToken, projectId, querryParams) => {

  const path = `/projects/${projectId}/blueprints`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data),
      queryStringParameters: querryParams
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};


////// POST Pre-Cache Project Blueprints 
export const cacheProjectBlueprints = (JWToken, projectId) => {

  const path = `/projects/${projectId}/blueprints/cache`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true // OPTIONAL (return the entire Axios response object instead of only response.data),
  };

  return API
  .post(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// DELETE Project editor from project
export const deleteProjectEditor = (JWToken, projectId, userId) => {

  const path = `/projects/${projectId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .del(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};


////// GET Project Followers
export const getProjectFollowers = (JWToken, projectId, querryParams) => {

  const path = `/projects/${projectId}/followers`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: querryParams,
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};


////// Follow Project
export const followProject = (JWToken, projectId) => {

  const path = `/projects/${projectId}/followers`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .post(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// Follow Project
export const unfollowProject = (JWToken, projectId, userId) => {

  const path = `/projects/${projectId}/followers/${userId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .del(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// Pin Asset to Profile
export const pinAssetToProjectProfile = (JWToken, projectId, assetData) => {

  const path = `/projects/${projectId}/pinned`
  const myInit = { // OPTIONAL
      body: assetData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .post(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

export const unpinUtilityFromProject = (JWToken, projectId, utilityId) => {

  const path = `/projects/${projectId}/pinned/utilities/${utilityId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .del(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

export const unpinResourceFromProject = (JWToken, projectId, resourceId) => {

  const path = `/projects/${projectId}/pinned/resource/${resourceId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .del(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

export const unpinTokenFromProject = (JWToken, projectId, tokenId) => {

  const path = `/projects/${projectId}/pinned/tokens/${tokenId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .del(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////////////////////////////////////////////////////////////////////////////////////////////
//                                      PROJECT SETTINGS                                  //
////////////////////////////////////////////////////////////////////////////////////////////

///// GET Project Settings

export const getProjectSettings = (JWToken, projectId) => {

  const path = `/projects/${projectId}/settings`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};


export const updateProjectSettings = (JWToken, projectId, projectSettingsData) => {

  const path = `/projects/${projectId}/settings`
  const myInit = { // OPTIONAL
      body: projectSettingsData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .put(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////////////////////////////////////////////////////////////////////////////////////////////
//                                      PROJECT API KEY                                         //
////////////////////////////////////////////////////////////////////////////////////////////

///// GET Project API KEY

export const getProjectApiSettings = (JWToken, projectId) => {

  const path = `/projects/${projectId}/apisettings`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

export const updateProjectApiSettings = (JWToken, projectId, projectData) => {

  const path = `/projects/${projectId}/apisettings`
  const myInit = { // OPTIONAL
      body: projectData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .put(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};


export const getProjectApikey = (JWToken, projectId) => {

  const path = `/projects/${projectId}/apikeys`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};


export const postProjectApikey = (JWToken, projectId) => {

  const path = `/projects/${projectId}/apikeys`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .post(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

export const deleteProjectApikey = (JWToken, projectId) => {

  const path = `/projects/${projectId}/apikeys`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .del(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////////////////////////////////////////////////////////////////////////////////////////////
//                                      PRICES ETH                                         //
////////////////////////////////////////////////////////////////////////////////////////////


export const getGasPrice = (JWToken, querryParams) => {

  const path = `/prices/ethereum/gas`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      queryStringParameters: querryParams,
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////////////////////////////////////////////////////////////////////////////////////////////
//                                      RESOURCES                                         //
////////////////////////////////////////////////////////////////////////////////////////////

////// GET All Resources
export const getAllResources = (JWToken, querryParams) => {

  const path = '/resources'
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: querryParams,
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};


////// POST new Resource
export const createResource = (JWToken, resourceData) => {

  const path = '/resources'
  const myInit = { // OPTIONAL
      body: resourceData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .post(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// GET Resource by Id
export const getResource = (JWToken, resourceId) => {

  const path = `/resources/${resourceId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// UPDATE Resource by Id
export const updateResource = (JWToken, resourceId, resourceData) => {

  const path = `/resources/${resourceId}`
  const myInit = { // OPTIONAL
      body: resourceData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .put(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};



////// DELETE Resource by Id
export const deleteResource = (JWToken, resourceId) => {

  const path = `/resources/${resourceId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .del(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};


////////////////////////////////////////////////////////////////////////////////////////////
//                                      REQUESTS                                          //
////////////////////////////////////////////////////////////////////////////////////////////

////// GET Request
export const getRequest = (JWToken, requestId) => {

  const path = `/requests/${requestId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// GET All Requests
export const getAllRequests = (JWToken, querryParams) => {

  const path = '/requests'
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: querryParams,
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};


////// POST new Request
export const createRequest = (JWToken, requestData) => {

  const path = '/requests'
  const myInit = { // OPTIONAL
      body: requestData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .post(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};


////// PUT Answer cooperation Request
export const updateRequestStatus = (JWToken, requestId, requestData) => {

  const path = `/requests/${requestId}`
  const myInit = { // OPTIONAL
      body: requestData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .put(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// DELETE Request by Id
export const deleteRequest = (JWToken, requestId) => {

  const path = `/requests/${requestId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .del(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////////////////////////////////////////////////////////////////////////////////////////////
//                                       TOKENS                                           //
////////////////////////////////////////////////////////////////////////////////////////////

////// GET All Token BP
export const getAllTokenBlueprints = (JWToken, querryParams) => {

  const path = `/tokens`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: querryParams,
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// POST new Token / mint
export const createToken = (JWToken, tokenData) => {

  const path = '/tokens'
  const myInit = { // OPTIONAL
      body: tokenData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .post(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// GET Token Blueprint by Id
export const getTokenBlueprint = (JWToken, platformId, tokenId, querryParams) => {

  const path = `/tokens/${platformId}/${tokenId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      queryStringParameters: querryParams,
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};


////// GET Token stats
export const getTokenStats = (JWToken, tokenId, platform, tokenIndex) => {

  const path = `/tokens/${platform}/${tokenId}/${tokenIndex}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// POST Token Metadata for itemURI
export const getMetadata = (JWToken, platform, tokenId) => {

  const path = `/tokens/${platform}/${tokenId}/metadata`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};


////// POST Token Metadata for itemURI
export const updateMetadata = (JWToken, tokenId, platform, metaData) => {

  const path = `/tokens/${platform}/${tokenId}/metadata`
  const myInit = { // OPTIONAL
      body: metaData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .put(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};


////// Set ItemURI
export const updateUri = (JWToken, tokenId, platform, itemUri) => {

  const path = `/tokens/${platform}/${tokenId}/itemUri`
  const myInit = { // OPTIONAL
      body: itemUri,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .put(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

/*export const getMetadataByUri = (uri) => {
  return fetch(uri, {
    method: 'GET'
    })
  .then(response => {
    //console.log("Res raw: ", response);
    response.json().then((s) => {//console.log("SSS: ", s); return s;});
  })
  .catch(error => {
    //console.log("Uri error: ", error);
  });
}*/
export const getMetadataByUri = (uri) => {
  axios.get(uri)
    .then((response) => {
      // handle success
      //console.log("Res: ", response);
    })
    .catch((error) => {
      // handle error
      //console.log("Err: ", error);
    })
    .then(() => {
      //console.log("Do yomething")
      // always executed
    });
  }
////////////////////////////////////////////////////////////////////////////////////////////
//                                    TRANSACTIONS                                        //
////////////////////////////////////////////////////////////////////////////////////////////

////// GET All Transactions
export const getAllTransactions = (JWToken, querryParams) => {

  const path = '/transactions'
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: querryParams,
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// GET Transaction by Id
export const getTransaction = (JWToken, transactionId, querryParams) => {

  const path = `/transactions/${transactionId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: querryParams,
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////////////////////////////////////////////////////////////////////////////////////////////
//                                        USERS                                           //
////////////////////////////////////////////////////////////////////////////////////////////

////// GET Users by filter
export const getAllUsers = (JWToken, querryParams) => {

  const path = '/users'
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: querryParams,
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// GET one user
export const getUser = (JWToken, userId) => {

  const path = `/users/${userId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// ADD one user profile
export const addUser = (JWToken, userData) => {

  const path = `/users`
  const myInit = { // OPTIONAL
      body: userData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .post(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// UPDATE one user profile
export const updateUser = (JWToken, userId, userData) => {

  const path = `/users/${userId}`
  const myInit = { // OPTIONAL
      body: userData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .put(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// Delete User
export const deleteUser = (JWToken, userId) => {

  const path = `/users/${userId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .del(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// Pin Asset to Profile
export const pinAssetToUserProfile = (JWToken, userId, assetData) => {

  const path = `/users/${userId}/pinned`
  const myInit = { // OPTIONAL
      body: assetData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .post(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

export const unpinUtilityFromUser = (JWToken, userId, utilityId) => {

  const path = `/users/${userId}/pinned/utilities/${utilityId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .del(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

export const unpinResourceFromUser = (JWToken, userId, resourceId) => {

  const path = `/users/${userId}/pinned/resource/${resourceId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .del(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

export const unpinTokenFromUser = (JWToken, userId, tokenId) => {

  const path = `/users/${userId}/pinned/tokens/${tokenId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .del(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};




////// Get Projects of user Watchlist
export const getUserProjectWatchlist = (JWToken, userId, querryParams) => {

  const path = `/users/${userId}/watchlistProjects`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: querryParams,
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// GET user settings
export const getUserSettings = (JWToken, userId) => {

  const path = `/users/${userId}/settings`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// UPDATE user settings
export const updateUserSettings = (JWToken, userId, settingData) => {

  const path = `/users/${userId}/settings`
  const myInit = { // OPTIONAL
      body: settingData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .put(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////////////////////////////////////////////////////////////////////////////////////////////
//                                      UTILITIES                                         //
////////////////////////////////////////////////////////////////////////////////////////////

////// GET utilities
export const getAllUtilities = (JWToken, querryParams) => {

  const path = '/utilities'
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: querryParams,
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};


////// GET one utility
export const getUtility = (JWToken, utilityId) => {

  const path = `/utilities/${utilityId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// UPDATE one utility
export const updateUtility = (JWToken, utilityId, utilityData) => {

  const path = `/utilities/${utilityId}`
  const myInit = { // OPTIONAL
    body: utilityData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .put(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// Create Utility
export const createUtility = (JWToken, utilityData) => {

  const path = '/utilities'
  const myInit = { // OPTIONAL
      body: utilityData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .post(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// Delete Utility
export const deleteUtility = (JWToken, utilityId) => {

  const path = `/utilities/${utilityId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .del(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////////////////////////////////////////////////////////////////////////////////////////////
//                                       WALLETS                                          //
////////////////////////////////////////////////////////////////////////////////////////////

////// GET wallets
export const getAllWallets = (JWToken, querryParams) => {

  const path = '/wallets'
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: querryParams,
    };

  return API
    .get(apiName, path, myInit)
    .then(response => {
      //console.log("API response: ", response);
      return response;
    })
    .catch(error => {
      //console.log("API error: ", error.response);
      return error.response;
    });
};

////// GET Supply
export const getSupply = (JWToken, walletId, querryParams) => {

  const path = `/wallets/${walletId}/supplies`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: querryParams,
    };

  return API
    .get(apiName, path, myInit)
    .then(response => {
      //console.log("API response: ", response);
      return response;
    })
    .catch(error => {
      //console.log("API error: ", error.response);
      return error.response;
    });
};


////// GET one wallet
export const getWallet = (JWToken, walletId) => {

  const path = `/wallets/${walletId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// GET balance
export const getBalances = (JWToken, walletId) => {

  const path = `/wallets/${walletId}/balances`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    // console.log("API error balances: ", error.response);
    return error.response;
  });
};

////// GET blueprints
export const getBlueprints = (JWToken, projectId, querryParams) => {

  const path = `/projects/${projectId}/blueprints`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: querryParams
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    // console.log("API error blueprints: ", error.response);
    return error.response;
  });
};

////// GET blueprints
export const getBlueprint = (JWToken, walletId, querryParams) => {

  const path = `/wallets/${walletId}/blueprints`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: querryParams
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    // console.log("API error blueprints: ", error.response);
    return error.response;
  });
};

////// GET platforms
export const getPlatforms = (JWToken, walletId) => {

  const path = `/wallets/${walletId}/platforms`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    // console.log("API error blueprints: ", error.response);
    return error.response;
  });
};

////// Verify Wallet
export const verifyWallet = (JWToken, walletId, walletData) => {

  const path = `/wallets/${walletId}/verification`
  const myInit = { // OPTIONAL
      body: walletData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .post(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// Update Wallet
export const addWallet = (JWToken, walletData) => {

  const path = '/wallets'
  const myInit = { // OPTIONAL
      body: walletData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .post(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// Delete Wallet
export const updateWallet = (JWToken, walletId, walletData) => {

  const path = `/wallets/${walletId}`
  const myInit = { // OPTIONAL
      body: walletData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .put(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// Delete Wallet
export const unLinkUserWallet = (JWToken, walletId) => {

  const path = `/wallets/${walletId}/unlink`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .post(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};


////// Delete Wallet
export const deleteWallet = (JWToken, walletId) => {

  const path = `/wallets/${walletId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .del(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////////////////////////////////////////////////////////////////////////////////////////////
//                                       ENJIN APPS                                       //
////////////////////////////////////////////////////////////////////////////////////////////

////// GET wallets
export const getAllEnjinApps = (JWToken, querryParams) => {

  const path = '/enjinapps'
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: querryParams,
    };

  return API
    .get(apiName, path, myInit)
    .then(response => {
      //console.log("API response: ", response);
      return response;
    })
    .catch(error => {
      //console.log("API error: ", error.response);
      return error.response;
    });
};

////// GET Supply
export const postEnjinApp = (JWToken, appData) => {

  const path = `/enjinapps`
  const myInit = { // OPTIONAL
      body: appData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    };

  return API
    .post(apiName, path, myInit)
    .then(response => {
      //console.log("API response: ", response);
      return response;
    })
    .catch(error => {
      //console.log("API error: ", error.response);
      return error.response;
    });
};


////// GET one wallet
export const getEnjinAppById = (JWToken, appId) => {

  const path = `/enjinapps/${appId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .get(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// GET balance
export const updateEnjinAppById = (JWToken, appId, appData) => {

  const path = `/enjinapps/${appId}`
  const myInit = { // OPTIONAL
      body: appData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .put(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};

////// GET blueprints
export const deleteEnjinAppById = (JWToken, appId, querryParams) => {

  const path = `/enjinapps/${appId}`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      queryStringParameters: querryParams,
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };

  return API
  .del(apiName, path, myInit)
  .then(response => {
    //console.log("API response: ", response);
    return response;
  })
  .catch(error => {
    //console.log("API error: ", error.response);
    return error.response;
  });
};


////////////////////////////////////////////////////////////////////////////////////////////
//                                       MESSAGES                                      //
////////////////////////////////////////////////////////////////////////////////////////////

////// GET chat Users
export const getAllChatUsers = (JWToken, querryParams) => {

  const path = '/messages/conversations'
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: querryParams,
    };

  return API
    .get(apiName, path, myInit)
    .then(response => {
      console.log("API response für Baschdi: ", response);
      return response;
    })
    .catch(error => {
      //console.log("API error: ", error.response);
      return error.response;
    });
};

export const updateAllChatUsers = (JWToken, userId, messageData) => {

  const path = `/messages/conversations/${userId}`
  const myInit = { // OPTIONAL
      body: messageData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    };

  return API
    .put(apiName, path, myInit)
    .then(response => {
      //console.log("API response: ", response);
      return response;
    })
    .catch(error => {
      //console.log("API error: ", error.response);
      return error.response;
    });
};

////// GET Supply
export const getConversation = (JWToken, querryParams) => {

  const path = `/messages`
  const myInit = { // OPTIONAL
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: querryParams,
    };

  return API
    .get(apiName, path, myInit)
    .then(response => {
      //console.log("API response: ", response);
      return response;
    })
    .catch(error => {
      //console.log("API error: ", error.response);
      return error.response;
    });
};

export const postMessage = (JWToken, messageData) => {

  const path = `/messages`
  const myInit = { // OPTIONAL
      body: messageData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    };

  return API
    .post(apiName, path, myInit)
    .then(response => {
      //console.log("API response: ", response);
      return response;
    })
    .catch(error => {
      //console.log("API error: ", error.response);
      return error.response;
    });
};

export const updateMessageStatus = (JWToken, messageId, messageData) => {

  const path = `/messages/${messageId}`
  const myInit = { // OPTIONAL
      body: messageData,
      headers: {
          // Accept: "application/json",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${JWToken}`
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    };

  return API
    .post(apiName, path, myInit)
    .then(response => {
      //console.log("API response: ", response);
      return response;
    })
    .catch(error => {
      //console.log("API error: ", error.response);
      return error.response;
    });
};
