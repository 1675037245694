import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'store'
import { DetailPair } from './sharedComponents/detailPair/detailPair'

interface IsProps {
  id: string | number
}

export const ProjectDetails = ({ id }: IsProps) => {
  const data = useSelector(
    (state: AppState) => state.networkVisualizerState.general.data.projects[id]
  )

  let { websiteUrl } = data

  return (
    <>
      <DetailPair title='Website' value={websiteUrl} link={websiteUrl} />
    </>
  )
}
