import { fullscreenIcon } from 'components/networkVisualizer/assets/icons'
import { toggleFullScreen } from 'components/networkVisualizer/networkVisualizerState/store/general/actions'
import React from 'react'
import { AppState } from 'store'
import { useDispatch, useSelector } from 'react-redux'

const _FullScreenButton = () => {
  const dispatch = useDispatch()

  const {initURL} = useSelector((state: AppState)=>state.auth);

  const onClick = () => dispatch(toggleFullScreen())

  return (
    <div onClick={onClick} className="full-screen-button">
      {initURL !== "/explore" ? 
      <img src={fullscreenIcon} alt="full screen button" />:null}
    </div>
  )
}

export const FullScreenButton = React.memo(_FullScreenButton)
