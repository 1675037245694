import React from 'react';
import {Link} from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IntlMessages from 'util/IntlMessages';

import {useDispatch, useSelector} from 'react-redux';

import {
    deleteUserAccount_
} from 'actions/Account'



const CardMenu = (props) => {

    const dispatch = useDispatch();

    const JWToken = localStorage.getItem('idToken');

    const {imageLink, username, menuState, anchorEl, handleRequestClose} = props;

    const userName = username;

    const deleteSelectedUser = () => {

        // dispatch(deleteUserAccount_({JWToken, userName}));
    }



    
    return (
        <Menu id="long-menu"
              anchorEl={anchorEl}
              open={menuState}
              onClose={handleRequestClose}

              MenuListProps={{
                  style: {
                      width: 150,
                      paddingTop: 0,
                      paddingBottom: 0
                  },
              }}>

                    <MenuItem onClick={handleRequestClose}>
                        <Link to={{
                            pathname:`./useraccounts/${userName}`, 
                            data: {
                                imageLink: imageLink
                                }
                            }}><IntlMessages id="popup.edit"/></Link>
                    </MenuItem>
                    <MenuItem onClick={handleRequestClose, deleteSelectedUser}>
                        <Link to={`./useraccounts`}><IntlMessages id="popup.delete"/></Link>
                    </MenuItem>

        </Menu>
    );
};

export default CardMenu;