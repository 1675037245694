import {
    HIDE_MESSAGE,
    INIT_URL,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    SIGNIN_FACEBOOK_USER,
    SIGNIN_FACEBOOK_USER_SUCCESS,
    SIGNIN_GITHUB_USER,
    SIGNIN_GITHUB_USER_SUCCESS,
    SIGNIN_GOOGLE_USER,
    SIGNIN_GOOGLE_USER_SUCCESS,
    SIGNIN_TWITTER_USER,
    SIGNIN_TWITTER_USER_SUCCESS,
    SIGNIN_USER,
    SIGNIN_USER_SUCCESS,
    SIGNIN_USER_UNVERIFIED,
    SIGNOUT_USER,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER,
    SIGNUP_USER_SUCCESS,
    SEND_CODE,
    SEND_CODE_SUCCESS,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    LOAD_USER_ACCOUNT_SUCCESS,
    GET_AUTH_USER,
    GET_AUTH_USER_SUCCESS,
    CLEAR_AUTHUSER,
    RESET_PASSWORD_CANCEL,
    CONFIRM_USER,
    CONFIRM_USER_SUCCESS,
    SIGNIN_GUEST,
    SIGNIN_GUEST_SUCCESS,
    SHOW_ERROR_PAGE,
    LOAD_EXPLORER_SUCCESS,
    AUTHUSERS_REFRESHED,
    AUTHUSER_REFRESHED
} from 'constants/ActionTypes';

export const userSignUp = (user) => {
    return {
        type: SIGNUP_USER,
        payload: user
    };
};
export const confirmUser = (user) => {
    return {
        type: CONFIRM_USER,
        payload: user
    };
};
export const confirmUserSuccess = (user) => {
    return {
        type: CONFIRM_USER_SUCCESS,
        payload: user
    };
};
export const userSignIn = (user) => {
    return {
        type: SIGNIN_USER,
        payload: user
    };
};
export const guestSignIn = () => {
    return {
        type: SIGNIN_GUEST
    };
};
export const getAuthUser = (data) => {
    return {
        type: GET_AUTH_USER,
        payload: data
    };
};

export const getAuthUserSuccess = (user) => {
    return {
        type: GET_AUTH_USER_SUCCESS,
        payload: user
    };
};

export const userSignOut = () => {
    return {
        type: SIGNOUT_USER
    };
};
export const userSignUpSuccess = (authUser) => {
    return {
        type: SIGNUP_USER_SUCCESS,
        payload: authUser
    };
};

export const userSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_USER_SUCCESS,
        payload: authUser
    }
};

export const guestSignInSuccess = (guestUser) => {
    return {
        type: SIGNIN_GUEST_SUCCESS,
        payload: guestUser
    }
};

export const loadUserAccountSuccess = (authUser) => {
    return {
        type: LOAD_USER_ACCOUNT_SUCCESS,
        payload: authUser
    }
}

export const userSignInUnverified = (authUser) => {
    return {
        type: SIGNIN_USER_UNVERIFIED,
        payload: authUser
    }
};

export const sendCode = (user) => {
    return {
        type: SEND_CODE,
        payload: user
    }
};

export const sendCodeSuccess = (user) => {
    return {
        type: SEND_CODE_SUCCESS,
        payload: user
    }
};


export const resetUserPassword = (user) => {
    return {
        type: RESET_PASSWORD,
        payload: user
    }
};

export const resetPasswordSuccess = (user) => {
    return {
        type: RESET_PASSWORD_SUCCESS,
        payload: user
    }
}

export const resetPasswordCancel = () => {
    return {
        type: RESET_PASSWORD_CANCEL,
    }
}


export const userSignOutSuccess = () => {
    return {
        type: SIGNOUT_USER_SUCCESS,
    }
};

export const clearAuthUser = () => {
    return {
        type: CLEAR_AUTHUSER,
    }
};

export const showAuthMessage = (message) => {
    return {
        type: SHOW_MESSAGE,
        payload: message
    };
};




export const userGoogleSignIn = () => {
    return {
        type: SIGNIN_GOOGLE_USER
    };
};
export const userGoogleSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_GOOGLE_USER_SUCCESS,
        payload: authUser
    };
};
export const userFacebookSignIn = () => {
    return {
        type: SIGNIN_FACEBOOK_USER
    };
};
export const userFacebookSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_FACEBOOK_USER_SUCCESS,
        payload: authUser
    };
};
export const setInitUrl = (url) => {
    return {
        type: INIT_URL,
        payload: url
    };
};
export const userTwitterSignIn = () => {
    return {
        type: SIGNIN_TWITTER_USER
    };
};
export const userTwitterSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_TWITTER_USER_SUCCESS,
        payload: authUser
    };
};
export const userGithubSignIn = () => {
    return {
        type: SIGNIN_GITHUB_USER
    };
};
export const userGithubSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_GITHUB_USER_SUCCESS,
        payload: authUser
    };
};
export const showAuthLoader = () => {
    return {
        type: ON_SHOW_LOADER,
    };
};

export const hideMessage = () => {
    return {
        type: HIDE_MESSAGE,
    };
};
export const hideAuthLoader = () => {
    return {
        type: ON_HIDE_LOADER,
    };
};

export const showErrorPage_ = (bool) => {
    return {
        type: SHOW_ERROR_PAGE,
        payload: bool
    };
};

/// EXPLORER

export const loadExplorerSuccess_ = (bool) => {
    return {
        type: LOAD_EXPLORER_SUCCESS,
        payload: bool
    };
};

export const authUsersRefreshed_ = (data) => {
    return {
        type: AUTHUSERS_REFRESHED,
        payload: data
    };
};


export const authUserRefreshed_ = (data) => {
    return {
        type: AUTHUSER_REFRESHED,
        payload: data
    };
};

