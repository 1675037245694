import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'store'

interface IsProps {
  noConnections: boolean
}

export const NoConnectionsPlaceholder = React.memo(
  ({ noConnections }: IsProps) => {
    const fetching = useSelector(
      (state: AppState) => state.networkVisualizerState.general.fetching
    )

    if (!noConnections || fetching) return null

    return (
      <div className='no-connections-placeholder'>
        <p>No connections found</p>
        <div className='outer-ring'>
          <div className='orb-line' />
          <div className='orb' />
        </div>
      </div>
    )
  }
)
