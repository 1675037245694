import { CLEAR_LINKING_DIALOGUE_ENJINAPP, DELETE_ENJINAPP_SUCCESS, ENJAPPS_REFRESHED, ENJAPP_REFRESHED, GET_ALL_ENJINAPPS_SUCCESS, GET_ENJINAPP_SUCCESS, LINK_ENJINAPP_SUCCESS, UPDATE_ENJINAPP_SUCCESS } from "constants/ActionTypes";


const INIT_STATE = {

    enjinAppList: [],
    addedEnjinApp: '',
    enjinAppLinkingData:'',
    linkingDialogueEnjinApp: 'enter',
    enjAppRefreshed: false,
    enjAppsRefreshed: false
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_ENJINAPPS_SUCCESS: {
            return {
                ...state,
                enjinAppList: action.payload
            }
        }
        case GET_ENJINAPP_SUCCESS: {
            return {
                ...state,
                enjinAppLinkingData: action.payload
            }
        }
        case LINK_ENJINAPP_SUCCESS: {
            return {
                ...state,
                linkingDialogueEnjinApp: 'code',
                addedEnjinApp: action.payload
            }
        }
        case UPDATE_ENJINAPP_SUCCESS: {
            return {
                ...state,
            }
        }
        case DELETE_ENJINAPP_SUCCESS: {
            return {
                ...state,
            }
        }

        case CLEAR_LINKING_DIALOGUE_ENJINAPP: {
            return {
                ...state,
                addedEnjinApp: '',
                linkingDialogueEnjinApp: 'enter'
            }
        }
        case ENJAPPS_REFRESHED: {
            return {
                ...state,
                enjAppsRefreshed: action.payload
            }
        }
        case ENJAPP_REFRESHED: {
            return {
                ...state,
                enjAppRefreshed: action.payload
            }
        }

        default:
            return state;
    }
}

