import {
    HIDE_ALERT_MSG,
    VIEW_ALERT_MSG
} from "constants/ActionTypes";

const INIT_STATE = {
    viewMessage: false,
    alertMessage: ''

    
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case VIEW_ALERT_MSG: {
            return {
                ...state,
                viewMessage: true,
                alertMessage: action.payload
            }
        }

        case HIDE_ALERT_MSG: {
            return {
                ...state,
                viewMessage: false,
                alertMessage: ''
            }
        }
        default:
            return state;
    }
}