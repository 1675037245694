import { API } from 'components/networkVisualizer/API/api'
import { searchAssetsFormatter } from 'components/networkVisualizer/networkVisualizerState/helpers/dataFormatters/searchAssetsFormatter/'
import { call, SagaReturnType } from 'redux-saga/effects'
import { CategoryTypes } from '../../../sharedTypes'
import { getCooperationsFormatter } from '../../dataFormatters/getCooperationsFormatter'

type ProjectsReturnType = ReturnType<typeof searchAssetsFormatter.toGeneralData>

type AssetsReturnType = ReturnType<
  typeof getCooperationsFormatter.toGeneralData
>

export type FetchAndFormatConnections = ProjectsReturnType | AssetsReturnType

/**
 * Helper function that fetches connections, formats them, and returns {data, connections}
 */
export function* fetchAndFormatConnections(
  id: string | number,
  type: CategoryTypes,
  expectedTypeKey: CategoryTypes,
  offset?: number,
  limit?: number,
  platformId?: string | number
) {
  if (type === 'projects') {
    const newConnectionsResponse: SagaReturnType<typeof API.searchAssets> = yield call(
      API.searchAssets,
      expectedTypeKey,
      { projectId: id, offset, limit }
    )

    const data: ProjectsReturnType = yield call(
      searchAssetsFormatter.toGeneralData,
      newConnectionsResponse.data
    )

    return data
  } else {
    const newConnectionsResponse: SagaReturnType<typeof API.getCooperations> = yield call(
      //@ts-ignore
      API.getCooperations,
      type,
      id,
      expectedTypeKey,
      { offset, limit },
      platformId
    )

    const data: AssetsReturnType = yield call(
      getCooperationsFormatter.toGeneralData,
      newConnectionsResponse.data
    )

    return data
  }
}
