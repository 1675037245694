export interface WalletInputState {
  walletId: string | number
  visible: boolean
}

export const SET_WALLET_INPUT_VALUE = 'SET_WALLET_INPUT_VALUE'

export interface SetWalletInputValue {
  type: typeof SET_WALLET_INPUT_VALUE
  payload: string | number
}

export const TOGGLE_WALLET_INPUT_VISIBILITY = 'TOGGLE_WALLET_INPUT_VISIBILITY'

export interface ToggleWalletInputVisibility {
  type: typeof TOGGLE_WALLET_INPUT_VISIBILITY
  payload?: boolean
}

export type WalletInputStateActions =
  | SetWalletInputValue
  | ToggleWalletInputVisibility

/**
 *
 * Redux Saga Actions
 *
 */

export const CHANGE_WALLET_INPUT_VALUE = 'CHANGE_WALLET_INPUT_VALUE'

export interface ChangeWalletInputValue {
  type: typeof CHANGE_WALLET_INPUT_VALUE
  payload: string | number
}

export const SUBMIT_WALLET_INPUT = 'SUBMIT_WALLET_INPUT'

export interface SubmitWalletInput {
  type: typeof SUBMIT_WALLET_INPUT
}
