import tokenIcon from 'assets/images/token_icon.png';
import utilityIcon from 'assets/images/utility_icon.png';
import resourceIcon from 'assets/images/resource_icon.png';
import unknownIcon from 'assets/images/unknown_icon.png';
import projectIcon from 'assets/images/project_icon.png';
import unknownBanner from 'assets/images/unknown_banner.png';
import fileIcon from 'assets/images/file_icon.png';

export const formatAssetData = (assetData, assetType) => {
    let asset = {};
    if (assetType === "tokens") {

        console.log("asset", assetData);
    
        let tokenType;
        let assetTitle;
        let assetImage;
        let assetDescription;
        let assetProperties;

        if (assetData.nonFungible) {
            tokenType = "NFT"
        } else {
            tokenType = "FT"
        }

        if (assetData.metadata) {
            assetImage = assetData.metadata.image || tokenIcon;
            assetTitle = assetData.metadata.name || assetData.name;
            assetDescription = assetData.metadata.description || "Such an awesome asset doesn't need a description";
            assetProperties = assetData.metadata.properties || null;
        } else {
            assetImage = tokenIcon;
            assetTitle = assetData.name || "Unknown";
            assetDescription = "Such an awesome asset doesn't need a description";
            assetProperties = null;
        }

        asset = {
            id : assetData.tokenId,
            platformId : assetData.platform.platformId,
            type : assetType,
            typeIcon: tokenIcon,
            title : assetTitle,
            typeName : "Token",
            description : assetDescription,
            image : assetImage,
            // footage : tokenBlueprint.footage.images,
            projectId: assetData.project?.projectId || 0,
            projectName : assetData.project?.name || "unknown",
            projectImage : assetData.project?.avatarImage ? assetData.project?.avatarImage.fullsize : projectIcon,
            userName : assetData.project?.user.displayName || "unknown",
            userId : assetData.project?.user.userId || "unknown",
            userAvatar: assetData.project?.user.avatarImage ? assetData.project?.user.avatarImage.thumbnail : unknownIcon,
            // projectBanner : tokenBlueprint.project.bannerImage,
            color : "#2196f3",
            primaryTagTitle : "Token Type",
            primaryTagValue : tokenType,
            primaryTagIcon : "https://cdn.imgbin.com/17/3/12/imgbin-artist-painting-computer-icons-painting-Fc8A0hPMKGcMM5YmaLGguqqEL.jpg",
            secondaryTagTitle : 'Token ID',
            secondaryTagValue : assetData.tokenId,
            assetTypeIcon : tokenIcon,
            secondaryTagIcon : "https://community.wolfram.com/c/portal/getImageAttachment?filename=ScreenShot2013-12-29at09.04.36.png&userId=63046",
            totalSupply : assetData.totalSupply,
            circulatingSupply : assetData.circulatingSupply,
            cooperations : assetData.cooperations,
            creatorAddress: assetData.creatorAddress,
            supplyModel: assetData.supplyModel,
            meltValue: assetData.meltValue,
            meltFeeRatio: assetData.meltFeeRatio,
            transferable: assetData.transferable,
            transferFeeType: assetData.transferFeeSettings?.type,
            transferFeeValue: assetData.transferFeeSettings?.value,
            transferFeeTokenId: assetData.transferFeeSettings.tokenId,
            properties: assetProperties,
            tags: assetData.tags || []

        }
    }
    
    if (assetType === "utilities") {
        asset = {
            id : Number(assetData.utilityId),
            type : assetType,
            typeIcon: utilityIcon,
            title : assetData.name,
            typeName : "Utility",
            description : assetData.description || "Such an awesome asset doesn't need a description",
            image : assetData.avatarImage?.fullsize || utilityIcon,
            footage : assetData.footage.images,
            videos: assetData.footage.videos,
            projectId : assetData.projectId,
            projectName : assetData.project.name,
            projectImage : assetData.project.avatarImage ? assetData.project.avatarImage.thumbnail : projectIcon,
            projectBanner : assetData.project.bannerImage ? assetData.project.bannerImage.fullsize : unknownBanner,
            userName : assetData.project.creatorId,
            userId : assetData.project.user.userId,
            color : "#e91e63",
            primaryTagTitle : "Utility Type",
            primaryTagValue : assetData.type || "Game Item",
            primaryTagIcon : "https://cdn4.vectorstock.com/i/1000x1000/14/18/gamepad-controller-icon-vector-25671418.jpg",
            secondaryTagTitle : 'Status',
            secondaryTagValue : assetData.status,
            assetTypeIcon : utilityIcon,
            secondaryTagIcon : "https://community.wolfram.com/c/portal/getImageAttachment?filename=ScreenShot2013-12-29at09.04.36.png&userId=63046",
            cooperations : assetData.cooperations || [],
            tags: assetData.tags || []
        }
    }
                
    if (assetType === "resources") {
        asset = {
            id : Number(assetData.resourceId),
            type : assetType,
            typeIcon: resourceIcon,
            title : assetData.name,
            typeName : "Resource",
            description : assetData.description || "Such an awesome asset doesn't need a description",
            image : assetData.avatarImage ? assetData.avatarImage.fullsize : resourceIcon,
            footage : assetData.footage.images,
            videos: assetData.footage.videos,
            projectId : assetData.projectId,
            projectName : assetData.project.name,
            projectImage : assetData.project.avatarImage ? assetData.project.avatarImage.thumbnail : projectIcon,
            projectBanner : assetData.project.bannerImage ? assetData.project.bannerImage.fullsize : unknownBanner,
            userName : assetData.project.creatorId,
            userId : assetData.project.user.userId,
            color : "#ff9800",
            primaryTagTitle : "Category",
            primaryTagValue : assetData.category || "unknown",
            primaryTagIcon : "https://cdn.imgbin.com/17/3/12/imgbin-artist-painting-computer-icons-painting-Fc8A0hPMKGcMM5YmaLGguqqEL.jpg",
            secondaryTagTitle : 'File Type',
            secondaryTagValue : assetData.fileType || "unknown",
            assetTypeIcon : resourceIcon,
            secondaryTagIcon : fileIcon,
            cooperations : assetData.cooperations,
            tags: assetData.tags || []
        }
    }

    return asset;
}


