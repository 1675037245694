import {all, call, delay, fork, put, select, takeEvery, takeLatest} from "redux-saga/effects";

import {
    CREATE_PROJECT,
    CREATE_PROJECT_SUCCESS,
    GET_ALL_PROJECTS,
    GET_ALL_PROJECTS_SUCCESS,
    GET_PROJECT,
    GET_PROJECT_SUCCESS,
    DELETE_PROJECT,
    DELETE_PROJECT_SUCCESS,
    ADD_PROJECT_EDITOR,
    ADD_PROJECT_EDITOR_SUCCESS,
    GET_PROJECT_FOLLOWERS,
    GET_PROJECT_FOLLOWERS_SUCCESS,
    FOLLOW_PROJECT,
    FOLLOW_PROJECT_SUCCESS,
    GET_ALL_PROJECT_EDITORS,
    GET_ALL_PROJECT_EDITORS_SUCCESS,
    DELETE_PROJECT_EDITOR,
    DELETE_PROJECT_EDITOR_SUCCESS,
    UNFOLLOW_PROJECT,
    UNFOLLOW_PROJECT_SUCCESS,
    UPDATE_PROJECT,
    GET_PROJECT_SETTINGS,
    UPDATE_PROJECT_SETTINGS,
    GET_PROJECT_APIKEYS,
    CREATE_PROJECT_APIKEYS,
    DELETE_PROJECT_APIKEYS,
    GET_PROJECT_PROFILE,
    PIN_ASSET_TO_PROJECT,
    UNPIN_ASSET_FROM_PROJECT,
    GET_PROJECT_API_SETTINGS,
    UPDATE_PROJECT_API_SETTINGS,
    SELECT_PROJECT,
    GET_GAS_PRICE,
    GET_ALL_PROJECT_BLUEPRINTS,
    CACHE_PROJECT_BLUEPRINTS
} from "constants/ActionTypes";
import {
    createProject_,
    createProjectSuccess_,
    getAllProjects_,
    getAllProjectsSuccess_,
    getProject_,
    getProjectSuccess_,
    updateProject_,
    updateProjectSuccess_,
    deleteProject_,
    deleteProjectSuccess_,
    addProjectEditor_,
    addProjectEditorSuccess_,
    getProjectFollowers_,
    getProjectFollowersSuccess_,
    followProject_,
    followProjectSuccess_,
    getAllProjectEditors_,
    getAllProjectEditorsSuccess_,
    deleteProjectEditor_,
    deleteProjectEditorSuccess_,
    unfollowProject_,
    unfollowProjectSuccess_,
    getProjectSettingsSuccess_,
    updateProjectSettingsSuccess_,
    createProjectApikeysSuccess_,
    deleteProjectApikeysSuccess_,
    getProjectApikeysSuccess_,
    getProjectProfileSuccess_,
    getProjectApiSettingsSuccess_,
    updateProjectApiSettings_,
    updateProjectApiSettingsSuccess_,
    getAllProjectsForRequestSuccess_,
    getGasPriceSuccess_,
    pinnedToProjectSuccess_,
    unpinnedFromProjectSuccess_,
    projectsRefreshed_,
    projectRefreshed_,
    apiSettingsRefreshed_,
    settingsRefreshed_,
    getAllProjectBlueprintsSuccess_,
    cacheProjectBlueprintsSuccess_
} from "actions/Project";
import {
    showAuthMessage,
    userSignInSuccess, 
    userSignInUnverified, 
    userSignOutSuccess, 
    userSignUpSuccess, 
    sendCodeSuccess, 
    resetPasswordSuccess,
    loadUserAccountSuccess,
    getAuthUserSuccess,
    userSignOut
} from "actions/Auth";
import {
    getAllProjects,
    postProject,
    getProject,
    updateProject,
    deleteProject,
    addProjectEditor,
    getAllProjectEditors,
    deleteProjectEditor,
    getProjectFollowers,
    followProject,
    getProjectSettings,
    updateProjectSettings,
    getProjectApikey,
    postProjectApikey,
    deleteProjectApikey,
    pinAssetToProjectProfile,
    unpinTokenFromProject,
    unpinUtilityFromProject,
    unpinResourceFromProject,
    getProjectApiSettings,
    updateProjectApiSettings,
    getBlueprints,
    getGasPrice,
    getAllProjectBlueprints,
    cacheProjectBlueprints
} from "../api";

import { Auth } from 'aws-amplify';
import { hideDashbaordLoader_, hideDialogueLoader_, hideIsFetching_, hideLocalFetching_, hideMainLoader_, hideMiniLoader_, hideScrollLoader_, hideTransparentLoader_, isLoadingMore_, showDashbaordLoader_, showDialogueLoader_, showIsFetching_, showLoaderCreateSuccess_, showLoaderDeleteSuccess_, showLoaderUpdateSuccess_, showLocalFetching_, showMainLoader_, showMiniLoader_, showScrollLoader_, showTransparentLoader_ } from "actions/IsFetching";
import { createNewImage } from "./Img";
import { updateImageSuccess_ } from "actions/Img";
import { errorHandler, successHandler } from "util/messageHandler";
import { viewAlertMessage_ } from "actions/Alert";
import { getSelectedBlueprintsSuccess_ } from "actions/Wallet";

// Get JWToken from Local Storage

function* createProjectWithName({payload}) {
    const {JWToken, projectData, imgData, refresh, noLoader} = payload;

    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showMainLoader_())
            } 
        }
        const apiResponse = yield call(postProject, JWToken, projectData);
        
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            console.log("project create error: ", apiResponse);
            // alert message
            const errorMessage = yield call(errorHandler, apiResponse);
            yield put(viewAlertMessage_(errorMessage)); 

            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse.data);
            if (imgData) {
                console.log("imgData: ", imgData);
                imgData.projectId = apiResponse.data.projectId;
                // console.log("imgData in Saga (after): ", imgData);
                const downloadUrl = yield call(createNewImage, JWToken, imgData);
                // console.log("image data exists");
                yield delay(2500);
                
                yield put(projectsRefreshed_(true));

                // yield put(successAction(apiResponse.error));  
                yield put(updateImageSuccess_(downloadUrl));
                // alert message   
                const successMessage = yield call(successHandler,"Project", "", "created");
                yield put(viewAlertMessage_(successMessage)); 
            } else {
                // console.log("image data does not exist");
                yield delay(2500);

                yield put(projectsRefreshed_(true));
                // yield put(successAction(apiResponse.error));  
                yield put(createProjectSuccess_()); 
                // alert message   
                const successMessage = yield call(successHandler,"Project", "", "created");
                yield put(viewAlertMessage_(successMessage)); 
            }
            
            
                 
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideMainLoader_());
    }
}


// Create New Project via API call
const api_getProjects = async (JWToken, userId) => {
    const response = new Object;
    try {
        const projectsList = await getAllProjects(JWToken, userId);
        response.projects = projectsList;
        // console.log("API RESPONSE: ", response.projects);
    } catch (error) {
        response.error = error;
        // console.log('error create project:', error);
    }
    return response;
}


function* getAllProjectsByUser({payload}) {
    const {JWToken, querryParams, forRequest, loadMore, refresh, noLoader} = payload;
    const projectList = yield select(state => state.project.projectList)

    // console.log("forRequest: ", forRequest);
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                if (!forRequest) {
                    yield put(showMainLoader_());
                }
            } 
        }
        

        if (!forRequest && loadMore) {
            yield put(showScrollLoader_());
            yield put(isLoadingMore_(true));
        }
        
        const apiResponse = yield call(getAllProjects, JWToken, querryParams);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            if (forRequest) {
                yield put(getAllProjectsForRequestSuccess_(apiResponse));
            } else {
                if (loadMore) {
                    yield put(getAllProjectsSuccess_([...projectList, ...apiResponse.data.projects])); 
                } else {
                    yield put(getAllProjectsSuccess_(apiResponse.data.projects));
                }
                
            }
            

            // yield put(successAction(apiResponse.error));        
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        if (!forRequest) {
            yield put(hideMainLoader_());
            yield put(hideTransparentLoader_());
            yield put(hideScrollLoader_());
            yield put(isLoadingMore_(false));
        }
        
    }
}

function* selectProjectByUser({payload}) {
    const {JWToken, querryParams, projectId, noLoader} = payload;

    try {
        if (!noLoader) {
            yield put(showMiniLoader_());
        }
        
        const apiResponse = yield call(getProject, JWToken, projectId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            if (apiResponse.data.projectId) {
                querryParams.projectId = apiResponse.data.projectId;
                querryParams.cached = true;
                // let walletId = apiResponse.data.walletId
                console.log("saga select project triggered!");
                const apiResponseBP = yield call(getAllProjectBlueprints, JWToken, projectId, querryParams);
        
                if (apiResponseBP.status>200) {
                    console.log("apiResponse_error get all projects: ", apiResponse);
                    // yield put(errorAction(apiResponse.error));
                    yield put(getSelectedBlueprintsSuccess_('')); 
                } else {
                    // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
                    console.log("apiResponse: ", apiResponseBP);
                    yield put(getSelectedBlueprintsSuccess_(apiResponseBP.data.blueprints));     
                }
            } else {
                yield put(getSelectedBlueprintsSuccess_('')); 
            } 
            

            // yield put(successAction(apiResponse.error));        
        }
    } catch (error) {
        console.log("catchError get all projects: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideMiniLoader_());
    }
}

function* getProjectById({payload}) {
    const {JWToken, projectId, dashboard, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                if (dashboard) {
                    yield put(showDashbaordLoader_());
                } else {
                    yield put(showMainLoader_());
                }
            }
        }
  
        const apiResponse = yield call(getProject, JWToken, projectId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error_getProject: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse_getProject: ", apiResponse);
            yield put(getProjectSuccess_(apiResponse.data));
            // yield put(successAction(apiResponse.error));        
        }
    } catch (error) {
        console.log("catchError project: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideMainLoader_());
        yield put(hideDashbaordLoader_());
        yield put(hideTransparentLoader_());
    }

}

function* getProjectProfileById({payload}) {
    const {JWToken, projectId, dashboard, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                if (dashboard) {
                    yield put(showDashbaordLoader_());
                } else {
                    yield put(showMainLoader_());
                }
            }
        }

        const apiResponse = yield call(getProject, JWToken, projectId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error_getProject: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse_getProject: ", apiResponse);
            yield put(getProjectProfileSuccess_(apiResponse.data));
            // yield put(successAction(apiResponse.error));        
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideMainLoader_());
        yield put(hideDashbaordLoader_());
        yield put(hideTransparentLoader_());
    }

}

function* updateProjectById({payload}) {
    const {JWToken, projectId, projectData, noLoader} = payload;
    try {
        if (!noLoader) {
            yield put(showTransparentLoader_());
        }
        
        let fetchingResult = {};
        
        const apiResponse = yield call(updateProject, JWToken, projectId, projectData);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            

            fetchingResult.error = "Update Error";
            yield put(showLoaderUpdateSuccess_(fetchingResult));
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(projectRefreshed_(true));
            fetchingResult.success = "Success!";
            yield put(showLoaderUpdateSuccess_(fetchingResult));
            // yield put(getProjectSuccess_());
            // yield put(successAction(apiResponse.error));  
            
            // alert message   
            const successMessage = yield call(successHandler,"Project", "", "updated");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 

        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
    }

}
function* deleteProjectById({payload}) {
    const {JWToken, projectId, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        let fetchingResult = {};
        
        const apiResponse = yield call(deleteProject, JWToken, projectId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            fetchingResult.error = "Something went wrong. Please try again later.";
            yield put(showLoaderDeleteSuccess_(fetchingResult));
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(projectsRefreshed_(true));
            fetchingResult.success = "Project succesfully deleted!";
            yield put(showLoaderDeleteSuccess_(fetchingResult));
            yield put(deleteProjectSuccess_());
            // yield put(successAction(apiResponse.error));  
            
            // alert message   
            const successMessage = yield call(successHandler,"Project", "", "deleted");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideDialogueLoader_());
        yield put(hideTransparentLoader_());
    }

}
function* addEditorToProjectWithData({payload}) {
    const {JWToken, projectId, userData, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        const apiResponse = yield call(addProjectEditor, JWToken, projectId, userData);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 

            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(projectRefreshed_(true));
            yield put(projectsRefreshed_(true));
            yield put(addProjectEditorSuccess_());
            // yield put(successAction(apiResponse.error));   
            
            // alert message   
            const successMessage = yield call(successHandler,"Editor", "", "added");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 

        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideDialogueLoader_());
    }

}

function* getProjectEditorsById({payload}) {
    const {JWToken, projectId, querryParams} = payload;
    try {
        const apiResponse = yield call(getAllProjectEditors, JWToken, projectId, querryParams);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(getAllProjectEditorsSuccess_(apiResponse.data));
            // yield put(successAction(apiResponse.error));        
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    }

}

function* deleteProjectEditorById({payload}) {
    const {JWToken, projectId, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        const apiResponse = yield call(deleteProject, JWToken, projectId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 

            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(projectRefreshed_(true));
            yield put(projectsRefreshed_(true));
            yield put(deleteProjectEditorSuccess_()); 

            // alert message   
            const successMessage = yield call(successHandler,"Editor", "", "deleted");
            yield put(viewAlertMessage_(successMessage)); 

                   
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 

        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideDialogueLoader_());
    }

}


function* getProjectBlueprintsById({payload}) {
    const {JWToken, projectId, querryParams, dashboard, noLoader, refresh} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                if (dashboard) {
                    yield put(showDashbaordLoader_());
                } else {
                    yield put(showMainLoader_());
                }
            }
        }
        const apiResponse = yield call(getAllProjectBlueprints, JWToken, projectId, querryParams);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(getAllProjectBlueprintsSuccess_(apiResponse.data.blueprints));
            // yield put(successAction(apiResponse.error));        
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideMainLoader_());
        yield put(hideDashbaordLoader_());
    }

}


function* cacheProjectBlueprintsById({payload}) {
    const {JWToken, projectId} = payload;
    try {
        
        const apiResponse = yield call(cacheProjectBlueprints, JWToken, projectId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(cacheProjectBlueprintsSuccess_());
            // yield put(successAction(apiResponse.error));        
        }
    } catch (error) {
        console.log("catchError: ", error);
        // yield put(errorAction(error));
    } 

}

function* getProjectFollowersById({payload}) {
    const {JWToken, projectId, querryParams, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showMainLoader_());
            } 
        }
        const apiResponse = yield call(getProjectFollowers, JWToken, projectId, querryParams);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(getProjectFollowersSuccess_(apiResponse.data));
            // yield put(successAction(apiResponse.error));        
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideMainLoader_());
    }

}



function* followProjectWithData({payload}) {
    const {JWToken, projectId, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        const apiResponse = yield call(followProject, JWToken, projectId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(followProjectSuccess_(apiResponse.data));        
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideMainLoader_());
    }
}
function* unfollowProjectWithData({payload}) {
    // console.log("SAGA SUCCESS!")
}


//// SETTINGS / apikeys

function* getProjectSettingsById({payload}) {
    const {JWToken, projectId, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDashbaordLoader_());
            } 
        }
        const apiResponse = yield call(getProjectSettings, JWToken, projectId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            let querryParams = {};
            const apiResponseGasPrice = yield call(getGasPrice, JWToken, querryParams={currency:apiResponse.data.gasPriceCurrency});
            if (apiResponseGasPrice.status>200) {
                console.log("gas price error"); 
            } else {
                yield put(getGasPriceSuccess_(apiResponseGasPrice.data));     
            }
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(getProjectSettingsSuccess_(apiResponse.data)); 
                   
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideDashbaordLoader_());
    }
}

function* updateProjectSettingsById({payload}) {
    const {JWToken, projectId, projectSettingsData, refresh, noLoader} = payload;
    let fetchingResult = {};
    try {
        if (!noLoader) {
            yield put(showTransparentLoader_());
        }
        const apiResponse = yield call(updateProjectSettings, JWToken, projectId, projectSettingsData);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: update error", apiResponse);

            fetchingResult.error = "Update Error";
            yield put(showLoaderUpdateSuccess_(fetchingResult));
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: update success", apiResponse);

            /*if (projectDistributionSettings && !currentGasPrice) {
            dispatch(getGasPrice_({JWToken, querryParams:{
                currency: projectDistributionSettings.gasPriceCurrency
            }}));
        }*/
            yield put(settingsRefreshed_(true));
            yield put(updateProjectSettingsSuccess_());     
            
            // alert message   
            const successMessage = yield call(successHandler,"Project Settings", "", "updated");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 

        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
    }
}

function* getProjectApikeysById({payload}) {
    const {JWToken, projectId, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            yield put(showTransparentLoader_());
        }

        const apiResponse = yield call(getProjectApikey, JWToken, projectId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(getProjectApikeysSuccess_(apiResponse.data));        
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
    }
}

function* updateProjectApiSettingsById({payload}) {
    const {JWToken, projectId, projectData, refresh, noLoader} = payload;
    let fetchingResult = {};
    try {
        if (!noLoader) {
            yield put(showTransparentLoader_());
        }
        const apiResponse = yield call(updateProjectApiSettings, JWToken, projectId, projectData);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: update error", apiResponse);

            fetchingResult.error = "Update Error";
            yield put(showLoaderUpdateSuccess_(fetchingResult));
            // yield put(errorAction(apiResponse.error));
            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: update success", apiResponse);
            yield put(apiSettingsRefreshed_(true));
            yield put(updateProjectApiSettingsSuccess_());       
            
            // alert message   
            const successMessage = yield call(successHandler,"Project Settings", "", "updated");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 

        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
    }
}

function* getProjectApiSettingsById({payload}) {
    const {JWToken, projectId, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            yield put(showTransparentLoader_());
        }
        const apiResponse = yield call(getProjectApiSettings, JWToken, projectId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(getProjectApiSettingsSuccess_(apiResponse.data));        
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
    }
}


function* createProjectApikeysWithData({payload}) {
    const {JWToken, projectId, refresh, noLoader} = payload;
    let fetchingResult = {};
    try {
        if (!noLoader) {
            yield put(showTransparentLoader_());
        }
        const apiResponse = yield call(postProjectApikey, JWToken, projectId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            fetchingResult.error = "Create Error";
            yield put(showLoaderCreateSuccess_(fetchingResult));
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(apiSettingsRefreshed_(true));
            fetchingResult.success = "Success";
            yield put(showLoaderCreateSuccess_(fetchingResult));
            yield put(createProjectApikeysSuccess_(apiResponse.data));      
            
            // alert message   
            const successMessage = yield call(successHandler,"API Keys", "", "created");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 

        // yield put(errorAction(error));
    } finally {
        yield put(hideDialogueLoader_());
        yield put(hideTransparentLoader_());
    }
}


function* deleteProjectApikeysById({payload}) {
    const {JWToken, projectId, refresh, noLoader} = payload;
    let fetchingResult = {};
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        const apiResponse = yield call(deleteProjectApikey, JWToken, projectId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            fetchingResult.error = "Delete Error";
            yield put(showLoaderDeleteSuccess_(fetchingResult));
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(apiSettingsRefreshed_(true));
            fetchingResult.success = "Success";
            yield put(showLoaderDeleteSuccess_(fetchingResult));
            yield put(deleteProjectApikeysSuccess_(apiResponse.data));   
            
            // alert message   
            const successMessage = yield call(successHandler,"API Keys", "", "deleted");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 

        // yield put(errorAction(error));
    } finally {
        yield put(hideDialogueLoader_());
        yield put(hideTransparentLoader_());
    }
}

function* pinAssetToProjectProfileWithData({payload}) {
    const {JWToken, projectId, assetData, refresh, noLoader} = payload;
    let fetchingResult = {};
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        const apiResponse = yield call(pinAssetToProjectProfile, JWToken, projectId, assetData);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse); 

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse); 
            yield put(projectRefreshed_(true));
            yield put(pinnedToProjectSuccess_(true));  
            
            // alert message   
            const successMessage = yield call(successHandler,"Asset", "", "pinned");
            yield put(viewAlertMessage_(successMessage)); 

        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 

        
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideDialogueLoader_());
    }

}

function* unpinAssetFromProjectProfileWithData({payload}) {
    const {JWToken, projectId, assetType, assetId, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        console.log({JWToken, projectId, assetType, assetId})
        let fetchingResult;
        let apiResponse;
        switch (assetType) {
            case "tokens": 
                let tokenId = assetId;
                apiResponse = yield call(unpinTokenFromProject, JWToken, projectId, tokenId);
            break;
            case "utilities": 
                let utilityId = assetId;
                apiResponse = yield call(unpinUtilityFromProject, JWToken, projectId, utilityId);
            break;
            case "resources": 
                let resourceId = assetId;
                apiResponse = yield call(unpinResourceFromProject, JWToken, projectId, resourceId);
            break;
            default: console.log("pin saga failed");
        }
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse); 
            yield put(projectRefreshed_(true));
            yield put(unpinnedFromProjectSuccess_(true))   

            // alert message   
            const successMessage = yield call(successHandler,"Asset", "", "unpinned");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideDialogueLoader_());
    }

}

function* getCurrentGasPriceWithData({payload}) {
    const {JWToken, querryParams} = payload;
        
    try {
        const apiResponse = yield call(getGasPrice, JWToken, querryParams);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            console.log("apiResponse_error_getProject: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse_getProject: ", apiResponse);
            console.log("gas: ", apiResponse.data);
            yield put(getGasPriceSuccess_(apiResponse.data));
            // yield put(successAction(apiResponse.error));        
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    }

}


export function* newProject() {
    yield takeLatest(CREATE_PROJECT, 
        createProjectWithName);
}

export function* allProjects() {
    yield takeLatest(GET_ALL_PROJECTS, 
        getAllProjectsByUser);
}
export function* readOneProject() {
    yield takeLatest(GET_PROJECT, 
        getProjectById);
}
export function* selectOneProject() {
    yield takeLatest(SELECT_PROJECT, 
        selectProjectByUser);
}
export function* readOneProjectProfile() {
    yield takeLatest(GET_PROJECT_PROFILE, 
        getProjectProfileById);
}
export function* updateOneProject() {
    yield takeLatest(UPDATE_PROJECT, 
        updateProjectById);
}
export function* removeOneProject() {
    yield takeLatest(DELETE_PROJECT, 
        deleteProjectById);
}
export function* addOneProjectEditor() {
    yield takeLatest(ADD_PROJECT_EDITOR, 
        addEditorToProjectWithData);
}
export function* readProjectEditors() {
    yield takeLatest(GET_ALL_PROJECT_EDITORS, 
        getProjectEditorsById);
}
export function* readProjectBlueprints() {
    yield takeLatest(GET_ALL_PROJECT_BLUEPRINTS, 
        getProjectBlueprintsById);
}
export function* precacheProjectBlueprints() {
    yield takeLatest(CACHE_PROJECT_BLUEPRINTS, 
        cacheProjectBlueprintsById);
}


export function* removeOneProjectEditor() {
    yield takeLatest(DELETE_PROJECT_EDITOR, 
        deleteProjectEditorById);
}
export function* readProjectFollowers() {
    yield takeLatest(GET_PROJECT_FOLLOWERS, 
        getProjectFollowersById);
}
export function* followOneProject() {
    yield takeLatest(FOLLOW_PROJECT, 
        followProjectWithData);
}
export function* unfollowOneProject() {
    yield takeLatest(UNFOLLOW_PROJECT, 
        unfollowProjectWithData);
}
// Settings & API KEYS
export function* getAllProjectSettings() {
    yield takeLatest(GET_PROJECT_SETTINGS, 
        getProjectSettingsById);
}
export function* updateAllProjectSettings() {
    yield takeLatest(UPDATE_PROJECT_SETTINGS, 
        updateProjectSettingsById);
}
export function* getAllProjectApikeys() {
    yield takeLatest(GET_PROJECT_APIKEYS, 
        getProjectApikeysById);
}
export function* addNewProjectApikeys() {
    yield takeLatest(CREATE_PROJECT_APIKEYS, 
        createProjectApikeysWithData);
}
export function* removeProjectApikeys() {
    yield takeLatest(DELETE_PROJECT_APIKEYS, 
        deleteProjectApikeysById);
}

export function* attachAssetToProject() {
    yield takeLatest(PIN_ASSET_TO_PROJECT, 
        pinAssetToProjectProfileWithData);
}
export function* detachAssetFromProject() {
    yield takeLatest(UNPIN_ASSET_FROM_PROJECT, 
        unpinAssetFromProjectProfileWithData);
}

export function* getAllProjectApiSettings() {
    yield takeLatest(GET_PROJECT_API_SETTINGS, 
        getProjectApiSettingsById);
}

export function* updateAllProjectApiSettings() {
    yield takeLatest(UPDATE_PROJECT_API_SETTINGS, 
        updateProjectApiSettingsById);
}

export function* getCurrentGasPriceData() {
    yield takeEvery(GET_GAS_PRICE, 
        getCurrentGasPriceWithData);
}


export default function* rootSaga() {
    yield all([fork(newProject),
        fork(allProjects),
        fork(readOneProject),
        fork(readOneProjectProfile),
        fork(updateOneProject),
        fork(removeOneProject),
        fork(addOneProjectEditor),
        fork(readProjectEditors),
        fork(readProjectBlueprints),
        fork(precacheProjectBlueprints),
        fork(selectOneProject),
        fork(removeOneProjectEditor),
        fork(readProjectFollowers),
        fork(followOneProject),
        fork(unfollowOneProject),
        fork(getAllProjectSettings),
        fork(updateAllProjectSettings),
        fork(getAllProjectApikeys),
        fork(addNewProjectApikeys),
        fork(removeProjectApikeys),
        fork(attachAssetToProject),
        fork(detachAssetFromProject),
        fork(getAllProjectApiSettings),
        fork(updateAllProjectApiSettings),
        fork(getCurrentGasPriceData)
    ]);
}