import {
    OPEN_HELP_SECTION,
    SET_ASSET_TYPE,
    SET_TAB_VALUE,
} from 'constants/ActionTypes'

export const setTabValue_ = (value) => {
    return {
        type: SET_TAB_VALUE,
        payload: value
    };
};

export const setAssetType_ = (value) => {
    return {
        type: SET_ASSET_TYPE,
        payload: value
    };
};

export const openHelpSection_ = (value) => {
    return {
        type: OPEN_HELP_SECTION,
        payload: value
    };
};


