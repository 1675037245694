import {
    EVENTS_REFRESHED,
    EVENT_REFRESHED,
    GET_ALL_EVENTS,
    GET_ALL_EVENTS_SUCCESS,
    LOAD_MORE_EVENTS_SUCCESS
} from 'constants/ActionTypes'

export const getAllEvents_ = (data) => {
    return {
        type: GET_ALL_EVENTS,
        payload: data
    };
};

export const getAllEventsSuccess_ = (events) => {
    return {
        type: GET_ALL_EVENTS,
        payload: events
    };
};

export const loadMoreEventsSuccess_ = (events) => {
    return {
        type: LOAD_MORE_EVENTS_SUCCESS,
        payload: events
    };
};

export const eventsRefreshed_ = (data) => {
    return {
        type: EVENTS_REFRESHED,
        payload: data
    };
};

export const eventRefreshed_ = (data) => {
    return {
        type: EVENT_REFRESHED,
        payload: data
    };
};