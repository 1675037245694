import { API } from 'components/networkVisualizer/API/api'
import {
  mergeVisualizerDataItemConnections,
  setVisualizerData,
  setVisualizerFetching,
} from 'components/networkVisualizer/networkVisualizerState/store/general/actions'
import {
  disableGraphNextButton,
  setWalletFetchPage,
} from 'components/networkVisualizer/networkVisualizerState/store/graph/actions'
import { MAX_GRAPH_CONNECTIONS } from 'components/networkVisualizer/networkVisualizerState/store/graph/reducer'
import { call, put, SagaReturnType, select } from 'redux-saga/effects'
import { AppState } from 'store'
import { getWalletCooperationsFormatter } from '../../dataFormatters/getWalletCooperationsFormatter/getWalletCooperationsFormatter'

// const currentIndexSelector = (state: AppState) =>
//   state.networkVisualizerState.graph.visibleConnectionsIndex

// type CurrentIndexSelector = ReturnType<typeof currentIndexSelector>

const walletFetchPageSelector = (state: AppState) =>
  state.networkVisualizerState.graph.walletFetchPage

type WalletFetchPageSelector = ReturnType<typeof walletFetchPageSelector>

export const WALLET_FETCH_LIMIT = MAX_GRAPH_CONNECTIONS

/**
 * Wallet connections are fetched per page. No way of knowing how many to expect and calling until fulfilling a fetch limit doesn't work since the API returns duplicates and doesn't seem to stop. Fetch page is kept in sync with the currentViewIndex.
 * @param walletId
 * @param blockchain
 */
export function* fetchAndSetWalletConnections(
  walletId: string | number,
  blockchain: string = 'ethereum'
) {
  yield put(setVisualizerFetching(true))
  yield put(disableGraphNextButton(true)) //Disable the button while the fetch loop is in progress. Once complete, ensure to enable it again.

  const fetchPage: WalletFetchPageSelector = yield select(
    walletFetchPageSelector
  )

  let totalResponseLength = 0
  let currentFetchPage = fetchPage

  FetchLoop: while (totalResponseLength < WALLET_FETCH_LIMIT) {
    currentFetchPage += 1
    try {
      const response: SagaReturnType<typeof API.getWalletCooperations> = yield call(
        API.getWalletCooperations,
        walletId,
        blockchain,
        {
          limit: WALLET_FETCH_LIMIT - totalResponseLength,
          page: currentFetchPage,
        } //How does the API work? Subtracting the fetch limit so that I can satisfy the limit exactly.
      )

      let response_l = response.data?.tokens?.length ?? 0 //API sometimes returns strange data object at the end. If data can't be iterated, assume it's empty.

      totalResponseLength += response_l

      const {
        data,
        connections,
      }: ReturnType<typeof getWalletCooperationsFormatter> = yield call(
        getWalletCooperationsFormatter,
        response.data
      )

      yield put(setVisualizerData(data)) //Ensure this is set before setting the connections.

      yield put(
        mergeVisualizerDataItemConnections({
          itemType: 'wallets',
          itemId: walletId,
          newConnectionsType: 'tokens', //Wallet data only returns tokens at the time of creation.
          newConnections: connections.tokens,
        })
      )

      yield put(setWalletFetchPage(currentFetchPage))

      if (totalResponseLength >= WALLET_FETCH_LIMIT && response_l != 0) {
        //If the totalResponseLength is satisfied (meaning the loop will not run again) and the last response length was not empty, enable the next button.
        yield put(disableGraphNextButton(false))
      }

      if (response_l === 0) {
        break FetchLoop
      }
    } catch (e) {
      console.log({ e })
      break FetchLoop
    }
  }

  yield put(setVisualizerFetching(false))
}
