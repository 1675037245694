import {all, call, fork, put, takeEvery, throttle, debounce, join} from "redux-saga/effects";

import {
    SEARCH_STRING
} from "constants/ActionTypes";

import {
    searchStringSuccess_,
    searchString_

} from "actions/Search";

import {
    getAllTokenBlueprints,
    getAllUtilities,
    getAllResources,
    getAllProjects
} from "../api";
import { getAllTokenBlueprints_ } from "actions/Token";
import { formatSearchResults } from "util/formatSearchResults";
import { hideDialogueLoader_, hideLocalFetching_, showDialogueLoader_, showLocalFetching_ } from "actions/IsFetching";
import { userSignOut } from "actions";


let searchResults = {};

function* fetchResources(JWToken, querryParams) {
    
    const apiResponse_resources = yield call(getAllResources, JWToken, querryParams);

    // resources handling
    if (apiResponse_resources.status>200) {
        if (apiResponse_resources.status === 401) {
            yield put(userSignOut());
        }
        // console.log("apiResponse_error: ", apiResponse_resources);
    } else {
        return apiResponse_resources;
    }
    
    
}

function* fetchTokens(JWToken, querryParams) {
    
    const apiResponse_tokens = yield call(getAllTokenBlueprints, JWToken, querryParams);

    // tokens handling
    if (apiResponse_tokens.status>200) {
        if (apiResponse_tokens.status === 401) {
            yield put(userSignOut());
        }
        // console.log("apiResponse_error: ", apiResponse_tokens);
    } else {
        return apiResponse_tokens;
    }
}

function* fetchTokensById(JWToken, querryParams) {
    
    const apiResponse_tokens_by_id = yield call(getAllTokenBlueprints, JWToken, querryParams);

    // tokens handling
    if (apiResponse_tokens_by_id.status>200) {
        if (apiResponse_tokens_by_id.status === 401) {
            yield put(userSignOut());
        }
        // console.log("apiResponse_error: ", apiResponse_tokens);
    } else {
        return apiResponse_tokens_by_id;
    }
}


function* fetchUtilities(JWToken, querryParams) {
    
    const apiResponse_utilities = yield call(getAllUtilities, JWToken, querryParams);

    // utilities handling
    if (apiResponse_utilities.status>200) {
        if (apiResponse_utilities.status === 401) {
            yield put(userSignOut());
        }
        // console.log("apiResponse_error: ", apiResponse_utilities);
    } else {
        return apiResponse_utilities;
    }
}

function* fetchProjects(JWToken, querryParams) {
    
    const apiResponse_projects = yield call(getAllProjects, JWToken, querryParams);

    // projects handling
    if (apiResponse_projects.status>200) {
        if (apiResponse_projects.status === 401) {
            yield put(userSignOut());
        }
        // console.log("apiResponse_error: ", apiResponse_projects);
    } else {
        return apiResponse_projects;
    }
}

function* fetchAllAssets(JWToken, querryParams) {
    const apiResponse_resources = yield fork(fetchResources, JWToken, querryParams);
    const apiResponse_tokens = yield fork(fetchTokens, JWToken, querryParams);
    const apiResponse_utilities = yield fork(fetchUtilities, JWToken, querryParams);
    const apiResponse_projects = yield fork(fetchProjects, JWToken, querryParams);
    const apiResponse_tokens_by_id = yield fork(fetchTokensById, JWToken, querryParams={limit:querryParams.limit, page:querryParams.page, tokenId:querryParams.name});


    const results = yield join([apiResponse_tokens, apiResponse_resources, apiResponse_utilities, apiResponse_projects, apiResponse_tokens_by_id]);
    return results;
}

function* searchItemByString({payload}) {
    const {searchText, JWToken, querryParams} = payload;
    try {
        yield put(showDialogueLoader_())
        const fetchResults = yield call(fetchAllAssets, JWToken, querryParams);


        // tokens handling
        if (fetchResults[0].status>200) {
            searchResults.tokens = fetchResults[0].data;
            // console.log("apiResponse_error: ", searchResults.tokens);
        } else {
            searchResults.tokens = fetchResults[0].data.tokens;
            // console.log("apiResponse: ", searchResults.tokens);
        }

        // resources handling
        if (fetchResults[1].status>200) {
            searchResults.resources = fetchResults[1].data;
            // console.log("apiResponse_error: ", searchResults.resources);
        } else {
            searchResults.resources = fetchResults[1].data.resources;
            // console.log("apiResponse: ", searchResults.resources);
        }

        // utilities handling
        if (fetchResults[2].status>200) {
            searchResults.utilities = fetchResults[2].data;
            // console.log("apiResponse_error: ", searchResults.utilities);
        } else {
            searchResults.utilities = fetchResults[2].data.utilities;
            // console.log("apiResponse: ", searchResults.utilities);
        }

        // projects handling
        if (fetchResults[3].status>200) {
            searchResults.projects = fetchResults[3].data;
            // console.log("apiResponse_error: ", searchResults.projects);
        } else {
            searchResults.projects = fetchResults[3].projects;
            // console.log("apiResponse: ", searchResults.projects);
        }

        // tokens byId handling
        if (fetchResults[4].status>200) {
            searchResults.tokensById = fetchResults[4].data;
            // console.log("apiResponse_error: ", searchResults.tokens);
        } else {
            searchResults.tokensById = fetchResults[4].data.tokens;
            // console.log("apiResponse: ", searchResults.tokens);
        }

        // console.log("SearchResults from Saga: ", searchResults);
        const formattedList = yield call(formatSearchResults, searchResults);
        yield put(searchStringSuccess_(formattedList));

    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideDialogueLoader_())
    }
}

export function* searchItemWatcher() {
    yield throttle(500, SEARCH_STRING, 
        searchItemByString);
}


export default function* rootSaga() {
    yield all([call(searchItemWatcher),
        
    ]);
}