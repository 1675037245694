import { CategoryTypes } from 'components/networkVisualizer/networkVisualizerState/sharedTypes'
import {
  fetchItemDetailsCooperations,
  setItemDetailsCooperationsTabIndex,
} from 'components/networkVisualizer/networkVisualizerState/store/itemDetails/actions'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { AppState } from 'store'
import { Fetching } from './components/fetching/fetching'
import { Header } from './components/header/header'
import { List } from './components/list/list'

const SORT_ORDER: CategoryTypes[] = ['utilities', 'tokens', 'resources']

export const cooperationCategoriesSelector = createSelector(
  (state: AppState) => state.networkVisualizerState.itemDetails,
  (state: AppState) => state.networkVisualizerState.general.data,
  ({ id, type }, data) => {
    let categories: CategoryTypes[] = []
    let itemData = data[type][id]
    if (itemData && itemData.cooperationsCount) {
      for (let k of SORT_ORDER) {
        if (itemData.cooperationsCount[k] !== undefined) {
          categories.push(k)
        }
      }
    }
    return categories
  }
)

export const connectionsSelector = createSelector(
  cooperationCategoriesSelector,
  (state: AppState) => state.networkVisualizerState.itemDetails.cooperations,
  (state: AppState) =>
    state.networkVisualizerState.itemDetails.cooperationsTabIndex,
  (categories, cooperations, activeTabIndex) => {
    let connectionType = categories[activeTabIndex]
    return cooperations[connectionType] ?? []
  }
)

export const noConnectionsSelector = createSelector(
  connectionsSelector,
  (connections) => connections.length < 1
)

const activeTabIndexSelector = (state: AppState) =>
  state.networkVisualizerState.itemDetails.cooperationsTabIndex

export const activeCooperationsCategorySelector = createSelector(
  cooperationCategoriesSelector,
  activeTabIndexSelector,
  (categories, activeIndex) => categories[activeIndex]
)

export const Cooperations = () => {
  const dispatch = useDispatch()
  const categories = useSelector(cooperationCategoriesSelector)

  const activeTabIndex = useSelector(activeTabIndexSelector)

  const setActiveTabIndex = useCallback(
    (newIndex: number) => {
      dispatch(setItemDetailsCooperationsTabIndex(newIndex))
    },
    [dispatch]
  )

  const fetchMoreItems = useCallback(() => {
    //Call is throttled in list component.
    dispatch(fetchItemDetailsCooperations())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchItemDetailsCooperations())
  }, [activeTabIndex])

  const activeCategory = categories[activeTabIndex]

  if (categories.length === 0) return null

  return (
    <div className='cooperations-wrapper'>
      <h2 className='header-text'>Assets</h2>
      <div className='cooperations-container'>
        <Fetching />
        <Header
          {...{ categories, activeTabIndex }}
          setCategory={setActiveTabIndex}
        />
        <div className='cooperations-list-container'>
          <List {...{ activeCategory, fetchMoreItems }} />
        </div>
      </div>
    </div>
  )
}
