import { API } from 'components/networkVisualizer/API/api'
import { searchAssetsFormatter } from 'components/networkVisualizer/networkVisualizerState/helpers/dataFormatters/searchAssetsFormatter/'
import { CategoryTypes } from 'components/networkVisualizer/networkVisualizerState/sharedTypes'
import {
  all,
  call,
  delay,
  fork,
  put,
  SagaReturnType,
  select,
  takeLatest,
} from 'redux-saga/effects'
import { AppState } from 'store'
import { setVisualizerData } from '../general/actions'
import {
  setFeaturedProjects,
  setFeaturedProjectsVisibility,
  setSearchResults,
  setVisualizerSearchFilter,
  setVisualizerSearchingStatus,
  setVisualizerSearchValue,
  setVisualizerTypingStatus,
} from './actions'
import {
  CHANGE_VISUALIZER_SEARCH_FILTER,
  CHANGE_VISUALIZER_SEARCH_VALUE,
  FETCH_VISUALIZER_FEATURED_PROJECTS,
  SearchFilter,
} from './types'

const SEARCH_RESULTS_FETCH_LIMIT = 25

const RESULTS_CATEGORY_ORDER: CategoryTypes[] = [
  'tokens',
  'utilities',
  'resources',
  'projects',
]

const searchFilterSelector = (state: AppState) =>
  state.networkVisualizerState.navMenu.searchFilter

const searchValueSelector = (state: AppState) =>
  state.networkVisualizerState.navMenu.searchValue

type SearchValueSelector = ReturnType<typeof searchValueSelector>

/**
 * Shared function used to make api request with query string and set response data.
 * Iterates over all categories and makes an API call for each category if the filter includes that category OR the filter is empty (length = 0).
 * Will only fetch a maximum of 25 results for each asset.
 * Function works independently.
 */

function* fetchAndSetResults() {
  const filter: ReturnType<typeof searchFilterSelector> = yield select(
    searchFilterSelector
  )

  const searchValue: SearchValueSelector = yield select(searchValueSelector)

  yield put(setVisualizerSearchingStatus(true))
  try {
    const categories = RESULTS_CATEGORY_ORDER

    const responses: SagaReturnType<typeof API.searchAssets>[] = yield all(
      categories.map((category) =>
        filter === 'all' || filter === category
          ? call(API.searchAssets, category, {
              name: searchValue,
              limit: SEARCH_RESULTS_FETCH_LIMIT,
            })
          : null
      )
    )

    const formattedData: ReturnType<typeof searchAssetsFormatter.toSearchResults> = yield call(
      searchAssetsFormatter.toSearchResults,
      responses
    )

    yield put(setSearchResults(formattedData))
    yield put(setFeaturedProjectsVisibility(false))

    //Adding the data to general data so that search result tokens can be navigated to in the graph. Or else changeSelectedNode won't be able to find the actual tokenId since the selector used looks at the general data and not the search results.
    for (let response of responses) {
      if (response) {
        const formattedGeneralData: ReturnType<typeof searchAssetsFormatter.toGeneralData> = yield call(
          searchAssetsFormatter.toGeneralData,
          response.data
        )
        yield put(setVisualizerData(formattedGeneralData.data))
      }
    }
  } catch (e) {
    //<<<< Handle error gracefully>>>>>>>>>>>
    console.log('Search request could not be made', e)
    yield put(setSearchResults([]))
  } finally {
    yield put(setVisualizerSearchingStatus(false))
  }
}

/**
 * Updates the search input.
 * Makes an API call after set delay.
 * Sets search results.
 *
 */
function* visualizerSearchValueWatcher() {
  yield takeLatest(CHANGE_VISUALIZER_SEARCH_VALUE, visualizerSearchValueFlow)
}

function* visualizerSearchValueFlow({
  type,
  payload,
}: {
  type: typeof CHANGE_VISUALIZER_SEARCH_VALUE
  payload: string
}) {
  yield all([
    put(setVisualizerSearchValue(payload)),
    put(setVisualizerTypingStatus(true)),
    put(setSearchResults([])),
  ])

  //Delay to allow user to keep typing if needed.
  yield delay(550)

  yield put(setVisualizerTypingStatus(false))

  yield call(fetchAndSetResults)
}

/**
 * Updating the asset type search filter (ie. 'tokens', 'projects', 'resources', 'utilities')
 */
function* visualizerSearchFilterWatcher() {
  yield takeLatest(CHANGE_VISUALIZER_SEARCH_FILTER, visualizerSearchFilterFlow)
}

function* visualizerSearchFilterFlow({
  type,
  payload,
}: {
  type: typeof CHANGE_VISUALIZER_SEARCH_FILTER
  payload: SearchFilter
}) {
  const searchFilter = yield select(searchFilterSelector)

  let newFilter = searchFilter === payload ? 'all' : payload

  yield put(setVisualizerSearchFilter(newFilter))

  yield call(fetchAndSetResults)
}

function* fetchVisualizerFeaturedProjectsWatcher() {
  yield takeLatest(
    FETCH_VISUALIZER_FEATURED_PROJECTS,
    fetchVisualizerFeaturedProjectsFlow
  )
}

function* fetchVisualizerFeaturedProjectsFlow({
  type,
}: {
  type: typeof FETCH_VISUALIZER_FEATURED_PROJECTS
}) {
  const response = yield call(API.searchAssets, 'projects', { featured: true })

  const formattedData = yield call(searchAssetsFormatter.toSearchResults, [
    response,
  ])

  yield put(setFeaturedProjects(formattedData))
}

export function* navMenuSagas() {
  yield fork(visualizerSearchValueWatcher)
  yield fork(visualizerSearchFilterWatcher)
  yield fork(fetchVisualizerFeaturedProjectsWatcher)
}
