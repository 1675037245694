import { arrowDownIcon } from 'components/networkVisualizer/assets/icons'
import { CategoryTypes } from 'components/networkVisualizer/networkVisualizerState/sharedTypes'
import { changeSelectedNode } from 'components/networkVisualizer/networkVisualizerState/store/graph/actions'
import {
  fetchVisualizerFeaturedProjects,
  hideVisualizerNavMenu,
  setFeaturedProjectsVisibility,
} from 'components/networkVisualizer/networkVisualizerState/store/navMenu/actions'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { SearchResultItem } from '../searchResults/components/results/components/searchResultItem/searchResultItem'

export const FeaturedProjects = () => {
  const dispatch = useDispatch()

  const visible = useSelector(
    (state: AppState) =>
      state.networkVisualizerState.navMenu.showFeaturedProjects
  )

  const data = useSelector(
    (state: AppState) => state.networkVisualizerState.navMenu.featuredProjects
  )

  const onPress = useCallback(
    (
      id: number | string,
      type: CategoryTypes,
      platformId?: string | number
    ) => {
      dispatch(hideVisualizerNavMenu())
      dispatch(changeSelectedNode(id, type, platformId))
    },
    [dispatch]
  )

  const toggleVisibility = useCallback(() => {
    dispatch(setFeaturedProjectsVisibility())
  }, [dispatch])

  useEffect(() => {
    dispatch(fetchVisualizerFeaturedProjects())
  }, [])

  return (
    <div className={`featured-projects-wrapper ${visible ? '' : 'collapsed'}`}>
      <div className='header-container'>
        <h3 className='header-text'>FEATURED</h3>
      </div>
      <div className='body-wrapper'>
        <div className='scroll-container'>
          {data.map((item, i) => (
            <SearchResultItem
              key={item.type + '-' + item.id}
              {...item}
              {...{ onPress }}
              featured
              featuredAnim={visible && i === 0}
            />
          ))}
          {data.length === 0 ? (
            <div className='no-data-placeholder-container'>
              <p className='placeholder-text'>
                {`Nothing to show at this time.\ncheck back later.`}
              </p>
            </div>
          ) : null}
        </div>
      </div>
      <div className='footer-container' onClick={toggleVisibility}>
        <img src={arrowDownIcon} className='arrow-down-icon' />
      </div>
    </div>
  )
}
