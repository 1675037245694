import {
  followersIcon,
  networkIcon,
} from 'components/networkVisualizer/assets/icons'
import { defaultImages } from 'components/networkVisualizer/assets/variables/defaultImages'
import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'store'
import { AssetNameAndType, DisplayImage, ItemDetail } from './sharedComponents'

interface IsProps {
  id: string | number
}

export const ProjectPopup = ({ id }: IsProps) => {
  const data = useSelector(
    (state: AppState) => state.networkVisualizerState.general.data.projects[id]
  )

  let {
    image,
    name,
    //What happen to this property?? It's not in the response anymore.
    followersCount,
    cooperationsCount,
  } = data

  let tokenCount = cooperationsCount ? cooperationsCount.tokens : undefined
  let utilitiesCount = cooperationsCount
    ? cooperationsCount.utilities
    : undefined
  let resourcesCount = cooperationsCount
    ? cooperationsCount.resources
    : undefined

  return (
    <div className='popup-container'>
      <DisplayImage image={image || defaultImages.projects} alt={name} />
      <div className='body-container'>
        <AssetNameAndType name={name} type={'project'} />
        <ItemDetail
          visible={followersCount !== undefined && followersCount !== null}
          image={followersIcon}
          imageAlt='followers'
        >
          <p className='secondary-text'>
            <span>{followersCount}</span> followers
          </p>
        </ItemDetail>

        <ItemDetail
          visible={tokenCount !== undefined && tokenCount !== null}
          image={networkIcon}
          imageAlt='tokens'
        >
          <p className='secondary-text'>
            <span>{tokenCount}</span> tokens
          </p>
        </ItemDetail>

        <ItemDetail
          visible={utilitiesCount !== undefined && utilitiesCount !== null}
          image={networkIcon}
          imageAlt='utilities'
        >
          <p className='secondary-text'>
            <span>{utilitiesCount}</span> utilities
          </p>
        </ItemDetail>

        <ItemDetail
          visible={resourcesCount !== undefined && resourcesCount !== null}
          image={networkIcon}
          imageAlt='resources'
        >
          <p className='secondary-text'>
            <span>{resourcesCount}</span> resources
          </p>
        </ItemDetail>
      </div>
    </div>
  )
}
