
import tokenIcon from 'assets/images/token_icon.png';
import utilityIcon from 'assets/images/utility_icon.png';
import resourceIcon from 'assets/images/resource_icon.png';
import unknownIcon from 'assets/images/unknown_icon.png';

export const formatTxSearchResults = async(searchResults) => {

    let formattedTxHashes = [];
    if (searchResults.txHashes.length) {
        await searchResults.txHashes.map((result) => 
            formattedTxHashes.push({
                id: result.txHash
            })
        )}
        
    let formattedEnjinTxIds = [];
    if (searchResults.enjinTxIds.length) {
        await searchResults.enjinTxIds.map((result) => 
            formattedEnjinTxIds.push({
                id: result.enjinTxId
            })
        )}
    


    const formattedResults = formattedTxHashes.concat(formattedEnjinTxIds);
    
    // console.log("formattedTokens: ", formattedResults);

    // const formattedResults = tokenBlueprints.concat(array2, array4);
    return formattedResults;
    
}