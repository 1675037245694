import {
    GET_ALL_ORDERS,
    GET_ALL_ORDERS_SUCCESS,
    PLACE_ORDER,
    PLACE_ORDER_SUCCESS,
    GET_ORDER,
    GET_ORDER_SUCCESS,
    DELETE_ORDER,
    DELETE_ORDER_SUCCESS,
    UPDATE_ORDER,
    UPDATE_ORDER_SUCCESS,
    GET_ORDER_QUEUE_ITEMS,
    GET_ORDER_QUEUE_ITEMS_SUCCESS,
    FORCE_SEND,
    FORCE_SEND_SUCCESS,
    CLEAR_ORDER_STAGE,
    SET_SELECTED_ORDER,
    REFRESH_ORDERS,
    SET_ORDER_ID,
    LOAD_MORE_ORDERS_SUCCESS,
    ORDER_REFRESHED,
    ORDERS_REFRESHED


} from 'constants/ActionTypes'

export const getAllOrders_ = (data) => {
    return {
        type: GET_ALL_ORDERS,
        payload: data
    };
};

export const getAllOrdersSuccess_ = (orders) => {
    return {
        type: GET_ALL_ORDERS_SUCCESS,
        payload: orders
    };
};

export const loadMoreOrdersSuccess_ = (orders) => {
    return {
        type: LOAD_MORE_ORDERS_SUCCESS,
        payload: orders
    };
};

export const placeOrder_ = (data) => {
    return {
        type: PLACE_ORDER,
        payload: data
    };
};

export const placeOrderSuccess_ = (orderId) => {
    return {
        type: PLACE_ORDER_SUCCESS,
        payload: orderId
    };
};

export const getOrder_ = (orderId) => {
    return {
        type: GET_ORDER,
        payload: orderId
    };
};

export const getOrderSuccess_ = (order) => {
    return {
        type: GET_ORDER_SUCCESS,
        payload: order
    };
};

export const updateOrder_ = (data) => {
    return {
        type: UPDATE_ORDER,
        payload: data
    };
};

export const updateOrderSuccess_ = (data) => {
    return {
        type: UPDATE_ORDER_SUCCESS,
        payload: data
    };
};

export const deleteOrder_ = (orderId) => {
    return {
        type: DELETE_ORDER,
        payload: orderId
    };
};

export const deleteOrderSuccess_ = () => {
    return {
        type: DELETE_ORDER_SUCCESS
    };
};

export const getOrderQueueItems_ = (data) => {
    return {
        type: GET_ORDER_QUEUE_ITEMS,
        payload: data
    };
};

export const getOrderQueueItemsSuccess_ = (data) => {
    return {
        type: GET_ORDER_QUEUE_ITEMS_SUCCESS,
        payload: data
    };
};

export const forceSend_ = (data) => {
    return {
        type: FORCE_SEND,
        payload: data
    };
};

export const forceSendSuccess_ = (data) => {
    return {
        type: FORCE_SEND_SUCCESS,
        payload: data
    };
};

export const clearOrderStage_ = () => {
    return {
        type: CLEAR_ORDER_STAGE
    };
};

export const setOrderId_ = (orderId) => {
    return {
        type: SET_ORDER_ID,
        payload: orderId
    };
};

export const setSelectedOrder_ = (order) => {
    return {
        type: SET_SELECTED_ORDER,
        payload: order
    };
};

export const refreshOrders_ = (orderData) => {
    return {
        type: REFRESH_ORDERS,
        payload: orderData
    };
};

export const orderRefreshed_ = (data) => {
    return {
        type: ORDER_REFRESHED,
        payload: data
    };
};

export const ordersRefreshed_ = (data) => {
    return {
        type: ORDERS_REFRESHED,
        payload: data
    };
};