import {
    GET_ALL_WALLETS_SUCCESS,
    GET_WALLET_SUCCESS,
    ADD_WALLET_SUCCESS,
    PROJECT_WALLET_CREATED,
    USER_WALLET_CREATED,
    DELETE_WALLET_SUCCESS, GET_BALANCES_SUCCESS, GET_BLUEPRINTS_SUCCESS, UNLINK_WALLET_SUCCESS, GET_WALLET_THUMB_SUCCESS, CLEAR_LINKING_DIALOGUE, GET_SUPPLY_SUCCESS, GET_SELECTED_BLUEPRINTS_SUCCESS, GET_USER_BALANCES_SUCCESS, SET_CACHED, GET_USER_WALLET_SUCCESS, GET_ALL_USER_WALLETS_SUCCESS, ADD_USER_WALLET_SUCCESS, CLEAR_USER_LINKING_DIALOGUE, SET_CACHED_LIST, WALLETS_REFRESHED, WALLET_REFRESHED, SET_LINKING_METHOD, LINK_WALLET_SUCCESS, GET_PLATFORMS_SUCCESS, VERIFY_WALLET_SUCCESS
} from "constants/ActionTypes";

const INIT_STATE = {
    
    loader: false,
    alertMessage: '',
    showMessage: false,
    walletListProject: '',
    walletListUser: '',
    addedWalletProject: '',
    addedWalletUser: '',
    walletLinkingDataProject:'',
    walletLinkingDataUser:'',
    linkingDialogueProject:'enter',
    linkingDialogueUser:'enter',
    projectBalances: '',
    userBalances: '',
    blueprints: '',
    walletThumbData: '',
    walletSupply: '',
    selectedBlueprints: '',
    cached: true,
    projectWalletCreated: false,
    userWalletCreated: false,
    cachedList: [],
    walletRefreshed: false,
    walletsRefreshed: false,
    walletVerified: false,
    linkingMethod: 'none',
    platforms: ''
};



export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_WALLETS_SUCCESS: {
            return {
                ...state,
                walletListProject: action.payload
            }
        }
        case GET_ALL_USER_WALLETS_SUCCESS: {
            return {
                ...state,
                walletListUser: action.payload
            }
        }
        case GET_WALLET_SUCCESS: {
            return {
                ...state,
                walletLinkingDataProject: action.payload
            }
        }
        case SET_LINKING_METHOD: {
            return {
                ...state,
                linkingMethod: action.payload,
            }
        }
        case GET_USER_WALLET_SUCCESS: {
            return {
                ...state,
                walletLinkingDataUser: action.payload
            }
        }
        case GET_WALLET_THUMB_SUCCESS: {
            return {
                ...state,
                walletThumbData: action.payload
            }
        }
        case GET_BALANCES_SUCCESS: {
            return {
                ...state,
                projectBalances: action.payload
            }
        }
        case GET_USER_BALANCES_SUCCESS: {
            return {
                ...state,
                userBalances: action.payload
            }
        }
        case GET_SUPPLY_SUCCESS: {
            return {
                ...state,
                walletSupply: action.payload
            }
        }
        case GET_BLUEPRINTS_SUCCESS: {
            return {
                ...state,
                blueprints: action.payload
            }
        }
        case GET_SELECTED_BLUEPRINTS_SUCCESS: {
            return {
                ...state,
                selectedBlueprints: action.payload
            }
        }
        case ADD_WALLET_SUCCESS: {
            return {
                ...state,
                addedWalletProject: action.payload,
                linkingDialogueProject:'selectplatform',
            }
        }
        case LINK_WALLET_SUCCESS: {
            return {
                ...state,
                linkingDialogueProject:'selectplatform'
            }
        }
        case GET_PLATFORMS_SUCCESS: {
            return {
                ...state,
                platforms: action.payload
            }
        }
        case PROJECT_WALLET_CREATED: {
            return {
                ...state,
                projectWalletCreated: action.payload,
            }
        }
        case ADD_USER_WALLET_SUCCESS: {
            return {
                ...state,
                addedWalletUser: action.payload,
                linkingDialogueUser:'code'
            }
        }
        case USER_WALLET_CREATED: {
            return {
                ...state,
                userWalletCreated: action.payload,
            }
        }
        case UNLINK_WALLET_SUCCESS: {
            return {
                ...state
            }
        }
        case DELETE_WALLET_SUCCESS: {
            return {
                ...state,
                loader: false
            }
        }

        case CLEAR_LINKING_DIALOGUE: {
            return {
                ...state,
                addedWalletProject: '',
                linkingDialogueProject: 'enter'
            }
        }
        case CLEAR_USER_LINKING_DIALOGUE: {
            return {
                ...state,
                addedWalletUser: '',
                linkingDialogueUser: 'enter'
            }
        }

        case SET_CACHED: {
            return {
                ...state,
                cached: false,
            }
        }
        case SET_CACHED_LIST: {
            return {
                ...state,
                cachedList: action.payload,
            }
        }
        case WALLETS_REFRESHED: {
            return {
                ...state,
                walletsRefreshed: action.payload,
            }
        }
        case WALLET_REFRESHED: {
            return {
                ...state,
                walletRefreshed: action.payload,
            }
        }
        case VERIFY_WALLET_SUCCESS: {
            return {
                ...state,
                walletVerified: action.payload,
            }
        }
        default:
            return state;
    }
}

