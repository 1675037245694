import {
    GET_ALL_USER_ACCOUNTS_SUCCESS,
    GET_USER_ACCOUNT_SUCCESS,
    UPDATE_USER_ACCOUNT_SUCCESS,
    DELETE_USER_ACCOUNT_SUCCESS,
    AUTHUSER_REFRESHED,
    AUTHUSERS_REFRESHED,
    ACCOUNT_REFRESHED,
    ACCOUNTS_REFRESHED
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    userAccountList: [],
    userAccount: '',
    accountRefreshed: false,
    accountsRefreshed: false
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_USER_ACCOUNTS_SUCCESS: {
            return {
                ...state,
                loader: false,
                userAccountList: action.payload
            }
        }
        case GET_USER_ACCOUNT_SUCCESS: {
            return {
                ...state,
                loader: false,
                userAccount: action.payload
            }
        }
        case UPDATE_USER_ACCOUNT_SUCCESS: {
            return {
                ...state,
                loader: false
            }
        }
        case DELETE_USER_ACCOUNT_SUCCESS: {
            return {
                ...state,
                loader: false
            }
        }
        case ACCOUNT_REFRESHED: {
            return {
                ...state,
                accountRefreshed: action.payload
            }
        }
        case ACCOUNTS_REFRESHED: {
            return {
                ...state,
                accountsRefreshed: action.payload
            }
        }
        default:
            return state;
    }
}