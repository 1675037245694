import { API } from 'components/networkVisualizer/API/api'
import { CategoryTypes } from 'components/networkVisualizer/networkVisualizerState/sharedTypes'
import { setVisualizerData } from 'components/networkVisualizer/networkVisualizerState/store/general/actions'
import { SetVisualizerDataPayload } from 'components/networkVisualizer/networkVisualizerState/store/general/types'
import { all, call, put, SagaReturnType, select } from 'redux-saga/effects'
import { AppState } from 'store'
import { getAssetDataFormatter } from '../../dataFormatters/getAssetDataFormatter'
import { getCooperationsFormatter } from '../../dataFormatters/getCooperationsFormatter'
import { tokenIdCreator } from '../../tokenIdCreator'
import { fetchAndSetConnections } from '../fetchAndSetConnections/fetchAndSetConnections'
import { CONNECTIONS_FETCH_LIMIT } from './fetchAndSetData'

export const currentConnectionsSelector = (
  state: AppState,
  id: string | number,
  type: CategoryTypes
) => state.networkVisualizerState.general.data[type][id].connections

/**
 * Fetch tokens, utilities, or resources data. Projects have their own function.
 * Will also fetch relevant connections.
 */
export function* fetchAndSetAssetData(
  id: string | number,
  type: Exclude<CategoryTypes, 'projects'>,
  platformId?: string | number
) {
  let connectionTypeToGet: CategoryTypes =
    type === 'tokens' ? 'utilities' : 'tokens'

  const {
    assetResponse,
    //If the filter is not being rest on node change, remove the parallel connections fetch as the asset might still be hidden.
    connectionsResponse,
  }: {
    assetResponse: SagaReturnType<typeof API.getAssetData>
    connectionsResponse: SagaReturnType<typeof API.getCooperations>
  } = yield all({
    assetResponse: call(API.getAssetData, type, id, platformId),
    //@ts-ignore
    connectionsResponse: call(
      API.getCooperations,
      type,
      id,
      connectionTypeToGet,
      {
        limit: CONNECTIONS_FETCH_LIMIT,
      },
      platformId
    ),
  })

  const assetData: ReturnType<typeof getAssetDataFormatter.toGeneralData> = yield call(
    getAssetDataFormatter.toGeneralData,
    assetResponse.data
  )
  const connectionsData: ReturnType<typeof getCooperationsFormatter.toGeneralData> = yield call(
    getCooperationsFormatter.toGeneralData,
    connectionsResponse.data
  )

  //Ensure the referenceId is used when referencing any part of the data payload object. Do not use for fetching data, use the 'id' instead.
  const referenceId = type === 'tokens' ? tokenIdCreator(id, platformId) : id

  const data: SetVisualizerDataPayload = {
    //Also contains a project so spreading that info.
    ...assetData,
    [type]: {
      ...assetData[type],
      [referenceId]: {
        ...assetData[type][referenceId],
        connections: {
          //Spread the project connection
          ...assetData[type][referenceId].connections,
          ...connectionsData.connections,
        },
      },
    },
    //Spread the data for the connection items
    ...connectionsData.data,
  }

  yield put(setVisualizerData(data))

  const currentConnections = yield select(
    (state: AppState) =>
      state.networkVisualizerState.general.data[type][referenceId].connections
  )

  // console.log('fetchAndSet data ', { data, currentConnections })

  let currentConnectionsCount = currentConnections
    ? Object.values(currentConnections).reduce(
        (t: number, c: any[]) => t + c.length,
        0
      )
    : 0

  /**
   * Criteria 1: Fetched connections are less than the limit. This currently only applies to tokens as the utilities and resources only fetch 1 type of connection. For tokens this will continue down the list of available connection types.
   * Criteria 2: The current set connections are less than the limit. This is to prevent additional fetches if the data has been previously set (By returning to a previously viewed asset). If not the next set of connections will be fetched.
   *  */

  if (
    connectionsResponse.data.cooperations.length < CONNECTIONS_FETCH_LIMIT &&
    currentConnectionsCount < CONNECTIONS_FETCH_LIMIT
  ) {
    // console.log('calling fetch and set connections')
    yield call(fetchAndSetConnections, id, type, platformId)
  }
}
