import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';
import { Avatar } from '@material-ui/core';
import logoTextIcon from 'assets/images/logo_text_icon.png';


export const AppLoaderFull = React.memo((explorer) => {

    const [message, setMessage] = useState('');

    let infoMessages = [
        "Give your NFT a utility and a purpose in life", 
        "Create NFTs and link them across games & brands",
        "Manage your NFT Blueprints & Distribution in one place",
        "Cooperate with fellow projects and grow a unique asset network"
    ];

    useEffect(()=> {

        let randomMessage = Math.floor(Math.random() * infoMessages.length);
        setMessage(randomMessage);

    }, [])



    const {appLoader} = useSelector(({isFetching}) => isFetching);

    if (!appLoader) return null

    return (
        <div className="row no-gutters loader-container-app justify-content-center align-items-space">
            <div className="animated slideInUpTiny animation-duration-3 col-12 loader-view-app d-flex justify-content-center align-items-end mb-4">
                    <div className="loader-view-app-circle-outer">
                        <CircularProgress size={100} thickness={2} />
                    </div>
            </div>
            {<div className="animated slideInUpTiny animation-duration-3 col-12 loader-view-app-logo d-flex justify-content-start align-items-end mb-4">
                <img style={{maxWidth: "130px"}}
                    src={logoTextIcon}
                />
            </div>}
            

            <div className="animated slideInUpTiny animation-duration-10 col-12 d-flex d-block justify-content-center align-items-start mt-4">
                <div className="row flex-column justify-content-center">
                    <h2 style={{color: "white", textAlign: "center", paddingBottom: "20px"}}><strong>{!explorer? "Welcome..." : "Loading..."}</strong></h2>
                    <h1 style={{color: "white", textAlign: "center", padding: "0 20% 0 20%"}}>{infoMessages[message]}</h1>
                </div>
            </div>
        </div> 
    )
})