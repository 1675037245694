import {
    CREATE_TOKEN,
    GET_TOKEN_BLUEPRINT,
    GET_TOKEN_STATS,
    UPDATE_URI,
    CREATE_TOKEN_SUCCESS,
    GET_TOKEN_BLUEPRINT_SUCCESS,
    GET_TOKEN_STATS_SUCCESS,
    UPDATE_URI_SUCCESS,
    GET_ALL_TOKEN_BLUEPRINTS,
    GET_ALL_TOKEN_BLUEPRINTS_SUCCESS,
    GET_TOKEN_METADATA_BY_URI,
    GET_TOKEN_METADATA_BY_URI_SUCCESS,
    UPDATE_METADATA_SUCCESS,
    UPDATE_METADATA,
    LOAD_MORE_TOKEN_BLUEPRINTS_SUCCESS,
    GET_METADATA,
    GET_METADATA_SUCCESS,
    METADATA_UPDATED,
    TOKEN_REFRESHED,
    TOKENS_REFRESHED

} from 'constants/ActionTypes';

export const createToken_ = (data) => {
    return {
        type: CREATE_TOKEN,
        payload: data
    };
};

export const createTokenSuccess_ = (tokenId) => {
    return {
        type: CREATE_TOKEN_SUCCESS,
        payload: tokenId
    };
};

export const getTokenBlueprint_ = (tokenId) => {
    return {
        type: GET_TOKEN_BLUEPRINT,
        payload: tokenId
    };
};


export const getTokenBlueprintSuccess_ = (tokenBlueprint) => {
    return {
        type: GET_TOKEN_BLUEPRINT_SUCCESS,
        payload: tokenBlueprint
    };
};

export const getAllTokenBlueprints_ = (data) => {
    return {
        type: GET_ALL_TOKEN_BLUEPRINTS,
        payload: data
    };
};

export const getAllTokenBlueprintsSuccess_ = (tokens) => {
    return {
        type: GET_ALL_TOKEN_BLUEPRINTS_SUCCESS,
        payload: tokens
    };
};

export const loadMoreTokenBlueprintsSuccess_ = (tokens) => {
    return {
        type: LOAD_MORE_TOKEN_BLUEPRINTS_SUCCESS,
        payload: tokens
    };
};

export const getTokenStats_ = (data) => {
    return {
        type: GET_TOKEN_STATS,
        payload: data
    };
};

export const getTokenStatsSuccess_ = (tokenStats) => {
    return {
        type: GET_TOKEN_STATS_SUCCESS,
        payload: tokenStats
    };
};

export const updateMetaData_ = (data) => {
    return {
        type: UPDATE_METADATA,
        payload: data
    };
};

export const updateMetaDataSuccess_ = (data) => {
    return {
        type: UPDATE_METADATA_SUCCESS,
        payload: data
    };
};

export const getMetadata_ = (data) => {
    return {
        type: GET_METADATA,
        payload: data
    };
};

export const getMetaDataSuccess_ = (data) => {
    return {
        type: GET_METADATA_SUCCESS,
        payload: data
    };
};

export const updateUri_ = (data) => {
    return {
        type: UPDATE_URI,
        payload: data
    };
};

export const updateUriSuccess_ = () => {
    return {
        type: UPDATE_URI_SUCCESS
    };
};

export const getTokenMetadataByUri_ = () => {
    return {
        type: GET_TOKEN_METADATA_BY_URI
    };
};

export const getTokenMetadataByUriSuccess_ = () => {
    return {
        type: GET_TOKEN_METADATA_BY_URI_SUCCESS
    };
};

export const metadataUpdated_ = (bool) => {
    return {
        type: METADATA_UPDATED,
        payload: bool
    };
};

export const tokenRefreshed_ = (bool) => {
    return {
        type: TOKEN_REFRESHED,
        payload: bool
    };
};
export const tokensRefreshed_ = (bool) => {
    return {
        type: TOKENS_REFRESHED,
        payload: bool
    };
};