import { CategoryTypes } from '../networkVisualizerState/sharedTypes'

export function categoryTypeToSingular(type: CategoryTypes): string {
  switch (type) {
    case 'tokens':
      return 'token'
    case 'utilities':
      return 'utility'
    case 'resources':
      return 'resource'
    case 'projects':
      return 'project'
    default:
      return type
  }
}
