import {
    GET_ALL_TRANSACTIONS_SUCCESS,
    GET_TRANSACTION_SUCCESS,
    TXS_REFRESHED,
    TX_REFRESHED
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    transactionList: '',
    transaction: '',
    txRefreshed: false,
    txsRefreshed: false
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_TRANSACTIONS_SUCCESS: {
            return {
                ...state,
                transactionList: action.payload
            }
        }
        case GET_TRANSACTION_SUCCESS: {
            return {
                ...state,
                transaction: action.payload
            }
        }
        case TX_REFRESHED: {
            return {
                ...state,
                txRefreshed: action.payload
            }
        }
        case TXS_REFRESHED: {
            return {
                ...state,
                txsRefreshed: action.payload
            }
        }
        default:
            return state;
    }
}