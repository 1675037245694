import { TokenResponse } from 'components/networkVisualizer/API/types/getAssetDataResponse/token'
import {
  Projects,
  Tokens,
} from 'components/networkVisualizer/networkVisualizerState/store/general/types'
import { tokenIdCreator } from './../../../tokenIdCreator'

interface TokenFormatterOutput {
  tokens: Tokens
  projects: Projects
}

export function tokenFormatter(response: TokenResponse): TokenFormatterOutput {
  let tokens: Tokens = {}

  let {
    tokenId,
    name,
    nonFungible,
    supplyModel,
    totalSupply,
    circulatingSupply,
    meltValue,
    meltFeeRatio,
    transferable,
    transferFeeSettings,
    appName,
    cooperationCounts,
    project,
    metadata,
    platformId,
  } = response

  let projects: Projects = project
    ? {
        [project.projectId]: {
          image: project?.avatarImage?.thumbnail,
          name: project?.name,
        },
      }
    : {}

  let referenceId = tokenIdCreator(tokenId, platformId)

  tokens[referenceId] = {
    tokenId,
    name,
    image: metadata?.image ?? null,
    cooperationsCount: cooperationCounts,
    nonFungible,
    supplyModel,
    totalSupply,
    circulatingSupply,
    meltValue,
    meltFeeRatio: meltFeeRatio ?? undefined,
    transferable,
    transferFee: transferFeeSettings?.value ?? undefined,
    appName,
    projectName: project?.name,
    //Empty array is assigned as it's used to determine whether the asset has a project or not
    connections: {
      projects: project ? [project?.projectId] : [],
    },
    platformId,
  }

  return {
    tokens,
    projects,
  }
}
