import { GET_ALL_CHAT_USERS, GET_ALL_CHAT_USERS_SUCCESS, GET_ALL_CONTACT_USERS, GET_ALL_CONTACT_USERS_SUCCESS, GET_CONVERSATION, GET_CONVERSATION_SUCCESS, LOAD_PREV_MESSAGES, ON_USER_SELECT, ON_USER_SELECT_SUCCESS, SEND_MEDIA_MESSAGE, SEND_MEDIA_MESSAGE_SUCCESS, SEND_TEXT_MESSAGE, SEND_TEXT_MESSAGE_SUCCESS, SET_ACTIVE_CHATUSERS, SET_ACTIVE_CONVERSATION, UPDATE_MESSAGE_STATUS, LOAD_PREV_MESSAGES_SUCCESS, UPDATE_ALL_CHAT_USERS, LOAD_MORE_CHAT_USERS_SUCCESS, LOAD_MORE_CONTACT_USERS_SUCCESS, CONVERSATIONS_REFRESHED, MESSAGES_REFRESHED } from "constants/ActionTypes";

export const getAllChatUsers_ = (data) => {
    return {
        type: GET_ALL_CHAT_USERS,
        payload: data
    };
};


export const getAllChatUsersSuccess_ = (userlist) => {
    return {
        type: GET_ALL_CHAT_USERS_SUCCESS,
        payload: userlist
    };
};

export const loadMoreChatUsersSuccess_ = (userlist) => {
    return {
        type: LOAD_MORE_CHAT_USERS_SUCCESS,
        payload: userlist
    };
};

export const getAllContactUsers_ = (data) => {
    return {
        type: GET_ALL_CONTACT_USERS,
        payload: data
    };
};

export const getAllContactUsersSuccess_ = (userlist) => {
    return {
        type: GET_ALL_CONTACT_USERS_SUCCESS,
        payload: userlist
    };
};

export const loadMoreContactUsersSuccess_ = (userlist) => {
    return {
        type: LOAD_MORE_CONTACT_USERS_SUCCESS,
        payload: userlist
    };
};

export const onUserSelect_ = (userid) => {
    return {
        type: ON_USER_SELECT,
        payload: userid
    };
}

export const onUserSelectSuccess_ = (user) => {
    return {
        type: ON_USER_SELECT_SUCCESS,
        payload: user
    };
}

export const sendTextMessage_ = (payl) => {
    return {
        type: SEND_TEXT_MESSAGE,
        payload: payl
    };
}

export const sendTextMessageSuccess_ = (payl) => {
    return {
        type: SEND_TEXT_MESSAGE_SUCCESS,
        payload: payl
    };
}

export const updateMessageStatus_ = (payl) => {
    return {
        type: UPDATE_MESSAGE_STATUS,
        payload: payl
    };
}

export const sendMediaMessage_ = (payl) => {
    return {
        type: SEND_MEDIA_MESSAGE,
        payload: payl
    };
}

export const sendMediaMessageSuccess_ = (payl) => {
    return {
        type: SEND_MEDIA_MESSAGE_SUCCESS,
        payload: payl
    };
}

export const getConversation_ = (payl) => {
    return {
        type: GET_CONVERSATION,
        payload: payl
    };
}

export const getConversationSuccess_ = (payl) => {
    return {
        type: GET_CONVERSATION_SUCCESS,
        payload: payl
    };
}

export const setActiveChatUsers_ = (payl) => {
    return {
        type: SET_ACTIVE_CHATUSERS,
        payload: payl
    };
}

export const setActiveConversation_ = (payl) => {
    return {
        type: SET_ACTIVE_CONVERSATION,
        payload: payl
    };
}

export const loadPrevMessages_ = (payl) => {
    return {
        type: LOAD_PREV_MESSAGES,
        payload: payl
    };
}

export const loadPrevMessagesSuccess_ = (payl) => {
    return {
        type: LOAD_PREV_MESSAGES_SUCCESS,
        payload: payl
    };
}

export const updateAllChatUsers_ = (payl) => {
    return {
        type: UPDATE_ALL_CHAT_USERS,
        payload: payl
    };
}


export const conversationsRefreshed_ = (payl) => {
    return {
        type: CONVERSATIONS_REFRESHED,
        payload: payl
    };
}

export const messagesRefreshed_ = (payl) => {
    return {
        type: MESSAGES_REFRESHED,
        payload: payl
    };
}


