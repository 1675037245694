import React,  {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import Avatar from '@material-ui/core/Avatar';
import DashNav from '../../../app/routes/Projects/Dashboard/DashNav';
import {NavLink, withRouter} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import DashTabs from "app/routes/Projects/Dashboard/DashTabs";
import { transparent1X1 } from "aws-amplify-react";
import { Icon } from "@material-ui/core";
import SettingsIcon from '@material-ui/icons/Settings';
import DocsNav from "./DocsNav";
import utilifyLogo from 'assets/images/logo_navbar.svg';

const DocsHeader = (props) => {
    
    const {project} = useSelector(({project}) => project);

    return (
        <div className="jr-profile-banner" style={{background: "linear-gradient(180deg, #01122dff, 50%, #023256ff 70%, #03446cff 100%)"}}>


            <div className="jr-profile-container">

                

                <div className="jr-profile-banner-top-pd">
                    <div className="jr-profile-banner-top-left">
                        <div className="jr-profile-banner-avatar">
                            <Avatar className="size-60" alt="..." src={utilifyLogo}/>
                        </div>
                        <div className="jr-profile-banner-avatar-info">
                            <h1 className="mb-2 jr-mb-sm-3 jr-fs-xxl jr-font-weight-light">{"Utilify Docs"}</h1>
                        </div>
                    </div>
                </div>
                {}
                <div className="jr-profile-banner-bottom">
                    <div className="jr-tab-list">
                        <DocsNav/>
                    </div>
                    <span className="jr-link jr-profile-setting">
                        <span className="d-inline-flex align-middle ml-1 jr-ml-sm-0">
                            <NavLink 
                                className="text-white mr-4"
                                style={{ textDecoration: 'none' }}
                                activeClassName="text-black"
                                to={`https://test.platform.utilify.io`}>
                                    Platform
                            </NavLink>
                            <NavLink 
                                className="text-white"
                                style={{ textDecoration: 'none' }}
                                activeClassName="text-black"
                                to={`https://utilify.io`}>
                                    Home
                            </NavLink>
                        </span>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default withRouter(DocsHeader);

