import {
    CREATE_PROJECT,
    CREATE_PROJECT_SUCCESS,
    GET_ALL_PROJECTS,
    GET_ALL_PROJECTS_SUCCESS,
    GET_PROJECT,
    GET_PROJECT_SUCCESS,
    UPDATE_PROJECT,
    UPDATE_PROJECT_SUCCESS,
    DELETE_PROJECT,
    DELETE_PROJECT_SUCCESS,
    ADD_PROJECT_EDITOR,
    ADD_PROJECT_EDITOR_SUCCESS,
    GET_PROJECT_FOLLOWERS,
    GET_PROJECT_FOLLOWERS_SUCCESS,
    FOLLOW_PROJECT,
    FOLLOW_PROJECT_SUCCESS,
    UNFOLLOW_PROJECT,
    UNFOLLOW_PROJECT_SUCCESS,
    GET_ALL_PROJECT_EDITORS,
    GET_ALL_PROJECT_EDITORS_SUCCESS,
    DELETE_PROJECT_EDITOR,
    DELETE_PROJECT_EDITOR_SUCCESS,
    GET_PROJECT_SETTINGS,
    UPDATE_PROJECT_SETTINGS,
    GET_PROJECT_APIKEYS,
    CREATE_PROJECT_APIKEYS,
    DELETE_PROJECT_APIKEYS,
    GET_PROJECT_SETTINGS_SUCCESS,
    UPDATE_PROJECT_SETTINGS_SUCCESS,
    GET_PROJECT_APIKEYS_SUCCESS,
    CREATE_PROJECT_APIKEYS_SUCCESS,
    DELETE_PROJECT_APIKEYS_SUCCESS,
    CLEAR_CREATED_APIKEYS_SUCCESS,
    SET_CURR_PROJECT_ID,
    GET_PROJECT_PROFILE_SUCCESS,
    GET_PROJECT_PROFILE,
    PIN_ASSET_TO_PROJECT,
    UNPIN_ASSET_FROM_PROJECT,
    GET_PROJECT_API_SETTINGS,
    GET_PROJECT_API_SETTINGS_SUCCESS,
    UPDATE_PROJECT_API_SETTINGS_SUCCESS,
    UPDATE_PROJECT_API_SETTINGS,
    GET_ALL_PROJECTS_FOR_REQUEST_SUCCESS,
    SELECT_PROJECT,
    SELECT_PROJECT_SUCCESS,
    LOAD_MORE_PROJECTS_SUCCESS,
    LOAD_MORE_PROJECT_EDITORS_SUCCESS,
    GET_GAS_PRICE,
    GET_GAS_PRICE_SUCCESS,
    PINNED_TO_PROJECT_SUCCESS,
    UNPINNED_FROM_PROJECT_SUCCESS,
    PROJECT_REFRESHED,
    PROJECTS_REFRESHED,
    SETTINGS_REFRESHED,
    SETTING_REFRESHED,
    API_SETTINGS_REFRESHED,
    GET_ALL_PROJECT_BLUEPRINTS,
    GET_ALL_PROJECT_BLUEPRINTS_SUCCESS,
    CACHE_PROJECT_BLUEPRINTS,
    CACHE_PROJECT_BLUEPRINTS_SUCCESS
} from 'constants/ActionTypes';

export const createProject_ = (projectData) => {
    return {
        type: CREATE_PROJECT,
        payload: projectData
    };
};

export const createProjectSuccess_ = (project) => {
    return {
        type: CREATE_PROJECT_SUCCESS,
        payload: project
    };
};

export const getAllProjects_ = (userId) => {
    return {
        type: GET_ALL_PROJECTS,
        payload: userId
    };
};

export const getAllProjectsSuccess_ = (projects) => {
    return {
        type: GET_ALL_PROJECTS_SUCCESS,
        payload: projects
    };
};

export const loadMoreProjectsSuccess_ = (projects) => {
    return {
        type: LOAD_MORE_PROJECTS_SUCCESS,
        payload: projects
    };
};

export const getAllProjectsForRequestSuccess_ = (projects) => {
    return {
        type: GET_ALL_PROJECTS_FOR_REQUEST_SUCCESS,
        payload: projects
    };
};

export const getProject_ = (projectId) => {
    return {
        type: GET_PROJECT,
        payload: projectId
    };
};

export const selectProject_ = (data) => {
    return {
        type: SELECT_PROJECT,
        payload: data
    };
};

export const selectProjectSuccess_ = (data) => {
    return {
        type: SELECT_PROJECT_SUCCESS,
        payload: data
    };
};

export const getProjectSuccess_ = (projectId) => {
    return {
        type: GET_PROJECT_SUCCESS,
        payload: projectId
    };
};

export const getProjectProfile_ = (projectId) => {
    return {
        type: GET_PROJECT_PROFILE,
        payload: projectId
    };
};

export const getProjectProfileSuccess_ = (projectId) => {
    return {
        type: GET_PROJECT_PROFILE_SUCCESS,
        payload: projectId
    };
};

export const updateProject_ = (data) => {
    return {
        type: UPDATE_PROJECT,
        payload: data
    };
};

export const updateProjectSuccess_ = () => {
    return {
        type: UPDATE_PROJECT_SUCCESS
    };
};

export const deleteProject_ = (projectId) => {
    return {
        type: DELETE_PROJECT,
        payload: projectId
    };
};

export const deleteProjectSuccess_ = () => {
    return {
        type: DELETE_PROJECT_SUCCESS
    };
};

export const addProjectEditor_ = (data) => {
    return {
        type: ADD_PROJECT_EDITOR,
        payload: data
    };
};

export const addProjectEditorSuccess_ = (user) => {
    return {
        type: ADD_PROJECT_EDITOR_SUCCESS,
        payload: user
    };
};

export const getAllProjectEditors_ = (data) => {
    return {
        type: GET_ALL_PROJECT_EDITORS,
        payload: data
    };
};

export const getAllProjectEditorsSuccess_ = (editors) => {
    return {
        type: GET_ALL_PROJECT_EDITORS_SUCCESS,
        payload: editors
    };
};

export const getAllProjectBlueprints_ = (data) => {
    return {
        type: GET_ALL_PROJECT_BLUEPRINTS,
        payload: data
    };
};

export const getAllProjectBlueprintsSuccess_ = (blueprints) => {
    return {
        type: GET_ALL_PROJECT_BLUEPRINTS_SUCCESS,
        payload: blueprints
    };
};

export const cacheProjectBlueprints_ = (data) => {
    return {
        type: CACHE_PROJECT_BLUEPRINTS,
        payload: data
    };
};

export const cacheProjectBlueprintsSuccess_ = (data) => {
    return {
        type: CACHE_PROJECT_BLUEPRINTS_SUCCESS,
        payload: data
    };
};


export const loadMoreProjectEditorsSuccess_ = (editors) => {
    return {
        type: LOAD_MORE_PROJECT_EDITORS_SUCCESS,
        payload: editors
    };
};

export const deleteProjectEditor_ = (data) => {
    return {
        type: DELETE_PROJECT_EDITOR,
        payload: data
    };
};

export const deleteProjectEditorSuccess_ = () => {
    return {
        type: DELETE_PROJECT_EDITOR_SUCCESS
    };
};

export const getProjectFollowers_ = (project) => {
    return {
        type: GET_PROJECT_FOLLOWERS,
        payload: project
    };
};

export const getProjectFollowersSuccess_ = (followers) => {
    return {
        type: GET_PROJECT_FOLLOWERS_SUCCESS,
        payload: followers
    };
};

export const followProject_ = (data) => {
    return {
        type: FOLLOW_PROJECT,
        payload: data
    };
};

export const followProjectSuccess_ = () => {
    return {
        type: FOLLOW_PROJECT_SUCCESS
    };
};

export const unfollowProject_ = (data) => {
    return {
        type: UNFOLLOW_PROJECT,
        payload: data
    };
};

export const unfollowProjectSuccess_ = () => {
    return {
        type: UNFOLLOW_PROJECT_SUCCESS
    };
};

export const getProjectSettings_ = (data) => {
    return {
        type: GET_PROJECT_SETTINGS,
        payload: data
    };
};

export const getProjectSettingsSuccess_ = (data) => {
    return {
        type: GET_PROJECT_SETTINGS_SUCCESS,
        payload: data
    };
};

export const updateProjectSettings_ = (data) => {
    return {
        type: UPDATE_PROJECT_SETTINGS,
        payload: data
    };
};

export const updateProjectSettingsSuccess_ = () => {
    return {
        type: UPDATE_PROJECT_SETTINGS_SUCCESS
    };
};

export const getProjectApikeys_ = (data) => {
    return {
        type: GET_PROJECT_APIKEYS,
        payload: data
    };
};

export const getProjectApikeysSuccess_ = (data) => {
    return {
        type: GET_PROJECT_APIKEYS_SUCCESS,
        payload: data
    };
};

export const createProjectApikeys_ = (data) => {
    return {
        type: CREATE_PROJECT_APIKEYS,
        payload: data
    };
};

export const createProjectApikeysSuccess_ = (data) => {
    return {
        type: CREATE_PROJECT_APIKEYS_SUCCESS,
        payload: data
    };
};

export const deleteProjectApikeys_ = (data) => {
    return {
        type: DELETE_PROJECT_APIKEYS,
        payload: data
    };
};

export const deleteProjectApikeysSuccess_ = () => {
    return {
        type: DELETE_PROJECT_APIKEYS_SUCCESS
    };
};

export const clearCreatedApikeysSuccess_ = () => {
    return {
        type: CLEAR_CREATED_APIKEYS_SUCCESS
    };
};

export const setCurrProjectId_ = (projectId) => {
    return {
        type: SET_CURR_PROJECT_ID,
        payload: projectId
    };
};

export const pinAssetToProject_ = (data) => {
    return {
        type: PIN_ASSET_TO_PROJECT,
        payload: data
    };
};

export const pinnedToProjectSuccess_ = (data) => {
    return {
        type: PINNED_TO_PROJECT_SUCCESS,
        payload: data
    };
};

export const unpinAssetFromProject_ = (data) => {
    return {
        type: UNPIN_ASSET_FROM_PROJECT,
        payload: data
    };
};

export const unpinnedFromProjectSuccess_ = (data) => {
    return {
        type: UNPINNED_FROM_PROJECT_SUCCESS,
        payload: data
    };
};

export const getProjectApiSettings_ = (data) => {
    return {
        type: GET_PROJECT_API_SETTINGS,
        payload: data
    };
};

export const getProjectApiSettingsSuccess_ = (data) => {
    return {
        type: GET_PROJECT_API_SETTINGS_SUCCESS,
        payload: data
    };
};

export const updateProjectApiSettings_ = (data) => {
    return {
        type: UPDATE_PROJECT_API_SETTINGS,
        payload: data
    };
};

export const updateProjectApiSettingsSuccess_ = (data) => {
    return {
        type: UPDATE_PROJECT_API_SETTINGS_SUCCESS,
        payload: data
    };
};

export const getGasPrice_ = () => {
    return {
        type: GET_GAS_PRICE
    };
};

export const getGasPriceSuccess_ = (data) => {
    return {
        type: GET_GAS_PRICE_SUCCESS,
        payload: data
    };
};

export const projectRefreshed_ = (data) => {
    return {
        type: PROJECT_REFRESHED,
        payload: data
    };
};

export const projectsRefreshed_ = (data) => {
    return {
        type: PROJECTS_REFRESHED,
        payload: data
    };
};

export const settingRefreshed_ = (data) => {
    return {
        type: SETTING_REFRESHED,
        payload: data
    };
};

export const settingsRefreshed_ = (data) => {
    return {
        type: SETTINGS_REFRESHED,
        payload: data
    };
};

export const apiSettingsRefreshed_ = (data) => {
    return {
        type: API_SETTINGS_REFRESHED,
        payload: data
    };
};