import {
    CREATE_TOKEN_SUCCESS,
    GET_TOKEN_BLUEPRINT_SUCCESS,
    GET_TOKEN_STATS_SUCCESS,
    UPDATE_METADATA_SUCCESS,
    UPDATE_URI_SUCCESS,
    GET_ALL_TOKEN_BLUEPRINTS_SUCCESS,
    GET_TOKEN_METADATA_BY_URI_SUCCESS,
    GET_METADATA_SUCCESS,
    METADATA_UPDATED,
    TOKEN_REFRESHED,
    TOKENS_REFRESHED
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    tokenList: '',
    createdTokenBlueprint: '',
    tokenBlueprint: '',
    itemUri: '',
    tokenStats: '',
    tokenMetadata: '',
    metadataUpdated: false,
    tokenRefreshed: false,
    tokensRefreshed: false
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CREATE_TOKEN_SUCCESS: {
            return {
                ...state,
                loader: false,
                createdTokenBlueprint: action.payload
            }
        }
        case GET_TOKEN_BLUEPRINT_SUCCESS: {
            return {
                ...state,
                tokenBlueprint: action.payload
            }
        }
        case GET_TOKEN_STATS_SUCCESS: {
            return {
                ...state,
                tokenStats: action.payload
               
            }
        }
        case GET_ALL_TOKEN_BLUEPRINTS_SUCCESS: {
            return {
                ...state,
                tokenList: action.payload
               
            }
        }
        case UPDATE_METADATA_SUCCESS: {
            return {
                ...state,
                itemUri: action.payload,
                metadataUpdated: true
            }
        }
        case GET_METADATA_SUCCESS: {
            return {
                ...state,
                tokenMetadata: action.payload
            }
        }
        case UPDATE_URI_SUCCESS: {
            return {
                ...state,
                loader: false
            }
        }

        case GET_TOKEN_METADATA_BY_URI_SUCCESS: {
            return {
                ...state,
                metadata: action.payload
            }
        }
        case METADATA_UPDATED: {
            return {
                ...state,
                metadataUpdated: false
            }
        }
        case TOKENS_REFRESHED: {
            return {
                ...state,
                tokensRefreshed: action.payload
            }
        }
        case TOKEN_REFRESHED: {
            return {
                ...state,
                tokenRefreshed: action.payload
            }
        }
        default:
            return state;
    }
}