import {
    UPDATE_IMG_BY_DATA,
    UPDATE_IMG_BY_DATA_SUCCESS,
    SET_SELECTED_IMAGE, DELETE_IMG,
    SET_SELECTED_BANNER, UPDATE_BANNER_BY_DATA, UPDATE_BANNER_BY_DATA_SUCCESS, UPLOAD_SUCCESS, DELETE_SUCCESS, DELETE_IMG_SUCCESS, IMG_REFRESHED, IMGS_REFRESHED
} from 'constants/ActionTypes'

export const updateImage_ = (data) => {
    return {
        type: UPDATE_IMG_BY_DATA,
        payload: data
    };
};

export const updateImageSuccess_ = (imgUrl) => {
    return {
        type: UPDATE_IMG_BY_DATA_SUCCESS,
        payload: imgUrl
    };
};

export const setSelectedImage_ = (imageFiles) => {
    return {
        type: SET_SELECTED_IMAGE,
        payload: imageFiles
    };
};

export const setSelectedBanner_ = (imageFiles) => {
    return {
        type: SET_SELECTED_BANNER,
        payload: imageFiles
    };
};

export const updateBanner_ = (data) => {
    return {
        type: UPDATE_BANNER_BY_DATA,
        payload: data
    };
};

export const updateBannerSuccess_ = (imgUrl) => {
    return {
        type: UPDATE_BANNER_BY_DATA_SUCCESS,
        payload: imgUrl
    };
};

export const uploadSuccess_ = (imgUrl) => {
    return {
        type: UPLOAD_SUCCESS,
        payload: imgUrl
    };
};

export const deleteImgSuccess_ = (imgUrl) => {
    return {
        type: DELETE_IMG_SUCCESS,
        payload: imgUrl
    };
};

export const deleteImg_ = (imageFiles) => {
    return {
        type: DELETE_IMG,
        payload: imageFiles
    };
};

export const imgRefreshed_ = (data) => {
    return {
        type: IMG_REFRESHED,
        payload: data
    };
};

export const imgsRefreshed_ = (data) => {
    return {
        type: IMGS_REFRESHED,
        payload: data
    };
};


