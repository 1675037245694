import AppsIcon from '@material-ui/icons/Apps'
import CardMembershipIcon from '@material-ui/icons/CardMembership'
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import SportsEsportsIcon from '@material-ui/icons/SportsEsports'
import {
  linkIcon,
  networkIcon,
} from 'components/networkVisualizer/assets/icons'
import { defaultImages } from 'components/networkVisualizer/assets/variables/defaultImages'
import { cooperationsCountAdder } from 'components/networkVisualizer/modules/cooperationsCountAdder'
import { numberWithCommas } from 'components/networkVisualizer/modules/numberManipulators'
import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'store'
import { AssetNameAndType, DisplayImage, ItemDetail } from './sharedComponents'

interface IsProps {
  id: string | number
}

export const UtilityPopup = ({ id }: IsProps) => {
  const data = useSelector(
    (state: AppState) => state.networkVisualizerState.general.data.utilities[id]
  )

  if (data === undefined) return null

  let { image, name, projectName, cooperationsCount, type } = data

  let totalCooperations = cooperationsCount
    ? cooperationsCountAdder(cooperationsCount)
    : undefined

  const typeDetails = utilityTypeIconSelector(type)

  return (
    <div className='popup-container'>
      <DisplayImage image={image || defaultImages.utilities} alt={name} />
      <div className='body-container'>
        <AssetNameAndType name={name} type={'utility'} />

        <ItemDetail
          // visible={type !== undefined}
          image={typeDetails.image}
          imageAlt='utility type'
        >
          <p className='secondary-text'>
            <span> {typeDetails.name}</span>
          </p>
        </ItemDetail>
        <ItemDetail
          visible={projectName !== undefined && projectName !== null}
          image={linkIcon}
          imageAlt='project name'
        >
          <p className='secondary-text'>
            Project <span> {projectName}</span>
          </p>
        </ItemDetail>
        <ItemDetail
          visible={
            cooperationsCount !== undefined && cooperationsCount !== null
          }
          image={networkIcon}
          imageAlt='total cooperations'
        >
          {totalCooperations && (
            <p className='secondary-text'>
              <span>{numberWithCommas(totalCooperations)}</span> total
              cooperations
            </p>
          )}
        </ItemDetail>
      </div>
    </div>
  )
}

function utilityTypeIconSelector(type: string | undefined | null) {
  switch (type) {
    case 'gameItem':
      return {
        name: 'Game Item',
        image: SportsEsportsIcon,
      }
    case 'voucher':
      return {
        name: 'Voucher',
        image: ConfirmationNumberIcon,
      }
    case 'discount':
      return {
        name: 'Discount',
        image: LocalOfferIcon,
      }
    case 'membership':
      return {
        name: 'Membership',
        image: CardMembershipIcon,
      }
    default:
      return {
        name: 'Other Type',
        image: AppsIcon,
      }
  }
}
