import { CategoryTypes } from '../../sharedTypes'
import {
  ChangeItemDetailsCooperationsTabIndex,
  CHANGE_ITEM_DETAILS_COOPERATIONS_TAB_INDEX,
  FetchItemDetailsCooperations,
  FETCH_ITEM_DETAILS_COOPERATIONS,
  HideItemDetails,
  HIDE_ITEM_DETAILS,
  ResetVisualizerItemDetailsState,
  RESET_VISUALIZER_ITEM_DETAILS_STATE,
  SetItemDetails,
  SetItemDetailsCooperations,
  SetItemDetailsCooperationsTabIndex,
  SetItemDetailsFetching,
  SET_ITEM_DETAILS,
  SET_ITEM_DETAILS_COOPERATIONS,
  SET_ITEM_DETAILS_COOPERATIONS_TAB_INDEX,
  SET_ITEM_DETAILS_FETCHING,
  ShowItemDetails,
  SHOW_ITEM_DETAILS,
} from './types'

export const showItemDetails = (
  id: number | string,
  type: CategoryTypes,
  platformId?: number | string
): ShowItemDetails => ({
  type: SHOW_ITEM_DETAILS,
  payload: { id, type, platformId },
})

export const setItemDetails = (
  id: number | string,
  type: CategoryTypes,
  platformId?: string | number
): SetItemDetails => ({
  type: SET_ITEM_DETAILS,
  payload: { id, type, platformId },
})

export const setItemDetailsFetching = (
  fetching: boolean
): SetItemDetailsFetching => ({
  type: SET_ITEM_DETAILS_FETCHING,
  payload: fetching,
})

export const hideItemDetails = (): HideItemDetails => ({
  type: HIDE_ITEM_DETAILS,
})

/**
 * Call to fetch items for the cooperations lists in Item Details.
 * Will infer the asset type, expected type, offset and limit.
 * No params are required.
 */
export const fetchItemDetailsCooperations = (): FetchItemDetailsCooperations => ({
  type: FETCH_ITEM_DETAILS_COOPERATIONS,
})

/**
 * Use this for adding more items to the cooperations list. Will merge previously fetched connections if available.
 * @param connectionType The connection type to merge with
 * @param connections The new connections to merge
 */
export const setItemDetailsCooperations = (
  connectionType: CategoryTypes,
  connections: (string | number)[]
): SetItemDetailsCooperations => ({
  type: SET_ITEM_DETAILS_COOPERATIONS,
  payload: {
    connectionType,
    connections,
  },
})

/**
 * Redux Saga Action
 *
 * Use this for changing the tab index as it takes care of the API calls.
 * @param newIndex The new index
 */
export const changeItemDetailsCooperationsTabIndex = (
  newIndex: number
): ChangeItemDetailsCooperationsTabIndex => ({
  type: CHANGE_ITEM_DETAILS_COOPERATIONS_TAB_INDEX,
  payload: newIndex,
})

/**
 * Do not call directly when changing tabs. Use changeItemDetailsCooperationsTabIndex instead.
 * @param newIndex The new index
 */
export const setItemDetailsCooperationsTabIndex = (
  newIndex: number
): SetItemDetailsCooperationsTabIndex => ({
  type: SET_ITEM_DETAILS_COOPERATIONS_TAB_INDEX,
  payload: newIndex,
})

export const resetVisualizerItemDetailsState = (): ResetVisualizerItemDetailsState => ({
  type: RESET_VISUALIZER_ITEM_DETAILS_STATE,
})
