import { Button } from '@material-ui/core';
import React from 'react';
import {useSelector} from 'react-redux';
import {withRouter} from 'react-router-dom';

const HomeNavBar = (props) => {

    const {initURL} = useSelector(({auth})=>auth);

    return (
        <div className="row home-nav-bar mr-1">
            {initURL === "/explore" && window.innerWidth > 500 && (
            <Button disabled={true} className="btn jr-btn bg-grey text-white mr-2">
                SIGNUP
            </Button>)}
            {initURL === "/explore" && (
            <Button className="btn jr-btn bg-secondary text-white mr-2" onClick={()=>props.history.push("/signin")}>
                LOGIN
            </Button>)}
            {initURL === "/signin" && (
            <Button className="btn jr-btn bg-secondary text-white mr-2" onClick={()=>props.history.push("/explore")}>
                EXPLORER
            </Button>)}
        </div>
    )
}

export default withRouter(HomeNavBar);