import { CategoryTypes } from '../../sharedTypes'

export interface ItemDetailsSelectionData {
  type: CategoryTypes
  id: number | string
  platformId?: number | string
}

type Cooperations = {
  [key in Exclude<CategoryTypes, 'projects'>]?: (number | string)[]
}

export interface ItemDetailsState extends ItemDetailsSelectionData {
  visible: boolean
  fetching: boolean
  cooperations: Cooperations
  cooperationsTabIndex: number
}

export const SHOW_ITEM_DETAILS = 'SHOW_ITEM_DETAILS'
//Redux Saga action.

export interface ShowItemDetails {
  type: typeof SHOW_ITEM_DETAILS
  payload: ItemDetailsSelectionData
}

export const SET_ITEM_DETAILS = 'SET_ITEM_DETAILS'

export interface SetItemDetails {
  type: typeof SET_ITEM_DETAILS
  payload: ItemDetailsSelectionData
}

export const HIDE_ITEM_DETAILS = 'HIDE_ITEM_DETAILS'

export interface HideItemDetails {
  type: typeof HIDE_ITEM_DETAILS
}

export const SET_ITEM_DETAILS_FETCHING = 'SET_ITEM_DETAILS_FETCHING'

export interface SetItemDetailsFetching {
  type: typeof SET_ITEM_DETAILS_FETCHING
  payload: boolean
}

export const FETCH_ITEM_DETAILS_COOPERATIONS = 'FETCH_ITEM_DETAILS_COOPERATIONS'

export interface FetchItemDetailsCooperations {
  type: typeof FETCH_ITEM_DETAILS_COOPERATIONS
}

export const SET_ITEM_DETAILS_COOPERATIONS = 'SET_ITEM_DETAILS_COOPERATIONS'

interface SetItemDetailsCooperationsPayload {
  connectionType: CategoryTypes
  connections: (string | number)[]
}

export interface SetItemDetailsCooperations {
  type: typeof SET_ITEM_DETAILS_COOPERATIONS
  payload: SetItemDetailsCooperationsPayload
}

//Redux Saga Action
export const CHANGE_ITEM_DETAILS_COOPERATIONS_TAB_INDEX =
  'CHANGE_ITEM_DETAILS_COOPERATIONS_TAB_INDEX'

export interface ChangeItemDetailsCooperationsTabIndex {
  type: typeof CHANGE_ITEM_DETAILS_COOPERATIONS_TAB_INDEX
  payload: number
}

export const SET_ITEM_DETAILS_COOPERATIONS_TAB_INDEX =
  'SET_ITEM_DETAILS_COOPERATIONS_TAB_INDEX'

export interface SetItemDetailsCooperationsTabIndex {
  type: typeof SET_ITEM_DETAILS_COOPERATIONS_TAB_INDEX
  payload: number
}

export const RESET_VISUALIZER_ITEM_DETAILS_STATE =
  'RESET_VISUALIZER_ITEM_DETAILS_STATE'

export interface ResetVisualizerItemDetailsState {
  type: typeof RESET_VISUALIZER_ITEM_DETAILS_STATE
}

export type ItemDetailsActions =
  | ShowItemDetails
  | SetItemDetails
  | SetItemDetailsFetching
  | HideItemDetails
  | SetItemDetailsCooperations
  | SetItemDetailsCooperationsTabIndex
  | ResetVisualizerItemDetailsState
