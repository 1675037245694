import {
    SEARCH_STRING, SEARCH_STRING_SUCCESS
} from 'constants/ActionTypes'

export const searchString_ = (data) => {
    return {
        type: SEARCH_STRING,
        payload: data
    };
};

export const searchStringSuccess_ = (data) => {
    return {
        type: SEARCH_STRING_SUCCESS,
        payload: data
    };
};