import {all, call, fork, put, select, takeEvery} from "redux-saga/effects";

import {
    GET_ALL_COOPERATIONS,
    GET_ALL_COOPERATIONS_SUCCESS,
    CREATE_COOPERATION,
    CREATE_COOPERATION_SUCCESS,
    RATE_COOPERATION,
    RATE_COOPERATION_SUCCESS,
    DELETE_COOPERATION_WITH_UTILITY,
    DELETE_COOPERATION_WITH_UTILITY_SUCCESS,
    DELETE_COOPERATION_WITH_RESOURCE,
    DELETE_COOPERATION_WITH_RESOURCE_SUCCESS,
    UPDATE_COOPERATION_WITH_UTILITY,
    UPDATE_COOPERATION_WITH_UTILITY_SUCCESS,
    UPDATE_COOPERATION_WITH_RESOURCE,
    UPDATE_COOPERATION_WITH_RESOURCE_SUCCESS
} from "constants/ActionTypes";

import {
    getAllCooperations_,
    getAllCooperationsSuccess_,
    createCooperation_,
    createCooperationSuccess_,
    rateCooperation_,
    rateCooperationSuccess_,
    deleteCooperationWithUtility_,
    deleteCooperationWithUtilitySuccess_,
    deleteCooperationWithResource_,
    deleteCooperationWithResourceSuccess_,
    updateCooperationWithUtilitySuccess_,
    updateCooperationWithResourceSuccess_,
    loadMoreCooperationsSuccess_,
    coopRefreshed_,
    coopsRefreshed_,

} from "actions/Cooperation";

import {
    getAllCooperations,
    createCooperation,
    rateCooperation,
    deleteCooperationWithUtility,
    deleteCooperationWithResource,
    updateCooperationWithUtility,
    updateCooperationWithResource
} from "../api";

import { Auth } from 'aws-amplify';
import { hideDashbaordLoader_, hideDialogueLoader_, hideIsFetching_, hideLocalFetching_, hideScrollLoader_, hideTransparentLoader_, isLoadingMore_, showDashbaordLoader_, showDialogueLoader_, showFetchingSuccess_, showIsFetching_, showLocalFetching_, showScrollLoader_, showTransparentLoader_ } from "actions/IsFetching";
import { formatCooperations } from "util/formatCooperations";
import { viewAlertMessage_ } from "actions/Alert";
import { errorHandler, successHandler } from "util/messageHandler";
import { userSignOut } from "actions";

// Get JWToken from Local Storage

function* getAllCooperationsByFilter({payload}) {
    const {JWToken, coopType, querryParams, refresh, loadMore, noLoader} = payload;
    const cooperationList = yield select(state => state.cooperation.cooperationList);
    if (!noLoader) {
        if (refresh === true) {
            yield put(showTransparentLoader_());
        } else {
            yield put(showDashbaordLoader_());
        }
    }

    if (loadMore) {
        yield put(showScrollLoader_());
        yield put(isLoadingMore_(true));
    }
    try {
        let apiResponse = {};
        let rawCoops = {};
        let assetType ='';
        let baseAsset='';

        if (querryParams.sortByBlueprint) {
            apiResponse.resources = yield call(getAllCooperations, JWToken, assetType="resources", querryParams);
            if (apiResponse.resources.status>200) {
                if (apiResponse.status === 401) {
                    yield put(userSignOut());
                }
                // console.log("apiResponse_error: ", apiResponse.resources);
                // yield put(errorAction(apiResponse.error));
            } else {
                // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
                // console.log("apiResponse: ", apiResponse);
                rawCoops.resources = yield apiResponse.resources.data.cooperations;       
            }

            apiResponse.utilities = yield call(getAllCooperations, JWToken, assetType="utilities", querryParams);
            if (apiResponse.utilities.status>200) {
                // console.log("apiResponse_error: ", apiResponse.utilities); 
                // yield put(errorAction(apiResponse.error));
            } else {
                // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
                // console.log("apiResponse: ", apiResponse);
                rawCoops.utilities = yield apiResponse.utilities.data.cooperations;        
            }
            // console.log("COOP LIST FROM SAGA: ", rawCoops);
            let formattedCooperationList = yield call(formatCooperations, rawCoops, baseAsset="tokens");
            if (loadMore) {
                yield put(getAllCooperationsSuccess_([...cooperationList, ...formattedCooperationList])); 
            } else {
                yield put(getAllCooperationsSuccess_(formattedCooperationList)); 
            }
            
            
        } else {

            if (coopType == "resources") {
                apiResponse.resources = yield call(getAllCooperations, JWToken, assetType="resources", querryParams);
                if (apiResponse.resources.status>200) {
                    if (apiResponse.status === 401) {
                        yield put(userSignOut());
                    }
                    // console.log("apiResponse_error: ", apiResponse.resources);
                    // yield put(errorAction(apiResponse.error));
                } else {
                    // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
                    // console.log("apiResponse: ", apiResponse);
                    rawCoops.resources = yield apiResponse.resources.data.cooperations;
                    rawCoops.utilities = yield [];
                    let formattedCooperationList = yield call(formatCooperations, rawCoops, baseAsset="resources");
                    // console.log("COOP LIST FROM SAGA: (resources)", rawCoops);
                    if (loadMore) {
                        yield put(getAllCooperationsSuccess_([...cooperationList, ...formattedCooperationList])); 
                    } else {
                        yield put(getAllCooperationsSuccess_(formattedCooperationList)); 
                    }
                             
                }

            } else if (coopType == "utilities") {
                apiResponse.utilities = yield call(getAllCooperations, JWToken, assetType="utilities", querryParams);
                if (apiResponse.utilities.status>200) {
                    if (apiResponse.status === 401) {
                        yield put(userSignOut());
                    }
                    // console.log("apiResponse_error: ", apiResponse);
                    // yield put(errorAction(apiResponse.error));
                } else {
                    // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
                    // console.log("apiResponse: ", apiResponse);
                    rawCoops.utilities = yield apiResponse.utilities.data.cooperations; 
                    rawCoops.resources = yield []; 
                    let formattedCooperationList = yield call(formatCooperations, rawCoops, baseAsset="utilities");
                    // console.log("COOP LIST FROM SAGA: (utilities)", rawCoops);
                    if (loadMore) {
                        yield put(getAllCooperationsSuccess_([...cooperationList, ...formattedCooperationList])); 
                    } else {
                        yield put(getAllCooperationsSuccess_(formattedCooperationList)); 
                    }    
                }
            }
            
        } 

        
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideDashbaordLoader_());
        yield put(hideTransparentLoader_());
        yield put(hideScrollLoader_());
        yield put(isLoadingMore_(false));
    }

}

function* createCooperationWithData({payload}) {
    const {JWToken, cooperationData, refresh, noLoader} = payload;
    if (!noLoader) {
        if (refresh === true) {
            yield put(showTransparentLoader_());
        } else {
            yield put(showDialogueLoader_());
        }
    }
    try {
        const apiResponse = yield call(createCooperation, JWToken, cooperationData);
        let fetchingResult = {};
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(coopsRefreshed_(true))
            yield put(createCooperationSuccess_()); 

            // alert message   
            const successMessage = yield call(successHandler,"Coop", "", "created");
            yield put(viewAlertMessage_(successMessage)); 
        }

    } catch (error) {
        // console.log("catchError: ", error);
        yield put(showFetchingSuccess_("error"));
        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
    } finally {
        yield put(hideDialogueLoader_());
        yield put(hideTransparentLoader_());
    }
}

function* rateCooperationWithValue({payload}) {
    const {JWToken, ratingData, refresh, noLoader} = payload;
    try {

        if (!noLoader) {
            if (refresh === true) {
                yield put(showDialogueLoader_());
            }
        }
        const apiResponse = yield call(rateCooperation, JWToken, ratingData);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(coopRefreshed_(true));
            yield put(coopsRefreshed_(true));
            yield put(rateCooperationSuccess_());        
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideDialogueLoader_())
    }

}

function* updateCooperationWithUtilityByData({payload}) {
    const {JWToken, tokenId, utilityId, coopData, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh === true) {
                yield put(showDialogueLoader_());
            } 
        }
        const apiResponse = yield call(updateCooperationWithUtility, JWToken, tokenId, utilityId, coopData);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(updateCooperationWithUtilitySuccess_()); 
            yield put(coopRefreshed_(true));
            yield put(coopsRefreshed_(true));  
            // alert message   
            const successMessage = yield call(successHandler,"Coop", "", "updated");
            yield put(viewAlertMessage_(successMessage));      
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally{
        yield put(hideDialogueLoader_());
    }

}

function* updateCooperationWithResourceByData({payload}) {
    const {JWToken, tokenId, resourceId, coopData, refresh, noLoader} = payload;
    if (!noLoader) {
        if (refresh === true) {
            yield put(showDialogueLoader_());
        } 
    }
    try {
        const apiResponse = yield call(updateCooperationWithResource, JWToken, tokenId, resourceId, coopData);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {

            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(updateCooperationWithResourceSuccess_()); 
            yield put(coopRefreshed_(true));
            yield put(coopsRefreshed_(true));

            // alert message   
            const successMessage = yield call(successHandler,"Coop", "", "updated");
            yield put(viewAlertMessage_(successMessage)); 

                   
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideDialogueLoader_());
    }

}

function* deleteCooperationWithUtilityByData({payload}) {
    const {JWToken, tokenId, platform, utilityId, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh === true) {
                yield put(showDialogueLoader_());
            } 
        }
        const apiResponse = yield call(deleteCooperationWithUtility, JWToken, tokenId, platform, utilityId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {

            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(deleteCooperationWithUtilitySuccess_());  
            yield put(coopsRefreshed_(true));
            
            // alert message   
            const successMessage = yield call(successHandler,"Coop", "", "deleted");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally{
        yield put(hideDialogueLoader_());
    }

}

function* deleteCooperationWithResourceByData({payload}) {
    const {JWToken, tokenId, resourceId, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh === true) {
                yield put(showDialogueLoader_());
            } 
        }
        const apiResponse = yield call(deleteCooperationWithResource, JWToken, tokenId, resourceId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {

            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(deleteCooperationWithResourceSuccess_(apiResponse.error));  
            yield put(coopsRefreshed_(true));      

            // alert message   
            const successMessage = yield call(successHandler,"Coop", "", "deleted");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideDialogueLoader_())
    }

}

export function* readAllCooperations() {
    yield takeEvery(GET_ALL_COOPERATIONS, 
        getAllCooperationsByFilter);
}

export function* newCooperation() {
    yield takeEvery(CREATE_COOPERATION, 
        createCooperationWithData);
}

export function* rateOneCooperation() {
    yield takeEvery(RATE_COOPERATION, 
        rateCooperationWithValue);
}

export function* updateUtilityCooperation() {
    yield takeEvery(UPDATE_COOPERATION_WITH_UTILITY, 
        updateCooperationWithUtilityByData);
}

export function* updateResourceCooperation() {
    yield takeEvery(UPDATE_COOPERATION_WITH_RESOURCE, 
        updateCooperationWithResourceByData);
}

export function* removeUtilityCooperation() {
    yield takeEvery(DELETE_COOPERATION_WITH_UTILITY, 
        deleteCooperationWithUtilityByData);
}

export function* removeResourceCooperation() {
    yield takeEvery(DELETE_COOPERATION_WITH_RESOURCE, 
        deleteCooperationWithResourceByData);
}

export default function* rootSaga() {
    yield all([fork(readAllCooperations),
        fork(newCooperation),
        fork(rateOneCooperation),
        fork(removeUtilityCooperation),
        fork(removeResourceCooperation),
        fork(updateUtilityCooperation),
        fork(updateResourceCooperation)
    ]);
}