import {
  SET_WALLET_INPUT_VALUE,
  TOGGLE_WALLET_INPUT_VISIBILITY,
  WalletInputState,
  WalletInputStateActions,
} from './types'

const defaultState: WalletInputState = {
  walletId: '',
  visible: false,
}

export const walletInputReducer = (
  state = defaultState,
  action: WalletInputStateActions
): WalletInputState => {
  switch (action.type) {
    case SET_WALLET_INPUT_VALUE:
      return {
        ...state,
        walletId: action.payload,
      }
    case TOGGLE_WALLET_INPUT_VISIBILITY:
      return {
        ...state,
        visible: action.payload ?? !state.visible,
      }
    default:
      return state
  }
}
