import {
    GET_ALL_NOTIFICATIONS,
    GET_ALL_NOTIFICATIONS_SUCCESS,
    LOAD_MORE_NOTIFICATIONS_SUCCESS,
    NOTIFICATIONS_REFRESHED,
    NOTIFICATION_REFRESHED,
    UPDATE_NOTIFICATION_STATUS,
    UPDATE_NOTIFICATION_STATUS_SUCCESS
} from 'constants/ActionTypes'

export const getAllNotifications_ = (data) => {
    return {
        type: GET_ALL_NOTIFICATIONS,
        payload: data
    };
};

export const getAllNotificationsSuccess_ = (notifications) => {
    return {
        type: GET_ALL_NOTIFICATIONS_SUCCESS,
        payload: notifications
    };
};

export const loadMoreNotificationsSuccess_ = (notifications) => {
    return {
        type: LOAD_MORE_NOTIFICATIONS_SUCCESS,
        payload: notifications
    };
};

export const updateNotificationStatus_ = (status) => {
    return {
        type: UPDATE_NOTIFICATION_STATUS,
        payload: status
    };
};

export const updateNotificationStatusSuccess_ = () => {
    return {
        type: UPDATE_NOTIFICATION_STATUS_SUCCESS
    };
};

export const notificationsRefreshed_ = (status) => {
    return {
        type: NOTIFICATIONS_REFRESHED,
        payload: status
    };
};

export const notificationRefreshed_ = (status) => {
    return {
        type: NOTIFICATION_REFRESHED,
        payload: status
    };
};

