import { API } from 'components/networkVisualizer/API/api'
import { setVisualizerData } from 'components/networkVisualizer/networkVisualizerState/store/general/actions'
import { call, put, select } from 'redux-saga/effects'
import { AppState } from 'store'
import { getAssetDataFormatter } from '../../dataFormatters/getAssetDataFormatter'
import { fetchAndSetConnections } from '../fetchAndSetConnections/fetchAndSetConnections'
import { CONNECTIONS_FETCH_LIMIT } from './fetchAndSetData'

export function* fetchProjectData(projectId: string | number) {
  const projectResponse = yield call(API.getAssetData, 'projects', projectId)

  const projectData: ReturnType<typeof getAssetDataFormatter.toGeneralData> = yield call(
    getAssetDataFormatter.toGeneralData,
    projectResponse.data
  )
  yield put(setVisualizerData(projectData))

  const connections = yield select(
    (state: AppState) =>
      state.networkVisualizerState.general.data.projects[projectId].connections
  )

  const connectionsCount = connections
    ? Object.values(connections).reduce(
        (total: number, current: any[]) => total + current.length,
        0
      )
    : 0

  //Connections are not fetched with the project. Fetch the connections if the current count is less than the limit. This is to prevent additional fetches if the data has been previously set (By returning to a previously viewed asset). If not the next set of connections will be fetched.
  if (connectionsCount < CONNECTIONS_FETCH_LIMIT) {
    yield call(fetchAndSetConnections, projectId, 'projects')
  }
}
