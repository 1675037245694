import {
    SHOW_IS_FETCHING,
    HIDE_IS_FETCHING,
    SHOW_FETCHING_SUCCESS,
    SHOW_FETCHING_ERROR,
    SHOW_LOCAL_FETCHING,
    HIDE_LOCAL_FETCHING,
    SHOW_TRANSPARENT_FETCHING,
    HIDE_TRANSPARENT_FETCHING,
    SHOW_MAIN_LOADER,
    HIDE_MAIN_LOADER,
    SHOW_DASHBOARD_LOADER,
    HIDE_DASHBOARD_LOADER,
    SHOW_APP_LOADER,
    HIDE_APP_LOADER,
    SHOW_DIALOGUE_LOADER,
    HIDE_DIALOGUE_LOADER,
    SHOW_TRANSPARENT_LOADER,
    HIDE_TRANSPARENT_LOADER,
    SHOW_LOADER_DELETE_SUCCESS,
    SHOW_LOADER_CREATE_SUCCESS,
    SHOW_LOADER_UPDATE_SUCCESS,
    VIEW_ALERT_MSG,
    GET_NEXT_PAGE,
    SHOW_SCROLL_LOADER,
    HIDE_SCROLL_LOADER,
    GET_NEXT_PAGE_DIALOGUE,
    SET_NEXT_PAGE_ORDERS,
    SET_NEXT_PAGE_TX,
    IS_LOADING_MORE,
    SHOW_MINI_LOADER,
    HIDE_MINI_LOADER
} from 'constants/ActionTypes'

export const showIsFetching_ = () => {
    return {
        type: SHOW_IS_FETCHING
    };
};

export const hideIsFetching_ = () => {
    return {
        type: HIDE_IS_FETCHING
    };
};

export const showLocalFetching_ = () => {
    return {
        type: SHOW_LOCAL_FETCHING
    };
};

export const hideLocalFetching_ = () => {
    return {
        type: HIDE_LOCAL_FETCHING
    };
};

export const showFetchingSuccess_ = (data) => {
    return {
        type: SHOW_FETCHING_SUCCESS,
        payload: data
    };
};

// NEU 
export const showTransparentLoader_ = () => {
    return {
        type: SHOW_TRANSPARENT_LOADER
    };
};

export const hideTransparentLoader_ = () => {
    return {
        type: HIDE_TRANSPARENT_LOADER
    };
};

export const showMiniLoader_ = () => {
    return {
        type: SHOW_MINI_LOADER
    };
};

export const hideMiniLoader_ = () => {
    return {
        type: HIDE_MINI_LOADER
    };
};
export const showMainLoader_ = () => {
    return {
        type: SHOW_MAIN_LOADER
    };
};

export const hideMainLoader_ = () => {
    return {
        type: HIDE_MAIN_LOADER
    };
};

export const showDashbaordLoader_ = () => {
    return {
        type: SHOW_DASHBOARD_LOADER
    };
};

export const hideDashbaordLoader_ = () => {
    return {
        type: HIDE_DASHBOARD_LOADER
    };
};

export const showAppLoader_ = () => {
    return {
        type: SHOW_APP_LOADER
    };
};

export const hideAppLoader_ = () => {
    return {
        type: HIDE_APP_LOADER
    };
};

export const showDialogueLoader_ = () => {
    return {
        type: SHOW_DIALOGUE_LOADER
    };
};

export const hideDialogueLoader_ = () => {
    return {
        type: HIDE_DIALOGUE_LOADER
    };
};

export const showScrollLoader_ = () => {
    return {
        type: SHOW_SCROLL_LOADER
    };
};

export const hideScrollLoader_ = () => {
    return {
        type: HIDE_SCROLL_LOADER
    };
};

export const showLoaderDeleteSuccess_ = (success) => {
    return {
        type: SHOW_LOADER_DELETE_SUCCESS,
        payload: success
    };
};

export const showLoaderCreateSuccess_ = (success) => {
    return {
        type: SHOW_LOADER_CREATE_SUCCESS,
        payload: success
    };
};


export const showLoaderUpdateSuccess_ = (success) => {
    return {
        type: SHOW_LOADER_UPDATE_SUCCESS,
        payload: success
    };
};

export const getNextPage_ = (page) => {
    return {
        type: GET_NEXT_PAGE,
        payload: page
    };
};

export const getNextPageDialogue_ = (page) => {
    return {
        type: GET_NEXT_PAGE_DIALOGUE,
        payload: page
    };
};

export const setNextPageOrders_ = (page) => {
    return {
        type: SET_NEXT_PAGE_ORDERS,
        payload: page
    };
};

export const setNextPageTx_ = (page) => {
    return {
        type: SET_NEXT_PAGE_TX,
        payload: page
    };
};

export const isLoadingMore_ = (loading) => {
    return {
        type: IS_LOADING_MORE,
        payload: loading
    };
};

