import {
    ENJIN_L_CONNECTED,
    ENJIN_L_CONNECTING,
    SET_CURRENT_ENJINID,
    SET_CURRENT_ETHADDRESS,
    SET_CURRENT_TOKNEID,
    SET_CURRENT_WALLETID,
    SET_CURR_PROJECT_ID,
    SET_UTILIFY_EVENT_CHANNELS,
    UTILIFY_L_CONNECTED,
    UTILIFY_L_CONNECTING,
} from "constants/ActionTypes";

const INIT_STATE = {
    currProjectId: '',
    currentEnjinId: '',
    currentWalletId: '',
    currentTokenId: '',
    currentEthAddress: '',
    enjinListenerConnected: false,
    utilifyListenerConnected: false,
    enjinListenerConnecting: false,
    utilifyListenerConnecting: false,
    utilifyEventChannels: [],

};


export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case SET_CURR_PROJECT_ID: {
            return {
                ...state,
                currProjectId: action.payload,
            }
        }

        case SET_CURRENT_ENJINID: {
            return {
                ...state,
                currentEnjinId: action.payload,
            }
        }

        case SET_CURRENT_WALLETID: {
            return {
                ...state,
                currentWalletId: action.payload,
            }
        }

        case SET_CURRENT_TOKNEID: {
            return {
                ...state,
                currentTokenId: action.payload,
            }
        }

        case SET_CURRENT_ETHADDRESS: {
            return {
                ...state,
                currentEthAddress: action.payload,
            }
        }

        case ENJIN_L_CONNECTED: {
            return {
                ...state,
                enjinListenerConnected: action.payload,
            }
        }

        case ENJIN_L_CONNECTING: {
            return {
                ...state,
                enjinListenerConnecting: action.payload,
            }
        }

        case UTILIFY_L_CONNECTED: {
            return {
                ...state,
                utilifyListenerConnected: action.payload,
            }
        }

        case UTILIFY_L_CONNECTING: {
            return {
                ...state,
                utilifyListenerConnecting: action.payload,
            }
        }

        case SET_UTILIFY_EVENT_CHANNELS: {
            return {
                ...state,
                utilifyEventChannels: action.payload,
            }
        }

        default:
            return state;
    }
}