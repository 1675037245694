import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import Auth from './Auth';
import Account from './Account';
import Cooperation from './Cooperation';
import Event from './Event';
import Img from './Img';
import Notification from './Notification';
import Order from './Order';
import Project from './Project';
import Request from './Request';
import Resource from './Resource';
import Token from './Token';
import Transaction from './Transaction';
import User from './User';
import Utility from './Utility';
import Wallet from './Wallet';
import IsFetching from './IsFetching';
import Navigation from './Navigation';
import Search from './Search';
import Asset from './Asset';
import EnjinApp from './EnjinApp';
import EventListeners from './EventListeners';
import Message from './Message';
import Window from './Window';
import Alert from './Alert';

import {networkVisualizerReducer} from 'components/networkVisualizer/networkVisualizerState/reducer';

export default (history: any) => combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    account: Account,
    cooperation: Cooperation,
    event: Event,
    img: Img,
    notification: Notification,
    order: Order,
    project: Project,
    request: Request,
    resource: Resource,
    token: Token,
    transaction: Transaction,
    user: User,
    utility: Utility,
    wallet: Wallet,
    isFetching: IsFetching,
    navigation: Navigation,
    search: Search,
    asset: Asset,
    enjinApp: EnjinApp,
    eventListeners: EventListeners,
    message: Message,
    window: Window,
    alert: Alert,
    networkVisualizerState: networkVisualizerReducer

});
