import {
    GET_ALL_USER_ACCOUNTS,
    GET_USER_ACCOUNT,
    UPDATE_USER_ACCOUNT,
    DELETE_USER_ACCOUNT,
    GET_ALL_USER_ACCOUNTS_SUCCESS,
    LOAD_MORE_USER_ACCOUNTS_SUCCESS,
    GET_USER_ACCOUNT_SUCCESS,
    UPDATE_USER_ACCOUNT_SUCCESS,
    DELETE_USER_ACCOUNT_SUCCESS,
    AUTHUSERS_REFRESHED,
    AUTHUSER_REFRESHED,
    ACCOUNT_REFRESHED,
    ACCOUNTS_REFRESHED

} from 'constants/ActionTypes'

export const getAllUserAccounts_ = (data) => {
    return {
        type: GET_ALL_USER_ACCOUNTS,
        payload: data
    };
};

export const getAllUserAccountsSuccess_ = (users) => {
    return {
        type: GET_ALL_USER_ACCOUNTS_SUCCESS,
        payload: users
    };
};

export const loadMoreUserAccountsSuccess_ = (users) => {
    return {
        type: LOAD_MORE_USER_ACCOUNTS_SUCCESS,
        payload: users
    };
};

export const getUserAccount_ = (data) => {
    return {
        type: GET_USER_ACCOUNT,
        payload: data
    };
};

export const getUserAccountSuccess_ = (user) => {
    return {
        type: GET_USER_ACCOUNT_SUCCESS,
        payload: user
    };
};

export const updateUserAccount_ = (data) => {
    return {
        type: UPDATE_USER_ACCOUNT,
        payload: data
    };
};

export const updateUserAccountSuccess_ = (user) => {
    return {
        type: UPDATE_USER_ACCOUNT_SUCCESS,
        payload: user
    };
};

export const deleteUserAccount_ = (userId) => {
    return {
        type: DELETE_USER_ACCOUNT,
        payload: userId
    };
};

export const deleteUserAccountSuccess_ = () => {
    return {
        type: DELETE_USER_ACCOUNT_SUCCESS
    };
};


export const accountRefreshed_ = (data) => {
    return {
        type: ACCOUNT_REFRESHED,
        payload: data
    };
};


export const accountsRefreshed_ = (data) => {
    return {
        type: ACCOUNTS_REFRESHED,
        payload: data
    };
};