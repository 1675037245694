import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import { hideDashbaordLoader_, hideDialogueLoader_, hideTransparentLoader_, showDashbaordLoader_, showDialogueLoader_, showLoaderCreateSuccess_, showLoaderDeleteSuccess_, showLoaderUpdateSuccess_, showTransparentLoader_ } from "actions/IsFetching";
import { DELETE_ENJINAPP, GET_ALL_ENJINAPPS, GET_ENJINAPP, LINK_ENJINAPP, UPDATE_ENJINAPP } from "constants/ActionTypes";
import { deleteEnjinAppById, getAllEnjinApps, getEnjinAppById, postEnjinApp, updateEnjinAppById } from "api";
import { deleteEnjinAppSuccess_, getAllEnjinAppsSuccess_, getEnjinAppSuccess_, linkEnjinAppSuccess_, updateEnjinAppSuccess_, enjAppRefreshed_, enjAppsRefreshed_ } from "actions/EnjinApp";
import { errorHandler, successHandler } from "util/messageHandler";
import { viewAlertMessage_ } from "actions/Alert";
import { userSignOut } from "actions";


// Get JWToken from Local Storage

function* getAppsByFiter({payload}) {
    const {JWToken, querryParams, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                 yield put(showDashbaordLoader_());
            }
        }
        
        const apiResponse = yield call(getAllEnjinApps, JWToken, querryParams);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(getAllEnjinAppsSuccess_(apiResponse.data));   
            // yield put(enjAppRefreshed_(true));
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideDashbaordLoader_());
        yield put(hideTransparentLoader_());

    }

}

function* getAppById({payload}) {
    const {JWToken, appId, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh === true) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            }
        }
        
        const apiResponse = yield call(getEnjinAppById, JWToken, appId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse Wallet GET: ", apiResponse.data);
            yield put(getEnjinAppSuccess_(apiResponse.data)); 
   
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // yield put(errorAction(error));
    } finally {
        yield put(hideDialogueLoader_());
        yield put(hideTransparentLoader_());
    }

}


function* linkAppByData({payload}) {
    const {JWToken, appData, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        
        const apiResponse = yield call(postEnjinApp, JWToken, appData);
        let fetchingResult = {};
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }

            
            // console.log("apiResponse_error: ", apiResponse);
            fetchingResult.error = "error";
            yield put(showLoaderCreateSuccess_(fetchingResult));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
            
            // yield put(errorAction(apiResponse.error));
            
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse.data);

            
            // yield put(enjAppsRefreshed_(true));
            fetchingResult.success = "success";
            yield put(showLoaderCreateSuccess_(fetchingResult));

            yield put(linkEnjinAppSuccess_(apiResponse.data));
            
            // alert message   
            const successMessage = yield call(successHandler,"Enjin App", "", "linked");
            yield put(viewAlertMessage_(successMessage)); 
            
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideDialogueLoader_());
        yield put(hideTransparentLoader_());
    }

}

function* updateAppByData({payload}) {
    const {JWToken, appId, appData, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        
        
        const apiResponse = yield call(updateEnjinAppById, JWToken, appId, appData);
        let fetchingResult = {};
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            
            fetchingResult.error = apiResponse.data.error;
            yield put(showLoaderUpdateSuccess_(fetchingResult));
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse.data);

            yield put(updateEnjinAppSuccess_(apiResponse.data)); 

            yield put(enjAppRefreshed_(true));
            fetchingResult.success = "Success!";
            // console.log("loaderupdateresultfromsaga", fetchingResult)
            yield put(showLoaderUpdateSuccess_(fetchingResult));  
            
            // alert message   
            const successMessage = yield call(successHandler,"Enjin App", "", "updated");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideDialogueLoader_());
    }
}

function* deleteAppById({payload}) {
    const {JWToken, appId, refresh, noLoader} = payload;
    let fetchingResult = {};
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        const apiResponse = yield call(deleteEnjinAppById, JWToken, appId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            fetchingResult.error = "Error";
            yield put(showLoaderDeleteSuccess_(fetchingResult));
            yield put(hideTransparentLoader_());
            // yield put(errorAction(apiResponse.error));

            // alert message   
            const errorMessage = yield call(errorHandler,"Enjin App", "", "deleted");
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);

            yield put(enjAppsRefreshed_(true));
            yield put(deleteEnjinAppSuccess_());        
            fetchingResult.success = "Success";
            yield put(showLoaderDeleteSuccess_(fetchingResult));  
            yield put(hideTransparentLoader_());

            // alert message   
            const successMessage = yield call(successHandler,"Enjin App", "", "removed");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        yield put(hideTransparentLoader_());
        yield put(hideDialogueLoader_)
        // yield put(errorAction(error));
    }

}

export function* readAllApps() {
    yield takeEvery(GET_ALL_ENJINAPPS, 
        getAppsByFiter);
}


export function* readOneApp() {
    yield takeEvery(GET_ENJINAPP, 
        getAppById);
}

export function* updateOneApp() {
    yield takeEvery(UPDATE_ENJINAPP, 
        updateAppByData);
}


export function* linkOneApp() {
    yield takeEvery(LINK_ENJINAPP, 
        linkAppByData);
}



export function* removeOneApp() {
    yield takeEvery(DELETE_ENJINAPP, 
        deleteAppById);
}

export default function* rootSaga() {
    yield all([fork(readAllApps),
        fork(readOneApp),
        fork(linkOneApp),
        fork(updateOneApp),
        fork(removeOneApp)
    ]);
}