import { WINDOW_WIDTH } from "constants/ActionTypes";

  
  const INIT_STATE = {
    width: window.innerWidth

};
  
export default (state = INIT_STATE, action) => {
    switch (action.type) {
          case WINDOW_WIDTH:
              return {
                  ...state,
                  width: action.width
              };
          default:
              return state;
      }
  };  