import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import CustomScrollbars from 'util/CustomScrollbars';
import Navigation from "../../components/Navigation";
import projectIcon from 'assets/images/project_icon.png';


const SideBarContent = () => {

    const [projectsLoaded, setProjectsLoaded] = useState(false);
    const [navigationMenus, setNavigationMenus] = useState([]);
    const [adminMenus, setAdminMenus] = useState([]);


    const {authUser} = useSelector(({auth})=> auth);

    var projectList = [];

    const truncate = (input) => input.length > 12 ? `${input.substring(0, 10)}...` : input;

    const listProjects = () => {
        if (authUser.profile !== undefined && authUser.profile.projects !== undefined) {

        
            projectList = authUser.profile.projects.map((data) => (
                {
                    name: truncate(data.project.name || "New Project"),
                    type: 'item',
                    link: `/app/projects/${data.project.projectId}/dashboard`,
                    avatarImage: data.project.avatarImage?.thumbnail ? data.project.avatarImage?.thumbnail : projectIcon
                }
            ));
            return projectList;
        }
    };


    var projectNavItems = [];

    

    useEffect(()=> {

        if (authUser.user !== undefined && authUser.user.attributes["custom:role"] === 'admin') {
            setAdminMenus([{
                name: 'sidebar.Admin',
                type: 'item',
                icon: 'account',
                link: '/app/adminpanel/useraccounts'
            }])
        }
        
        if (authUser.profile !== undefined && adminMenus) {
            projectNavItems = null // listProjects();
            setNavigationMenus(adminMenus.concat([
                {
                    name: 'sidebar.main',
                    type: 'section',
                    children: [
                        {
                            name: 'sidebar.home',
                            type: 'item',
                            icon: 'view-dashboard',
                            link: '/app/home'
                            
                        },        
                        {
                            name: 'sidebar.profile',
                            type: 'item',
                            icon: 'account',
                            link: `/app/profile/${authUser.profile.userId}`
                        },
                        {
                            name: 'sidebar.wallets',
                            type: 'item',
                            icon: 'balance-wallet',
                            link: `/app/wallets`
                        },
                        {
                            name: 'sidebar.projects',
                            type: 'collapse',
                            icon: 'folder',
                            link: '/app/projects/all',
                            children: listProjects()
                        },
                        {
                            name: 'sidebar.messages',
                            type: 'item',
                            icon: 'comments',
                            link: '/app/messages'
                        },
                        {
                            name: 'sidebar.notifications',
                            type: 'item',
                            icon: 'notifications-active',
                            link: '/app/notifications'
                        },
                    ]
                },
                {
                    name: 'sidebar.support',
                    type: 'section',
                    children: [
                        
                        {
                            name: 'sidebar.docs',
                            type: 'item',
                            icon: 'floppy',
                            link: '/app/docs'
                        },
                        {
                            name: 'sidebar.contact',
                            type: 'item',
                            icon: 'comment-more',
                            link: '/app/contact'
                        },
                    ]
                }
             
            ]));
            setProjectsLoaded(true);
        
        }
    },[authUser, projectsLoaded])

    if (authUser.profile === undefined) return null
    
    return (
            <CustomScrollbars className=" scrollbar">
                {authUser.profile !== undefined && (
                <Navigation menuItems={navigationMenus}/>)}
            </CustomScrollbars>
    );
};

export default SideBarContent;
