import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link, withRouter} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import {
    clearAuthUser,
    confirmUser,
    hideMessage,
    showAuthLoader,
    userFacebookSignIn,
    userGithubSignIn,
    userGoogleSignIn,
    userSignOutSuccess,
    userSignUp,
    userTwitterSignIn
} from 'actions/Auth';
import logo from "assets/images/logo_trans.svg";
import DialogueLoaderTransparent from 'components/Loader/DialogueLoaderTransparent';

export const parseQueryString = (string) => {
    return string.slice(1).split("&")
    .map((queryParam) => {
      let kvp = queryParam.split("=")
      return { key: kvp[0], value: kvp[1] }
    })
    .reduce((query, kvp) => {
      query[kvp.key] = kvp.value
      return query
    }, {})
  }

const ConfirmUser = (props) => {

    console.log("confirm: ", props.location);

    const dispatch = useDispatch();
    const {authUser, alertMessage, showMessage, stage} = useSelector(({auth}) => auth);
    let timeout;
    useEffect(() => {
        if (showMessage) {
            timeout = setTimeout(() => {
                dispatch(hideMessage());
            }, 3000);
        }
        if (authUser !== null) {
            dispatch(clearAuthUser());
        }

        let userVerificationData = parseQueryString(props.location.search);
        console.log("email verification: ", userVerificationData);

        let name = userVerificationData.user_name;
        let code = userVerificationData.confirmation_code;

        

        dispatch(confirmUser({name, code}));

        /*if (stage === 2) {
            timeout = setTimeout(() => {
                props.history.push('/signin');
            }, 2000);
        }*/

        return () => {
            clearTimeout(timeout);
        }
        

    }, [props.history, dispatch, stage]);

    let message = "Your account is now being verified... ";
    let messageSuccess = "Account successfully verified! ";

    return (
        <div
            className="app-login-container justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="row justify-content-center mt-4 mb-3">
                    <Link className="logo-lg" to="/" title="Jambo">
                        <img src={logo} alt="jambo" title="jambo"/>
                    </Link>
                </div>

            {stage === 1 && (
                <div className="row justify-content-center mt-5 mb-5">

                    <div className="col-12 d-flex justify-content-center mb-5">
                        <h1 className="d-flex d-block" style={{textAlign: "center"}}>{"Verifying Account ..."}</h1>
                    </div>
                    
                    <div className="col-12 d-flex justify-content-center mt-5 mb-2">
                        <h4 className="text-muted d-flex d-block" style={{textAlign: "center"}}>{message}</h4>
                    </div>
                    
                </div>
            )}
            {stage === 2 && (
                <div className="row justify-content-center mt-5 mb-5">

                    <div className="col-12 d-flex justify-content-center mb-3">
                        <h1 className="d-flex d-block" style={{textAlign: "center"}}>{"Success!"}</h1>
                    </div>
                    
                    <div className="col-12 d-flex justify-content-center mb-5">
                        <h4 className="text-muted d-flex d-block" style={{textAlign: "center"}}>{messageSuccess}</h4>
                    </div>
                    
                    <div className="col-12 d-flex justify-content-center mb-2">
                        <div className="row justify-content-center mb-3">
                            <Button variant="contained" color="primary" onClick={()=> props.history.push("/signin")}>
                                Go to Login
                            </Button>
                        </div>
                    </div>
                </div>
            )}

            {showMessage && NotificationManager.error(alertMessage)}
            <NotificationContainer/>
            <DialogueLoaderTransparent />
        </div>
    )
};


export default withRouter(ConfirmUser);
