import {all, call, delay, fork, put, select, take, takeEvery, takeLatest} from "redux-saga/effects";

import {
    GET_ALL_ORDERS,
    GET_ALL_ORDERS_SUCCESS,
    PLACE_ORDER,
    PLACE_ORDER_SUCCESS,
    GET_ORDER,
    GET_ORDER_SUCCESS,
    DELETE_ORDER,
    DELETE_ORDER_SUCCESS,
    UPDATE_ORDER,
    UPDATE_ORDER_SUCCESS,
    GET_ORDER_QUEUE_ITEMS,
    GET_ORDER_QUEUE_ITEMS_SUCCESS,
    FORCE_SEND,
    FORCE_SEND_SUCCESS,
    REFRESH_ORDERS
} from "constants/ActionTypes";

import {
    getAllOrders_,
    getAllOrdersSuccess_,
    placeOrder_,
    placeOrderSuccess_,
    getOrder_,
    getOrderSuccess_,
    updateOrder_,
    updateOrderSuccess_,
    getOrderQueueItems_,
    getOrderQueueItemsSuccess_,
    deleteOrder_,
    deleteOrderSuccess_,
    forceSend_,
    forceSendSuccess_,
    setSelectedOrder_,
    setOrderId_,
    orderRefreshed_,
    ordersRefreshed_

} from "actions/Order";

import {
    getAllOrders,
    placeOrder,
    getOrder,
    updateOrder,
    getOrderQueueItems,
    deleteOrder,
    forceSend

} from "../api";

import { Auth } from 'aws-amplify';
import { hideDashbaordLoader_, hideDialogueLoader_, hideScrollLoader_, hideTransparentLoader_, isLoadingMore_, showDashbaordLoader_, showDialogueLoader_, showLoaderCreateSuccess_, showLoaderDeleteSuccess_, showLoaderUpdateSuccess_, showScrollLoader_, showTransparentLoader_ } from "actions/IsFetching";
import { errorHandler, infoHandler, successHandler } from "util/messageHandler";
import { viewAlertMessage_ } from "actions/Alert";
import WithIconTimeLineItem from "components/timeline/WithIconTimeLineItem";
import { userSignOut } from "actions";


function* getAllOrdersByFilter({payload}) {
    // console.log('get all orders called')
    const {JWToken, querryParams, refresh, loadMore, noLoader} = payload;
    const orderList = yield select(state => state.order.orderList)

    if (!noLoader) {
        if (refresh) {
            yield put(showTransparentLoader_());
        } else {
            yield put(showDashbaordLoader_());
        } 
    }

    if (loadMore) {
        yield put(showScrollLoader_());
        yield put(isLoadingMore_(true));
    }
    
    try {
        // console.log("JWT: ", JWToken);
        // console.log("querryParams: ", querryParams);
        const apiResponse = yield call(getAllOrders, JWToken, querryParams);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            if (loadMore) {
                yield put(getAllOrdersSuccess_([...orderList, ...apiResponse.data.orders])); 
            } else {
                yield put(getAllOrdersSuccess_(apiResponse.data.orders)); 
            }
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideDashbaordLoader_());
        yield put(hideTransparentLoader_());
        yield put(hideScrollLoader_());
        yield put(isLoadingMore_(false));

    }

}

function* placeNewOrderWithData({payload}) {
    const {JWToken, orderData, refresh, noLoader} = payload;

    if (!noLoader) {
        if (refresh) {
            yield put(showTransparentLoader_());
        } else {
            yield put(showDialogueLoader_());
        } 
    }
    
    let fetchingResult = {};
    try {
        const apiResponse = yield call(placeOrder, JWToken, orderData);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            
            fetchingResult.error = "error";
            yield put(showLoaderCreateSuccess_(fetchingResult));
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
            // alert message   
            
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse order placed: ", apiResponse);
            yield put(placeOrderSuccess_(apiResponse.data)); 
            yield put(orderRefreshed_(true)); 
            yield put(ordersRefreshed_(true));
            fetchingResult.success = "success";
            yield put(showLoaderCreateSuccess_(fetchingResult));    

            // alert message
            const successMessage = yield call(successHandler,"Order", "", "placed");
            yield put(viewAlertMessage_(successMessage));  

            /*const infoMessage = yield call(infoHandler, "order placed");
            yield put(viewAlertMessage_(infoMessage)); */

        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideDialogueLoader_());
        yield put(hideTransparentLoader_());
    }

}

function* getOrderById({payload}) {
    const {JWToken, orderId, refresh, noLoader} = payload;

    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDashbaordLoader_());
            } 
        }
        
        const apiResponse = yield call(getOrder, JWToken, orderId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(getOrderSuccess_(apiResponse.data));        
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideDashbaordLoader_());
        yield put(hideTransparentLoader_());
    }

}

function* getOrderQueueItemsByData({payload}) {
    const {JWToken, querryParams, refresh, noLoader} = payload;
    
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        const apiResponse = yield call(getOrderQueueItems, JWToken, querryParams);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            
            yield put(getOrderQueueItemsSuccess_(apiResponse.data));        
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideDialogueLoader_());
    }

}

function* deleteOrderById({payload}) {
    const {JWToken, orderId, refresh, noLoader} = payload;

    let fetchingResult = {};
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        const apiResponse = yield call(deleteOrder, JWToken, orderId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            fetchingResult.error = "error";
            yield put(showLoaderDeleteSuccess_(fetchingResult));
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(ordersRefreshed_(true));
            yield put(orderRefreshed_(true));
            fetchingResult.success = "success";
            yield put(showLoaderDeleteSuccess_(fetchingResult));
            yield put(deleteOrderSuccess_());  
            
            // alert message   
            const successMessage = yield call(successHandler,"Order", "", "deleted");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideDialogueLoader_());
        yield put(hideTransparentLoader_());
    }

}

function* updateOrderById({payload}) {
    const {JWToken, orderId, orderData, refresh, noLoader} = payload;

    let fetchingResult = {};

    try {
        if (!noLoader) {
            yield put(showTransparentLoader_());
        }
        const apiResponse = yield call(updateOrder, JWToken, orderId, orderData);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            fetchingResult.error = "Something went wrong";
            yield put(showLoaderUpdateSuccess_(fetchingResult));
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(orderRefreshed_(true));
            yield put(ordersRefreshed_(true));
            fetchingResult.success = "Order update success";
            yield put(showLoaderUpdateSuccess_(fetchingResult));
            yield put(updateOrderSuccess_());   
            
            /* alert message   
            const successMessage = yield call(successHandler,"Order", "", "updated");
            yield put(viewAlertMessage_(successMessage)); */
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_())
    }

}

function* forceSendItems({payload}) {
    const {JWToken, orderData, refresh, noLoader} = payload;

    let fetchingResult = {};
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_())
            } 
        }
        
        const apiResponse = yield call(forceSend, JWToken, orderData);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            fetchingResult.error = "error";
            yield put(showLoaderCreateSuccess_(fetchingResult));
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(orderRefreshed_(true));
            yield put(ordersRefreshed_(true));
            fetchingResult.success = "success";
            yield put(showLoaderCreateSuccess_(fetchingResult));  
            
            // alert message   
            const successMessage = yield call(successHandler,"Order", "", "completed");
            yield put(viewAlertMessage_(successMessage)); 

            const infoMessage = yield call(infoHandler,"force send requested");
            yield put(viewAlertMessage_(infoMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideDialogueLoader_());
        yield put(hideTransparentLoader_());
    }

}

function* waitFor(selector) {
    if (yield select(selector)) return

    while(true){
     yield take('*')
     if (yield select(selector)) return true
 }}

function* refreshOrdersFlow({payload}) {
    const {limit, page} = payload
    const orderList = yield select(state => state.order.orderList)
    const fetchedOrderId = yield select(state => state.order.order.orderId)
    const currentOrderId = yield select(state => state.order.orderId)
    const ordersRefreshed = yield select(state => state.order.ordersRefreshed)
    const project = yield select(state => state.project.project)

    // console.log({currentOrderId})

    if (!orderList && project) {
        yield put(getAllOrders_({querryParams: {
            limit: limit || 50,
            page: page || 1,
            projectId: project.projectId,
        }}))
    } else if (orderList) {
        yield put(setSelectedOrder_(orderList.find( o => o.orderId == currentOrderId)))

        if (fetchedOrderId !== currentOrderId) {
            yield put(getOrderSuccess_(''))
        }
    }

    if (ordersRefreshed) {
        yield all([
            put(ordersRefreshed_(false)),
            put(getOrder_({currentOrderId})),
            put(getAllOrders_({querryParams: {
                limit: limit || 50,
                page: page || 1,
                projectId: project.projectId
            }, refresh: true}))
        ])
    }

}

export function* readAllOrders() {
    yield takeEvery(GET_ALL_ORDERS, 
        getAllOrdersByFilter);
}

export function* createOneOrder() {
    yield takeEvery(PLACE_ORDER, 
        placeNewOrderWithData);
}

export function* readOneOrder() {
    yield takeEvery(GET_ORDER, 
        getOrderById);
}

export function* readOrderQueue() {
    yield takeEvery(GET_ORDER_QUEUE_ITEMS, 
        getOrderQueueItemsByData);
}

export function* removeOneOrder() {
    yield takeEvery(DELETE_ORDER, 
        deleteOrderById);
}

export function* updateOneOrder() {
    yield takeEvery(UPDATE_ORDER, 
        updateOrderById);
}

export function* sendItemsOnDemand() {
    yield takeEvery(FORCE_SEND, 
        forceSendItems);
}

export function* refreshOrdersWatcher() {
    yield takeLatest(REFRESH_ORDERS, 
        refreshOrdersFlow);
}

export default function* rootSaga() {
    yield all([fork(readAllOrders),
        fork(createOneOrder),
        fork(readOneOrder),
        fork(removeOneOrder),
        fork(updateOneOrder),
        fork(readOrderQueue),
        fork(sendItemsOnDemand),
        fork(refreshOrdersWatcher)
    ]);
}