import MainLoader from 'components/Loader/MainLoader';
import React, { useEffect } from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import asyncComponent from '../../util/asyncComponent';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { hideAlertMessage_ } from 'actions/Alert';
import { hideAppLoader_, showAppLoader_ } from 'actions/IsFetching';


const Routes = ({match}) => {

       const {viewMessage, alertMessage} = useSelector(({alert}) => alert);


       const dispatch = useDispatch();


       useEffect(()=>{
              // console.log("Message: ", viewMessage, alertMessage);
              let timeout;
              if (viewMessage) {
                     timeout = setTimeout(() => {
                         dispatch(hideAlertMessage_());
                     }, 100);
                 }
              return () => {
                     clearTimeout(timeout);
              }

              
       }, [viewMessage])


       return (
       <>
       <MainLoader />
       <Switch>
       
              <Route path={`${match.url}/home`}
                     component={asyncComponent(() => import('./Home'))}/>
              <Route exact path={`${match.url}/profile/:userId`}
                     component={asyncComponent(() => import('./ProfilePage'))}/>
              <Route exact path={`${match.url}/projects/:userId/assets/:assetType`}
                     component={asyncComponent(() => import('./MoreAssets'))}/>
              <Route exact path={`${match.url}/profile/:userId/edit`}
                     component={asyncComponent(() => import('./ProfilePage/EditProfile'))}/>
              <Route path={`${match.url}/projects`}
                     component={asyncComponent(() => import('./Projects'))}/>
              <Route path={`${match.url}/assets/:type/:platform/:id`}
                     component={asyncComponent(() => import('./Asset'))}/>
              <Route path={`${match.url}/messages`}
                     component={asyncComponent(() => import('./Messages'))}/>
              <Route path={`${match.url}/notifications`}
                     component={asyncComponent(() => import('./Notifications'))}/>
              <Route path={`${match.url}/groups`}
                     component={asyncComponent(() => import('./Groups'))}/>
              <Route path={`${match.url}/explore`}
                     component={asyncComponent(() => import('./Explore'))}/>
              <Route path={`${match.url}/adminpanel`}
                     component={asyncComponent(() => import('./AdminPanel'))}/>
              <Route path={`${match.url}/tokendistribution`}
                     component={asyncComponent(() => import('./TokenDistribution'))}/>
              <Route path={`${match.url}/accountsettings`}
                     component={asyncComponent(() => import('./AccountSettings'))}/>
              <Route path={`${match.url}/wallets`}
                     component={asyncComponent(() => import('./UserWallets'))}/>
              <Route path={`${match.url}/docs`}
                     component={asyncComponent(() => import('./DocsInApp'))}/>
              <Route path={`${match.url}/contact`}
                     component={asyncComponent(() => import('./ContactForm'))}/>
              
              {/*<Route component={asyncComponent(() => import("app/routes/extraPages/routes/404"))}/>*/}
       </Switch>
              {viewMessage && alertMessage.type === "error" && NotificationManager.error(alertMessage.message, alertMessage.title, 5000)}
              {viewMessage && alertMessage.type === "success" && NotificationManager.success(alertMessage.message, alertMessage.title, 5000)}
              {viewMessage && alertMessage.type === "warning" && NotificationManager.warning(alertMessage.message, alertMessage.title, 5000)}
              {viewMessage && alertMessage.type === "info" && NotificationManager.info(alertMessage.message, alertMessage.title, 5000)}
              
              <NotificationContainer/>
       </>)
}

export default withRouter(Routes);

