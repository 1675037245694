import React, { useEffect } from 'react';
import { Chip, Avatar, Icon, CircularProgress, Zoom, Paper, Grow } from '@material-ui/core';
import { useSelector } from 'react-redux';


const ScrollLoader = (props) => {

    const {scrollLoader, loaderResult} = useSelector(({isFetching}) => isFetching);

    const {showLoader} = props;
  
    return (
        <div>
            {(scrollLoader === true || showLoader === true) && (
                <div className="row no-gutters loader-container-scroll justify-content-center align-items-center">
                    <div className="col-2 d-flex loader-view-scroll justify-content-center align-items-center">
                        <CircularProgress size={40} thickness={3}/>
                    </div>
                </div> 
            )} 
        </div>
    );
};

export default ScrollLoader;