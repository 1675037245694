import { GraphTypes } from 'components/networkVisualizer/networkVisualizerState/sharedTypes'
import React, { useEffect } from 'react'
import { animated, useSpring } from 'react-spring'
import { getRadius } from '../../modules/graphHelpers'
import { itemSize, springConfig } from '../item/item'

const transformValue = (i: number, radius: number, connectionsLength: number) =>
  `rotate(${((i - 1) * 360) / connectionsLength}deg) translate3D(${radius /
    2}em, 0, 0)`

interface IsProps {
  type: GraphTypes
  i: number
  visible: boolean
  c_l: number
}

export const TetherLine = ({ type, i, visible, c_l }: IsProps) => {
  const radius = getRadius(itemSize, c_l)

  const [wrapperSpringProps, setWrapperSpring] = useSpring(() => ({
    from: { transform: transformValue(i, radius, c_l), '--i': i - 1 },
    config: springConfig,
  }))

  const [lineSpring, setLineSpring] = useSpring(() => ({
    from: { transform: 'scaleX(0)' },
    to: { transform: 'scaleX(1)' },
    config: springConfig,
  }))

  useEffect(() => {
    //@ts-ignore
    setWrapperSpring.current[0].start({
      transform: transformValue(i, radius, c_l),
      '--i': i - 1,
    })
  }, [i, c_l, radius, setWrapperSpring])

  useEffect(() => {
    if (!visible) {
      //@ts-ignore
      setLineSpring.current[0].start({ transform: 'scaleX(0)' })
    } else {
      //@ts-ignore
      setLineSpring.current[0].start({ transform: 'scaleX(1)' })
    }
  }, [visible, setLineSpring])

  return (
    <animated.div className={`tether-line ${type}`} style={wrapperSpringProps}>
      <animated.span className={'line'} style={lineSpring} />
      <span className='line-slider'></span>
    </animated.div>
  )
}
