import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';

export default {
    palette: {
        primary: {
            light: "#03446cff",
            main: "#03446cff",
            dark: "#03446cff",
            contrastText: '#fff'
        },
        secondary: {
            light: "#06cbeeff",
            main: "#06cbeeff",
            dark: "#06cbeeff",
            contrastText: '#fff'
        },
        // type: THEME_TYPES.LIGHT,
        sidebar: {
          bgColor: '#fff',
          textColor: 'rgba(0, 0, 0, 0.6)',
          textDarkColor: 'rgba(0, 0, 0, 0.87)',
          textActiveColor: '#6200EE',
          navHoverBgColor: 'rgb(229, 229, 229)',
          navActiveBgColor: 'rgb(239, 229, 253)',
          borderColor: 'rgba(33, 33, 33, 0.08)',
        },
        horizontalNav: {
          navigationColor: 'rgba(255, 255, 255, 0.74)',
          navigationActiveColor: 'rgba(255, 255, 255, 1)',
          textColor: 'rgba(0, 0, 0, 0.6)',
          textDarkColor: 'rgba(0, 0, 0, 0.87)',
          textActiveColor: '#6200EE',
          menuHoverBgColor: 'rgb(229, 229, 229)',
          menuActiveBgColor: 'rgb(239, 229, 253)',
        },
        common: {
          dark: '#020202',
        },
        background: {
          paper: '#FFFFFF',
          default: '#f4f4f7',
        },
        text: {
          primary: 'rgba(0, 0, 0, 0.87)',
          secondary: 'rgba(0, 0, 0, 0.6)',
          disabled: 'rgba(0, 0, 0, 0.38)',
          hint: 'rgba(0, 0, 0, 0.3)',
          white: '#fff',
        },
        borderColor: {
          main: 'rgba(0, 0, 0, 0.06)',
          dark: 'rgba(0, 0, 0, 0.12)',
        },
        lightBtn: {
          bgColor: '#f5f5f5',
          textColor: 'rgba(0, 0, 0, 0.38)',
        },
        popupColor: {
          main: '#fff',
        },
      },
      overrides: {
        MuiTab: {
          textColorPrimary: {
            color: 'rgba(0, 0, 0, 0.87)',
          },
        },
        MuiPopover: {
          paper: {
            backgroundColor: '#FFFFFF',
          },
        },
        MuiDialog: {
          paper: {
            backgroundColor: '#FFFFFF',
          },
        },
      },

    status: {
        danger: 'orange',
    },
    typography: {
        fontFamily: 'Nunito+Sans',

        button: {
            fontWeight: 500,
            textAlign: 'capitalize'
        }
        
    },
    overrides: {
        MuiOutlinedInput: {
            root:{
                backgroundColor: "white",
                fontWeight: 500,
                
            }
        }
    }
};
