import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {NavLink, withRouter} from 'react-router-dom';
import { Tab, Tabs } from '@material-ui/core';
import { setAssetType_, setTabValue_ } from 'actions';



const DashTabs = (props) => {

    // const [currentTabvalue, setCurrentTabValue] = useState("all");

    const dispatch = useDispatch();


    const {tabVal, assetType} = useSelector(({navigation}) => navigation);
    const {project} = useSelector(({project}) => project);
    const {tokenBlueprint} = useSelector(({token})=>token);

    

    useEffect(() => {
        
        if (props.location.pathname === `/app/projects/${project.projectId}/dashboard/tokens`) {
            dispatch(setTabValue_(`/app/projects/${project.projectId}/dashboard/tokens/blueprints`))
            dispatch(setAssetType_('tokens'))
            props.history.push(`/app/projects/${project.projectId}/dashboard/tokens/blueprints`);
        }

        if (props.location.pathname === `/app/projects/${project.projectId}/dashboard/edit`) {
            props.history.push(`/app/projects/${project.projectId}/dashboard/edit/general`);
        } 

        if (props.location.pathname === `/app/projects/${project.projectId}/dashboard/requests`) {
            dispatch(setAssetType_('requests'))
            dispatch(setTabValue_(`/app/projects/${project.projectId}/dashboard/requests/received`))
            props.history.push(`/app/projects/${project.projectId}/dashboard/requests/received`);
        } 
        
        
    }, [assetType])

    const handleChangeTab = (event, value) => {
        dispatch(setTabValue_(value));
        props.history.push(value);
    }

    const hideTabs = () => {
        dispatch(setAssetType_("none"));
    }
    
    
        return (
            <div className="tabs-menu">
                {assetType !== "none" && (
                <div className="row">
                    <div className="col-xl-8 col-lg-8 col-md-12 d-flex justify-content-between align-items-center mb-4">
                        <Tabs
                            TabIndicatorProps={{ style: { background: "#2daeee" } }}
                            style={{zIndex: 1000}}
                            className="primary"
                            value={tabVal}
                            indicatorColor="primary"
                            onChange={handleChangeTab}
                            aria-label="disabled tabs example"
                        >
                            {/*(assetType === "tokens" || (assetType === "resources" || assetType === "utilities")) && (<Tab label="All" value={`/app/projects/${project.projectId}/dashboard/${assetType}/all`} />)*/}
                            {/*(assetType === "tokens" || (assetType === "resources" || assetType === "utilities")) && (<Tab label="Co-Ops" value={`/app/projects/${project.projectId}/dashboard/${assetType}/coops`} />)*/}
                            {assetType === "tokens" && (<Tab label="Blueprints" value={`/app/projects/${project.projectId}/dashboard/tokens/blueprints`}/>)}
                            {assetType === "tokens" && (<Tab label="Balances" value={`/app/projects/${project.projectId}/dashboard/tokens/balances`}/>)}
                            {/*assetType === "tokens" && (<Tab label="Orders" value={`/app/projects/${project.projectId}/dashboard/tokens/orders`}/>)*/}
                            {/*assetType === "tokens" && (<Tab label="Transactions" value={`/app/projects/${project.projectId}/dashboard/tokens/transactions`}/>)*/}
                            {assetType === "tokensettings" && tokenBlueprint.tokenId && (<Tab label="Tokendata" value={`/app/projects/${project.projectId}/dashboard/tokens/blueprints/${tokenBlueprint.tokenId}/edit/tokendata`}/>)}
                            {assetType === "tokensettings" && tokenBlueprint.tokenId && (<Tab label="Metadata" value={`/app/projects/${project.projectId}/dashboard/tokens/blueprints/${tokenBlueprint.tokenId}/edit/metadata`}/>)}
                            {assetType === "projectsettings" && (<Tab label="General" value={`/app/projects/${project.projectId}/dashboard/edit/general`}/>)}
                            {/*assetType === "projectsettings" && (<Tab label="Distribution" value={`/app/projects/${project.projectId}/dashboard/edit/distribution`}/>)*/}
                            {assetType === "projectsettings" && (<Tab label="API" value={`/app/projects/${project.projectId}/dashboard/edit/network`}/>)}
                            {assetType === "requests" && (<Tab label="Received" value={`/app/projects/${project.projectId}/dashboard/requests/received`}/>)}
                            {assetType === "requests" && (<Tab label="Sent" value={`/app/projects/${project.projectId}/dashboard/requests/sent`}/>)}
                            
                        </Tabs>  
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-12 d-flex justify-content-end align-items-center mb-4">

                        {/*assetType === 'tokens' && (
                            <NavLink 
                                className="jr-btn align-self-center bg-white text-indigo ml-2"
                                variant="outlined"
                                style={{ textDecoration: 'none', marginBottom: '0px', border: "solid 1px"}}
                                onClick={hideTabs}
                                to={"/app/projects/"+ project.projectId + "/dashboard/tokens/wallets"}>
                                    Project Wallets
                            </NavLink>
                        )*/}
                            
                    </div>
                </div>)}
          </div>
        );
    }
;

export default withRouter(DashTabs);

