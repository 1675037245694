import axios, { AxiosResponse } from 'axios'
import { CategoryTypes } from 'components/networkVisualizer/networkVisualizerState/sharedTypes'
import { GetAssetDataResponse } from './types/getAssetDataResponse'
import { GetCooperationsResponse } from './types/getCooperationsResponse'
import { GetWalletCooperations } from './types/getWalletCooperations'
import { SearchAssetsResponse } from './types/searchAssetsResponse'

//Add production URL here or import it from another file.
const url = process.env.REACT_APP_EXPLORER_API
// const url = 'https://d1kfyd722jt8ef.cloudfront.net/explorer'

axios.defaults.baseURL = url

/**
 *
 * @param type 'tokens' | 'utilities' | 'projects' | 'resources'
 * @param id asset ID
 * @param platformId Only required for tokens
 */
function getAssetData(
  type: CategoryTypes,
  id: string | number,
  platformId?: string | number
): Promise<AxiosResponse<GetAssetDataResponse>> {
  return axios.get(
    `${type}/${platformId !== undefined ? platformId + '/' : ''}${id}`
  )
}

interface SearchParams {
  // [key: string]: any
  name?: string
  limit?: number
  offset?: number
  projectId?: number | string
  featured?: boolean //Only used for fetching featured
}

function searchAssets(
  type: CategoryTypes,
  params: SearchParams
): Promise<AxiosResponse<SearchAssetsResponse>> {
  return axios.get(`${type}`, {
    params,
  })
}

// function getFeaturedProjects(
//   // type: CategoryTypes,
//   // params: SearchParams
// ): Promise<AxiosResponse<SearchAssetsResponse>> {
//   return axios.get(`tokens`, {
//     {featured: true},
//   })
// }

/**
 * Used for getting the cooperations of either tokens, utilities or resources. Tokens can request for utilities or resources while utilities and resources can only request for tokens.
 *
 */
interface GetCooperationsParams {
  limit?: number
  offset?: number
}

function getCooperations<
  T1 extends 'tokens' | 'utilities' | 'resources',
  T2 extends T1 extends 'tokens' ? 'utilities' | 'resources' : 'tokens'
>(
  assetType: T1,
  assetId: number | string,
  cooperationType: T2,
  params?: GetCooperationsParams,
  platformId?: string | number
): Promise<AxiosResponse<GetCooperationsResponse>> {
  return axios.get(
    `${assetType}/${
      platformId !== undefined ? platformId + '/' : ''
    }${assetId}/${cooperationType}`,
    {
      params,
    }
  )
}

interface WalletParams {
  limit?: number
  page?: number
}

/**
 * Only used for fetching wallet connections.
 * @param walletId
 * @param blockchain
 */
function getWalletCooperations(
  walletId: string | number,
  blockchain: string,
  params?: WalletParams
): Promise<AxiosResponse<GetWalletCooperations>> {
  return axios.get(`balances/${blockchain}/${walletId}`, { params })
}

export const API = {
  url,
  getAssetData,
  searchAssets,
  getCooperations,
  getWalletCooperations,
}
