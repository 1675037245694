import { shareArrowIcon } from 'components/networkVisualizer/assets/icons'
import { GraphTypes } from 'components/networkVisualizer/networkVisualizerState/sharedTypes'
import React from 'react'
import CSSTransition from 'react-transition-group/CSSTransition'
import { ProjectPopup } from './components/project'
import { ResourcePopup } from './components/resource'
import { TokenPopup } from './components/token'
import { UtilityPopup } from './components/utility'

interface PopupProps {
  id: string | number
  type: GraphTypes
}

const PopupSelector = React.memo(({ id, type }: PopupProps) => {
  switch (type) {
    case 'tokens':
      return <TokenPopup {...{ id }} />
    case 'utilities':
      return <UtilityPopup {...{ id }} />
    case 'resources':
      return <ResourcePopup {...{ id }} />
    case 'projects':
      return <ProjectPopup {...{ id }} />
    default:
      return null
  }
})

interface IsProps extends PopupProps {
  visible: boolean
  onMouseEnter: () => void
  onMouseLeave: () => void
  viewDetails: (e: React.MouseEvent) => void
  copyLink: (e: React.MouseEvent) => void
}

export const Popup = React.memo(
  ({
    visible,
    id,
    type,
    onMouseEnter,
    onMouseLeave,
    viewDetails,
    copyLink,
  }: IsProps) => (
    <CSSTransition
      in={visible}
      timeout={{
        enter: 250,
        exit: 250,
      }}
      mountOnEnter
      unmountOnExit
    >
      <div
        className={`popup-wrapper ${type}`}
        {...{ onMouseEnter, onMouseLeave }}
      >
        <PopupSelector {...{ id, type }} />
        <div className='buttons-row-wrapper'>
          <div className='view-details-button' onClick={viewDetails}>
            <p className='details-button-text'>Read More</p>
          </div>
          <div className='share-button-wrapper'>
            <div className='share-button' onClick={copyLink}>
              <img src={shareArrowIcon} alt='share arrow' />
            </div>
            <div className='custom-tool-tip'>
              <p className='tool-tip-text'>copy link</p>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  )
)
