import {
    GET_ALL_TRANSACTIONS,
    GET_ALL_TRANSACTIONS_SUCCESS,
    GET_TRANSACTION,
    GET_TRANSACTION_SUCCESS,
    LOAD_MORE_TRANSACTIONS_SUCCESS,
    SEARCH_TX_STRING,
    SEARCH_TX_STRING_SUCCESS,
    TXS_REFRESHED,
    TX_REFRESHED,


} from 'constants/ActionTypes';

export const getAllTransactions_ = (data) => {
    return {
        type: GET_ALL_TRANSACTIONS,
        payload: data
    };
};

export const getAllTransactionsSuccess_ = (transactions) => {
    return {
        type: GET_ALL_TRANSACTIONS_SUCCESS,
        payload: transactions
    };
};

export const loadMoreTransactionsSuccess_ = (transactions) => {
    return {
        type: LOAD_MORE_TRANSACTIONS_SUCCESS,
        payload: transactions
    };
};

export const getTransaction_ = (transactionId) => {
    return {
        type: GET_TRANSACTION,
        payload: transactionId
    };
};

export const getTransactionSuccess_ = (transaction) => {
    return {
        type: GET_TRANSACTION_SUCCESS,
        payload: transaction
    };
};

export const searchTxString_ = (string) => {
    return {
        type: SEARCH_TX_STRING,
        payload: string
    };
};

export const searchTxStringSuccess_ = (txs) => {
    return {
        type: SEARCH_TX_STRING_SUCCESS,
        payload: txs
    };
};


export const txRefreshed_ = (data) => {
    return {
        type: TX_REFRESHED,
        payload: data
    };
};


export const txsRefreshed_ = (data) => {
    return {
        type: TXS_REFRESHED,
        payload: data
    };
};