import React, {useEffect, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import SearchBox from "components/SearchBox";
import CardHeader from "components/dashboard/Common/CardHeader/index";
import { searchStringSuccess_, searchString_ } from "actions";
import {Dropdown, DropdownMenu, DropdownToggle, Popover} from 'reactstrap';
import SearchResults from "components/SearchBox/SearchResults";
import SearchResultsInProject from "components/SearchBox/SearchResultsInProject";



const SearchModule = (props) => {

    const {inProject} = props;

    const JWToken = localStorage.getItem("idToken");

    const dispatch = useDispatch();
    const {project} = useSelector(({project})=> project);

    const [searchText, setSearchText] = useState('');
    const [searchResultContainer, setSearchResultContainer] = useState(false);


    const onSearchFieldSelect = () => {
        if (searchText == '') {
            setSearchResultContainer(false);
            dispatch(searchStringSuccess_([]));
        } else {
            setSearchResultContainer(!searchResultContainer)
        }
        
    };

    const onResultSelect = () => {
        setSearchResultContainer(false)
    };

    let timeOut;

    const updateSearchText = (evt) => {
        setSearchText(evt.target.value);

        if (evt.target.value === '') {
            setSearchResultContainer(false);
            dispatch(searchStringSuccess_(''))
        } else {
            setSearchResultContainer(true);
            let querryParams = {
                name: evt.target.value,
                limit: 50,
                page: 1,
            }
            if (inProject) {
                querryParams.projectId = project.projectId;
            }
            // timeOut = setTimeout(() => {
                dispatch(searchString_({JWToken, querryParams}));
            // }, 500);
        }

        
    };

    return (

        <Dropdown
            className="search-container"
            isOpen={searchResultContainer}
            toggle={onSearchFieldSelect}>

            <DropdownToggle
                className="d-inline-block"
                tag="span"
                data-toggle="dropdown">
                <SearchBox styleName="" placeholder={inProject? "Search project assets..." : "Search platform ..."}
                    onChange={updateSearchText}
                    value={searchText}/>
            </DropdownToggle>

            <DropdownMenu right onClick={onResultSelect}>
                {/*<CardHeader styleName="align-items-center"
                            heading={"Assets"}/>*/}
                {inProject ? 
                <SearchResultsInProject projectId={project ? project.projectId : null}/>
                :
                <SearchResults />}
            </DropdownMenu>
        </Dropdown>

    );
};


export default SearchModule;
