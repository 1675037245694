import { defaultImages } from 'components/networkVisualizer/assets/variables/defaultImages'
import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import CSSTransition from 'react-transition-group/CSSTransition'
import { createSelector } from 'reselect'
import { AppState } from 'store'
import { ActionButtons } from './components/actionButtons/actionButtons'
import { Body } from './components/body/body'
import { Header } from './components/header/header'

const activeItemSelector = createSelector(
  (state: AppState) => state.networkVisualizerState.itemDetails,
  ({ id, type }) => ({ id, type })
)

const ItemDetailsComp = React.memo(() => {
  const { id, type } = useSelector(activeItemSelector, shallowEqual)

  const image = useSelector(
    (state: AppState) =>
      state.networkVisualizerState.general.data[type][id].image
  )

  let backgroundImage = image || defaultImages[type]

  return (
    <div className='item-details-wrapper'>
      <div className='item-details-container'>
        {type === 'projects' ? (
          <div
            className='background-image-container'
            style={{ backgroundImage: `url(${backgroundImage})` }}
          >
            {/* <img
              src={backgroundImage}
              alt='asset image'
              className='background-image'
            /> */}
          </div>
        ) : null}
        <Header {...{ id, type }} />
        <Body {...{ id, type }} />
        <ActionButtons {...{ id, type }} />
      </div>
    </div>
  )
})

export const ItemDetails = () => {
  const isVisible = useSelector(
    (state: AppState) => state.networkVisualizerState.itemDetails.visible
  )

  return (
    <CSSTransition
      in={isVisible}
      timeout={{
        enter: 500,
        exit: 300,
      }}
      classNames={'details-transition-'}
      unmountOnExit
      mountOnEnter
    >
      <ItemDetailsComp />
    </CSSTransition>
  )
}
