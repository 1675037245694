import { CategoryTypes, SelectionData } from '../../sharedTypes'

interface GraphFilterState {
  tokens: boolean
  utilities: boolean
  projects: boolean
  resources: boolean
}

export interface GraphState {
  selectedNode: SelectionData
  filter: GraphFilterState
  maxVisibleConnections: number
  visibleConnectionsIndex: number
  walletFetchPage: number //Only used for keeping track of fetch page when on a wallet. Ensure to reset value on selected node change.
  disableNextButton: boolean //Only used when wallet is centered since there is no data about total number of connections.
}

export const SET_SELECTED_NODE = 'SET_SELECTED_NODE'

export interface SetSelectedNode {
  type: typeof SET_SELECTED_NODE
  payload: SelectionData
}

export const SET_GRAPH_FILTER_STATE = 'SET_GRAPH_FILTER_STATE'

export interface SetGraphFilterState {
  type: typeof SET_GRAPH_FILTER_STATE
  payload: CategoryTypes
}

export const RESET_GRAPH_FILTER_STATE = 'RESET_GRAPH_FILTER_STATE'

export interface ResetGraphFilterState {
  type: typeof RESET_GRAPH_FILTER_STATE
}

export const CHANGE_VISIBLE_CONNECTIONS_SET = 'CHANGE_VISIBLE_CONNECTIONS_SET'

export interface ChangeVisibleConnectionsSet {
  type: typeof CHANGE_VISIBLE_CONNECTIONS_SET
  payload: number
}

export const SET_VISIBLE_CONNECTIONS_SET = 'SET_VISIBLE_CONNECTIONS_SET'

export interface SetVisibleConnectionsSet {
  type: typeof SET_VISIBLE_CONNECTIONS_SET
  payload: number
}

export const SET_WALLET_FETCH_PAGE = 'SET_WALLET_FETCH_PAGE'

export interface SetWalletFetchPage {
  type: typeof SET_WALLET_FETCH_PAGE
  payload: number
}

export const DISABLE_GRAPH_NEXT_BUTTON = 'DISABLE_GRAPH_NEXT_BUTTON'

export interface DisableGraphNextButton {
  type: typeof DISABLE_GRAPH_NEXT_BUTTON
  payload: boolean
}

export const RESET_VISUALIZER_GRAPH_STATE = 'RESET_VISUALIZER_GRAPH_STATE'

export interface ResetVisualizerGraphState {
  type: typeof RESET_VISUALIZER_GRAPH_STATE
}

export type GraphActions =
  | SetSelectedNode
  | SetGraphFilterState
  | ResetGraphFilterState
  | SetVisibleConnectionsSet
  | SetWalletFetchPage
  | DisableGraphNextButton
  | ResetVisualizerGraphState

/**
 *
 * Redux Saga Actions
 *
 */

export const CHANGE_SELECTED_NODE = 'CHANGE_SELECTED_NODE'

export type ChangeSelectedNodePayload = SelectionData & {
  platformId?: string | number
}

export interface ChangeSelectedNode {
  type: typeof CHANGE_SELECTED_NODE
  payload: ChangeSelectedNodePayload
}

export const TOGGLE_GRAPH_FILTER_STATE = 'TOGGLE_GRAPH_FILTER_STATE'

export interface ToggleGraphFilterState {
  type: typeof TOGGLE_GRAPH_FILTER_STATE
  payload: CategoryTypes
}
