import {
    HIDE_MESSAGE,
    INIT_URL,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    SIGNIN_FACEBOOK_USER_SUCCESS,
    SIGNIN_GITHUB_USER_SUCCESS,
    SIGNIN_GOOGLE_USER_SUCCESS,
    SIGNIN_TWITTER_USER_SUCCESS,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER_SUCCESS,
    SEND_CODE,
    SEND_CODE_SUCCESS,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    SIGNIN_USER_UNVERIFIED,
    GET_AUTH_USER_SUCCESS,
    GET_USER_ACCOUNT_SUCCESS,
    CLEAR_AUTHUSER,
    RESET_PASSWORD_CANCEL,
    SIGNIN_GUEST_SUCCESS,
    SHOW_ERROR_PAGE,
    LOAD_EXPLORER_SUCCESS,
    AUTHUSER_REFRESHED,
    AUTHUSERS_REFRESHED
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    initURL: '',
    authUser: localStorage.getItem('idToken'),
    guestUser: '',
    stage: 1,
    destination: '',
    showErrorPage: false,
    explorerLoaded: false,
    authUserRefreshed: false,
    authUsersRefreshed: false
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SIGNUP_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                initURL: '/verifyaccount',
                authUser: action.payload
            }
        }
        case SIGNIN_USER_UNVERIFIED: {
            return {
                ...state,
                loader: false,
                initURL: '/verifyaccount',
                authUser: action.payload,
                showMessage: true,
                alertMessage: "Please confirm your eMail address"

            }
        }
        case SIGNIN_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                initURL: '/app/home',
                authUser: action.payload
            }
        }
        case SIGNIN_GUEST_SUCCESS: {
            return {
                ...state,
                loader: false,
                initURL: '/explore',
                guestUser: action.payload
            }
        }
        case GET_AUTH_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case INIT_URL: {
            return {
                ...state,
                initURL: action.payload
            }
        }
        case SIGNOUT_USER_SUCCESS: {
            return {
                ...state,
                authUser: null,
                initURL: '/explore',
                loader: false
            }
        }

        case CLEAR_AUTHUSER: {
            return {
                ...state,
                authUser: null,
            }
        }

        case SEND_CODE_SUCCESS: {
            return {
                ...state,
                stage: 2,
                destination: action.payload
            }
        }

        case RESET_PASSWORD: {
            return {
                ...state,
                loader: false
            }
        }

        case RESET_PASSWORD_SUCCESS: {
            return {
                ...state,
                // initURL: '/signin',
                stage: 2,
            }
        }

        case RESET_PASSWORD_CANCEL: {
            return {
                ...state,
                stage: 1
            }
        }

        case SHOW_MESSAGE: {
            return {
                ...state,
                alertMessage: action.payload,
                showMessage: true,
                loader: false
            }
        }
        case HIDE_MESSAGE: {
            return {
                ...state,
                alertMessage: '',
                showMessage: false,
                loader: false
            }
        }

        case SIGNIN_GOOGLE_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case SIGNIN_FACEBOOK_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case SIGNIN_TWITTER_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case SIGNIN_GITHUB_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case ON_SHOW_LOADER: {
            return {
                ...state,
                loader: true
            }
        }
        case ON_HIDE_LOADER: {
            return {
                ...state,
                loader: false
            }
        }
        case SHOW_ERROR_PAGE: {
            return {
                ...state,
                initURL: "/error",
                showErrorPage: action.payload
            }
        }
        case LOAD_EXPLORER_SUCCESS: {
            return {
                ...state,
                explorerLoaded: action.payload
            }
        }
        case AUTHUSER_REFRESHED: {
            return {
                ...state,
                authUserRefreshed: action.payload
            }
        }
        case AUTHUSERS_REFRESHED: {
            return {
                ...state,
                authUsersRefreshed: action.payload
            }
        }

        default:
            return state;
    }
}
