import { ResourceResponse } from 'components/networkVisualizer/API/types/getAssetDataResponse/resource'
import {
  Projects,
  Resources,
} from 'components/networkVisualizer/networkVisualizerState/store/general/types'

/*
<<<<<<<<<<<<<Check before production>>>>>>>>>>>>>>>>>>>>

1. Get cooperations array schema to be able to map out connections object.

*/

interface ResourceFormatterOutput {
  resources: Resources
  projects: Projects
}

export function resourceFormatter(
  response: ResourceResponse
): ResourceFormatterOutput {
  let resources: Resources = {}

  let {
    resourceId,
    name,
    avatarImage,
    project,
    category,
    cooperationCounts,
    modelType,
    platform,
    license,
    fileType,
  } = response

  let projects: Projects = project
    ? {
        [project.projectId]: {
          name: project?.name,
          image: project?.avatarImage?.thumbnail,
        },
      }
    : {}

  resources[resourceId] = {
    name,
    image: avatarImage?.thumbnail ?? undefined,
    cooperationsCount: cooperationCounts,
    projectName: project?.name,
    category,
    modelType,
    platform,
    license,
    fileType,
    //Empty array is assigned as it's used to determine whether the asset has a project or not
    connections: {
      projects: project ? [project?.projectId] : [],
    },
  }

  return {
    resources,
    projects,
  }
}
