import React, {useEffect} from "react";
import {withRouter} from 'react-router-dom';
import {createMuiTheme} from "@material-ui/core/styles";
import {ThemeProvider} from "@material-ui/styles";
import URLSearchParams from "url-search-params";
import MomentUtils from "@date-io/moment";
import {MuiPickersUtilsProvider} from "material-ui-pickers";
import {Redirect, Route, Switch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {IntlProvider} from "react-intl";
import "assets/vendors/style";
import indigoTheme from "./themes/indigoTheme";
import cyanTheme from "./themes/cyanTheme";
import orangeTheme from "./themes/orangeTheme";
import amberTheme from "./themes/amberTheme";
import pinkTheme from "./themes/pinkTheme";
import blueTheme from "./themes/blueTheme";
import purpleTheme from "./themes/purpleTheme";
import greenTheme from "./themes/greenTheme";
import AppLocale from "../lngProvider";
import {
    AMBER,
    BLUE,
    CYAN,
    DARK_AMBER,
    DARK_BLUE,
    DARK_CYAN,
    DARK_DEEP_ORANGE,
    DARK_DEEP_PURPLE,
    DARK_GREEN,
    DARK_INDIGO,
    DARK_PINK,
    DEEP_ORANGE,
    DEEP_PURPLE,
    GREEN,
    INDIGO,
    PINK
} from "constants/ThemeColors";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";
import VerifyAccount from "./VerifyAccount";
import {setInitUrl, getAuthUser} from "../actions/Auth";
import RTL from "util/RTL";
import {setDarkTheme, setThemeColor} from "../actions/Setting";
import AppLayout from "./AppLayout";
import {getUserAccount} from "../api"
import ConfirmUser from "./ConfirmUser";
import ResetPassword from "./ResetPassword";
import Docs from "./Docs";


import { Auth } from 'aws-amplify';



/////// AWS
import AWS from 'aws-sdk/global'
import AWSMqttClient from 'aws-mqtt'
import { enjinLConnected_ } from "actions/EventListeners";
import { aws_refreshSession, getCurrentAuthUser } from "sagas/Auth";
import explore from "./explore";
import { AppLoaderFull } from "components/Loader/AppLoaderFull";
const AWSIoTData = require('aws-iot-device-sdk');





const RestrictedRoute = ({component: Component, authUser, ...rest}) =>
    <Route
        {...rest}
        render={props =>
            authUser
                ? <Component {...props} />
                : <Redirect
                    to={{
                        pathname: '/explore',
                        state: {from: props.location}
                    }}
                />}
    />;


const App = (props) => {

    
    

    const dispatch = useDispatch();
    const {themeColor, darkTheme, locale, isDirectionRTL} = useSelector(({settings}) => settings);
    const {authUser, initURL} = useSelector(({auth}) => auth);
    const isDarkTheme = darkTheme;  
    const {match, location} = props;

    


    useEffect(() => {
        window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

        if (initURL === '') {
            dispatch(setInitUrl(props.history.location.pathname));
        }
        const params = new URLSearchParams(props.location.search);
        if (params.has("theme-name")) {
            dispatch(setThemeColor(params.get('theme-name')));
        }
        if (params.has("dark-theme")) {
            dispatch(setDarkTheme());
        }

        

    }, [dispatch, authUser, initURL, props.history.location.pathname, props.location.search]);

    useEffect(() => {
        let interval;
        if (initURL !== "/explore") {
            interval = setInterval(() => {
                aws_refreshSession();
                // console.log("interval");
            }, 600000);
        }
        return () => clearInterval(interval);
      }, [initURL]);
    

    const getColorTheme = (themeColor, applyTheme) => {
        switch (themeColor) {
            case INDIGO: {
                applyTheme = createMuiTheme(indigoTheme);
                break;
            }
            case CYAN: {
                applyTheme = createMuiTheme(cyanTheme);
                break;
            }
            case AMBER: {
                applyTheme = createMuiTheme(amberTheme);
                break;
            }
            case DEEP_ORANGE: {
                applyTheme = createMuiTheme(orangeTheme);
                break;
            }
            case PINK: {
                applyTheme = createMuiTheme(pinkTheme);
                break;
            }
            case BLUE: {
                applyTheme = createMuiTheme(blueTheme);
                break;
            }
            case DEEP_PURPLE: {
                applyTheme = createMuiTheme(purpleTheme);
                break;
            }
            case GREEN: {
                applyTheme = createMuiTheme(greenTheme);
                break;
            }
            case DARK_INDIGO: {
                applyTheme = createMuiTheme({...indigoTheme, direction: 'rtl'});
                break;
            }
            case DARK_CYAN: {
                applyTheme = createMuiTheme(cyanTheme);
                break;
            }
            case DARK_AMBER: {
                applyTheme = createMuiTheme(amberTheme);
                break;
            }
            case DARK_DEEP_ORANGE: {
                applyTheme = createMuiTheme(orangeTheme);
                break;
            }
            case DARK_PINK: {
                applyTheme = createMuiTheme(pinkTheme);
                break;
            }
            case DARK_BLUE: {
                applyTheme = createMuiTheme(blueTheme);
                break;
            }
            case DARK_DEEP_PURPLE: {
                applyTheme = createMuiTheme(purpleTheme);
                break;
            }
            case DARK_GREEN: {
                applyTheme = createMuiTheme(greenTheme);
                break;
            }
            default :
                createMuiTheme(indigoTheme);
        }
        return applyTheme;
    };

    let applyTheme = createMuiTheme(indigoTheme);
    if (isDarkTheme) {
        document.body.classList.add('dark-theme');
        applyTheme = createMuiTheme(darkTheme)
    } else {
        applyTheme = getColorTheme(INDIGO, applyTheme);
    }
    
    if (location.pathname === '/') {

        if (authUser === null) {
            props.history.push('/explore'); //return (<Redirect to={'/signin'}/>);
        } else if (initURL ==='/verifyaccount') {    
            props.history.push('/verifyaccount'); // return (<Redirect to={'/verifyaccount'}/>);
        } else if (initURL === '' || initURL === '/' || initURL === '/app/home') {
            props.history.push('/app/home') // return (<Redirect to={'/app/home'}/>);
        }
    }

    
    if (authUser?.user && location.pathname === '/signin') {
        Auth.currentAuthenticatedUser({
            bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        }).then((user) =>{
            // console.log("user is logged in")
            
            props.history.push('/app/home') })
        .catch(err => {
            // console.log("user not auth")
            props.history.push('/signin')}); 
    }


    if (location.pathname === '/docs') {
        props.history.push('/docs/overview');//     return (<Redirect to={'/Docs'}/>);
    }

    if (location.pathname === '/explore') {
        // props.history.push('/explore');
    }

    /*if (location.pathname === '/confirmuser') {
        // props.history.push('/confirmuser'); // return <Redirect to={'/ConfirmUser'}/>
    }*/

    if (isDirectionRTL) {
        applyTheme.direction = 'rtl';
        document.body.classList.add('rtl')
    } else {
        document.body.classList.remove('rtl');
        applyTheme.direction = 'ltr';
    }

    
    const currentAppLocale = AppLocale[locale.locale];
    return (
        <ThemeProvider theme={applyTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <IntlProvider
                    locale={currentAppLocale.locale}
                    messages={currentAppLocale.messages}>
                    <RTL>
                        <div className="app-main">

                            
                            <Switch>
                                <RestrictedRoute path={`${match.url}app`} authUser={authUser}
                                                 component={AppLayout}/>
                                <Route path='/signin' component={SignIn}/>
                                {/*<Route path='/signup' component={SignUp}/>*/}
                                <Route path='/forgotpassword' component={ForgotPassword}/>
                                <Route path='/verifyaccount' component={VerifyAccount}/>
                                <Route path='/confirmuser' component={ConfirmUser}/>
                                <Route path='/resetpassword' component={ResetPassword}/>
                                <Route path='/docs' component={Docs}/>
                                {<Route path='/explore' component={explore}/>}
                                {/*<Route*/}
                                {/*  component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>*/}
                            </Switch>
                            <AppLoaderFull />
                        </div>
                    </RTL>
                </IntlProvider>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
        
    );
};


export default withRouter(App);
