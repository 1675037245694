import {
    SET_TAB_VALUE,
    SET_ASSET_TYPE,
    OPEN_HELP_SECTION
} from "constants/ActionTypes";

const INIT_STATE = {
    tabVal: 'none',
    assetType: 'none',
    helpSectionOpen: false,
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_TAB_VALUE: {
            return {
                ...state,
                tabVal: action.payload
            }
        }
        case SET_ASSET_TYPE: {
            return {
                ...state,
                assetType: action.payload
            }
        }
        case OPEN_HELP_SECTION: {
            return {
                ...state,
                helpSectionOpen: action.payload
            }
        }
        default:
            return state;
    }
}