import {
  ChangeVisualizerSearchFilter,
  ChangeVisualizerSearchValue,
  CHANGE_VISUALIZER_SEARCH_FILTER,
  CHANGE_VISUALIZER_SEARCH_VALUE,
  FeaturedProjects,
  FetchVisualizerFeaturedProjects,
  FETCH_VISUALIZER_FEATURED_PROJECTS,
  HideVisualizerNavMenu,
  HIDE_NETWORK_VISUALIZER_NAV_MENU,
  ResetVisualizerNavMenuState,
  RESET_VISUALIZER_NAV_MENU_STATE,
  SearchFilter,
  SearchResultsType,
  SetFeaturedProjects,
  SetFeaturedProjectsVisibility,
  SetSearchingStatus,
  SetSearchResults,
  SetVisualizerSearchFilter,
  SetVisualizerSearchTypingStatus,
  SetVisualizerSearchValue,
  SET_SEARCH_RESULTS,
  SET_VISUALIZER_FEATURED_PROJECTS,
  SET_VISUALIZER_FEATURED_PROJECTS_VISIBILITY,
  SET_VISUALIZER_SEARCHING_STATUS,
  SET_VISUALIZER_SEARCH_FILTER,
  SET_VISUALIZER_SEARCH_TYPING_STATUS,
  SET_VISUALIZER_SEARCH_VALUE,
  ShowVisualizerNavMenu,
  TOGGLE_NETWORK_VISUALIZER_NAV_MENU,
} from './types'

export const showVisualizerNavMenu = (): ShowVisualizerNavMenu => ({
  type: TOGGLE_NETWORK_VISUALIZER_NAV_MENU,
})

export const hideVisualizerNavMenu = (): HideVisualizerNavMenu => ({
  type: HIDE_NETWORK_VISUALIZER_NAV_MENU,
})

//Used by Redux Saga. Do not call directly.
export const setVisualizerSearchFilter = (
  category: SearchFilter
): SetVisualizerSearchFilter => ({
  type: SET_VISUALIZER_SEARCH_FILTER,
  payload: category,
})

//Used by Redux Saga. Do not call directly.
export const setVisualizerTypingStatus = (
  isTyping: boolean
): SetVisualizerSearchTypingStatus => ({
  type: SET_VISUALIZER_SEARCH_TYPING_STATUS,
  payload: isTyping,
})

//Used by Redux Saga. Do not call directly.
export const setVisualizerSearchingStatus = (
  searching: boolean
): SetSearchingStatus => ({
  type: SET_VISUALIZER_SEARCHING_STATUS,
  payload: searching,
})

//Used by Redux Saga. Do not call directly.
export const setVisualizerSearchValue = (
  newValue: string
): SetVisualizerSearchValue => ({
  type: SET_VISUALIZER_SEARCH_VALUE,
  payload: newValue,
})

//Used by Redux Saga. Do not call directly.
export const setSearchResults = (
  payload: SearchResultsType
): SetSearchResults => ({
  type: SET_SEARCH_RESULTS,
  payload,
})

/**
 * Sets the featured projects and toggles featuredProjectsLoading to false
 * @param payload
 */
export const setFeaturedProjects = (
  payload: FeaturedProjects
): SetFeaturedProjects => ({
  type: SET_VISUALIZER_FEATURED_PROJECTS,
  payload,
})

/**
 * Expand/collapse featured projects
 * @param payload boolean
 */
export const setFeaturedProjectsVisibility = (
  payload?: boolean
): SetFeaturedProjectsVisibility => ({
  type: SET_VISUALIZER_FEATURED_PROJECTS_VISIBILITY,
  payload,
})

export const resetVisualizerNavMenuState = (): ResetVisualizerNavMenuState => ({
  type: RESET_VISUALIZER_NAV_MENU_STATE,
})

/**
 *
 * Redux Saga Actions
 *
 */

/**
 * Call this to change the search filter.
 * @param newCategory CategoryType or null
 */
export const changeVisualizerSearchFilter = (
  newCategory: SearchFilter
): ChangeVisualizerSearchFilter => ({
  type: CHANGE_VISUALIZER_SEARCH_FILTER,
  payload: newCategory,
})

/**
 * Use to updated search value
 */
export const changeVisualizerSearchValue = (
  newValue: string
): ChangeVisualizerSearchValue => ({
  type: CHANGE_VISUALIZER_SEARCH_VALUE,
  payload: newValue,
})

/**
 * Use to fetch the featured projects for the nav menu.
 */
export const fetchVisualizerFeaturedProjects = (): FetchVisualizerFeaturedProjects => ({
  type: FETCH_VISUALIZER_FEATURED_PROJECTS,
})
