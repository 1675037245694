import { CLEAR_LINKING_DIALOGUE_ENJINAPP, DELETE_ENJINAPP, DELETE_ENJINAPP_SUCCESS, ENJAPPS_REFRESHED, ENJAPP_REFRESHED, GET_ALL_ENJINAPPS, GET_ALL_ENJINAPPS_SUCCESS, GET_ENJINAPP, GET_ENJINAPP_SUCCESS, LINK_ENJINAPP, LINK_ENJINAPP_SUCCESS, LOAD_MORE_ENJINAPPS_SUCCESS, UPDATE_ENJINAPP, UPDATE_ENJINAPP_SUCCESS } from "constants/ActionTypes";


export const getAllEnjinApps_ = (data) => {
    return {
        type: GET_ALL_ENJINAPPS,
        payload: data
    };
};

export const getAllEnjinAppsSuccess_ = (data) => {
    return {
        type: GET_ALL_ENJINAPPS_SUCCESS,
        payload: data
    };
};

export const loadMoreEnjinAppsSuccess_ = (data) => {
    return {
        type: LOAD_MORE_ENJINAPPS_SUCCESS,
        payload: data
    };
};

export const getEnjinApp_ = (data) => {
    return {
        type: GET_ENJINAPP,
        payload: data
    };
};

export const getEnjinAppSuccess_ = (data) => {
    return {
        type: GET_ENJINAPP_SUCCESS,
        payload: data
    };
};

export const linkEnjinApp_ = (data) => {
    return {
        type: LINK_ENJINAPP,
        payload: data
    };
};

export const linkEnjinAppSuccess_ = (data) => {
    return {
        type: LINK_ENJINAPP_SUCCESS,
        payload: data
    };
};

export const updateEnjinApp_ = (data) => {
    return {
        type: UPDATE_ENJINAPP,
        payload: data
    };
};

export const updateEnjinAppSuccess_ = (data) => {
    return {
        type: UPDATE_ENJINAPP_SUCCESS,
        payload: data
    };
};

export const deleteEnjinApp_ = (data) => {
    return {
        type: DELETE_ENJINAPP,
        payload: data
    };
};

export const deleteEnjinAppSuccess_ = (data) => {
    return {
        type: DELETE_ENJINAPP_SUCCESS,
        payload: data
    };
};

export const clearLinkingDialogueEnjinApp_ = (data) => {
    return {
        type: CLEAR_LINKING_DIALOGUE_ENJINAPP,
        payload: data
    };
};

export const enjAppsRefreshed_ = (data) => {
    return {
        type: ENJAPPS_REFRESHED,
        payload: data
    };
};

export const enjAppRefreshed_ = (data) => {
    return {
        type: ENJAPP_REFRESHED,
        payload: data
    };
};
