import projectIcon from 'assets/images/project_icon.png'
import resourceIcon from 'assets/images/resource_icon.png'
import tokenIcon from 'assets/images/token_icon.png'
import utilityIcon from 'assets/images/utility_icon.png'
import { walletIcon } from 'components/networkVisualizer/assets/icons'

/* 
Insert your default images here <<<<<<<<<<<<<<<<<<<<<<<
*/

export const defaultImages = {
  tokens: tokenIcon,
  utilities: utilityIcon,
  resources: resourceIcon,
  projects: projectIcon,
  wallet: walletIcon,
}
