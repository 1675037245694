import React from "react";
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import apispec from "assets/vendors/swagger/utilify-api-doc";
import DocsHeader from "components/profile/DocsHeader";
import {Link , Route, Switch, withRouter} from "react-router-dom";
import Overview from "./Overview";
import Api from "./API";
import Sdk from "./SDK";
import Platform from "./Platform";
import Footer from "components/Footer";


const Docs = (props) => {
    return (
        <div className="app-wrapper">
            <DocsHeader />
            <Switch>
                <Route path={`${props.match.url}/overview`}
                    component={Overview}/>
                <Route path={`${props.match.url}/api`}
                    component={Api}/>
                <Route path={`${props.match.url}/sdk`}
                    component={Sdk}/>
                <Route path={`${props.match.url}/platform`}
                    component={Platform}/>

            </Switch>
            
        </div>
        
        
    )
    
}

export default withRouter(Docs);