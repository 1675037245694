import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import {
    DELETE_IMG,
    UPDATE_BANNER_BY_DATA,
    UPDATE_IMG_BY_DATA,
    UPDATE_IMG_BY_DATA_SUCCESS
} from "constants/ActionTypes";

import {
    updateImage_,
    updateImageSuccess_, updateBannerSuccess_, uploadSuccess_, deleteImgSuccess_, imgRefreshed_, imgsRefreshed_

} from "actions/Img";

import {
    deleteImg,
    getImgUrl,
    uploadImg
} from "../api";

import { Auth } from 'aws-amplify';
import { updateUser_ } from "actions/User";
import { hideDialogueLoader_, hideTransparentLoader_, showDashbaordLoader_, showDialogueLoader_, showLoaderUpdateSuccess_, showTransparentLoader_ } from "actions/IsFetching";
import { errorHandler, successHandler } from "util/messageHandler";
import { viewAlertMessage_ } from "actions/Alert";
import { userSignOut } from "actions";

const CONTENT_TYPE_SUFFIX_MAPPINGS = {
    'image/jpg': 'jpg',
    'image/jpeg': 'jpg',
    'image/svg+xml': 'svg',
    'image/png': 'png',
};
const getSupportedContentTypes = () => {
    return Object.keys(CONTENT_TYPE_SUFFIX_MAPPINGS);
}

const isValidImageContentType = (contentType) => {
    return Object.keys(CONTENT_TYPE_SUFFIX_MAPPINGS).includes(contentType);
}

const getFileSuffixForContentType = (contentType) => {
    return CONTENT_TYPE_SUFFIX_MAPPINGS[contentType];
}

export const createNewImage = async(JWToken, imgData) => {
    delete imgData.imgPreviewUrl;
    const apiResponse = await getImgUrl(JWToken, imgData);
        // console.log("API RESPONSE STATUS: ", apiResponse)
    
        if (apiResponse.status>200) {
            // console.log("apiResponse_error: ", apiResponse);

            return apiResponse;
            // yield put(errorAction(apiResponse.error));

            
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse.data.uploadUrl);
            console.log("before img upload: ", apiResponse, imgData)
            const fileType = await getFileSuffixForContentType(imgData.imgFile.type);
            console.log("CONTENT TYPE: ", imgData);     
            const uploadUrl = apiResponse.data.uploadUrl;
            await uploadImg(uploadUrl, imgData.imgFile, imgData.imgFile.type);
            const downloadUrl = `https://utility-imgs-401439326364.s3.amazonaws.com/uploads/${apiResponse.data.imageId}.${fileType}`;
            // console.log("DOWNLOAD URL: ", downloadUrl);



            return downloadUrl;
        }
}



function* updateBannerByData({payload}) {
    const {JWToken, bannerData, refresh, noLoader} = payload;
    delete bannerData.imgPreviewUrl;
    const userId = localStorage.getItem('userId');
    const imgData = bannerData;
    try {
        if (!noLoader) {
            yield put(showTransparentLoader_());
        }
        let fetchingResult = {};
        // console.log("IMG Data: ", imgData);
        const apiResponse = yield call(getImgUrl, JWToken, imgData);
        // console.log("API RESPONSE STATUS: ", apiResponse)
        
        if (apiResponse.status>200) {
            // console.log("apiResponse_error: ", apiResponse);

            fetchingResult.error = "error";
            yield put(showLoaderUpdateSuccess_(fetchingResult));
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse.data.uploadUrl);

            
            const fileType = getFileSuffixForContentType(imgData.imgFile.type);
            // console.log("CONTENT TYPE: ", imgData.imgFile.type);     
            const uploadUrl = apiResponse.data.uploadUrl;
            yield call(uploadImg, uploadUrl, imgData.imgFile, imgData.imgFile.type);
            const downloadUrl = `https://utility-imgs-401439326364.s3.amazonaws.com/uploads/${apiResponse.data.imageId}.${fileType}`;
            // console.log("DOWNLOAD URL: ", downloadUrl);
            yield put(updateBannerSuccess_(downloadUrl));

            yield put(imgRefreshed_(true));
            fetchingResult.success = "success";
            yield put(showLoaderUpdateSuccess_(fetchingResult));
            /*const userData = {
                userAvatar: downloadUrl
            }  
            yield put(updateUser_({JWToken, userId, userData}));*/

            // alert message   
            const successMessage = yield call(successHandler,"Image", "", "updated");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
    }

}

function* updateImgByData({payload}) {
    const {JWToken, imgData, refresh, noLoader} = payload;
    delete imgData.imgPreviewUrl;
    const userId = localStorage.getItem('userId');
    const fetchingResult = {};
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        // console.log("IMG Data: ", imgData);
        const apiResponse = yield call(getImgUrl, JWToken, imgData);
        // console.log("API RESPONSE: ", apiResponse)
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            

            fetchingResult.error = "error";
            yield put(showLoaderUpdateSuccess_(fetchingResult));
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse.data.uploadUrl);

            
            const fileType = getFileSuffixForContentType(imgData.imgFile.type);
            // console.log("CONTENT TYPE: ", imgData.imgFile.type);     
            const uploadUrl = apiResponse.data.uploadUrl;
            const uploadImage = yield call(uploadImg, uploadUrl, imgData.imgFile, imgData.imgFile.type);
            // console.log("uploadImage: ", imgData.imgFile);
            const downloadUrl = `http://stage.test.files.utilify.io.s3.amazonaws.com/images/thumbnails/${apiResponse.data.imageId}.${fileType}`;
            // console.log("DOWNLOAD URL: ", downloadUrl);
            yield put(updateImageSuccess_(downloadUrl));

             
            yield put(imgRefreshed_(true));
            fetchingResult.success = "success";
            yield put(showLoaderUpdateSuccess_(fetchingResult));

            // alert message   
            const successMessage = yield call(successHandler,"Image", "", "updated");
            yield put(viewAlertMessage_(successMessage));
            /*const userData = {
                userAvatar: downloadUrl
            }  
            yield put(updateUser_({JWToken, userId, userData}));*/
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideDialogueLoader_());
        yield put(hideTransparentLoader_());
    }

}



function* deleteImgByData({payload}) {
    const {JWToken, imageId, refresh, noLoader} = payload;
    const userId = localStorage.getItem('userId');
    try { 
        if (!noLoader) {
            yield put(showDialogueLoader_());
        }
        const apiResponse = yield call(deleteImg, JWToken, imageId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 

        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            yield put(deleteImgSuccess_("deleted"))
            // console.log("apiResponse: ", apiResponse.data.uploadUrl);
            yield put(imgsRefreshed_(true));
            // alert message   
            const successMessage = yield call(successHandler,"Image", "", "deleted");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideDialogueLoader_());
    }

}

export function* getImgUrlForUpload() {
    yield takeEvery(UPDATE_IMG_BY_DATA, 
        updateImgByData);
}

export function* getBannerUrlForUpload() {
    yield takeEvery(UPDATE_BANNER_BY_DATA, 
        updateBannerByData);
}

export function* deleteImgfromFootageList() {
    yield takeEvery(DELETE_IMG, 
        deleteImgByData);
}

export default function* rootSaga() {
    yield all([fork(getImgUrlForUpload),
        fork(getBannerUrlForUpload),
        fork(deleteImgfromFootageList)
    ]);
}