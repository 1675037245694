import {
  exchangeIcon,
  linkIcon,
  networkIcon,
  platformIcon,
} from 'components/networkVisualizer/assets/icons'
import { defaultImages } from 'components/networkVisualizer/assets/variables/defaultImages'
import { cooperationsCountAdder } from 'components/networkVisualizer/modules/cooperationsCountAdder'
import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'store'
import { AssetNameAndType, DisplayImage, ItemDetail } from './sharedComponents'

interface IsProps {
  id: string | number
}

export const TokenPopup = ({ id }: IsProps) => {
  const data = useSelector(
    (state: AppState) => state.networkVisualizerState.general.data.tokens[id]
  )

  if (data === undefined) return null

  let {
    image,
    name,
    appName,
    circulatingSupply,
    cooperationsCount,
    platformName,
  } = data

  let totalCooperations = cooperationsCount
    ? cooperationsCountAdder(cooperationsCount)
    : undefined

  return (
    <div className='popup-container'>
      <DisplayImage image={image || defaultImages.tokens} alt={name} />
      <div className='body-container'>
        <AssetNameAndType name={name} type={'token'} />
        <ItemDetail
          image={platformIcon}
          imageAlt='platform'
          visible={platformName !== undefined && platformName !== null}
        >
          <p className='secondary-text'>
            <span>{platformName}</span>
          </p>
        </ItemDetail>
        <ItemDetail
          image={networkIcon}
          imageAlt='cooperations'
          visible={
            totalCooperations !== undefined && totalCooperations !== null
          }
        >
          <p className='secondary-text'>
            <span>{totalCooperations}</span> cooperations
          </p>
        </ItemDetail>
        <ItemDetail
          image={linkIcon}
          imageAlt='app name'
          visible={appName !== undefined && appName !== null}
        >
          <p className='secondary-text'>
            Project
            <span> {appName}</span>
          </p>
        </ItemDetail>
        <ItemDetail
          image={exchangeIcon}
          imageAlt='in circulation'
          visible={
            circulatingSupply !== undefined && circulatingSupply !== null
          }
        >
          <p className='secondary-text'>
            Supply in circulation
            <span> {circulatingSupply}</span>
          </p>
        </ItemDetail>
      </div>
    </div>
  )
}
