import {all, fork} from 'redux-saga/effects';
import authSagas from './Auth';
import projectSagas from './Project';
import accountSagas from './Account';
import cooperationSagas from './Cooperation';
import eventSagas from './Event';
import imgSagas from './Img';
import notificationSagas from './Notification';
import orderSagas from './Order';
import requestSagas from './Request';
import resourceSagas from './Resource';
import tokenSagas from './Token';
import transactionSagas from './Transaction';
import userSagas from './User';
import utilitySagas from './Utility';
import walletSagas from './Wallet';
import searchSagas from './Search';
import enjinAppSagas from './EnjinApp';
import messageSagas from './Message';
import searchTx from './SearchTx';
import {networkVisualizerSagas} from 'components/networkVisualizer/networkVisualizerState/sagas.ts';


export default function* rootSaga() { // getState was removed
        
        yield fork(authSagas)
        yield fork(accountSagas)
        yield fork(cooperationSagas)
        yield fork(eventSagas)
        yield fork(imgSagas)
        yield fork(notificationSagas)
        yield fork(orderSagas)
        yield fork(projectSagas)
        yield fork(requestSagas)
        yield fork(resourceSagas)
        yield fork(tokenSagas)
        yield fork(transactionSagas)
        yield fork(userSagas)
        yield fork(utilitySagas)
        yield fork(walletSagas)
        yield fork(searchSagas)
        yield fork(enjinAppSagas)
        yield fork(messageSagas)
        yield fork(searchTx)
        yield fork(networkVisualizerSagas)
    
}
