import { walletIcon } from 'components/networkVisualizer/assets/icons'
import { setVisualizerData } from 'components/networkVisualizer/networkVisualizerState/store/general/actions'
import { VisualizerData } from 'components/networkVisualizer/networkVisualizerState/store/general/types'
import { all, call, put } from 'redux-saga/effects'
import { fetchAndSetWalletConnections } from '../fetchAndSetWalletConnections/fetchAndSetWalletConnections'

export function* fetchAndSetWalletData(
  walletId: string | number,
  blockchain: string = 'ethereum'
) {
  const walletData: Partial<VisualizerData> = {
    wallets: {
      [walletId]: {
        name: walletId.toString(),
        image: walletIcon,
        connections: { tokens: [] },
      },
    },
  }

  yield all([
    put(setVisualizerData(walletData)),
    call(fetchAndSetWalletConnections, walletId, blockchain),
  ])
}
