import {
    GET_ALL_USERS,
    GET_USER,
    DELETE_USER,
    PIN_TOKEN_TO_PROFILE,
    PIN_RESOURCE_TO_PROFILE,
    PIN_UTILITY_TO_PROFILE,
    GET_USER_PROJECT_WATCHLIST,
    GET_ALL_USERS_SUCCESS,
    GET_USER_SUCCESS,
    DELETE_USER_SUCCESS,
    PIN_TOKEN_TO_PROFILE_SUCCESS,
    PIN_RESOURCE_TO_PROFILE_SUCCESS,
    PIN_UTILITY_TO_PROFILE_SUCCESS,
    GET_USER_PROJECT_WATCHLIST_SUCCESS,
    ADD_USER,
    ADD_USER_SUCCESS,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    GET_USER_SETTINGS,
    GET_USER_SETTINGS_SUCCESS,
    UPDATE_USER_SETTINGS,
    UPDATE_USER_SETTINGS_SUCCESS,
    PIN_ASSET_TO_USER,
    UNPIN_ASSET_FROM_USER,
    LOAD_MORE_USERS_SUCCESS,
    PINNED_TO_USER_SUCCESS,
    UNPINNED_FROM_USER_SUCCESS,
    USER_REFRESHED,
    USERS_REFRESHED

} from 'constants/ActionTypes';

export const getAllUsers_ = (data) => {
    return {
        type: GET_ALL_USERS,
        payload: data
    };
};

export const getAllUsersSuccess_ = (users) => {
    return {
        type: GET_ALL_USERS_SUCCESS,
        payload: users
    };
};

export const loadMoreUsersSuccess_ = (users) => {
    return {
        type: LOAD_MORE_USERS_SUCCESS,
        payload: users
    };
};
export const getUser_ = (payload) => {
    return {
        type: GET_USER,
        payload: payload
    };
};

export const getUserSuccess_ = (user) => {
    return {
        type: GET_USER_SUCCESS,
        payload: user
    };
};

export const addUser_ = (user) => {
    return {
        type: ADD_USER,
        payload: user
    };
};

export const addUserSuccess_ = (user) => {
    return {
        type: ADD_USER_SUCCESS,
        payload: user
    };
};

export const updateUser_ = (data) => {
    return {
        type: UPDATE_USER,
        payload: data
    };
};

export const updateUserSuccess_ = () => {
    return {
        type: UPDATE_USER_SUCCESS
    };
};



export const deleteUser_ = (userId) => {
    return {
        type: DELETE_USER,
        payload: userId
    };
};

export const deleteUserSuccess_ = () => {
    return {
        type: DELETE_USER_SUCCESS
    };
};

export const pinAssetToUser_ = (data) => {
    return {
        type: PIN_ASSET_TO_USER,
        payload: data
    };
};


export const pinnedToUserSuccess_ = (data) => {
    return {
        type: PINNED_TO_USER_SUCCESS,
        payload: data
    };
};

export const unpinAssetFromUser_ = (data) => {
    return {
        type: UNPIN_ASSET_FROM_USER,
        payload: data
    };
};

export const unpinnedFromUserSuccess_ = (data) => {
    return {
        type: UNPINNED_FROM_USER_SUCCESS,
        payload: data
    };
};

export const getUserProjectWatchlist_ = (data) => {
    return {
        type: GET_USER_PROJECT_WATCHLIST,
        payload: data
    };
};

export const getUserProjectWatchlistSuccess_ = (projects) => {
    return {
        type: GET_USER_PROJECT_WATCHLIST_SUCCESS,
        payload: projects
    };
};

export const getUSerSettings_ = (userId) => {
    return {
        type: GET_USER_SETTINGS,
        payload: userId
    };
};

export const getUSerSettingsSuccess_ = (data) => {
    return {
        type: GET_USER_SETTINGS_SUCCESS,
        payload: data
    };
};

export const updateUSerSettings_ = (data) => {
    return {
        type: UPDATE_USER_SETTINGS,
        payload: data
    };
};

export const updateUSerSettingsSuccess_ = () => {
    return {
        type: UPDATE_USER_SETTINGS_SUCCESS
    };
};

export const userRefreshed_ = (data) => {
    return {
        type: USER_REFRESHED,
        payload: data
    };
};

export const usersRefreshed_ = (data) => {
    return {
        type: USERS_REFRESHED,
        payload: data
    };
};