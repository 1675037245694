import { activeCooperationsCategorySelector } from 'components/networkVisualizer/components/itemDetails/components/body/components/sharedComponents/cooperations/cooperations'
import { setVisualizerData } from 'components/networkVisualizer/networkVisualizerState/store/general/actions'
import { call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { AppState } from 'store'
import { fetchAndFormatConnections } from '../../helpers/dataFetchers/fetchAndSetConnections/_fetchAndFormatConnections'
import { fetchAndSetData } from '../../helpers/dataFetchers/fetchAndSetData/fetchAndSetData'
import { FetchAndFormatConnections } from './../../helpers/dataFetchers/fetchAndSetConnections/_fetchAndFormatConnections'
import {
  fetchItemDetailsCooperations,
  setItemDetails,
  setItemDetailsCooperations,
  setItemDetailsFetching,
} from './actions'
import {
  FETCH_ITEM_DETAILS_COOPERATIONS,
  ItemDetailsSelectionData,
  SHOW_ITEM_DETAILS,
} from './types'

/**
 *
 * Helpers
 *
 */

const tokenIdSelector = (state: AppState, id: string | number) =>
  state.networkVisualizerState.general.data.tokens[id].tokenId

type TokenIdSelector = ReturnType<typeof tokenIdSelector>

/**
 *
 */

function* showItemDetailsWatcher() {
  yield takeLatest(SHOW_ITEM_DETAILS, showItemDetailsFlow)
}

function* showItemDetailsFlow({
  type,
  payload,
}: {
  type: typeof SHOW_ITEM_DETAILS
  payload: ItemDetailsSelectionData
}) {
  yield put(setItemDetails(payload.id, payload.type, payload.platformId))
  //Project data returned from fetchAssetData is not complete. When opening the item details screen the complete project data needs to be fetched.
  if (payload.type === 'projects') {
    yield call(fetchAndSetData, payload.id, 'projects')
    yield put(fetchItemDetailsCooperations())
  }
}

/**
 * Fetches items for the relevant cooperations list in Item Details.
 */
function* fetchItemDetailsCooperationsWatcher() {
  yield takeLatest(
    FETCH_ITEM_DETAILS_COOPERATIONS,
    fetchItemDetailsCooperationsFlow
  )
}

const itemDetailsStateSelector = (state: AppState) =>
  state.networkVisualizerState.itemDetails

const cooperationsCountSelector = createSelector(
  itemDetailsStateSelector,
  (state: AppState) => state.networkVisualizerState.general.data,
  ({ id, type }, data) => data[type][id].cooperationsCount ?? {}
)

const currentConnectionsCountSelector = createSelector(
  activeCooperationsCategorySelector,
  (state: AppState) => state.networkVisualizerState.itemDetails.cooperations,
  (activeCategory, cooperations) => cooperations[activeCategory]?.length ?? 0
)

const COOPERATIONS_FETCH_LIMIT = 16

function* fetchItemDetailsCooperationsFlow() {
  const activeCooperationsCategory: ReturnType<typeof activeCooperationsCategorySelector> = yield select(
    activeCooperationsCategorySelector
  )

  const {
    id,
    type,
    platformId,
  }: ReturnType<typeof itemDetailsStateSelector> = yield select(
    itemDetailsStateSelector
  )

  const cooperationsCount: ReturnType<typeof cooperationsCountSelector> = yield select(
    cooperationsCountSelector
  )

  const currentCountOfType: ReturnType<typeof currentConnectionsCountSelector> = yield select(
    currentConnectionsCountSelector
  )

  //Only fetch if there are connections available and the current connections list is less than the expected count.
  if (
    cooperationsCount[activeCooperationsCategory] &&
    cooperationsCount[activeCooperationsCategory] > currentCountOfType
  ) {
    yield put(setItemDetailsFetching(true))
    try {
      let fetchId = id

      if (type === 'tokens') {
        const tokenId = yield select((state: AppState) =>
          tokenIdSelector(state, id)
        )

        fetchId = tokenId
      }

      const { data, connections }: FetchAndFormatConnections = yield call(
        fetchAndFormatConnections,
        fetchId,
        type,
        activeCooperationsCategory,
        currentCountOfType,
        COOPERATIONS_FETCH_LIMIT,
        platformId
      )

      yield put(setVisualizerData(data))

      const connectionKeys = Object.keys(connections) as Array<
        keyof typeof connections
      >

      for (let k of connectionKeys) {
        yield put(setItemDetailsCooperations(k, connections[k]))
      }

      yield put(setItemDetailsFetching(false))
    } catch (e) {
      // console.log('error fetching', e)
    }
  }
}

export function* itemDetailsSagas() {
  yield fork(showItemDetailsWatcher)
  yield fork(fetchItemDetailsCooperationsWatcher)
}
