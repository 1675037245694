import moment from 'moment';

import {
    SHOW_IS_FETCHING,
    HIDE_IS_FETCHING,
    SHOW_FETCHING_SUCCESS,
    SHOW_FETCHING_ERROR,
    SHOW_LOCAL_FETCHING,
    HIDE_LOCAL_FETCHING,
    SHOW_TRANSPARENT_FETCHING,
    HIDE_TRANSPARENT_FETCHING,
    SHOW_TRANSPARENT_LOADER,
    HIDE_TRANSPARENT_LOADER,
    SHOW_APP_LOADER,
    HIDE_APP_LOADER,
    SHOW_MAIN_LOADER,
    HIDE_MAIN_LOADER,
    SHOW_DIALOGUE_LOADER,
    HIDE_DIALOGUE_LOADER,
    SHOW_DASHBOARD_LOADER,
    HIDE_DASHBOARD_LOADER,
    SHOW_LOADER_CREATE_SUCCESS,
    SHOW_LOADER_DELETE_SUCCESS,
    SHOW_LOADER_UPDATE_SUCCESS,
    GET_ALL_CHAT_USERS_SUCCESS,
    GET_CONVERSATION_SUCCESS,
    ON_USER_SELECT,
    SET_ACTIVE_CHATUSERS,
    SET_ACTIVE_CONVERSATION,
    LOAD_PREV_MESSAGES,
    LOAD_PREV_MESSAGES_SUCCESS,
    SEND_TEXT_MESSAGE_SUCCESS,
    CONVERSATIONS_REFRESHED,
    MESSAGES_REFRESHED
} from "constants/ActionTypes";

/* {
        id: "LegendGames",
        channelId: 'chat_channel_45352521',
        name: 'Alex Dolgove',
        profile_pic: 'https://via.placeholder.com/150x150',
        status: 'away',
        mood: 'English versions from the 1914 translation by H. Rackham',
        lastMessage: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem',
        unreadMessage: '',
        lastMessageTime: '20 min ago',
        favourite: true,
      },
      {
        id: "InnerPanda",
        channelId: 'chat_channel_45352522',
        name: 'Domnic Brown',
        profile_pic: 'https://via.placeholder.com/150x150',
        status: 'online',
        mood: 'English versions from the 1914 translation by H. Rackham',
        lastMessage: 'It is a long established fact',
        unreadMessage: '4',
        lastMessageTime: 'Yesterday',
        favourite: true,
      },
      {
        id: "Micho",
        channelId: 'chat_channel_45352523',
        name: 'Domnic Harris',
        profile_pic: 'https://via.placeholder.com/150x150',
        status: 'offline',
        mood: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem',
        lastMessage: 'There are many variations of passages of ',
        unreadMessage: '',
        lastMessageTime: '20/11/17',
        favourite: false,
      }*/

      /*{
                messageType: 'text',
                type: 'sent',
                message: 'It is a long established fact',
                sentAt: moment().subtract(10, 'days'),
              },
              {
                messageType: 'text',
                type: 'received',
                message: 'I must explain to you how all this mistaken idea of denouncing ',
                sentAt: moment().subtract(10, 'days'),
              },
              {
                messageType: 'text',
                type: 'sent',
                message: 'The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested',
                sentAt: moment().subtract(9, 'days'),
              },
              {
                messageType: 'text',
                type: 'received',
                message: 'There are many variations of passages of ',
                sentAt: moment().subtract(9, 'days'),
              },
              {
                messageType: 'text',
                type: 'received',
                message: 'All the Lorem Ipsum generators on the',
                sentAt: moment().subtract(2, 'days'),
              },
              {
                messageType: 'text',
                type: 'sent',
                message: 'There are many variations of passages of ',
                sentAt: moment().subtract(2, 'days'),
              },
              {
                messageType: 'text',
                type: 'received',
                message: 'It is a long established fact',
                sentAt: moment().subtract(1, 'days'),
              },
              {
                messageType: 'text',
                type: 'sent',
                message: 'The standard chunk of Lorem Ipsum used since the 1500s',
                sentAt: moment(),
              }*/ 

const INIT_STATE = {
    chatUsers: '',
    currentChatUser: '',
    contacts: '',
    isChatSideBarCollapsed: false,
    conversation: [],
    newMessagesCount: 0,
    activeChatUsers: '',
    activeConversation: '',
    prevMessages: '',
    newMessage: '',
    conversationsRefreshed: false,
    messagesRefreshed: false

};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case SHOW_LOADER_UPDATE_SUCCESS: {
            return {
                ...state,
                loaderUpdateResult: action.payload
            }
        }
        case GET_ALL_CHAT_USERS_SUCCESS: {
            return {
                ...state,
                chatUsers: action.payload
            }
        }
        case ON_USER_SELECT: {
            return {
                ...state,
                currentChatUser: action.payload
            }
        }
        case GET_CONVERSATION_SUCCESS: {
            return {
                ...state,
                conversation: action.payload
            }
        }
        case SET_ACTIVE_CHATUSERS: {
          return {
              ...state,
              activeChatUsers: action.payload
          }
        }
        case SET_ACTIVE_CONVERSATION: {
          return {
              ...state,
              activeConversation: action.payload
          }
        }
        case LOAD_PREV_MESSAGES_SUCCESS: {
          return {
              ...state,
              prevMessages: action.payload
          }
        }
        case SEND_TEXT_MESSAGE_SUCCESS: {
          return {
              ...state,
              newMessage: action.payload
          }
        }
        case CONVERSATIONS_REFRESHED: {
          return {
              ...state,
              conversationsRefreshed: action.payload
          }
        }
        case MESSAGES_REFRESHED: {
          return {
              ...state,
              messagesRefreshed: action.payload
          }
        }
        default:
            return state;
    }
}