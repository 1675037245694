import { CategoryTypes } from '../../sharedTypes'
import {
  DISABLE_GRAPH_NEXT_BUTTON,
  GraphActions,
  GraphState,
  RESET_GRAPH_FILTER_STATE,
  RESET_VISUALIZER_GRAPH_STATE,
  SET_GRAPH_FILTER_STATE,
  SET_SELECTED_NODE,
  SET_VISIBLE_CONNECTIONS_SET,
  SET_WALLET_FETCH_PAGE,
} from './types'

/**
 * Total number of connections to fill the graph
 */
export const MAX_GRAPH_CONNECTIONS = 8

const defaultItem = {
  type: 'tokens' as CategoryTypes,
  id: 0,
}

const defaultFilter = {
  tokens: true,
  utilities: true,
  projects: true,
  resources: true,
}

const defaultState: GraphState = {
  selectedNode: defaultItem,
  filter: defaultFilter,
  maxVisibleConnections: MAX_GRAPH_CONNECTIONS, //Does this need to be in redux? Will it ever change?
  visibleConnectionsIndex: 0,
  walletFetchPage: 0, //Start at 0, prior to the first fetch the fetch page is incremented
  disableNextButton: false,
}

export const graphReducer = (
  state = defaultState,
  action: GraphActions
): GraphState => {
  switch (action.type) {
    case SET_SELECTED_NODE:
      return {
        ...state,
        selectedNode: action.payload,
        walletFetchPage: 0, //Reset when new node is centered.
        disableNextButton: false, //Reset when new node is centered.
      }
    case SET_GRAPH_FILTER_STATE:
      let { filter } = state
      return {
        ...state,
        filter: {
          ...filter,
          [action.payload]: !filter[action.payload],
        },
      }
    case RESET_GRAPH_FILTER_STATE:
      return {
        ...state,
        filter: defaultFilter,
      }
    case SET_WALLET_FETCH_PAGE:
      return {
        ...state,
        walletFetchPage: action.payload,
      }
    case DISABLE_GRAPH_NEXT_BUTTON:
      return {
        ...state,
        disableNextButton: action.payload,
      }
    case SET_VISIBLE_CONNECTIONS_SET:
      return {
        ...state,
        visibleConnectionsIndex: action.payload,
      }
    case RESET_VISUALIZER_GRAPH_STATE:
      return defaultState
    default:
      return state
  }
}
