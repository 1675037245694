import React from "react";
import "swagger-ui-react/swagger-ui.css"
import PageTitle from "components/PageLayout/PageTitle";


const Platform = () => {
    return (
        <div>
            <div className="mt-5 mb-5 ml-4">
                <PageTitle title={"Platform"}/>
            </div>
            
            <h1 className="text-muted" style={{textAlign: "center"}}>In progress...</h1>
        </div>
        
        
    )
    
}

export default Platform;