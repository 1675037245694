import React, { useEffect, useState } from 'react';
import {Avatar, Badge, Box, List} from '@material-ui/core';
import {NavLink} from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IntlMessages from "../../util/IntlMessages";
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    
    navCount: {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.common.white,
      padding: 2,
      borderRadius: '50%',
      marginLeft: 'auto',
      width: 24,
      height: 24,
      textAlign: 'center',
      lineHeight: '15px',
    }
  }));

const NavMenuItem = props => {

    const classes = useStyles();
    const {name, icon, avatarImage, link} = props;
    const {chatUsers} = useSelector(({message})=>message);
    const {notificationList} = useSelector(({notification})=>notification);
    const [unread, setUnread] = useState(0);
    const [newNotif, setNewNotif] = useState(false);


    useEffect(()=> {
        if (chatUsers) {
            chatUsers.map((user, index) => {
                setUnread(unread + user.unreadMessages);
            })
        }

        if (notificationList && notificationList.some(notification => notification.isRead === false)) {
            setNewNotif(true);
        }  else {
            setNewNotif(false);
        }


    }, [notificationList, chatUsers])

    return (
        <List component="div" className='nav-menu-item'>
            <NavLink className="prepend-icon nav-menu-link" to={link}>
                {/* Display an icon if any */}
                {!!icon && (
                    <div>
                        <i className={'zmdi zmdi-hc-fw  zmdi-' + icon}/>
                        {((name !== "sidebar.messages") && (name !== "sidebar.notifications")) && (
                        <span className="nav-text"><IntlMessages id={name}/></span>)}
                        {name === "sidebar.messages" && (
                        <span className="nav-text"><IntlMessages id={name}/>{' '}
                            {unread > 0 && (
                            <Badge color="secondary" overlap="circle" badgeContent='' variant="dot">
                                <p>{'   '}</p>
                            </Badge>)}
                        </span>)}
                        {name === "sidebar.notifications" && (
                        <span className="nav-text"><IntlMessages id={name}/>{' '}  
                            {newNotif && (                      
                            <Badge color="secondary" overlap="circle" badgeContent=" " variant="dot">
                                <p>{'   '}</p>
                            </Badge>)}
                        </span>)}
                                  
                    </div>
                )}
                {!!avatarImage && (
                <div className="row">
                    <div className="col-3 justify-content-center">
                        <Avatar
                        alt='...'
                        src={avatarImage}
                        className="user-avatar-custom align-middle"
                    />
                    </div>
                    <div className="col-9 justify-content-center align-self-center">
                        <div className="nav-text align-middle">{name}</div>
                    </div>
                    
                </div>
                )}
                
            </NavLink>
        </List>
    )
};

export default NavMenuItem;