import React, {useEffect} from "react";
import * as typeformEmbed from '@typeform/embed'
import { Button, IconButton } from "@material-ui/core";



const TypeForm = () => {

    useEffect(()=>{

        let timer1;
        let formPopup;
            formPopup = typeformEmbed.makePopup('https://hymedallug.typeform.com/to/cIIZLd', {
            mode: 'drawer_left',          
            hideHeaders: true,
            hideFooter: true
        });

        

        let timer2;
        timer2 = setTimeout(()=>{
            document.getElementById('apply').addEventListener('click', function () {
                formPopup.open();
            });
        }, 500)

        return () => clearTimeout(timer2);
        
    },[])

    
    return (
        <div className="type-form"> 
            <Button id="apply" variant="outlined" className="btn jr-btn bg-secondary text-white mr-2">
                JOIN PRE-ALPHA
            </Button>
        </div>
        
        
    )
    
}

export default TypeForm;