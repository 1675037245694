import usePrevious from 'components/networkVisualizer/modules/usePrevious'
import {
  CategoryTypes,
  GraphTypes,
} from 'components/networkVisualizer/networkVisualizerState/sharedTypes'
import { isEqual } from 'lodash'
import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { AppState } from 'store'
import { Item, itemSize } from './components/item/item'
import { NoConnectionsPlaceholder } from './components/noConnectionsPlaceholder/noConnectionsPlaceholder'
import { TetherLine } from './components/tetherLine/tetherLine'
import { getSpaceBetween } from './modules/graphHelpers'

export const GRAPH_ASSET_ORDER: CategoryTypes[] = [
  'utilities',
  'tokens',
  'resources',
  'projects',
]

export interface GraphItemType {
  id: string | number
  type: GraphTypes
}

function isItemInArray(
  id: string | number,
  type: GraphTypes,
  array: GraphItemType[]
) {
  return array.some((item) => item.id === id && item.type === type)
}

const connectionsSetSelector = createSelector(
  (state: AppState) => state.networkVisualizerState.graph.maxVisibleConnections,
  (state: AppState) =>
    state.networkVisualizerState.graph.visibleConnectionsIndex,
  (maxConnections, currentSet) => {
    let startIndex = currentSet * maxConnections
    let endIndex = startIndex + maxConnections
    return { startIndex, endIndex }
  }
)

interface ConnectedItem {
  id: string | number
  type: GraphTypes
}

type ConnectedItems = ConnectedItem[]

// const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual)

const itemsSelector = createSelector(
  (state: AppState) => state.networkVisualizerState.general.data,
  (state: AppState) => state.networkVisualizerState.graph.selectedNode,
  (state: AppState) => state.networkVisualizerState.graph.filter,
  connectionsSetSelector,
  (data, selectedNode, filter, { startIndex, endIndex }) => {
    let connectedItems: ConnectedItems = []
    let selectedItem = data[selectedNode.type][selectedNode.id]
    if (selectedItem) {
      let { connections } = selectedItem
      if (connections) {
        for (let k of GRAPH_ASSET_ORDER) {
          if (connections[k] && filter[k]) {
            for (let id of connections[k]) {
              connectedItems.push({ id, type: k })
            }
          }
        }
      }
    }
    //Wallet data isn't fetched the same as the rest of the asset data. Wallet connections only show the current visible connections. Previous connections are removed with every page change.
    const connections = connectedItems.slice(startIndex, endIndex)
    const allItems: ConnectedItems = [selectedNode, ...connections]
    return allItems
  }
)

const detailsScreenItemSelector = createSelector(
  (state: AppState) => state.networkVisualizerState.itemDetails,
  ({ id, type }) => ({ id, type })
)

const detailsScreenVisibilitySelector = (state: AppState) =>
  state.networkVisualizerState.itemDetails.visible

const GraphComponent = () => {
  const showConnectionsDelay = useRef<NodeJS.Timeout>()
  const newItems = useSelector(itemsSelector)

  const detailsScreenItem = useSelector(detailsScreenItemSelector)

  const detailsIsVisible = useSelector(detailsScreenVisibilitySelector)

  const [currentItems, setCurrentItems] = useState<GraphItemType[]>(newItems)

  const [visibleItems, setVisibleItems] = useState<GraphItemType[]>(newItems)

  // console.log({ newItems, currentItems, visibleItems })

  const isNodeVisible = useCallback(
    (id: string | number, type: GraphTypes) =>
      isItemInArray(id, type, visibleItems),
    [visibleItems]
  )

  const previousNewItems = usePrevious(newItems)

  useEffect(() => {
    if (!isEqual(previousNewItems, newItems)) {
      // console.log('1.0')
      let itemsToRemain = currentItems.filter(({ id, type }) =>
        isItemInArray(id, type, newItems)
      )
      if (!isEqual(itemsToRemain, visibleItems)) {
        // console.log('1.1')
        setVisibleItems(itemsToRemain)
        //Using onRest in react spring component was not dependable when navigating quickly as sometimes the item glitches out and the onRest is not called. A timer may be more reliable.
        showConnectionsDelay.current = setTimeout(
          () => setCurrentItems(newItems),
          500
        )
      } else {
        // console.log('1.2')
        setCurrentItems(newItems)
      }
    }
  }, [newItems, previousNewItems, currentItems, visibleItems])

  useEffect(() => {
    // console.log('2.0')
    setVisibleItems(currentItems)
  }, [currentItems])

  useEffect(() => {
    return () => {
      // if (onHideCompleteDebounce.current)
      //   clearTimeout(onHideCompleteDebounce.current)
      if (showConnectionsDelay.current)
        clearTimeout(showConnectionsDelay.current)
    }
  }, [])

  //Find a proper fix for this. When there are 2 or less connections the tangent gets calculated to a very high number causing the radius to be [incorrectly] calculated as an infinite decimal that gets rounded to 0.
  let c_l = currentItems.length - 1 < 3 ? 3 : currentItems.length - 1

  let circleStyle = useMemo(() => {
    let tan = +Math.tan(Math.PI / c_l).toFixed(2)

    return {
      '--d': `${itemSize}em`,
      '--m': c_l,
      '--tan': tan,
      '--rel': getSpaceBetween(c_l), //The amount of space between items (1 = one image size),
    } as CSSProperties
  }, [c_l])

  return (
    <div className='circle' style={circleStyle}>
      <NoConnectionsPlaceholder noConnections={currentItems.length === 1} />
      {currentItems.map(
        ({ id, type }, i) =>
          i > 0 && (
            <TetherLine
              key={type + id}
              {...{ type, i, c_l }}
              visible={isNodeVisible(id, type)}
            />
          )
      )}
      {currentItems.map(({ id, type }, i) => (
        <Item
          key={type + id}
          {...{ id, type, i }}
          active={
            detailsScreenItem.id === id &&
            detailsScreenItem.type === type &&
            detailsIsVisible
          }
          outerConnectionsLength={c_l}
          visible={isNodeVisible(id, type)}
        />
      ))}
    </div>
  )
}

// GraphComponent.whyDidYouRender = true

export const Graph = React.memo(GraphComponent)
