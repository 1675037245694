import { walletIcon } from 'components/networkVisualizer/assets/icons'
import {
  changeWalletInputValue,
  submitWalletInput,
  toggleWalletInputVisibility,
} from 'components/networkVisualizer/networkVisualizerState/store/walletInput/actions'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { AppState } from 'store'

export const WalletInput = () => {
  const dispatch = useDispatch()

  const isVisible = useSelector(
    (state: AppState) => state.networkVisualizerState.walletInput.visible
  )

  const inputValue = useSelector(
    (state: AppState) => state.networkVisualizerState.walletInput.walletId
  )

  const onHeaderPress = useCallback(() => {
    dispatch(toggleWalletInputVisibility())
  }, [dispatch])

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let newValue = e.target.value
      dispatch(changeWalletInputValue(newValue))
    },
    [dispatch]
  )

  const onSubmit = useCallback(() => {
    dispatch(submitWalletInput())
  }, [dispatch])

  return (
    <div className={`wallet-component-wrapper`}>
      <div className='floating-header' onClick={onHeaderPress}>
        <img src={walletIcon} alt='wallet' className='wallet-icon' />
      </div>
      <CSSTransition
        in={isVisible}
        timeout={{ enter: 250, exit: 250 }}
        mountOnEnter
        unmountOnExit
      >
        <div className='body-container'>
          <div className='input-container'>
            <div className='blockchain-picker'>
              <p className='blockchain-item-text'>ETH</p>
            </div>
            <input
              type='text'
              value={inputValue}
              onChange={onInputChange}
              placeholder={'wallet address'}
            />
            <div
              onClick={onSubmit}
              className={`submit-button ${inputValue === '' ? 'disabled' : ''}`}
            >
              <p>View</p>
            </div>
          </div>
          <h4 className='input-header'>* Enter a wallet address to view it</h4>
        </div>
      </CSSTransition>
    </div>
  )
}
