import {
    GET_ALL_RESOURCES,
    GET_ALL_RESOURCES_SUCCESS,
    CREATE_RESOURCE,
    CREATE_RESOURCE_SUCCESS,
    GET_RESOURCE,
    GET_RESOURCE_SUCCESS,
    DELETE_RESOURCE,
    DELETE_RESOURCE_SUCCESS,
    UPDATE_RESOURCE,
    UPDATE_RESOURCE_SUCCESS,
    LOAD_MORE_RESOURCES_SUCCESS,
    RESOURCE_REFRESHED,
    RESOURCES_REFRESHED

} from 'constants/ActionTypes';

export const getAllResources_ = (data) => {
    return {
        type: GET_ALL_RESOURCES,
        payload: data
    };
};

export const getAllResourcesSuccess_ = (resources) => {
    return {
        type: GET_ALL_RESOURCES_SUCCESS,
        payload: resources
    };
};

export const loadMoreResourcesSuccess_ = (resources) => {
    return {
        type: LOAD_MORE_RESOURCES_SUCCESS,
        payload: resources
    };
};

export const createResource_ = (data) => {
    return {
        type: CREATE_RESOURCE,
        payload: data
    };
};

export const createResourceSuccess_ = (resourceId) => {
    return {
        type: CREATE_RESOURCE_SUCCESS,
        payload: resourceId
    };
};

export const getResource_ = (resourceId) => {
    return {
        type: GET_RESOURCE,
        payload: resourceId
    };
};

export const updateResource_ = (data) => {
    return {
        type: UPDATE_RESOURCE,
        payload: data
    };
};

export const updateResourceSuccess_ = () => {
    return {
        type: UPDATE_RESOURCE_SUCCESS
    };
};

export const getResourceSuccess_ = (resource) => {
    return {
        type: GET_RESOURCE_SUCCESS,
        payload: resource
    };
};

export const deleteResource_ = (resourceId) => {
    return {
        type: DELETE_RESOURCE,
        payload: resourceId
    };
};

export const deleteResourceSuccess_ = () => {
    return {
        type: DELETE_RESOURCE_SUCCESS
    };
};

export const resourceRefreshed_ = (data) => {
    return {
        type: RESOURCE_REFRESHED,
        payload: data
    };
};

export const resourcesRefreshed_ = (data) => {
    return {
        type: RESOURCES_REFRESHED,
        payload: data
    };
};