import React, { useEffect } from 'react';
import { Chip, Avatar, Icon, CircularProgress, Zoom, Paper, Grow } from '@material-ui/core';
import { useSelector } from 'react-redux';


const DialogueLoaderTransparent = (props) => {

    const {dialogueLoader} = useSelector(({isFetching}) => isFetching);

    
  
    return (
        <div>
            {dialogueLoader == true && (
                <div className="row no-gutters loader-container-dialogue-transparent justify-content-center align-items-center">
                    <div className="col-2 d-flex loader-view-dialogue justify-content-center align-items-center">
                        <CircularProgress size={60} thickness={2}/>
                    </div>
                </div> 
            )}
        </div>
    );
};

export default DialogueLoaderTransparent;