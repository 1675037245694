import {
    CREATE_PROJECT_SUCCESS,
    GET_ALL_PROJECTS_SUCCESS,
    GET_PROJECT_SUCCESS,
    DELETE_PROJECT_SUCCESS,
    ADD_PROJECT_EDITOR_SUCCESS,
    GET_PROJECT_FOLLOWERS_SUCCESS,
    FOLLOW_PROJECT_SUCCESS,
    GET_ALL_PROJECT_EDITORS_SUCCESS,
    DELETE_PROJECT_EDITOR_SUCCESS,
    UNFOLLOW_PROJECT_SUCCESS,
    GET_PROJECT_SETTINGS_SUCCESS,
    UPDATE_PROJECT_SETTINGS_SUCCESS,
    GET_PROJECT_APIKEYS_SUCCESS,
    CREATE_PROJECT_APIKEYS_SUCCESS,
    DELETE_PROJECT_APIKEYS_SUCCESS,
    CLEAR_CREATED_APIKEYS_SUCCESS,
    SET_CURR_PROJECT,
    SET_CURR_PROJECT_ID,
    GET_PROJECT_PROFILE_SUCCESS,
    GET_PROJECT_API_SETTINGS,
    UPDATE_PROJECT_API_SETTINGS,
    GET_PROJECT_API_SETTINGS_SUCCESS,
    UPDATE_PROJECT_API_SETTINGS_SUCCESS,
    GET_ALL_PROJECTS_FOR_REQUEST_SUCCESS,
    GET_GAS_PRICE_SUCCESS,
    PINNED_TO_PROJECT_SUCCESS,
    UNPINNED_FROM_PROJECT_SUCCESS,
    PROJECT_REFRESHED,
    PROJECTS_REFRESHED,
    SETTINGS_REFRESHED,
    SETTING_REFRESHED,
    API_SETTINGS_REFRESHED,
    GET_ALL_PROJECT_BLUEPRINTS_SUCCESS,
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    projectList:'',
    projectListForRequest:'',
    createdProject: '',
    project: '',
    projectUserList: [],
    projectFollowerList: [],
    projectDistributionSettings: '',
    projectApiKeys: '',
    createdApiKeys: '',
    apiKeyStage: 1,
    currProjectId: '',
    projectProfile: '',
    apiSettings: '',
    currentGasPrice: '',
    pinnedToProjectSuccess: false,
    unpinnedFromProjectSuccess: false,
    projectRefreshed: false,
    projectsRefreshed: false,
    settingsRefreshed: false,
    settingRefreshed: false,
    apiSettingsRefreshed: false,
    projectBlueprintList: ''

};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CREATE_PROJECT_SUCCESS: {
            return {
                ...state,
                loader: false,
                createdProject: action.payload
            }
        }
        case GET_ALL_PROJECTS_SUCCESS: {
            return {
                ...state,
                loader: false,
                projectList: action.payload
            }
        }
        case GET_ALL_PROJECTS_FOR_REQUEST_SUCCESS: {
            return {
                ...state,
                projectListForRequest: action.payload
            }
        }
        case GET_PROJECT_SUCCESS: {
            return {
                ...state,
                loader: false,
                project: action.payload
            }
        }
        case GET_PROJECT_PROFILE_SUCCESS: {
            return {
                ...state,
                projectProfile: action.payload
            }
        }
        case DELETE_PROJECT_SUCCESS: {
            return {
                ...state,
                loader: false
            }
        }
        case ADD_PROJECT_EDITOR_SUCCESS: {
            return {
                ...state,
                loader: false,
                userAccountList: action.payload
            }
        }
        case GET_PROJECT_FOLLOWERS_SUCCESS: {
            return {
                ...state,
                loader: false,
                projectFollowerList: action.payload
            }
        }
        case FOLLOW_PROJECT_SUCCESS: {
            return {
                ...state,
                loader: false
            }
        }
        case GET_ALL_PROJECT_EDITORS_SUCCESS: {
            return {
                ...state,
                loader: false,
                projectUserList: action.payload
            }
        }
        case GET_ALL_PROJECT_BLUEPRINTS_SUCCESS: {
            return {
                ...state,
                loader: false,
                projectBlueprintList: action.payload
            }
        }
        case DELETE_PROJECT_EDITOR_SUCCESS: {
            return {
                ...state,
                loader: false
            }
        }
        case UNFOLLOW_PROJECT_SUCCESS: {
            return {
                ...state,
                loader: false
            }
        }
        case GET_PROJECT_SETTINGS_SUCCESS: {
            return {
                ...state,
                projectDistributionSettings: action.payload
            }
        }
        case UPDATE_PROJECT_SETTINGS_SUCCESS: {
            return {
                ...state,
            }
        }
        case GET_PROJECT_APIKEYS_SUCCESS: {
            return {
                ...state,
                projectApiKeys: action.payload
            }
        }
        case CREATE_PROJECT_APIKEYS_SUCCESS: {
            return {
                ...state,
                apiKeyStage: 2,
                createdApiKeys: action.payload
            }
        }
        case DELETE_PROJECT_APIKEYS_SUCCESS: {
            return {
                ...state,
                apiKeyStage: 1
            }
        }
        case CLEAR_CREATED_APIKEYS_SUCCESS: {
            return {
                ...state,
                apiKeyStage: 1,
                createdApiKeys: ''
            }
        }
        case SET_CURR_PROJECT_ID: {
            return {
                ...state,
                currProjectId: action.payload,
            }
        }
        case GET_PROJECT_API_SETTINGS_SUCCESS: {
            return {
                ...state,
                apiSettings: action.payload,
            }
        }
        case UPDATE_PROJECT_API_SETTINGS_SUCCESS: {
            return {
                ...state
            }
        }
        case GET_GAS_PRICE_SUCCESS: {
            return {
                ...state,
                currentGasPrice: action.payload
            }
        }
        case PINNED_TO_PROJECT_SUCCESS: {
            return {
                ...state,
                pinnedToProjectSuccess: action.payload
            }
        }
        case UNPINNED_FROM_PROJECT_SUCCESS: {
            return {
                ...state,
                unpinnedFromProjectSuccess: action.payload
            }
        }
        case PROJECT_REFRESHED: {
            return {
                ...state,
                projectRefreshed: action.payload
            }
        }
        case PROJECTS_REFRESHED: {
            return {
                ...state,
                projectsRefreshed: action.payload
            }
        }
        case SETTINGS_REFRESHED: {
            return {
                ...state,
                settingsRefreshed: action.payload
            }
        }
        case SETTING_REFRESHED: {
            return {
                ...state,
                settingRefreshed: action.payload
            }
        }
        case API_SETTINGS_REFRESHED: {
            return {
                ...state,
                apiSettingsRefreshed: action.payload
            }
        }

        default:
            return state;
    }
}