import {
    GET_ALL_RESOURCES_SUCCESS,
    CREATE_RESOURCE_SUCCESS,
    GET_RESOURCE_SUCCESS,
    UPDATE_RESOURCE_SUCCESS,
    DELETE_RESOURCE_SUCCESS,
    RESOURCE_REFRESHED,
    RESOURCES_REFRESHED
    
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    resourceList: '',
    createdResource: '',
    resource: '',
    resourceRefreshed: false,
    resourcesRefreshed: false
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_RESOURCES_SUCCESS: {
            return {
                ...state,
                loader: false,
                resourceList: action.payload
            }
        }
        case CREATE_RESOURCE_SUCCESS: {
            return {
                ...state,
                loader: false,
                createdResource: action.payload
            }
        }
        case GET_RESOURCE_SUCCESS: {
            return {
                ...state,
                loader: false,
                resource: action.payload
            }
        }
        case UPDATE_RESOURCE_SUCCESS: {
            return {
                ...state,
                loader: false
            }
        }
        case DELETE_RESOURCE_SUCCESS: {
            return {
                ...state,
                loader: false
            }
        }
        case RESOURCE_REFRESHED: {
            return {
                ...state,
                resourceRefreshed: action.payload
            }
        }
        case RESOURCES_REFRESHED: {
            return {
                ...state,
                resourcesRefreshed: action.payload
            }
        }
        default:
            return state;
    }
}