import React, { useEffect, useState } from 'react';
import {useDispatch} from 'react-redux';

import NotificationItem from './NotificationItem';
import CustomScrollbars from 'util/CustomScrollbars';
import { useSelector } from 'react-redux';
import { getNextPageDialogue_, getNextPage_ } from 'actions/IsFetching';
import { getAllNotifications_ } from 'actions/Notification';

const AppNotification = () => {

    const dispatch = useDispatch()

    const JWToken = localStorage.getItem('idToken');
    const userId = localStorage.getItem('userId');

    const [nextPage, setNextPage] = useState(1);

    const {notificationList} = useSelector(({notification})=>notification);

    const {project} = useSelector(({project})=>project);

    const {newPageDialogue} = useSelector(({isFetching})=>isFetching);
    const limit = 10;
    

    const handleScroll = event => {
        const element = event.target;
        // console.log(element.scrollHeight - element.scrollTop -1, element.clientHeight)
        // console.log(2);
        if (element.scrollHeight - element.scrollTop - 1 === element.clientHeight) {
            
            dispatch(getNextPageDialogue_(nextPage));
            setNextPage(nextPage + 1);
            // console.log("next page: ", nextPage);
        }
      };

    useEffect(()=> {
        if (notificationList && notificationList.length % limit === 0) {
            let projectId = project.projectId
            dispatch(getAllNotifications_({JWToken, querryParams:{limit: limit, page:1, userId: userId}, refresh: true}));
            dispatch(getAllNotifications_({JWToken, querryParams:{
                limit: limit,
                page: (notificationList.length / limit) + 1,
                userId: userId
            }, loadMore: true, noLoader:true}))} 
        
    },[newPageDialogue])
      
    

    if (!notificationList) return null

    return (
        <CustomScrollbars className="messages-list scrollbar" style={{height: 280}} onScroll={handleScroll}>
            <ul className="list-unstyled">
                {notificationList.map((data, index) => <NotificationItem key={index} data={data}/>)
                }
            </ul>
        </CustomScrollbars>
    )
};

export default AppNotification;

