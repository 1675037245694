import { changeVisualizerSearchFilter } from 'components/networkVisualizer/networkVisualizerState/store/navMenu/actions'
import { SearchFilter as SearchFilterTypes } from 'components/networkVisualizer/networkVisualizerState/store/navMenu/types'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { SearchFilterItem } from './components/searchFilterItem/searchFilterItem'

const categories: SearchFilterTypes[] = [
  'all',
  'tokens',
  'utilities',
  'resources',
  'projects',
]

export const SearchFilter = () => {
  const dispatch = useDispatch()

  const activeFilters = useSelector(
    (state: AppState) => state.networkVisualizerState.navMenu.searchFilter
  )

  const changeCategory = useCallback(
    (newCategory: SearchFilterTypes) => {
      dispatch(changeVisualizerSearchFilter(newCategory))
    },
    [dispatch]
  )
  return (
    <div className='categories-wrapper'>
      <div className='categories-container'>
        {categories.map((category) => (
          <SearchFilterItem
            key={category}
            category={category}
            onClick={changeCategory}
            active={activeFilters.includes(category)}
          />
        ))}
      </div>
    </div>
  )
}
