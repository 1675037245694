import { defaultImages } from 'components/networkVisualizer/assets/variables/defaultImages'
import { ShapePicker } from 'components/networkVisualizer/components/sharedComponents/itemShapes/itemShapes'
import { CategoryTypes } from 'components/networkVisualizer/networkVisualizerState/sharedTypes'
import { changeSelectedNode } from 'components/networkVisualizer/networkVisualizerState/store/graph/actions'
import { hideItemDetails } from 'components/networkVisualizer/networkVisualizerState/store/itemDetails/actions'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { ItemBody } from './components/itemBody/itemBody'

interface IsProps {
  id: string | number
  type: CategoryTypes
}

export const Item = ({ id, type }: IsProps) => {
  const dispatch = useDispatch()

  const data = useSelector(
    (state: AppState) => state.networkVisualizerState.general.data[type][id]
  )
  let platformId = data?.platformId
  const onClick = () => {
    dispatch(changeSelectedNode(id, type, platformId))
    dispatch(hideItemDetails())
  }

  if (!data) return null

  let { name } = data

  let image = data.image || defaultImages[type]

  return (
    <div className='list-item-wrapper' onClick={onClick}>
      <div className='list-item-image-wrapper'>
        <div className='image-container'>
          <ShapePicker {...{ id, type, image }} imageAlt={name} />
        </div>
      </div>
      <div className='list-item-body-container'>
        <ItemBody {...{ id, type, platformId }} />
      </div>
    </div>
  )
}
