import {
    GET_ALL_COOPERATIONS,
    GET_ALL_COOPERATIONS_SUCCESS,
    CREATE_COOPERATION,
    CREATE_COOPERATION_SUCCESS,
    RATE_COOPERATION,
    RATE_COOPERATION_SUCCESS,
    DELETE_COOPERATION_WITH_UTILITY,
    DELETE_COOPERATION_WITH_RESOURCE,
    DELETE_COOPERATION_WITH_UTILITY_SUCCESS,
    DELETE_COOPERATION_WITH_RESOURCE_SUCCESS,
    UPDATE_COOPERATION_WITH_RESOURCE,
    UPDATE_COOPERATION_WITH_RESOURCE_SUCCESS,
    UPDATE_COOPERATION_WITH_UTILITY,
    UPDATE_COOPERATION_WITH_UTILITY_SUCCESS,
    LOAD_MORE_COOPERATIONS_SUCCESS,
    COOPS_REFRESHED,
    COOP_REFRESHED
    
} from 'constants/ActionTypes'

export const getAllCooperations_ = (data) => {
    return {
        type: GET_ALL_COOPERATIONS,
        payload: data
    };
};

export const getAllCooperationsSuccess_ = (cooperations) => {
    return {
        type: GET_ALL_COOPERATIONS_SUCCESS,
        payload: cooperations
    };
};


export const loadMoreCooperationsSuccess_ = (cooperations) => {
    return {
        type: LOAD_MORE_COOPERATIONS_SUCCESS,
        payload: cooperations
    };
};

export const createCooperation_ = (data) => {
    return {
        type: CREATE_COOPERATION,
        payload: data
    };
};

export const createCooperationSuccess_ = (cooperation) => {
    return {
        type: CREATE_COOPERATION_SUCCESS,
        payload: cooperation
    };
};

export const rateCooperation_ = (rating) => {
    return {
        type: RATE_COOPERATION,
        payload: rating
    };
};

export const rateCooperationSuccess_ = () => {
    return {
        type: RATE_COOPERATION_SUCCESS
    };
};

export const deleteCooperationWithUtility_ = (cooperationData) => {
    return {
        type: DELETE_COOPERATION_WITH_UTILITY,
        payload: cooperationData
    };
};

export const deleteCooperationWithUtilitySuccess_ = () => {
    return {
        type: DELETE_COOPERATION_WITH_UTILITY_SUCCESS
    };
};

export const deleteCooperationWithResource_ = (cooperationData) => {
    return {
        type: DELETE_COOPERATION_WITH_RESOURCE,
        payload: cooperationData
    };
};

export const deleteCooperationWithResourceSuccess_ = () => {
    return {
        type: DELETE_COOPERATION_WITH_RESOURCE_SUCCESS
    };
};

export const updateCooperationWithUtility_ = (cooperationData) => {
    return {
        type: UPDATE_COOPERATION_WITH_UTILITY,
        payload: cooperationData
    };
};

export const updateCooperationWithUtilitySuccess_ = () => {
    return {
        type: UPDATE_COOPERATION_WITH_UTILITY_SUCCESS
    };
};

export const updateCooperationWithResource_ = (cooperationData) => {
    return {
        type: UPDATE_COOPERATION_WITH_RESOURCE,
        payload: cooperationData
    };
};

export const updateCooperationWithResourceSuccess_ = () => {
    return {
        type: UPDATE_COOPERATION_WITH_RESOURCE_SUCCESS
    };
};

export const coopsRefreshed_ = (data) => {
    return {
        type: COOPS_REFRESHED,
        payload: data
    };
};

export const coopRefreshed_ = (data) => {
    return {
        type: COOP_REFRESHED,
        payload: data
    };
};