import { CategoryTypes } from 'components/networkVisualizer/networkVisualizerState/sharedTypes'
import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'store'

interface IsProps {
  id: string | number
  type: CategoryTypes
}

export const ItemBody = ({ id, type }: IsProps) => {
  //Projects are never part of cooperations.
  if (type !== 'projects') {
    return <AssetInfo {...{ id, type }} />
  }

  return null
}

interface AssetInfoProps {
  id: string | number
  type: Exclude<CategoryTypes, 'projects'>
}

const AssetInfo = ({ id, type }: AssetInfoProps) => {
  const data = useSelector(
    (state: AppState) => state.networkVisualizerState.general.data[type][id]
  )

  let { name, projectName } = data

  const rating =
    'fileType' in data
      ? `File Type: ${data.fileType}`
      : 'rating' in data
      ? `rating: ${data.rating}`
      : ''

  const expiry = data.expiry ? data.expiry : undefined

  return <BodyComponent {...{ projectName, name, rating, expiry }} />
}

interface BodyComponentProps {
  projectName?: string
  name?: string
  rating?: string
  expiry?: string
}

const BodyComponent = ({
  projectName,
  name,
  rating,
  expiry,
}: BodyComponentProps) => (
  <div className="utility info-wrapper">
    <div className="text-container">
      <p className="project-text">{projectName}</p>
      <p className="name-text">{name}</p>
    </div>
    <div className="sub-container">
      <p className="rating-text">{rating}</p>
      <p className="expiry-text">{expiry ? `exp: ${expiry}` : ''}</p>
    </div>
  </div>
)
