import { visibilityIcon } from 'components/networkVisualizer/assets/icons'
import { CategoryTypes } from 'components/networkVisualizer/networkVisualizerState/sharedTypes'
import { toggleGraphFilterState } from 'components/networkVisualizer/networkVisualizerState/store/graph/actions'
import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { ShapePicker } from '../sharedComponents/itemShapes/itemShapes'

const types: CategoryTypes[] = ['tokens', 'utilities', 'projects', 'resources']

interface FilterItemProps {
  type: CategoryTypes
  onClick: (type: CategoryTypes) => void
}

const FilterItem = ({ type, onClick }: FilterItemProps) => {
  const typeVisible = useSelector(
    (state: AppState) => state.networkVisualizerState.graph.filter[type]
  )

  return (
    <div className="item-wrapper" onClick={() => onClick(type)}>
      <div className="item-container">
        <div className="image-wrapper">
          <div className="image-container">
            <ShapePicker
              {...{ type }}
              noImage
              id={type}
              imageAlt={`filter-${type}-example`}
            />
          </div>
        </div>
        <p className="name-text">{type}</p>
        <div
          className={`visibility-icon-container ${typeVisible && 'visible'}`}
        >
          <img src={visibilityIcon} alt="visibility" />
        </div>
      </div>
    </div>
  )
}

export const Filter = () => {
  const filterThrottle = useRef<NodeJS.Timeout>()
  const dispatch = useDispatch()

  const toggleItemFilter = useCallback(
    (type: CategoryTypes) => {
      if (filterThrottle.current === undefined) {
        dispatch(toggleGraphFilterState(type))
        filterThrottle.current = setTimeout(() => {
          clearTimeout(filterThrottle.current!)
          filterThrottle.current = undefined
        }, 1500)
      }
    },
    [dispatch]
  )

  useEffect(() => {
    return () => {
      if (filterThrottle.current) clearTimeout(filterThrottle.current)
    }
  }, [])

  return (
    <div className="filter-wrapper">
      <div className="items-container">
        {types.map((type) => (
          <FilterItem key={type} {...{ type }} onClick={toggleItemFilter} />
        ))}
      </div>
    </div>
  )
}
