import {
    GET_ALL_UTILITIES_SUCCESS,
    GET_UTILITY_SUCCESS,
    CREATE_UTILITY_SUCCESS,
    DELETE_UTILITY_SUCCESS,
    UPDATE_UTILITY_SUCCESS,
    UTILITIY_REFRESHED,
    UTILITIES_REFRESHED
} from "constants/ActionTypes";

const INIT_STATE = {
    alertMessage: '',
    showMessage: false,
    utilityList: '',
    createdUtility: '',
    utility:'',
    utilityRefreshed: false,
    utilitiesRefreshed: false
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_UTILITIES_SUCCESS: {
            return {
                ...state,
                utilityList: action.payload
            }
        }
        case GET_UTILITY_SUCCESS: {
            return {
                ...state,
                utility: action.payload
            }
        }
        case CREATE_UTILITY_SUCCESS: {
            return {
                ...state,
                loader: false
            }
        }
        case DELETE_UTILITY_SUCCESS: {
            return {
                ...state,
                loader: false
            }
        }
        case UPDATE_UTILITY_SUCCESS: {
            return {
                ...state,
                loader: false,
                utility: action.payload
            }
        }
        case UTILITIY_REFRESHED: {
            return {
                ...state,
                utilityRefreshed: action.payload
            }
        }
        case UTILITIES_REFRESHED: {
            return {
                ...state,
                utilitiesRefreshed: action.payload
            }
        }
        default:
            return state;
    }
}
