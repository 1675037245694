import React from 'react'
import { useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { AppState } from 'store'

export const FetchingSpinner = () => {
  const isFetching = useSelector(
    (state: AppState) => state.networkVisualizerState.general.fetching
  )

  return (
    <CSSTransition
      in={isFetching}
      timeout={{
        enter: 100,
        exit: 250,
      }}
      mountOnEnter
      unmountOnExit
    >
      <div className="fetching-spinner-wrapper">
        <div className="center-circle"></div>
        <div className="fetching-outer-circle"></div>
        <div className="fetching-inner-circle"></div>
      </div>
    </CSSTransition>
  )
}
