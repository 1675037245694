import {
    SEARCH_STRING_SUCCESS
} from "constants/ActionTypes";

const INIT_STATE = {
    searchResults: '',
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SEARCH_STRING_SUCCESS: {
            return {
                ...state,
                searchResults: action.payload
            }
        }
        default:
            return state;
    }
}