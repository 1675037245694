import React from 'react';
import { Avatar, Button } from '@material-ui/core';



const HelpStep = ({number, title, titleIcons, description}) => {


    return (

        <div className={`row justify-content-start mb-1 mt-4`}>
            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex justify-content-end align-items-center mb-1">
                <h1 className="jr-fs-xlxl jr-font-weight-medium align-items-center" style={{marginBottom: "0rem", color: "#d6f9fefc"}}>{number}</h1>
            </div>

            <div className="col-xl-11 col-lg-11 col-md-11 col-sm-11 d-flex justify-content-start align-items-center mb-1">
                <h1 className="jr-fs-xxl jr-font-weight-medium align-items-center" style={{marginBottom: "0rem", color: "#d6f9fefc"}}>{title}</h1>
            </div>

            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 d-flex justify-content-end align-items-center mb-1">
            </div>

            <div className="col-xl-11 col-lg-11 col-md-11 col-sm-11 d-flex flex-row justify-content-start align-items-center mb-1">
                {titleIcons && titleIcons.map((icon, i) => (<Avatar key={i} src={icon} className="size-60 mr-2"/>))}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 mb-2">
                <h6 style={{marginBottom:"0.25rem", marginLeft: "4.25rem", color: "#d6f9fefc"}}>{description}</h6>
            </div>
        </div>
    )

}

export default HelpStep;