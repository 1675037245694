import { GetWalletCooperations } from 'components/networkVisualizer/API/types/getWalletCooperations'
import {
  Connections,
  SetVisualizerDataPayload,
  Tokens,
} from 'components/networkVisualizer/networkVisualizerState/store/general/types'
import { tokenIdCreator } from '../../tokenIdCreator'

interface ToGeneralDataOutput {
  data: Pick<SetVisualizerDataPayload, 'tokens'>
  connections: Connections
}

/**
 * Used for formatting wallet cooperations. Only tokens can be associated with wallets, update formatter if other asset types are included.
 *
 * Data that is returned from this output contains the general data to be merged with the existing along with the connections to be merged with the main item.
 *
 * Removes Duplicates automatically! (API currently returns duplicates)
 */
export function getWalletCooperationsFormatter(
  response: GetWalletCooperations
): ToGeneralDataOutput {
  let tokens: Tokens = {}
  let connections: Connections = { tokens: [] }

  response.tokens.forEach((token) => {
    let {
      tokenId,
      name,
      nonFungible,
      // supplyModel,
      // totalSupply,
      // circulatingSupply,
      // meltValue,
      // meltFeeRatio,
      // transferable,
      // transferFeeSettings,
      appName,
      // cooperations,
      project,
      metadata,
      cooperationCounts,
      platform,
      platformId,
      tokenIndex,
    } = token

    let generalReferenceId = tokenIdCreator(tokenId, platformId)
    let walletReferenceId = `${generalReferenceId}${
      tokenIndex ? '-' + tokenIndex : ''
    }`

    if (!connections.tokens.includes(walletReferenceId)) {
      //Check duplicates, there seems to be duplicates returned in the response. Don't know if this is for testing or will be the same for production. Don't rely on the API.
      connections.tokens.push(walletReferenceId)

      let tokenObj = {
        tokenId,
        name,
        image: metadata?.image ?? null,
        // connections,
        nonFungible,
        // supplyModel,
        // totalSupply,
        // circulatingSupply,
        // meltValue,
        // meltFeeRatio: meltFeeRatio ?? undefined,
        // transferable,
        // transferFee: transferFeeSettings.value ?? undefined,
        appName,
        // collaborationsCount,
        platformName: platform.name,
        platformId,
        projectName: project?.name ?? undefined,
        cooperationsCount: cooperationCounts,
      }

      tokens[walletReferenceId] = tokenObj
      //Tokens are duplicated so that when navigating to a wallet connected token, the graph will be able to find the token using only the tokenId and platformId
      tokens[generalReferenceId] = tokenObj
    }
  })

  return { data: { tokens }, connections }
}
