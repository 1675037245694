import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'store'

export const SearchingLoader = () => {
  const visible = useSelector(
    (state: AppState) => state.networkVisualizerState.navMenu.searching
  )

  if (!visible) return null

  return (
    <div className="searching-loader-wrapper">
      <div className="searching-loader-container">
        <div className="spinner"></div>
      </div>
    </div>
  )
}
