import { CategoryTypes } from 'components/networkVisualizer/networkVisualizerState/sharedTypes'
import React, { useCallback } from 'react'

/**
 *
 * >>>>>>>>>>>>>>>>>Insert actual button functionality here<<<<<<<<<<<<<<<<<<<
 */
interface IsProps {
  id: number | string
  type: CategoryTypes
}

export const ActionButtons = ({ id, type }: IsProps) =>
  type === 'projects' ? (
    <ProjectButtons {...{ id, type }} />
  ) : (
    <AssetButtons {...{ id, type }} />
  )

const AssetButtons = ({ id, type }: IsProps) => {
  const requestCooperation = useCallback(() => {
    alert(`request coop ${type}-${id}`)
  }, [id, type])

  const endCooperation = useCallback(() => {
    alert(`end coop ${type}-${id}`)
  }, [id, type])

  return (
    <ButtonsGroup
      button1={{
        buttonText: 'Request Coop',
        onClick: requestCooperation,
        disabled: true,
      }}
      button2={{
        buttonText: 'End Coop',
        onClick: endCooperation,
        danger: true,
        disabled: true,
      }}
    />
  )
}

const ProjectButtons = ({ id, type }: IsProps) => {
  const followProject = useCallback(() => {
    alert(`Follow Project ${type}-${id}`)
  }, [id, type])

  const unfollowProject = useCallback(() => {
    alert(`Unfollow Project ${type}-${id}`)
  }, [id, type])

  return (
    <ButtonsGroup
      button1={{
        buttonText: 'Follow Project',
        onClick: followProject,
        disabled: true,
      }}
      button2={{
        buttonText: 'Unfollow Project',
        onClick: unfollowProject,
        danger: true,
        disabled: true,
      }}
    />
  )
}

interface ButtonsGroupProps {
  button1: ButtonProps
  button2: ButtonProps
}

const ButtonsGroup = ({ button1, button2 }: ButtonsGroupProps) => (
  <div className="buttons-group-wrapper">
    <Button {...{ ...button1 }} />
    <Button {...{ ...button2 }} />
  </div>
)

interface ButtonProps {
  buttonText: string
  onClick: () => void
  danger?: boolean
  disabled?: boolean
}

const Button = ({ buttonText, onClick, danger, disabled }: ButtonProps) => (
  <div
    className={`cooperation-button${danger ? ' danger' : ''}${
      disabled ? ' disabled' : ''
    }`}
    onClick={onClick}
  >
    <p className="button-text">{buttonText}</p>
  </div>
)
