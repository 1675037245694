import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import {NavLink, withRouter} from 'react-router-dom';
import { Badge } from '@material-ui/core';
import { setAssetType_, setTabValue_ } from 'actions';
import SettingsIcon from '@material-ui/icons/Settings';


const DashNav = () => {

    const {project} = useSelector(({project}) => project);
    const {requestList} = useSelector(({request})=>request);

    const [newRequest, setNewRequest] = useState(false);

    const dispatch = useDispatch();

    const loadTokensTabs = () => {
        dispatch(setAssetType_("tokens"))
        dispatch(setTabValue_(`/app/projects/${project.projectId}/dashboard/tokens/blueprints`))
    }

    const loadUtilitiesTabs = () => {
        dispatch(setAssetType_("utilities"))
        dispatch(setTabValue_(`/app/projects/${project.projectId}/dashboard/utilities`))
    }

    const loadResourcesTabs = () => {
        dispatch(setAssetType_("resources"))
        dispatch(setTabValue_(`/app/projects/${project.projectId}/dashboard/resources`))
    }

    const loadRequestsTabs = () => {
        dispatch(setAssetType_("requests"))
        dispatch(setTabValue_(`/app/projects/${project.projectId}/dashboard/requests/received`))
    }

    const hideTabs = () => {
        dispatch(setAssetType_("none"))
        dispatch(setTabValue_("none"))
    }

    useEffect(() => {
        if (requestList && requestList.some(request => request.status === 'pending')) {
            setNewRequest(true);
        }  else {
            setNewRequest(false);
        }
    }, [requestList])
    
        return (
            <div className="row dash-nav">
                <ul className="custom-navbar-nav d-flex justify-content-center">
                    <li className="d-flex header-nav-menu-item align-items-center justify-content-center">

                        <NavLink 

                            className="dashnav-item"
                            style={{ textDecoration: 'none', textAlign: "center" }}
                            onClick={hideTabs}
                            
                            to={"/app/projects/"+ project.projectId + "/dashboard/overview"}><IntlMessages id="pages.overview"/>
                        </NavLink>

                    </li>
                    <li className="d-flex header-nav-menu-item align-items-center justify-content-center">
                        <NavLink 
                            className="dashnav-item" 
                            style={{ textDecoration: 'none', textAlign: "center" }}
                            onClick={loadUtilitiesTabs}
                            to={"/app/projects/"+ project.projectId + "/dashboard/utilities"}><IntlMessages id="pages.utilities"/>
                        </NavLink>
                    </li>
                    <li className="d-flex header-nav-menu-item align-items-center justify-content-center">
                        <NavLink
                            className="dashnav-item" 
                            style={{ textDecoration: 'none', textAlign: "center" }}
                            onClick={loadTokensTabs}

                            to={"/app/projects/"+ project.projectId + "/dashboard/tokens/blueprints"}
                            ><IntlMessages id="pages.tokens"/>
                        </NavLink>
                    </li>
                    <li className="d-flex header-nav-menu-item align-items-center justify-content-center">
                        <NavLink 
                            className="dashnav-item" 
                            style={{ textDecoration: 'none', textAlign: "center" }}
                            onClick={loadResourcesTabs}

                            to={"/app/projects/"+ project.projectId + "/dashboard/resources"}><IntlMessages id="pages.resources"/>
                        </NavLink>
                    </li>
                    
                    <li className="d-flex header-nav-menu-item align-items-center justify-content-center">                     
                            
                        <NavLink 
                            className="dashnav-item" 
                            style={{ textDecoration: 'none', textAlign: "center" }}
                            onClick={loadRequestsTabs}

                            to={"/app/projects/"+ project.projectId + "/dashboard/requests"}>
                                <span className="nav-text"><IntlMessages id="pages.requests"/>{' '}   
                                    {newRequest ?                      
                                    <Badge color="secondary" overlap="circle" badgeContent=" " variant="dot">
                                        <p>{'   '}</p> 
                                    </Badge>
                                    : null}
                                </span>
                                
                        </NavLink>
                    </li>
                    <li className="d-flex header-nav-menu-item align-items-center justify-content-center">
                        <NavLink 
                            className="dashnav-item" 
                            style={{ textDecoration: 'none', textAlign: "center" }}
                            onClick={loadUtilitiesTabs}
                            to={"/app/projects/"+ project.projectId + "/dashboard/wallets"}>Project Wallets
                        </NavLink>
                    </li>
                    <li className="d-flex header-nav-menu-item align-items-center justify-content-center">
                        <NavLink 
                            className="dashnav-item" 
                            style={{ textDecoration: 'none', textAlign: "center" }}
                            onClick={loadUtilitiesTabs}
                            to={"/app/projects/"+ project.projectId + "/dashboard/edit"}>Settings<SettingsIcon fontSize="small"/>
                        </NavLink>
                    </li>
                    
                    {/*<li className="d-flex header-nav-menu-item align-items-center justify-content-center">
                        <NavLink 
                            className="dashnav-item" 
                            style={{ textDecoration: 'none', textAlign: "center" }}
                            to={"/app/projects/"+ project.projectId + "/dashboard/testpage"}>Testpage
                        </NavLink>
                    </li>*/}
                    {/*authUser.user !== undefined && authUser.user.attributes["custom:role"] === 'admin' && (
                    <li className="nav-item nav-padding">
                        <NavLink 
                            className="jr-btn bg-grey text-white"
                            style={{ textDecoration: 'none' }}
                            activeClassName="jr-btn bg-warning text-white"
                            to="/app/dashboard/projects/cooperations/all"><IntlMessages id="pages.adminmenu"/>
                        </NavLink>
                    </li>)*/}
                </ul>
            </div>
        );
    }
;

export default withRouter(DashNav);

