import {all, call, fork, put, select, takeEvery, takeLatest} from "redux-saga/effects";

import {
    GET_ALL_USER_ACCOUNTS,
    GET_USER_ACCOUNT,
    UPDATE_USER_ACCOUNT,
    DELETE_USER_ACCOUNT,
    GET_ALL_USER_ACCOUNTS_SUCCESS,
    GET_USER_ACCOUNT_SUCCESS,
    UPDATE_USER_ACCOUNT_SUCCESS,
    DELETE_USER_ACCOUNT_SUCCESS
} from "constants/ActionTypes";

import {
    getAllUserAccounts_,
    getAllUserAccountsSuccess_,
    getUserAccount_,
    getUserAccountSuccess_,
    updateUserAccount_,
    updateUserAccountSuccess_,
    deleteUserAccount_,
    deleteUserAccountSuccess_,
    accountRefreshed_,
    accountsRefreshed_
} from "actions/Account";

import {
    getAllUserAccounts,
    getUserAccount,
    updateUserAccount,
    deleteUserAccount,
} from "../api";

import { Auth } from 'aws-amplify';
import { hideAuthLoader, showAuthLoader, userSignOut } from "actions";
import { showIsFetching_, hideIsFetching_, showTransparentLoader_, hideTransparentLoader_, showLoaderCreateSuccess_, showLoaderUpdateSuccess_, showMainLoader_, hideMainLoader_, showScrollLoader_, isLoadingMore_, hideScrollLoader_ } from "actions/IsFetching";
import { errorHandler, successHandler } from "util/messageHandler";
import { viewAlertMessage_ } from "actions/Alert";

// Get JWToken from Local Storage

function* getAllUserAccountsAsAdmin({payload}) {
    const {JWToken, querryParams, loadMore} = payload;
    const userAccountList = yield select(state => state.account.userAccountList)
    try {
        if (loadMore) {
            yield put(showScrollLoader_());
            yield put(isLoadingMore_(true));
        }
        // yield put(showIsFetching_()); 
        const apiResponse = yield call(getAllUserAccounts, JWToken, querryParams);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            if (loadMore) {
                yield put(getAllUserAccountsSuccess_([...userAccountList, ...apiResponse.data.accounts]));   
            } else {
                yield put(getAllUserAccountsSuccess_(apiResponse.data.accounts));  
            }
              
            
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideIsFetching_());
        if (loadMore) {
            yield put(hideScrollLoader_());
            yield put(isLoadingMore_(false));
        }
    }
}


function* getUserAccountByName({payload}) {
    const {JWToken, userId, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh === true) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showMainLoader_());
            }
        }
        // yield put(showIsFetching_());
        const apiResponse = yield call(getUserAccount, JWToken, userId);
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse.data.message);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(getUserAccountSuccess_(apiResponse.data));        
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideMainLoader_());
    }

}

function* updateUserAccountData({payload}) {
    const {JWToken, userId, accountData, refresh, noLoader} = payload;

    try {
        if (!noLoader) {
            if (refresh === true) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showMainLoader_());
            }
        }
        let fetchingResult = {}; 
        const apiResponse = yield call(updateUserAccount, JWToken, userId, accountData);
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse.data.message);

            

            fetchingResult.error = "Error";
            yield put(showLoaderUpdateSuccess_(fetchingResult));
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);

            yield put(accountRefreshed_(true));
            yield put(accountsRefreshed_(true));
            fetchingResult.error = "Success";
            yield put(showLoaderUpdateSuccess_(fetchingResult));
            yield put(updateUserAccountSuccess_());     

             // alert message   
             const successMessage = yield call(successHandler,"Account", "", "updated");
             yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 

        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideMainLoader_());
    }

}

function* deleteUserAccountByName({payload}) {
    const {userName, JWToken, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh === true) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showMainLoader_());
            }
        }
        const apiResponse = yield call(deleteUserAccount, JWToken, userName);
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse.data.message);
            // yield put(errorAction(apiResponse.error));
            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
            
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(accountsRefreshed_(true));
            yield put(deleteUserAccountSuccess_());
            // alert message   
            const successMessage = yield call(successHandler,"Account", "", "deleted");
            yield put(viewAlertMessage_(successMessage)); 

                    
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 

        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideMainLoader_())
    }

}

export function* readAllUserAccounts() {
    yield takeEvery(GET_ALL_USER_ACCOUNTS, 
        getAllUserAccountsAsAdmin);
}

export function* readOneUserAccount() {
    yield takeEvery(GET_USER_ACCOUNT, 
        getUserAccountByName);
}

export function* refreshUserAccount() {
    yield takeLatest(UPDATE_USER_ACCOUNT, 
        updateUserAccountData);
}

export function* removeUserAccount() {
    yield takeLatest(DELETE_USER_ACCOUNT, 
        deleteUserAccountByName);
}


export default function* rootSaga() {
    yield all([fork(readAllUserAccounts),
        fork(readOneUserAccount),
        fork(refreshUserAccount),
        fork(removeUserAccount)
    ]);
}