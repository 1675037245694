import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link, withRouter} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import logo from "assets/images/logo_trans.svg";
import {
    hideMessage,
    showAuthLoader,
    userFacebookSignIn,
    userGithubSignIn,
    userGoogleSignIn,
    userSignUp,
    userTwitterSignIn,
    sendCode,
    sendCodeSuccess,
    resetUserPassword
} from 'actions/Auth';
import { resetPassword } from 'sagas/Auth';
import DialogueLoader from 'components/Loader/DialogueLoader';
import DialogueLoaderTransparent from 'components/Loader/DialogueLoaderTransparent';
import { showLoaderCreateSuccess_ } from 'actions/IsFetching';
import { parseQueryString } from './ConfirmUser';

const ForgotPassword = (props) => {

    const [name, setName] = useState("");
    const [errorPasswordRepeat, setErrorPasswordRepeat] = useState(false);
    const [helperPasswordRepeat, setHelperPasswordRepeat] = useState('');
    const [errorPassword, setErrorPassword] = useState(false);
    const [helperPassword, setHelperPassword] = useState('');
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password_repeat, setPassword_repeat] = useState("");
    const [code, setCode] = useState("");
    const [actionDisabled, setActionDisabled] = useState(true);


    const dispatch = useDispatch();
    const {loader, alertMessage, showMessage, authUser, stage, loaderCreateResult} = useSelector(({auth}) => auth);
    let timeout;
    useEffect(() => {
        if (showMessage) {
            timeout = setTimeout(() => {
                dispatch(hideMessage());
            }, 3000);
        }


        let passwordResetData = parseQueryString(props.location.search);

        setName(passwordResetData.user_name);
        setCode(passwordResetData.confirmation_code);

        if (loaderCreateResult !== undefined) {
            dispatch(showLoaderCreateSuccess_({}));
            props.history.push('/app/signin');
        }

        return () => {
            clearTimeout(timeout);
        }

        // dispatch(resetCodeSuccess(1));


    }, [showMessage, loaderCreateResult]);

    const checkPasswordParameters = (evt) => {
        evt.preventDefault();
        setPassword(evt.target.value);
        setPassword_repeat('');
        setErrorPasswordRepeat(false);
        if (evt.target.value.length < 8) {
            setErrorPassword(true);
            setHelperPassword("Password needs to have at least 8 characters");
        } else {
            setErrorPassword(false);
            setHelperPassword("");
        }
    }

    const checkPasswordRepeat = (evt) => {
        setPassword_repeat(evt.target.value);
        if (evt.target.value !== password) {
            setErrorPasswordRepeat(true);
            setHelperPasswordRepeat("Password doesn't match");
            setActionDisabled(true);
        } else {
            setErrorPasswordRepeat(false);
            setHelperPasswordRepeat("");
            if (errorPassword === false) {
                setActionDisabled(false);
            }
            
        }
    }
    

    return (
        
        <div
            className="app-login-container justify-content-center align-items-center">
                <div className="row justify-content-center mt-5 mb-4">
                    <Link className="logo-lg" to="/" title="Jambo">
                        <img src={logo} alt="jambo" title="jambo"/>
                    </Link>
                </div>

                {stage == 1 && (
                    <div className="app-login-main-content mb-5">
                    <DialogueLoaderTransparent />
                    <div className="app-login-content">
                        <div className="app-login-header">
                            <h1>Reset your Password</h1>
                        </div>

                        <div className="mb-4">
                            <p>Enter and confirm your new password.</p>
                        </div>

                        <div className="app-login-form">
                            <form>
                                {/*<TextField
                                    type="text"
                                    variant="outlined"
                                    label="Name"
                                    onChange={(event) => setName(event.target.value)}
                                    fullWidth
                                    defaultValue={name}
                                    margin="normal"
                                    className="mt-0 mb-4"
                                />*/}

                                <TextField
                                    type="password"
                                    variant="outlined"
                                    error={errorPassword} 
                                    helperText={helperPassword}
                                    onChange={checkPasswordParameters}
                                    label={<IntlMessages id="appModule.password"/>}
                                    fullWidth
                                    defaultValue={password}
                                    margin="normal"
                                    className="mt-0 mb-4"
                                />

                                {<TextField
                                    type="password"
                                    variant="outlined"
                                    onChange={checkPasswordRepeat}
                                    error={errorPasswordRepeat} 
                                    helperText={helperPasswordRepeat}
                                    label="Confirm Password"
                                    fullWidth
                                    defaultValue={email}
                                    margin="normal"
                                    className="mt-0 mb-4"
                                />}

                                {/*<TextField
                                    type="code"
                                    variant="outlined"
                                    onChange={(event) => setCode(event.target.value)}
                                    label={<IntlMessages id="appModule.code"/>}
                                    fullWidth
                                    defaultValue={password}
                                    margin="normal"
                                    className="mt-0 mb-4"
                                />*/}

                                {alertMessage === '' && (
                                <div className="mt-3 mb-3 d-flex align-items-center justify-content-end">
                                    <Button type="submit" disabled={actionDisabled} variant="contained" onClick={(event) => {
                                        event.preventDefault();
                                        dispatch(resetUserPassword({name, code, password}));
                                    }} color="primary">
                                        Reset Password
                                    </Button>
                                    
                                </div>
                                )}
                                
                                {alertMessage !== '' && (
                                    <div className="mt-3 mb-3 d-flex align-items-center justify-content-end">
                                    <Button disabled={actionDisabled} variant="contained" onClick={() => {
                                        props.history.push('/forgotpassword');
                                    }} color="primary">
                                        New Code
                                    </Button>
                                    
                                </div>
                                )}

                                {/*<div className="app-social-block my-1 my-sm-3">
                                    <IntlMessages
                                        id="signIn.connectWith"/>
                                    <ul className="social-link">
                                        <li>
                                            <IconButton className="icon"
                                                        onClick={() => {
                                                            dispatch(showAuthLoader());
                                                            dispatch(userFacebookSignIn());
                                                        }}>
                                                <i className="zmdi zmdi-facebook"/>
                                            </IconButton>
                                        </li>

                                        <li>
                                            <IconButton className="icon"
                                                        onClick={() => {
                                                            dispatch(showAuthLoader());
                                                            dispatch(userTwitterSignIn());
                                                        }}>
                                                <i className="zmdi zmdi-twitter"/>
                                            </IconButton>
                                        </li>

                                        <li>
                                            <IconButton className="icon"
                                                        onClick={() => {
                                                            dispatch(showAuthLoader());
                                                            dispatch(userGoogleSignIn());

                                                        }}>
                                                <i className="zmdi zmdi-google-plus"/>
                                            </IconButton>
                                        </li>

                                        <li>
                                            <IconButton className="icon"
                                                        onClick={() => {
                                                            dispatch(showAuthLoader());
                                                            dispatch(userGithubSignIn());
                                                        }}>
                                                <i className="zmdi zmdi-github"/>
                                            </IconButton>
                                        </li>
                                    </ul>
                                                    </div>*/}
                            </form>
                        </div>
                    </div>
                </div>
                )}



                {stage == 2 &&
                    <div className="row justify-content-center mt-5 mb-5">

                        <div className="col-12 d-flex justify-content-center mb-3">
                            <h1 className="d-flex d-block" style={{textAlign: "center"}}>{"Reset successful!"}</h1>
                        </div>
                        
                        <div className="col-12 d-flex justify-content-center mb-5">
                            <h4 className="text-muted d-flex d-block" style={{textAlign: "center"}}>{"Your password has been successfully reset!"}</h4>
                        </div>
                        
                        <div className="col-12 d-flex justify-content-center mb-2">
                            <div className="row justify-content-center mb-3">
                                <Button variant="contained" color="primary" onClick={()=> props.history.push("/signin")}>
                                    Go to Login
                                </Button>
                            </div>
                        </div>
                    </div>
                }




                {
                    loader &&
                    <div className="loader-view">
                        <CircularProgress/>
                    </div>
                }
                {showMessage && NotificationManager.error(alertMessage)}
                <NotificationContainer/>
        </div>
    )
};


export default withRouter(ForgotPassword);
