import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'store'
import { DetailPair } from './sharedComponents/detailPair/detailPair'

interface IsProps {
  id: string | number
}

export const ResourceDetails = ({ id }: IsProps) => {
  const data = useSelector(
    (state: AppState) => state.networkVisualizerState.general.data.resources[id]
  )

  let { projectName, category, modelType, platform, license } = data

  return (
    <>
      <DetailPair title='Project Name' value={projectName} />
      <DetailPair title='Category' value={category} />
      <DetailPair title='Model Type' value={modelType} />
      <DetailPair title='Platform' value={platform} />
      <DetailPair title='License' value={license} />
    </>
  )
}
