// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

//Contact Module const

export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

export const FETCH_ALL_CONTACT = 'fetch_all_contact';
export const FETCH_ALL_CONTACT_SUCCESS = 'fetch_all_contact_success';
export const ADD_FAVOURITE = 'add_favourite';
export const ON_CONTACT_SELECT = 'on_contact_select';
export const ON_ADD_CONTACT = 'on_add_contact';
export const ON_CONTACT_CLOSE = 'on_contact_close';
export const ON_FILTER_OPTION_SELECT = 'on_filter_option_select';
export const ON_SAVE_CONTACT = 'on_save_contact';
export const ON_DELETE_CONTACT = 'on_delete_contact';
export const ON_DELETE_SELECTED_CONTACT = 'on_delete_selected_contact';
export const FILTER_CONTACT = 'filter_contact';
export const GET_ALL_CONTACT = 'get_all_contact';
export const GET_UNSELECTED_ALL_CONTACT = 'get_unselected_all_contact';
export const ON_ALL_CONTACT_SELECT = 'on_all_contact_select';
export const UPDATE_SEARCH_USER = 'update_search_user';
export const ON_TOGGLE_DRAWER = 'on_toggle_drawer';
export const HANDLE_REQUEST_CLOSE = 'handle_request_close';
export const HIDE_CONTACT_LOADER = 'hide_contact_loader';

export const CONVERSATIONS_REFRESHED = 'conversations_refreshed';
export const MESSAGES_REFRESHED = 'messages_refreshed';
//Auth const
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const CONFIRM_USER = 'confirm_user';
export const CONFIRM_USER_SUCCESS = 'confirm_user_success';
export const SIGNIN_GOOGLE_USER = 'signin_google_user';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'signin_google_user_success';
export const SIGNIN_FACEBOOK_USER = 'signin_facebook_user';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'signin_facebook_user_success';
export const SIGNIN_TWITTER_USER = 'signin_twitter_user';
export const SIGNIN_TWITTER_USER_SUCCESS = 'signin_twitter_user_success';
export const SIGNIN_GITHUB_USER = 'signin_github_user';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_SUCCESS';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNIN_GUEST = 'signin_guest';
export const SIGNIN_GUEST_SUCCESS = 'signin_guest_success';
export const SIGNIN_USER_UNVERIFIED = 'signin_user_unverified'
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const CLEAR_AUTHUSER = 'clear_authuser';
export const INIT_URL = 'init_url';

export const SEND_CODE = 'send_code';
export const SEND_CODE_SUCCESS = 'send_code_success';
export const RESET_PASSWORD = 'reset_password';
export const RESET_PASSWORD_SUCCESS = 'reset_password_success';
export const RESET_PASSWORD_CANCEL = 'reset_password_cancel';
export const LOAD_USER_ACCOUNT_SUCCESS = 'load_user_account_success';

export const SHOW_ERROR_PAGE = 'show_error_page';

export const ACCOUNT_REFRESHED = 'account_refreshed';
export const ACCOUNTS_REFRESHED = 'accounts_refreshed';

// Chat Module const


export const FILTER_USERS = 'filter_users';
export const ON_SELECT_USER = 'on_select_user';
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const USER_INFO_STATE = 'user_info_state';
export const SUBMIT_COMMENT = 'submit_comment';
export const UPDATE_MESSAGE_VALUE = 'update_message_value';
export const UPDATE_SEARCH_CHAT_USER = 'update_search_chat_user';


export const GET_ALL_CHAT_USERS = 'get_all_chat_users';
export const GET_ALL_CHAT_USERS_SUCCESS = 'get_all_chat_users_success';
export const LOAD_MORE_CHAT_USERS_SUCCESS = 'load_more_chat_users_success';
export const GET_ALL_CONTACT_USERS = 'get_all_contact_users';
export const GET_ALL_CONTACT_USERS_SUCCESS = 'get_all_contact_users_success';
export const LOAD_MORE_CONTACT_USERS_SUCCESS = 'load_more_contact_users_success';
export const ON_USER_SELECT = 'on_user_select';
export const ON_USER_SELECT_SUCCESS = 'on_user_select_success';
export const SEND_TEXT_MESSAGE = 'send_text_message';
export const SEND_TEXT_MESSAGE_SUCCESS = 'send_text_message_success';
export const SEND_MEDIA_MESSAGE = 'send_media_message';
export const SEND_MEDIA_MESSAGE_SUCCESS = 'send_media_message_success';
export const GET_CONVERSATION = 'get_conversation';
export const GET_CONVERSATION_SUCCESS = 'get_conversation_success';
export const UPDATE_MESSAGE_STATUS = 'update_message_status';
export const SET_ACTIVE_CHATUSERS = 'set_active_chatusers';
export const SET_ACTIVE_CONVERSATION = 'set_active_conversation';
export const LOAD_PREV_MESSAGES = 'load_prev_messages';
export const LOAD_PREV_MESSAGES_SUCCESS = 'load_prev_messages_success';
export const UPDATE_ALL_CHAT_USERS = 'update_all_chat_users';

//// Mail Module const


export const ADD_LABEL = 'ADD_LABEL';
export const GET_ALL_MAIL = 'get_all_mail';
export const FETCH_ALL_MAIL = 'fetch_all_mail';
export const FETCH_ALL_MAIL_SUCCESS = 'fetch_all_mail_success';
export const GET_IMPORTANT_MAIL = 'get_important_mail';
export const GET_NAV_FILTERS = 'get_nav_filters';
export const GET_NAV_LABELS = 'get_nav_labels';
export const GET_NAV_FOLDER = 'get_nav_folder';
export const GET_READ_MAIL = 'get_read_mail';
export const GET_STARRED_MAIL = 'get_starred_mail';
export const GET_UNIMPORTANT_MAIL = 'get_unimportant_mail';
export const GET_UNREAD_MAIL = 'get_unread_mail';
export const GET_UNSELECTED_ALL_MAIL = 'get_unselected_all_mail';
export const GET_UNSTARRED_MAIL = 'get_unstarred_mail';
export const ON_ALL_MAIL_SELECT = 'on_all_mail_select';
export const ON_FOLDER_MENU_ITEM_SELECT = 'on_folder_menu_item_select';
export const ON_FOLDER_SELECT = 'on_folder_select';
export const ON_IMPORTANT_SELECT = 'on_important_select';
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select';
export const GET_MARK_AS_START = 'get_mark_as_start';
export const ON_LABEL_SELECT = 'on_label_select';
export const ON_MAIL_CHECKED = 'on_mail_checked';
export const ON_MAIL_SELECT = 'on_mail_select';
export const ON_MAIL_SEND = 'on_mail_send';
export const ON_OPTION_MENU_ITEM_SELECT = 'on_option_menu_item_select';
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select';
export const ON_START_SELECT = 'on_start_select';
export const SEARCH_MAIL = 'search_mail';
export const ON_DELETE_MAIL = 'on_delete_mail';
export const SET_CURRENT_MAIL_NULL = 'set_current_mail_null';
export const ON_COMPOSE_MAIL = 'on_compose_mail';

//// TO-DO Module const

export const ON_SORTEND = 'on_sortend';
export const FETCH_ALL_TODO = 'fetch_all_todo';
export const FETCH_ALL_TODO_SUCCESS = 'fetch_all_todo-success';
export const FETCH_ALL_TODO_CONVERSATION = 'fetch_all_todo_conversation';
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS = 'fetch_all_todo_conversation_success';
export const SELECT_ALL_TODO = 'select_all_todo';
export const GET_ALL_TODO = 'get_all_todo';
export const GET_UNSELECTED_ALL_TODO = 'get_unselected_all_todo';
export const GET_STARRED_TODO = 'get_starred_todo';
export const GET_UNSTARRED_TODO = 'get_unstarred_todo';
export const GET_IMPORTANT_TODO = 'get_important_todo';
export const GET_UNIMPORTANT_TODO = 'get_unimportant_todo';
export const ON_LABEL_UPDATE = 'on_label_update';
export const ON_TODO_UPDATE = 'on_todo_update';
export const ON_DELETE_TODO = 'on_delete_todo';
export const SEARCH_TODO = 'search_todo';
export const SHOW_TODOS = 'show_todos';
export const GET_TODO_CONVERSATION = 'get_todo_conversation';
export const ON_TODO_CHECKED = 'on_todo_checked';
export const ON_TODO_ADD = 'on_todo_add';
export const ON_TODO_SELECT = 'on_todo_select';
export const SET_CURRENT_TODO_NULL = 'set_current_todo_null';
export const REMOVE_LABEL = 'remove_label';
export const UPDATE_SEARCH = 'update_search';


//// ACCOUNT const

export const GET_ALL_USER_ACCOUNTS = 'get_all_user_accounts';
export const GET_ALL_USER_ACCOUNTS_SUCCESS = 'get_all_user_accounts_success';
export const LOAD_MORE_USER_ACCOUNTS_SUCCESS = 'load_more_user_accounts_success';
export const GET_USER_ACCOUNT = 'get_user_account';
export const GET_USER_ACCOUNT_SUCCESS = 'get_user_account_success';
export const UPDATE_USER_ACCOUNT = 'update_user_account';
export const UPDATE_USER_ACCOUNT_SUCCESS = 'update_user_account_success';
export const DELETE_USER_ACCOUNT = 'delete_user_account';
export const DELETE_USER_ACCOUNT_SUCCESS = 'delete_user_account_success';

export const GET_AUTH_USER = 'get_auth_user';
export const GET_AUTH_USER_SUCCESS = 'get_auth_user_success';

export const AUTHUSER_REFRESHED = 'authuser_refreshed';
export const AUTHUSERS_REFRESHED = 'authusers_refreshed';


//// COOPERATIONS const

export const GET_ALL_COOPERATIONS = 'get_all_cooperations';
export const GET_ALL_COOPERATIONS_SUCCESS = 'get_all_cooperations_success';
export const LOAD_MORE_COOPERATIONS_SUCCESS = 'load_more_cooperations_success';
export const CREATE_COOPERATION = 'create_cooperation';
export const CREATE_COOPERATION_SUCCESS = 'create_cooperation_success';
export const RATE_COOPERATION = 'rate_cooperation';
export const RATE_COOPERATION_SUCCESS = 'rate_cooperation_success';
export const DELETE_COOPERATION_WITH_UTILITY = 'delete_cooperation_with_utility'; /////
export const DELETE_COOPERATION_WITH_UTILITY_SUCCESS = 'delete_cooperation_with_utility_success';
export const DELETE_COOPERATION_WITH_RESOURCE = 'delete_cooperation_with_resource'; /////
export const DELETE_COOPERATION_WITH_RESOURCE_SUCCESS = 'delete_cooperation_with_resource_success';
export const UPDATE_COOPERATION_WITH_UTILITY = 'update_cooperation_with_utility'; /////
export const UPDATE_COOPERATION_WITH_UTILITY_SUCCESS = 'update_cooperation_with_utility_success';
export const UPDATE_COOPERATION_WITH_RESOURCE = 'update_cooperation_with_resource'; /////
export const UPDATE_COOPERATION_WITH_RESOURCE_SUCCESS = 'update_cooperation_with_resource_success';

export const COOP_REFRESHED = 'coop_refreshed';
export const COOPS_REFRESHED = 'coops_refreshed';

//// EVENTS const
export const GET_ALL_EVENTS = 'get_all_events';
export const GET_ALL_EVENTS_SUCCESS = 'get_all_events_success';
export const LOAD_MORE_EVENTS_SUCCESS = 'load_more_events_success';

export const EVENT_REFRESHED = 'event_refreshed';
export const EVENTS_REFRESHED = 'events_refreshed';

//// IMGS const
export const UPDATE_IMG_BY_DATA = 'update_img_by_data'; ////
export const UPDATE_IMG_BY_DATA_SUCCESS = 'update_img_by_data_success';
export const UPDATE_BANNER_BY_DATA = 'update_banner_by_data'; ////
export const UPDATE_BANNER_BY_DATA_SUCCESS = 'update_banner_by_data_success';
export const SET_SELECTED_IMAGE = 'set_selected_image';
export const SET_SELECTED_BANNER = 'set_selected_banner';
export const UPLOAD_SUCCESS = 'upload_success';
export const DELETE_IMG_SUCCESS = 'delete_img_success';
export const DELETE_IMG = 'delete_img';

export const IMG_REFRESHED = 'img_refreshed';
export const IMGS_REFRESHED = 'imgs_refreshed';

//// NOTIFICATIONS const
export const GET_ALL_NOTIFICATIONS = 'get_all_notificatoins';
export const GET_ALL_NOTIFICATIONS_SUCCESS = 'get_all_notificatoins_success';
export const UPDATE_NOTIFICATION_STATUS = 'update_notification_status';
export const UPDATE_NOTIFICATION_STATUS_SUCCESS = 'update_notification_status_success';
export const LOAD_MORE_NOTIFICATIONS_SUCCESS = 'load_more_notificatoins_success';

export const NOTIFICATION_REFRESHED = 'notification_refreshed';
export const NOTIFICATIONS_REFRESHED = 'notifications_refreshed';



//// ORDERS const
export const GET_ALL_ORDERS = 'get_all_orders';
export const PLACE_ORDER = 'place_order';
export const GET_ORDER = 'get_order';
export const UPDATE_ORDER = 'update_order'; /////
export const DELETE_ORDER = 'delete_order';
export const GET_ORDER_QUEUE_ITEMS = 'get_order_success_queue_items'; /////
export const FORCE_SEND = 'force_send'; /////
export const ORDER_REFRESHED = 'order_refreshed';
export const ORDERS_REFRESHED = 'orders_refreshed';
//
export const GET_ALL_ORDERS_SUCCESS = 'get_all_orders_success';
export const LOAD_MORE_ORDERS_SUCCESS = 'load_more_orders_success';
export const PLACE_ORDER_SUCCESS = 'place_order_success';
export const GET_ORDER_SUCCESS = 'get_order_success';
export const DELETE_ORDER_SUCCESS = 'delete_order_success';
export const UPDATE_ORDER_SUCCESS = 'update_order_success';
export const GET_ORDER_QUEUE_ITEMS_SUCCESS = 'get_order_success_queue_items_success';
export const FORCE_SEND_SUCCESS = 'force_send_success';
export const CLEAR_ORDER_STAGE = 'clear_order_stage';

export const SET_ORDER_ID = "set_order_id";
export const SET_SELECTED_ORDER = 'set_selected_order';
export const REFRESH_ORDERS = 'refresh_orders';

//// PROJECTS const
export const CREATE_PROJECT = 'create_project';
export const CREATE_PROJECT_SUCCESS = 'create_project_success';
export const GET_ALL_PROJECTS = 'get_all_projects';
export const GET_ALL_PROJECTS_SUCCESS = 'get_all_projects_success';
export const LOAD_MORE_PROJECTS_SUCCESS = 'load_more_projects_success';
export const GET_ALL_PROJECTS_FOR_REQUEST_SUCCESS = 'get_all_projects_for_request_success';
export const GET_PROJECT = 'get_project';
export const SELECT_PROJECT = 'select_project';
export const SELECT_PROJECT_SUCCESS = 'select_project_success';
export const GET_PROJECT_SUCCESS = 'get_project_success';
export const UPDATE_PROJECT = 'update_project'; /////
export const UPDATE_PROJECT_SUCCESS = 'update_project_success';
export const DELETE_PROJECT = 'delete_project';
export const DELETE_PROJECT_SUCCESS = 'delete_project_success';
export const ADD_PROJECT_EDITOR = 'add_project_editor';
export const ADD_PROJECT_EDITOR_SUCCESS = 'add_project_editor_success';
export const GET_ALL_PROJECT_EDITORS = 'get_all_project_editors'; ////
export const GET_ALL_PROJECT_EDITORS_SUCCESS = 'get_all_project_editors_success';
export const GET_ALL_PROJECT_BLUEPRINTS = 'get_all_project_blueprints';
export const GET_ALL_PROJECT_BLUEPRINTS_SUCCESS = 'get_all_project_blueprints_success';
export const CACHE_PROJECT_BLUEPRINTS = 'cache_project_blueprints';
export const CACHE_PROJECT_BLUEPRINTS_SUCCESS = 'cache_project_blueprints_success';

export const LOAD_MORE_PROJECT_EDITORS_SUCCESS = 'load_more_project_editors_success';
export const DELETE_PROJECT_EDITOR = 'delete_project_editor'; /////
export const DELETE_PROJECT_EDITOR_SUCCESS = 'delete_project_editor_success';
export const GET_PROJECT_FOLLOWERS = 'get_project_followers';
export const GET_PROJECT_FOLLOWERS_SUCCESS = 'get_project_followers_success';
export const FOLLOW_PROJECT = 'follow_project';
export const FOLLOW_PROJECT_SUCCESS = 'follow_project_success';
export const UNFOLLOW_PROJECT = 'unfollow_project'; /////
export const UNFOLLOW_PROJECT_SUCCESS = 'unfollow_project_success';
export const GET_PROJECT_PROFILE = 'get_project_profile';
export const GET_PROJECT_PROFILE_SUCCESS = 'get_project_profile_success';

export const PIN_ASSET_TO_PROJECT = 'pin_asset_to_project';
export const UNPIN_ASSET_FROM_PROJECT = 'unpin_asset_from_project';
export const PINNED_TO_PROJECT_SUCCESS = 'pinned_to_project_success';
export const UNPINNED_FROM_PROJECT_SUCCESS = 'unpinned_from_project_success';

export const PROJECT_REFRESHED = 'project_refreshed';
export const PROJECTS_REFRESHED = 'projects_refreshed';


//// PROJECT SETTINGS & API KEYS
export const GET_PROJECT_SETTINGS = "get_project_settings";
export const GET_PROJECT_API_SETTINGS = "get_project_api_settings";
export const UPDATE_PROJECT_API_SETTINGS = "update_project_api_settings";
export const UPDATE_PROJECT_SETTINGS = "update_project_settings";
export const GET_PROJECT_APIKEYS = "get_project_apikeys";
export const CREATE_PROJECT_APIKEYS = "create_project_apikeys";
export const DELETE_PROJECT_APIKEYS = "delete_project_apikeys";

export const GET_PROJECT_SETTINGS_SUCCESS = "get_project_settings_success";
export const GET_PROJECT_API_SETTINGS_SUCCESS = "get_project_api_settings_success";
export const UPDATE_PROJECT_API_SETTINGS_SUCCESS = "update_project_api_settings_success";
export const UPDATE_PROJECT_SETTINGS_SUCCESS = "update_project_settings_success";
export const GET_PROJECT_APIKEYS_SUCCESS = "get_project_apikeys_success";
export const CREATE_PROJECT_APIKEYS_SUCCESS = "create_project_apikeys_success";
export const DELETE_PROJECT_APIKEYS_SUCCESS = "delete_project_apikeys_success";

export const CLEAR_CREATED_APIKEYS_SUCCESS = "clear_created_apikeys_success";
export const SET_CURR_PROJECT_ID = 'set_curr_project_id';

export const GET_GAS_PRICE = 'get_gas_price';
export const GET_GAS_PRICE_SUCCESS = 'get_gas_price_success';

export const SETTING_REFRESHED = 'setting_refreshed';
export const SETTINGS_REFRESHED = 'settings_refreshed';
export const API_SETTINGS_REFRESHED = 'api_settings_refreshed';

//// RESOURCES const
export const GET_ALL_RESOURCES = 'get_all_resources';
export const CREATE_RESOURCE = 'create_resource';
export const GET_RESOURCE = 'get_resource';
export const UPDATE_RESOURCE = 'update_resource'; ////
export const DELETE_RESOURCE = 'delete_resource';
export const GET_ALL_RESOURCES_SUCCESS = 'get_all_resources_success';
export const LOAD_MORE_RESOURCES_SUCCESS = 'load_more_resources_success';
export const CREATE_RESOURCE_SUCCESS = 'create_resource_success';
export const GET_RESOURCE_SUCCESS = 'get_resource_success';
export const UPDATE_RESOURCE_SUCCESS = 'update_resource_success';
export const DELETE_RESOURCE_SUCCESS = 'delete_resource_success';

export const RESOURCE_REFRESHED = 'resource_refreshed';
export const RESOURCES_REFRESHED = 'resources_refreshed';

// LISTENERS

export const ENJIN_L_CONNECTED = 'enjin_l_connected';
export const UTILIFY_L_CONNECTED = 'utilify_l_connected';
export const ENJIN_L_CONNECTING = 'enjin_l_connecting';
export const UTILIFY_L_CONNECTING = 'utilify_l_connecting';
export const SET_CURRENT_ENJINID = 'set_current_enjinid';
export const SET_CURRENT_TOKNEID = 'set_current_tokenid';
export const SET_CURRENT_WALLETID = 'set_current_walletid';
export const SET_CURRENT_ETHADDRESS = 'set_current_ethaddress';
export const SET_UTILIFY_EVENT_CHANNELS = 'set_utilify_event_channels';





//// REQUESTS const
export const GET_REQUEST = 'get_request';
export const GET_ALL_REQUESTS = 'get_all_requests';
export const CREATE_REQUEST = 'create_request';
export const UPDATE_REQUEST_STATUS = 'update_request_status';
export const DELETE_REQUEST = 'delete_request';
//
export const GET_REQUEST_SUCCESS = 'get_request_success';
export const GET_ALL_REQUESTS_SUCCESS = 'get_all_requests_success';
export const GET_ALL_REQUESTS_OVERVIEW_SUCCESS = 'get_all_requests_overview_success';
export const GET_ALL_REQUESTS_RECEIVED_SUCCESS = 'get_all_requests_received_success';
export const GET_ALL_REQUESTS_SENT_SUCCESS = 'get_all_requests_sent_success';
export const LOAD_MORE_REQUESTS_SUCCESS = 'load_more_requests_success';
export const CREATE_REQUEST_SUCCESS = 'create_request_success';
export const UPDATE_REQUEST_STATUS_SUCCESS = 'update_request_status_success';
export const DELETE_REQUEST_SUCCESS = 'delete_request_success';

export const REQUEST_REFRESHED = 'request_refreshed';
export const REQUESTS_REFRESHED = 'requests_refreshed';

//// TOKENS const
export const CREATE_TOKEN = 'create_token';
export const GET_TOKEN_BLUEPRINT = 'get_token_blueprint';
export const GET_TOKEN_STATS = 'get_token_status';
export const GET_METADATA = 'get_metadata';
export const UPDATE_METADATA = 'update_metadata';
export const UPDATE_URI = 'update_uri';
export const GET_ALL_TOKEN_BLUEPRINTS = 'get_all_token_blueprints';
export const GET_TOKEN_METADATA_BY_URI = 'get_token_metadata_by_uri';

export const TOKEN_REFRESHED = 'token_refreshed';
export const TOKENS_REFRESHED = 'tokens_refreshed';


//
export const CREATE_TOKEN_SUCCESS = 'create_token_success';
export const GET_TOKEN_BLUEPRINT_SUCCESS = 'get_token_blueprint_success';
export const GET_TOKEN_STATS_SUCCESS = 'get_token_status_success';
export const GET_METADATA_SUCCESS = 'get_metadata_success';
export const UPDATE_METADATA_SUCCESS = 'update_metadata_success';
export const METADATA_UPDATED = 'metadata_updated';
export const UPDATE_URI_SUCCESS = 'update_uri_success';
export const GET_ALL_TOKEN_BLUEPRINTS_SUCCESS = 'get_all_token_blueprints_success';
export const LOAD_MORE_TOKEN_BLUEPRINTS_SUCCESS = 'load_more_token_blueprints_success';
export const GET_TOKEN_METADATA_BY_URI_SUCCESS = 'get_token_metadata_by_uri_success';


//// TRANSACTIONS const
export const GET_ALL_TRANSACTIONS = 'get_all_transactions';
export const GET_TRANSACTION = 'get_transaction';
export const SEARCH_TX_STRING = 'search_tx_string';

export const TX_REFRESHED = 'tx_refreshed';
export const TXS_REFRESHED = 'txs_refreshed';
//
export const GET_ALL_TRANSACTIONS_SUCCESS = 'get_all_transactions_success';
export const LOAD_MORE_TRANSACTIONS_SUCCESS = 'load_more_transactions_success';
export const GET_TRANSACTION_SUCCESS = 'get_transaction_success';
export const SEARCH_TX_STRING_SUCCESS = 'search_tx_string_success';

//// USERS const
export const GET_ALL_USERS = 'get_all_usersr';
export const GET_USER = 'get_user';
export const ADD_USER = 'add_user'; /////
export const UPDATE_USER = 'update_user'; /////
export const DELETE_USER = 'delete_user';
export const PIN_TOKEN_TO_PROFILE = 'pin_token_to_profile';
export const PIN_RESOURCE_TO_PROFILE = 'pin_resource_to_profile';
export const PIN_UTILITY_TO_PROFILE = 'pin_utility_to_profile';
export const GET_USER_PROJECT_WATCHLIST = 'get_user_project_watchlist';
export const GET_USER_SETTINGS = 'get_user_settings'; ///////
export const UPDATE_USER_SETTINGS = 'update_user_settings'; //////
export const PIN_ASSET_TO_USER = 'pin_asset_to_user';
export const UNPIN_ASSET_FROM_USER = 'unpin_asset_from_user';

export const USER_REFRESHED = 'user_refreshed';
export const USERS_REFRESHED = 'users_refreshed';
//
export const GET_ALL_USERS_SUCCESS = 'get_all_users_success';
export const LOAD_MORE_USERS_SUCCESS = 'load_more_users_success';
export const GET_USER_SUCCESS = 'get_user_success';
export const ADD_USER_SUCCESS = 'add_user_success';
export const UPDATE_USER_SUCCESS = 'update_user_success'; 
export const DELETE_USER_SUCCESS = 'delete_user_success';
export const PIN_TOKEN_TO_PROFILE_SUCCESS = 'pin_token_to_profile_success';
export const PIN_RESOURCE_TO_PROFILE_SUCCESS = 'pin_resource_to_profile_success';
export const PIN_UTILITY_TO_PROFILE_SUCCESS = 'pin_utility_to_profile_success';
export const GET_USER_PROJECT_WATCHLIST_SUCCESS = 'get_user_project_watchlist_success';
export const GET_USER_SETTINGS_SUCCESS = 'get_user_settings_success'; 
export const UPDATE_USER_SETTINGS_SUCCESS = 'update_user_settings_success';
export const PINNED_TO_USER_SUCCESS = 'pinned_to_user_success';
export const UNPINNED_FROM_USER_SUCCESS = 'unpinned_from_user_success';

//// UTILITIES const
export const GET_ALL_UTILITIES = 'get_all_utilities';
export const GET_UTILITY = 'get_utility';
export const UPDATE_UTILITY = 'update_utility';
export const CREATE_UTILITY = 'create_utility';
export const DELETE_UTILITY = 'delete_utility';

export const UTILITIY_REFRESHED = 'utility_refreshed';
export const UTILITIES_REFRESHED = 'utilities_refreshed';
//
export const GET_ALL_UTILITIES_SUCCESS = 'get_all_utilities_success';
export const LOAD_MORE_UTILITIES_SUCCESS = 'load_more_utilities_success';
export const GET_UTILITY_SUCCESS = 'get_utility_success';
export const UPDATE_UTILITY_SUCCESS = 'update_utility_success';
export const CREATE_UTILITY_SUCCESS = 'create_utility_success';
export const DELETE_UTILITY_SUCCESS = 'delete_utility_success';

//// WALLETS const
export const GET_ALL_WALLETS = 'get_all_wallets';
export const GET_WALLET = 'get_wallet';
export const GET_WALLET_THUMB = 'get_wallet_thumb';
export const SET_LINKING_METHOD = 'set_linking_method';
export const ADD_WALLET = 'add_wallet';
export const VERIFY_WALLET = 'verify_wallet';
export const VERIFY_WALLET_SUCCESS = 'verify_wallet_success';
export const GET_PLATFORMS = 'get_platforms';
export const GET_PLATFORMS_SUCCESS = 'get_platforms_success';
export const DELETE_WALLET = 'delete_wallet';
export const UPDATE_WALLET = 'update_wallet';
export const GET_BALANCES = 'get_balances';
export const GET_BLUEPRINTS = 'get_blueprints';
export const GET_BLUEPRINT = 'get_blueprint';
export const UNLINK_WALLET = 'unlink_wallet';
export const CLEAR_LINKING_DIALOGUE = 'clear_linking_dialogue';
export const GET_SUPPLY = 'get_supply';
export const SET_CACHED = 'set_chached';
export const SET_CACHED_LIST = 'set_cached_list';

export const WALLET_REFRESHED = 'wallet_refreshed';
export const WALLETS_REFRESHED = 'wallets_refreshed';

// Project Wallets
export const GET_ALL_WALLETS_SUCCESS = 'get_all_wallets_success';
export const LOAD_MORE_WALLETS_SUCCESS = 'load_more_wallets_success';
export const GET_WALLET_SUCCESS = 'get_wallet_success';
export const GET_WALLET_THUMB_SUCCESS = 'get_wallet_thumb_success';
export const ADD_WALLET_SUCCESS = 'add_wallet_success';
export const LINK_WALLET_SUCCESS = 'link_wallet_success';
export const PROJECT_WALLET_CREATED = 'project_wallet_created';
export const DELETE_WALLET_SUCCESS = 'delete_wallet_success';
export const UPDATE_WALLET_SUCCESS = 'update_wallet_success';
export const GET_BALANCES_SUCCESS = 'get_balances_success';
export const GET_BLUEPRINTS_SUCCESS = 'get_blueprints_success';
export const LOAD_MORE_BLUEPRINTS_SUCCESS = 'load_more_blueprints_success';
export const GET_SELECTED_BLUEPRINTS_SUCCESS = 'get_selected_blueprints_success';
export const LOAD_MORE_SELECTED_BLUEPRINTS_SUCCESS = 'load_more_blueprints_success';
export const UNLINK_WALLET_SUCCESS = 'unlink_wallet_success';
export const GET_SUPPLY_SUCCESS = 'get_supply_success';

// User Wallets
export const GET_ALL_USER_WALLETS_SUCCESS = 'get_all_user_wallets_success';
export const LOAD_MORE_USER_WALLETS_SUCCESS = 'load_more_user_wallets_success';
export const GET_USER_WALLET_SUCCESS = 'get_user_wallet_success';
export const ADD_USER_WALLET_SUCCESS = 'add_user_wallet_success';
export const USER_WALLET_CREATED = 'user_wallet_created';
export const DELETE_USER_WALLET_SUCCESS = 'delete_user_wallet_success';
export const UPDATE_USER_WALLET_SUCCESS = 'update_user_wallet_success';
export const GET_USER_BALANCES_SUCCESS = 'get_user_balances_success';
export const UNLINK_USER_WALLET_SUCCESS = 'unlink_user_wallet_success';
export const CLEAR_USER_LINKING_DIALOGUE = 'clear_user_linking_dialogue';

//// EnjinApps Const

export const GET_ALL_ENJINAPPS = "get_all_enjinapps";
export const GET_ALL_ENJINAPPS_SUCCESS = "get_all_enjinapps_success";
export const LOAD_MORE_ENJINAPPS_SUCCESS = "load_more_enjinapps_success";
export const GET_ENJINAPP = "get_enjinapp";
export const GET_ENJINAPP_SUCCESS = "get_enjinapp_success";
export const LINK_ENJINAPP = "link_enjinapp";
export const LINK_ENJINAPP_SUCCESS = "link_enjinapp_success";
export const UPDATE_ENJINAPP = "update_enjinapp";
export const UPDATE_ENJINAPP_SUCCESS = "update_enjinapp_success";
export const DELETE_ENJINAPP = "delete_enjinapp";
export const DELETE_ENJINAPP_SUCCESS = "delete_enjinapp_success";
export const CLEAR_LINKING_DIALOGUE_ENJINAPP = "clear_linking_dialogue_enjinapp";

export const ENJAPP_REFRESHED = 'enjapp_refreshed';
export const ENJAPPS_REFRESHED = 'enjapps_refreshed';

//// Loading Const
export const SHOW_IS_FETCHING = 'show_is_fetching';
export const HIDE_IS_FETCHING = 'hide_is_fetching';
export const SHOW_LOCAL_FETCHING = 'show_local_fetching';
export const HIDE_LOCAL_FETCHING = 'hide_local_fetching';
export const SHOW_FETCHING_SUCCESS = 'show_fetching_success';
export const SHOW_TRANSPARENT_FETCHING = 'show_transparent_fetching';
export const HIDE_TRANSPARENT_FETCHING = 'hide_transparent_fetching';

export const SHOW_MAIN_LOADER = 'show_main_loader';
export const HIDE_MAIN_LOADER = 'hide_main_loader';
export const SHOW_APP_LOADER = 'show_app_loader';
export const HIDE_APP_LOADER = 'hide_app_loader';
export const SHOW_MINI_LOADER = 'show_mini_loader';
export const HIDE_MINI_LOADER = 'hide_mini_loader';
export const SHOW_DASHBOARD_LOADER = 'show_dashboard_loader';
export const HIDE_DASHBOARD_LOADER = 'hide_dashboard_loader';
export const SHOW_DIALOGUE_LOADER = 'show_dialogue_loader';
export const HIDE_DIALOGUE_LOADER = 'hide_dialogue_loader';
export const SHOW_TRANSPARENT_LOADER = 'show_transparent_loader';
export const HIDE_TRANSPARENT_LOADER = 'hide_transparent_loader';
export const SHOW_SCROLL_LOADER = 'show_scroll_loader';
export const HIDE_SCROLL_LOADER = 'hide_scroll_loader';
export const SHOW_LOADER_CREATE_SUCCESS = 'show_loader_create_success';
export const SHOW_LOADER_DELETE_SUCCESS = 'show_loader_delete_success';
export const SHOW_LOADER_UPDATE_SUCCESS = 'show_loader_update_success';

export const VIEW_ALERT_MSG = "view_alert_msg";
export const HIDE_ALERT_MSG = "hide_alert_msg";

//// Navigation
export const SET_TAB_VALUE = 'set_tab_value';
export const SET_ASSET_TYPE = 'set_asset_type';

export const OPEN_HELP_SECTION = 'open_help_section';
export const OPEN_HELP_OVERVIEW = 'open_help_overview';
export const OPEN_HELP_TOKENS = 'open_help_tokens';
export const OPEN_HELP_RESOURCES = 'open_help_resources';
export const OPEN_HELP_UTILITIES = 'open_help_utilities';

export const LOAD_EXPLORER_SUCCESS = 'load_explorer_success';


///// SEARCH
export const SEARCH_STRING = 'search_string';
export const SEARCH_STRING_SUCCESS = 'search_string_success';

///// ASSETS
export const GET_ASSET_SUCCESS = 'get_asset_success';
export const ASSETS_REFRESHED = 'assets_refreshed';
export const ASSET_REFRESHED = 'asset_refreshed'

///// SCROLL
export const GET_NEXT_PAGE = 'get_next_page';
export const GET_NEXT_PAGE_DIALOGUE = 'get_next_page_dialogue';
export const IS_LOADING_MORE = 'is_loading_more';

///// PAGEINATE
export const SET_NEXT_PAGE_ORDERS = 'set_next_page_orders';
export const SET_NEXT_PAGE_TX = 'set_next_page_tx';