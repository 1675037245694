import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'store'
import { DetailPair } from './sharedComponents/detailPair/detailPair'

interface IsProps {
  id: string | number
}

export const TokenDetails = ({ id }: IsProps) => {
  const data = useSelector(
    (state: AppState) => state.networkVisualizerState.general.data.tokens[id]
  )

  let {
    appName,
    meltValue,
    meltFeeRatio,
    supplyModel,
    totalSupply,
    circulatingSupply,
    transferable,
    transferFee,
    platformName,
  } = data

  return (
    <>
      <DetailPair title='Platform' value={platformName} />
      <DetailPair title='App Name' value={appName} />
      <DetailPair
        title='Melt Value'
        value={meltValue ? `${meltValue} enj` : null}
      />
      <DetailPair title='Supply Model' value={supplyModel} />
      <DetailPair title='Circulating Supply' value={circulatingSupply} />
      <DetailPair title='Total Supply' value={totalSupply} />
      <DetailPair
        title='Melt Fee'
        value={meltFeeRatio ? `${meltFeeRatio}%` : null}
      />
      <DetailPair title='Transferable' value={transferable} />
      <DetailPair
        title='Transfer Fee'
        value={transferFee ? `${transferFee} enj` : null}
      />
    </>
  )
}
