import { CategoryTypes } from 'components/networkVisualizer/networkVisualizerState/sharedTypes'
import React from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { Cooperations } from './components/sharedComponents/cooperations/cooperations'
import { ProjectDetails } from './components/_projectDetails'
import { ResourceDetails } from './components/_resourceDetails'
import { TokenDetails } from './components/_tokenDetails'
import { UtilityDetails } from './components/_utilityDetails'

interface IsProps {
  id: string | number
  type: CategoryTypes
}

export const Body = ({ id, type }: IsProps) => {
  return (
    <div className='body-wrapper'>
      <div className='body-container'>
        <Scrollbars
          autoHide
          autoHideDuration={150}
          renderTrackVertical={VerticalTrack}
          renderThumbVertical={VerticalThumb}
          className='body-scrollbar'
        >
          <div className='body-scrollbar-container'>
            {type === 'tokens' && <TokenDetails {...{ id }} />}
            {type === 'utilities' && <UtilityDetails {...{ id }} />}
            {type === 'resources' && <ResourceDetails {...{ id }} />}
            {type === 'projects' && <ProjectDetails {...{ id }} />}
            <Cooperations />
          </div>
        </Scrollbars>
      </div>
    </div>
  )
}

const VerticalTrack = () => <div className='body-track' />
const VerticalThumb = () => <div className='body-thumb' />
