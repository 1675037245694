import { nextIcon } from 'components/networkVisualizer/assets/icons'
import {
  changeVisibleConnectionsSet,
  disableGraphNextButton,
} from 'components/networkVisualizer/networkVisualizerState/store/graph/actions'
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { AppState } from 'store'

export const totalExpectedSetsSelector = createSelector(
  (state: AppState) => state.networkVisualizerState.graph.selectedNode,
  (state: AppState) => state.networkVisualizerState.graph.maxVisibleConnections,
  (state: AppState) => state.networkVisualizerState.general.data,
  (state: AppState) => state.networkVisualizerState.graph.filter,
  ({ id, type }, maxVisibleConnections, data, filter) => {
    const itemData = data[type][id] ?? null

    let totalConnections = 0

    if (itemData) {
      let { cooperationsCount, connections } = itemData

      if (cooperationsCount) {
        for (let k of Object.keys(cooperationsCount)) {
          if (filter[k]) {
            totalConnections += cooperationsCount[k]
          }
        }
      }
      //When fetching initial asset data, a project array is assigned if there is a project or not so this can be used as a conditional. If data formatters are changed, check back on this. Projects are not included in cooperationsCount.
      if (itemData.connections?.projects?.length ?? 0 > 0) {
        totalConnections += 1
      }
    }

    let totalSets = Math.ceil(totalConnections / maxVisibleConnections)

    return totalSets || 1
  }
)

export const currentConnectionsIndexSelector = createSelector(
  totalExpectedSetsSelector,
  (state: AppState) =>
    state.networkVisualizerState.graph.visibleConnectionsIndex,
  (total, current) => (current > total - 1 ? total - 1 : current)
)

export const ConnectionsNav = React.memo(() => {
  const dispatch = useDispatch()

  const onClickThrottle = useRef<NodeJS.Timeout | null>(null)

  const selectedNodeType = useSelector(
    (state: AppState) => state.networkVisualizerState.graph.selectedNode.type
  )

  const currentConnectionsIndex = useSelector(
    (state: AppState) =>
      state.networkVisualizerState.graph.visibleConnectionsIndex
  )

  const totalExpectedSets = useSelector(totalExpectedSetsSelector)

  const disableNextButton = useSelector(
    (state: AppState) => state.networkVisualizerState.graph.disableNextButton
  )

  let disableNext =
    disableNextButton ||
    (selectedNodeType !== 'wallets' &&
      currentConnectionsIndex + 1 >= totalExpectedSets)

  const onNextPress = () => {
    if (!disableNext) {
      if (onClickThrottle.current === null) {
        onClickThrottle.current = setTimeout(() => {
          clearTimeout(onClickThrottle.current!)
          onClickThrottle.current = null
        }, 1500)
        dispatch(changeVisibleConnectionsSet(currentConnectionsIndex + 1))
      }
    }
  }

  const onPreviousPress = () => {
    if (currentConnectionsIndex > 0) {
      if (onClickThrottle.current === null) {
        onClickThrottle.current = setTimeout(() => {
          clearTimeout(onClickThrottle.current!)
          onClickThrottle.current = null
        }, 1500)
        dispatch(changeVisibleConnectionsSet(currentConnectionsIndex - 1))
        dispatch(disableGraphNextButton(false)) //Reset the graph next button when going back (required for wallets, should not cause any updates for other asset types). If not reset here, the next button is not activated again from anywhere else.
      }
    }
  }

  useEffect(() => {
    return () => {
      if (onClickThrottle.current) clearTimeout(onClickThrottle.current)
    }
  }, [])

  return (
    <div className='connections-nav-wrapper'>
      <div className='nav-button previous' onClick={onPreviousPress}>
        <img src={nextIcon} alt='previous' />
      </div>
      <div className='text-container'>
        <p className='set-count'>{`${currentConnectionsIndex + 1}${
          selectedNodeType === 'wallets' ? '' : ' / ' + totalExpectedSets
        }`}</p>
      </div>
      <div
        className={`nav-button next ${disableNext ? 'disabled' : ''}`}
        onClick={onNextPress}
      >
        <img src={nextIcon} alt='next' />
      </div>
    </div>
  )
})
