import { loadExplorerSuccess_, setInitUrl } from 'actions'
import { hideAppLoader_, showAppLoader_ } from 'actions/IsFetching'
import { AppLoaderFull } from 'components/Loader/AppLoaderFull'
import { NetworkVisualizer } from 'components/networkVisualizer/networkVisualizer'
import { openNetworkVisualizer } from 'components/networkVisualizer/networkVisualizerState/store/general/actions'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import HomeNavBar from './HomeNavBar'
import TypeForm from './TypeForm'

const Explore = (props) => {
  const dispatch = useDispatch()

  const { initURL, explorerLoaded } = useSelector(({ auth }) => auth)

  useEffect(() => {
    let timer
    if (!explorerLoaded) {
      dispatch(showAppLoader_())
      timer = setTimeout(() => {
        dispatch(hideAppLoader_())
        dispatch(loadExplorerSuccess_(true))
      }, 1800)
    }

    dispatch(openNetworkVisualizer(1, 'projects'))

    if (initURL !== '/explore') {
      dispatch(setInitUrl('/explore'))
    }

    return () => clearTimeout(timer)
  }, [])

  return (
    <div className='app-wrapper'>
      <NetworkVisualizer />
      <AppLoaderFull explorer={true} />
      <HomeNavBar />
      {window.innerWidth > 500 && <TypeForm />}
    </div>
  )
}

export default withRouter(Explore)
