import {
    GET_ALL_COOPERATIONS_SUCCESS,
    CREATE_COOPERATION_SUCCESS,
    RATE_COOPERATION_SUCCESS,
    DELETE_COOPERATION_WITH_UTILITY_SUCCESS,
    DELETE_COOPERATION_WITH_RESOURCE_SUCCESS,
    UPDATE_COOPERATION_WITH_UTILITY_SUCCESS,
    UPDATE_COOPERATION_WITH_RESOURCE_SUCCESS,
    COOPS_REFRESHED,
    COOP_REFRESHED
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    cooperationList: [],
    coopRefreshed: false,
    coopsRefreshed: false
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_COOPERATIONS_SUCCESS: {
            return {
                ...state,
                cooperationList: action.payload
            }
        }
        case CREATE_COOPERATION_SUCCESS: {
            return {
                ...state
            }
        }
        case RATE_COOPERATION_SUCCESS: {
            return {
                ...state
            }
        }
        case DELETE_COOPERATION_WITH_UTILITY_SUCCESS: {
            return {
                ...state
            }
        }
        case DELETE_COOPERATION_WITH_RESOURCE_SUCCESS: {
            return {
                ...state
            }
        }
        case UPDATE_COOPERATION_WITH_UTILITY_SUCCESS: {
            return {
                ...state
            }
        }
        case UPDATE_COOPERATION_WITH_RESOURCE_SUCCESS: {
            return {
                ...state,
                loader: false
            }
        }
        case COOPS_REFRESHED: {
            return {
                ...state,
                coopsRefreshed: action.payload
            }
        }
        case COOP_REFRESHED: {
            return {
                ...state,
                coopRefreshed: action.payload
            }
        }
        default:
            return state;
    }
}