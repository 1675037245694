
import tokenIcon from 'assets/images/token_icon.png';
import utilityIcon from 'assets/images/utility_icon.png';
import resourceIcon from 'assets/images/resource_icon.png';
import projectIcon from 'assets/images/project_icon.png';

export const formatSearchResults = async(searchResults) => {
    console.log(searchResults);
    let formattedTokens = [];
    if (searchResults.tokens.length) {
        await searchResults.tokens.map((result) => 
            formattedTokens.push({
                id: result.tokenId,
                platform: result.platformId,
                name: result.name,
                avatarImage: result.metadata ? result.metadata.image : tokenIcon,
                projectName: result.project?.name || "Unknown",
                type: "tokens",
                typeName: "Token Blueprint",
                color : "#2196f3"
            })
        )}
    let formattedTokensById = [];
    if (searchResults.tokensById.length) {
        await searchResults.tokensById.map((result) => 
            formattedTokensById.push({
                id: result.tokenId,
                name: result.name,
                avatarImage: result.metadata ? result.metadata.image : tokenIcon,
                projectName: result.project?.name || "Unknown",
                type: "tokens",
                typeName: "Token Blueprint",
                color : "#2196f3"
            })
        )}
    let formattedUtilities = [];
    if (searchResults.utilities.length) {
        await searchResults.utilities.map((result) => 
            formattedUtilities.push({
                id: result.utilityId,
                name: result.name,
                avatarImage: result.avatarImage ? result.avatarImage.thumbnail : utilityIcon,
                projectName: result.project.name,
                type: "utilities",
                typeName: "Utility",
                color : "#e91e63"
            })
        )}

    let formattedResources = [];
    if (searchResults.resources.length) {
        await searchResults.resources.map((result) => 
            formattedResources.push({
                id: result.resourceId,
                name: result.name,
                avatarImage: result.avatarImage ? result.avatarImage.thumbnail : resourceIcon,
                projectName: result.project.name,
                type: "resources",
                typeName: "Resource",
                color : "#ff9800"
            })
        )}
    
    let formattedProjects = [];
    if (searchResults.projects.length) {
        await searchResults.projects.map((result) => 
            formattedProjects.push({
                id: result.projectId,
                name: result.name,
                avatarImage: result.avatarImage ? result.avatarImage.thumbnail : projectIcon,
                projectName: result.creatorId,
                type: "projects",
                typeName: "Project",
                color : "#797979ff"
                
            })
        )}

    const formattedResults = formattedTokens.concat(formattedUtilities, formattedResources, formattedProjects, formattedTokensById);
    
    // console.log("formattedTokens: ", formattedResults);

    // const formattedResults = tokenBlueprints.concat(array2, array4);
    return formattedResults;
    
}