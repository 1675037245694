import {
  fileIcon,
  linkIcon,
  modelIcon,
} from 'components/networkVisualizer/assets/icons'
import { defaultImages } from 'components/networkVisualizer/assets/variables/defaultImages'
import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'store'
import { AssetNameAndType, DisplayImage, ItemDetail } from './sharedComponents'

interface IsProps {
  id: string | number
}

export const ResourcePopup = ({ id }: IsProps) => {
  const data = useSelector(
    (state: AppState) => state.networkVisualizerState.general.data.resources[id]
  )

  let { image, name, fileType, modelType, projectName } = data

  return (
    <div className='popup-container'>
      <DisplayImage image={image || defaultImages.resources} alt={name} />
      <div className='body-container'>
        <AssetNameAndType name={name} type={'resource'} />

        <ItemDetail
          visible={projectName !== undefined && projectName !== null}
          image={linkIcon}
          imageAlt='project name'
        >
          <p className='secondary-text'>
            Project
            <span> {projectName}</span>
          </p>
        </ItemDetail>
        <ItemDetail
          visible={fileType !== undefined && fileType !== null}
          image={fileIcon}
          imageAlt='file type'
        >
          <p className='secondary-text'>
            File type <span>{fileType}</span>
          </p>
        </ItemDetail>
        <ItemDetail
          visible={modelType !== undefined && modelType !== null}
          image={modelIcon}
          imageAlt='model type'
        >
          <p className='secondary-text'>
            Model type
            <span> {modelType}</span>
          </p>
        </ItemDetail>
      </div>
    </div>
  )
}
