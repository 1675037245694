import {
    SHOW_IS_FETCHING,
    HIDE_IS_FETCHING,
    SHOW_FETCHING_SUCCESS,
    SHOW_FETCHING_ERROR,
    SHOW_LOCAL_FETCHING,
    HIDE_LOCAL_FETCHING,
    SHOW_TRANSPARENT_FETCHING,
    HIDE_TRANSPARENT_FETCHING,
    SHOW_TRANSPARENT_LOADER,
    HIDE_TRANSPARENT_LOADER,
    SHOW_APP_LOADER,
    HIDE_APP_LOADER,
    SHOW_MAIN_LOADER,
    HIDE_MAIN_LOADER,
    SHOW_DIALOGUE_LOADER,
    HIDE_DIALOGUE_LOADER,
    SHOW_DASHBOARD_LOADER,
    HIDE_DASHBOARD_LOADER,
    SHOW_LOADER_CREATE_SUCCESS,
    SHOW_LOADER_DELETE_SUCCESS,
    SHOW_LOADER_UPDATE_SUCCESS,
    VIEW_ALERT_MSG,
    GET_NEXT_PAGE,
    SHOW_SCROLL_LOADER,
    HIDE_SCROLL_LOADER,
    GET_NEXT_PAGE_DIALOGUE,
    SET_NEXT_PAGE_ORDERS,
    SET_NEXT_PAGE_TX,
    SHOW_MINI_LOADER,
    HIDE_MINI_LOADER
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    loaderResult: '',
    localLoader: false,
    // neu
    appLoader: false,
    mainLoader: false,
    miniLoader: false,
    dashboardLoader: false,
    dialogueLoader: false,
    transparentLoader: false,
    loaderCreateResult: {},
    loaderUpdateResult: {},
    loaderDeleteResult: {},
    viewMessage: false,
    alertMessage: '',
    newPage: 0,
    newPageDialogue: 0,
    newPageOrders: 0,
    newPageTx: 0,
    isLoadingMore: false,
    scrollLoader: false

    
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SHOW_IS_FETCHING: {
            return {
                ...state,
                loader: true
            }
        }
        case HIDE_IS_FETCHING: {
            return {
                ...state,
                loader: false
            }
        }
        case SHOW_LOCAL_FETCHING: {
            return {
                ...state,
                localLoader: true,
                dialogueLoader: true
            }
        }
        case HIDE_LOCAL_FETCHING: {
            return {
                ...state,
                localLoader: false,
                dialogueLoader: false
            }
        }
        case SHOW_TRANSPARENT_LOADER: {
            return {
                ...state,
                transparentLoader: true
            }
        }
        case HIDE_TRANSPARENT_LOADER: {
            return {
                ...state,
                transparentLoader: false
            }
        }
        case SHOW_APP_LOADER: {
            return {
                ...state,
                appLoader: true
            }
        }
        case HIDE_APP_LOADER: {
            return {
                ...state,
                appLoader: false
            }
        }

        case SHOW_MINI_LOADER: {
            return {
                ...state,
                miniLoader: true
            }
        }
        case HIDE_MINI_LOADER: {
            return {
                ...state,
                miniLoader: false
            }
        }

        case SHOW_MAIN_LOADER: {
            return {
                ...state,
                mainLoader: true
            }
        }
        case HIDE_MAIN_LOADER: {
            return {
                ...state,
                mainLoader: false
            }
        }

        case SHOW_DIALOGUE_LOADER: {
            return {
                ...state,
                localLoader: true,
                dialogueLoader: true
            }
        }
        case HIDE_DIALOGUE_LOADER: {
            return {
                ...state,
                localLoader: false,
                dialogueLoader: false
            }
        }

        case SHOW_DASHBOARD_LOADER: {
            return {
                ...state,
                dashboardLoader: true
            }
        }
        case HIDE_DASHBOARD_LOADER: {
            return {
                ...state,
                dashboardLoader: false
            }
        }
        case SHOW_SCROLL_LOADER: {
            return {
                ...state,
                scrollLoader: true
            }
        }
        case HIDE_SCROLL_LOADER: {
            return {
                ...state,
                scrollLoader: false
            }
        }
        case SHOW_FETCHING_SUCCESS: {
            return {
                ...state,
                loaderResult: action.payload
            }
        }
        case SHOW_LOADER_CREATE_SUCCESS: {
            return {
                ...state,
                loaderCreateResult: action.payload
            }
        }

        case SHOW_LOADER_DELETE_SUCCESS: {
            return {
                ...state,
                loaderDeleteResult: action.payload
            }
        }

        case SHOW_LOADER_UPDATE_SUCCESS: {
            return {
                ...state,
                loaderUpdateResult: action.payload
            }
        }

        case VIEW_ALERT_MSG: {
            return {
                ...state,
                viewMessage: true,
                alertMessage: action.payload
            }
        }

        case GET_NEXT_PAGE: {
            return {
                ...state,
                newPage: action.payload
            }
        }

        case GET_NEXT_PAGE_DIALOGUE: {
            return {
                ...state,
                newPageDialogue: action.payload
            }
        }

        case SET_NEXT_PAGE_ORDERS: {
            return {
                ...state,
                newPageOrders: action.payload
            }
        }

        case SET_NEXT_PAGE_TX: {
            return {
                ...state,
                newPageTx: action.payload
            }
        }
        default:
            return state;
    }
}