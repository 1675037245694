import { colors } from 'components/networkVisualizer/assets/styles/variables'
import { defaultImages } from 'components/networkVisualizer/assets/variables/defaultImages'
import { GraphTypes } from 'components/networkVisualizer/networkVisualizerState/sharedTypes'
import React, { SyntheticEvent } from 'react'

/**
 * Utilities and Resources are SVG elements while Tokens and Projects are simple CSS shapes.
 *imageAlt and id attribute are important specifically for Utilities and Resources as the 'pattern' svg element relies on a unique id to be used as a background image.
 *Only when the noImage prop is true does an image not get rendered. All other cases (including undefined or null images) will fallback to a default image for each asset type. (This is currently only being utilized for the graph filter.)
 */

interface ShapeProps {
  imageAlt: string
  image?: string | null
  id: string | number
  noImage?: boolean
}

interface ImageWithFallbackProps extends Omit<ShapeProps, 'noImage' | 'id'> {
  defaultImage: string
}

//To handle image's that don't get resolved by replacing them with a default image.
const ImageWithFallback = ({
  image,
  imageAlt,
  defaultImage,
}: ImageWithFallbackProps) => {
  const onError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = defaultImage
    e.currentTarget.alt = 'Default'
  }

  return <img src={image ?? defaultImage} alt={imageAlt} onError={onError} />
}

const SVGBackgroundGradient = ({ id }: { id: string }) => (
  <linearGradient id={id} x1='0%' y1='0%' x2='100%' y2='100%'>
    <stop offset='0%' stopColor='#72C0E7FF' stopOpacity='1' />
    <stop offset='100%' stopColor='#A9E2EDFF' stopOpacity='1' />
  </linearGradient>
)

const TokenShape = ({ imageAlt, image, noImage }: ShapeProps) => (
  <div className='item-shape-wrapper token'>
    <div className={`item-image-container${noImage ? ' no-image' : ''}`}>
      {!noImage && (
        <ImageWithFallback
          {...{ image, imageAlt }}
          defaultImage={defaultImages.tokens}
        />
      )}
    </div>
  </div>
)

const ProjectShape = ({ imageAlt, image, noImage }: ShapeProps) => (
  <div className='item-shape-wrapper project'>
    <div className={`item-image-container${noImage ? ' no-image' : ''}`}>
      {!noImage && (
        <ImageWithFallback
          {...{ image, imageAlt }}
          defaultImage={defaultImages.projects}
        />
      )}
    </div>
  </div>
)

const WalletShape = ({ imageAlt, image, noImage }: ShapeProps) => (
  <div className='item-shape-wrapper wallet'>
    <div className={`item-image-container${noImage ? ' no-image' : ''}`}>
      {!noImage && (
        <ImageWithFallback
          {...{ image, imageAlt }}
          defaultImage={defaultImages.wallet}
        />
      )}
    </div>
  </div>
)

interface SVGProps extends ShapeProps {
  strokeWidth?: number
}

function imageIdCreator(type: string, id: string | number) {
  //Required to create an unique ID as some of the imageAlt values include spaces and will not work as an ID.
  //2021-04-22 Update! Using the imageAlt to create the unique ID was not working when the name (imageAlt) included special characters (ie. Beard's) since special characters can't be used as reference ID's.
  return type + '-' + id + 'background-image'
}

const UtilityShape = ({
  imageAlt,
  image,
  id,
  noImage,
  strokeWidth = 4,
}: SVGProps) => {
  let imageId = imageIdCreator('utility', id)
  const gradientId = `utility-background-gradient`
  let defaultImage = defaultImages.utilities

  return (
    <div className='item-shape-wrapper utility'>
      <svg
        viewBox='0 0 100 100'
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
      >
        <defs>
          <pattern
            id={imageId}
            height='100%'
            width='100%'
            patternContentUnits='objectBoundingBox'
          >
            {!noImage && (
              <image
                height='1'
                width='1'
                preserveAspectRatio='xMidYMid slice'
                href={image ?? defaultImage}
              />
            )}
            <SVGBackgroundGradient id={gradientId} />
          </pattern>
        </defs>
        <polygon
          //Duplicate of below polygon to provide as a background color for images as some images have a transparent background.
          points='25,8 75,8 100,50 75,92 25,92 0,50'
          fill={noImage ? colors.screenBg : `url(#${gradientId})`}
          strokeWidth={strokeWidth}
        />
        <polygon
          points='25,8 75,8 100,50 75,92 25,92 0,50'
          fill={noImage ? 'none' : `url(#${imageId})`}
          stroke={colors.utility}
          strokeWidth={strokeWidth}
          id='utility-polygon'
        />
      </svg>
    </div>
  )
}

const ResourceShape = ({
  imageAlt,
  image,
  id,
  noImage,
  strokeWidth = 6,
}: SVGProps) => {
  let imageId = imageIdCreator('resource', id)
  const gradientId = `resource-background-gradient`
  let defaultImage = defaultImages.resources

  return (
    <div className='item-shape-wrapper resource'>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 166.12 146.87'>
        <defs>
          <pattern
            id={imageId}
            height='100%'
            width='100%'
            patternContentUnits='objectBoundingBox'
          >
            {!noImage && (
              <image
                height='1'
                width='1'
                preserveAspectRatio='xMidYMid slice'
                href={image ?? defaultImage}
                // fill={colors.screenBg}
              />
            )}
            <SVGBackgroundGradient id={gradientId} />
          </pattern>
        </defs>
        <g id='aa2ee70b-e3bd-4c59-a5a0-4f179f881108' data-name='Layer 2'>
          <g id='fb19a45e-e609-46f4-8bfb-223639ba1a40' data-name='Layer 1'>
            <path
              //Duplicate of below path to provide as a background color for images as some images have a transparent background.
              d='M77.18,7.9,5.42,132.19a6.79,6.79,0,0,0,5.88,10.18H154.82a6.78,6.78,0,0,0,5.88-10.18L88.94,7.9A6.79,6.79,0,0,0,77.18,7.9Z'
              fill={noImage ? colors.screenBg : `url(#${gradientId})`}
              strokeMiterlimit='10'
              strokeWidth={strokeWidth}
            />
            <path
              d='M77.18,7.9,5.42,132.19a6.79,6.79,0,0,0,5.88,10.18H154.82a6.78,6.78,0,0,0,5.88-10.18L88.94,7.9A6.79,6.79,0,0,0,77.18,7.9Z'
              fill={!noImage ? `url(#${imageId})` : 'none'}
              stroke={colors.resource}
              strokeMiterlimit='10'
              strokeWidth={strokeWidth}
            />
          </g>
        </g>
      </svg>
    </div>
  )
}

type IsProps = ShapeProps &
  SVGProps & {
    type: GraphTypes
  }

export const ShapePicker = React.memo(
  ({ type, image, imageAlt, id, noImage, strokeWidth }: IsProps) => {
    switch (type) {
      case 'tokens':
        return <TokenShape {...{ noImage, image, imageAlt, id }} />
      case 'projects':
        return <ProjectShape {...{ noImage, image, imageAlt, id }} />
      case 'utilities':
        return (
          <UtilityShape {...{ noImage, image, imageAlt, id, strokeWidth }} />
        )
      case 'resources':
        return (
          <ResourceShape {...{ noImage, image, imageAlt, id, strokeWidth }} />
        )
      case 'wallets':
        return <WalletShape {...{ noImage, image, imageAlt, id }} />
    }
  }
)
