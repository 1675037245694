import { connectionIcon } from 'components/networkVisualizer/assets/icons'
import { defaultImages } from 'components/networkVisualizer/assets/variables/defaultImages'
import { ShapePicker } from 'components/networkVisualizer/components/sharedComponents/itemShapes/itemShapes'
import { ToolTip } from 'components/networkVisualizer/components/sharedComponents/toolTip/toolTip'
import { categoryTypeToSingular } from 'components/networkVisualizer/modules/stringManipulators'
import { CategoryTypes } from 'components/networkVisualizer/networkVisualizerState/sharedTypes'
import { changeSelectedNode } from 'components/networkVisualizer/networkVisualizerState/store/graph/actions'
import { hideItemDetails } from 'components/networkVisualizer/networkVisualizerState/store/itemDetails/actions'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

interface IsProps {
  id: number | string
  type: CategoryTypes
}

export const Header = ({ id, type }: IsProps) => {
  const dispatch = useDispatch()

  const data = useSelector(
    (state: AppState) => state.networkVisualizerState.general.data[type][id]
  )

  const changeSelectedItem = useCallback(() => {
    let platformId = data?.platformId
    dispatch(changeSelectedNode(id, type, platformId))
    dispatch(hideItemDetails())
  }, [id, type, dispatch, data.platformId])

  let { name } = data

  let image = data.image || defaultImages[type]

  return (
    <div className='header-wrapper'>
      <div className='header-image-container'>
        <ShapePicker {...{ id, type, image }} imageAlt={`${name} header`} />
      </div>
      <div className={`type-container`}>
        <h2 className={`type-text ${type}-color`}>
          {categoryTypeToSingular(type)}
        </h2>
      </div>
      <div className='text-container'>
        <div className='name-text-container'>
          <h1 className='header-text'>{name}</h1>
          {'nonFungible' in data && (
            <h2
              className={`subheader-text fungible-text ${data.nonFungible &&
                'non-fungible'}`}
            >
              {data.nonFungible ? ' (NFT)' : ' (FT)'}
            </h2>
          )}
        </div>
        <h2 className='subheader-text'>{`ID ${id}`}</h2>
      </div>
      <div className='view-connections-button-wrapper'>
        <ToolTip text='view connections' left />
        <div className='view-connections-button' onClick={changeSelectedItem}>
          <img src={connectionIcon} alt='connections' />
        </div>
      </div>
    </div>
  )
}
