import {
  changeSelectedNode,
  resetVisualizerGraphState,
} from 'components/networkVisualizer/networkVisualizerState/store/graph/actions'
import { all, fork, put, takeLatest } from 'redux-saga/effects'
import { SelectionData } from '../../sharedTypes'
import { resetVisualizerItemDetailsState } from '../itemDetails/actions'
import {
  resetVisualizerNavMenuState,
  showVisualizerNavMenu,
} from '../navMenu/actions'
import {
  resetVisualizerGeneralState,
  setNetworkVisualizerOpeningState,
  toggleNetworkVisualizer,
} from './actions'
import {
  CLOSE_NETWORK_VISUALIZER,
  OPEN_NETWORK_VISUALIZER,
  RESET_NETWORK_VISUALIZER,
} from './types'

//Initial opening of the network visualizer.
function* openNetworkVisualizerWatcher() {
  yield takeLatest(OPEN_NETWORK_VISUALIZER, openNetworkVisualizerFlow)
}

function* openNetworkVisualizerFlow({
  type,
  payload,
}: {
  type: typeof OPEN_NETWORK_VISUALIZER
  payload: SelectionData
}) {
  yield all([
    put(toggleNetworkVisualizer(true)),
    put(changeSelectedNode(payload.id, payload.type)),
    put(setNetworkVisualizerOpeningState(true)),
  ])

  if (window.innerWidth >= 768) {
    yield put(showVisualizerNavMenu())
  }
}

function* resetNetworkVisualizerWatcher() {
  yield takeLatest(RESET_NETWORK_VISUALIZER, resetNetworkVisualizerFlow)
}
//This will reset ALL the states in networkVisualizer
function* resetNetworkVisualizerFlow({
  type,
}: {
  type: typeof RESET_NETWORK_VISUALIZER
}) {
  yield all([
    put(resetVisualizerGeneralState()),
    put(resetVisualizerGraphState()),
    put(resetVisualizerItemDetailsState()),
    put(resetVisualizerNavMenuState()),
  ])
}

function* closeNetworkVisualizerWatcher() {
  yield takeLatest(CLOSE_NETWORK_VISUALIZER, closeNetworkVisualizerFlow)
}

function* closeNetworkVisualizerFlow({
  type,
}: {
  type: typeof CLOSE_NETWORK_VISUALIZER
}) {
  yield put(toggleNetworkVisualizer(false))
}

export function* generalSagas() {
  yield fork(openNetworkVisualizerWatcher)
  yield fork(resetNetworkVisualizerWatcher)
  yield fork(closeNetworkVisualizerWatcher)
}
