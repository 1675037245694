import React, { useEffect } from 'react';
import CustomScrollbars from 'util/CustomScrollbars';
import { useDispatch, useSelector } from 'react-redux';
import SearchResultCard from './SearchResultCard';
import { CardActionArea } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { searchStringSuccess_ } from 'actions';
import DialogueLoader from 'components/Loader/DialogueLoader';

const SearchResults = () => {

    const dispatch = useDispatch();

    const handleSelectSearchResult = () =>{
        dispatch(searchStringSuccess_(''));
    }

    const {searchResults} = useSelector(({search}) => search);
    // console.log("searchresults: ", searchResults);
    if (searchResults.length === undefined || searchResults.length === 0) return <DialogueLoader />

    return (
        <>
        <CustomScrollbars className="messages-list scrollbar" style={{height: 280}}>
            <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    Assets
                    <ul className="list-unstyled">
                        {searchResults.map((result, index) => {
                        return result.type !== "projects" ?
                            <CardActionArea  key={index} onClick={handleSelectSearchResult}>
                                <Link 
                                style={{ textDecoration: 'none' }}
                                to={{
                                    svariant:"contained", color:"primary",
                                    pathname: result.type === "tokens" ? `/app/assets/${result.type}/${result.platform}/${result.id}` : `/app/assets/${result.type}/1/${result.id}`, 
                                    data: {
                                        id: "id"
                                        }
                                    }}>
                                        <SearchResultCard result={result}/>
                                </Link>
                            </CardActionArea> : null
                        })}
                    </ul>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                    Projects
                    <ul className="list-unstyled">
                        {searchResults.map((result, index) => {
                        return result.type === "projects" ?
                            <CardActionArea  key={index} onClick={handleSelectSearchResult}>
                                <Link 
                                style={{ textDecoration: 'none' }}
                                to={{
                                    svariant:"contained", color:"primary",
                                    pathname: result.type === "tokens" ? `/app/${result.type}/${result.platform}/${result.id}` : `/app/${result.type}/${result.id}`, 
                                    data: {
                                        id: "id"
                                        }
                                    }}>
                                        <SearchResultCard result={result}/>
                                </Link>
                            </CardActionArea> : null
                        })}
                    </ul>
                </div>
            </div>
            
        </CustomScrollbars>
        </>
    )
};

export default SearchResults;