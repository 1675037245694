import React, {useEffect, useState} from "react";
import {Link, withRouter} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import {COLLAPSED_DRAWER, FIXED_DRAWER} from "constants/ActionTypes";
import SearchBox from "components/SearchBox";
import MailNotification from "../../../../components/MailNotification";
import AppNotification from "../../../../components/AppNotification";
import CardHeader from "components/dashboard/Common/CardHeader/index";
import {switchLanguage, toggleCollapsedNav} from "actions/Setting";
import IntlMessages from "util/IntlMessages";
import LanguageSwitcher from "components/LanguageSwitcher/index";
import { getAllTokenBlueprints_ } from "actions/Token";
import { searchString_ } from "actions";
import {Dropdown, DropdownMenu, DropdownToggle, Popover} from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import SearchModule from './SearchModule';
import UserInfo from "components/UserInfo";
import { getAllNotifications_, notificationsRefreshed_, updateNotificationStatus_ } from "actions/Notification";
import { showLoaderUpdateSuccess_ } from "actions/IsFetching";
import { Button } from "@material-ui/core";
import { openNetworkVisualizer } from "components/networkVisualizer/networkVisualizerState/store/general/actions";

let timeout;
function sleep(delay = 0) {
    return new Promise((resolve) => {
      timeout = setTimeout(resolve, delay);
    });
  }

  const useStyles = makeStyles((theme) => ({
    typography: {
      padding: theme.spacing(2),
    },
  }));

  

const Index = (props) => {



    const JWToken = localStorage.getItem("idToken");
    const userId = localStorage.getItem('userId');

    const dispatch = useDispatch();
    const {drawerType, locale, navCollapsed} = useSelector(({settings}) => settings);
    const {notificationList, notificationsRefreshed} = useSelector(({notification})=>notification);
    const {authUser} = useSelector(({auth})=>auth);

    const [langSwitcher, setLangSwitcher] = useState(false);
    const [mailNotification, setMailNotification] = useState(false);
    const [appNotification, setAppNotification] = useState(false);
    const [newNotifications, setNewNotifications] = useState(false);
    const [searchBox, setSearchBox] = useState(true);
    const [apps, setApps] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchResultContainer, setSearchResultContainer] = useState(false)

    const limit = 10;
    // const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);


    const onAppNotificationSelect = () => {
        setAppNotification(!appNotification)
    };

    const onSearchFieldSelect = () => {
        setSearchResultContainer(!searchResultContainer)
    };

    const onMailNotificationSelect = () => {
        setMailNotification(!mailNotification)
    };
    const onLangSwitcherSelect = (event) => {
        setLangSwitcher(!langSwitcher);
    };

    const onAppsSelect = () => {
        setApps(!apps)
    };

    const handleRequestClose = () => {
        setSearchBox(false);
        setLangSwitcher(false);
        setMailNotification(false);
        setSearchBox(false);
        setApps(false);
    };

    const onToggleCollapsedNav = (e) => {
        const val = !navCollapsed;
        dispatch(toggleCollapsedNav(val));
        
    };

    useEffect(() => {
        if (notificationList && notificationList.some(notification => notification.isRead === false)) {
            setNewNotifications("icon-alert");
        }  else {
            setNewNotifications("");
        }

        if (notificationsRefreshed) {
            dispatch(notificationsRefreshed_(false));
            dispatch(getAllNotifications_({JWToken, querryParams:{limit: limit, page:1, userId: userId}, noLoader: true}));
        }

        return () => {
            clearTimeout(timeout);
        }
    }, [notificationList, notificationsRefreshed])

    
    const Apps = () => {
        
        return (
            <ul className="jr-list jr-list-half">
                <li className="jr-list-item">
                    <Link className="jr-list-link" to="/app/calendar/basic">
                        <i className="zmdi zmdi-calendar zmdi-hc-fw"/>
                        <span className="jr-list-text"><IntlMessages id="sidebar.calendar.basic"/></span>
                    </Link>
                </li>

                <li className="jr-list-item">
                    <Link className="jr-list-link" to="/app/to-do">
                        <i className="zmdi zmdi-check-square zmdi-hc-fw"/>
                        <span className="jr-list-text"><IntlMessages id="sidebar.appModule.toDo"/></span>
                    </Link>
                </li>

                <li className="jr-list-item">
                    <Link className="jr-list-link" to="/app/mail">
                        <i className="zmdi zmdi-email zmdi-hc-fw"/>
                        <span className="jr-list-text"><IntlMessages id="sidebar.appModule.mail"/></span>
                    </Link>
                </li>

                <li className="jr-list-item">
                    <Link className="jr-list-link" to="/app/chat">
                        <i className="zmdi zmdi-comment zmdi-hc-fw"/>
                        <span className="jr-list-text"><IntlMessages id="sidebar.appModule.chat"/></span>
                    </Link>
                </li>

                <li className="jr-list-item">
                    <Link className="jr-list-link" to="/app/contact">
                        <i className="zmdi zmdi-account-box zmdi-hc-fw"/>
                        <span className="jr-list-text"><IntlMessages id="sidebar.appModule.contact"/></span>
                    </Link>
                </li>

                <li className="jr-list-item">
                    <Link className="jr-list-link" to="/">
                        <i className="zmdi zmdi-plus-circle-o zmdi-hc-fw"/>
                        <span className="jr-list-text">Add New</span>
                    </Link>
                </li>
            </ul>);
    };

    let timeOut;
    const updateSearchText = (evt) => {

        setSearchBox(true);

        
        setSearchText(evt.target.value);

        let querryParams = {
            name: searchText,
            limit: 50,
            page: 1
        }
        timeOut = setTimeout(() => {
            dispatch(searchString_({searchText, JWToken, querryParams}));
        }, 500);
        

        

    };

    const onSwitchLanguage = (lang) => {
        dispatch(switchLanguage(lang))
    };

    const onNotificationsCollapse = () => {
        dispatch(updateNotificationStatus_({JWToken, isRead: true}));
    }

    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? "d-block d-xl-none" : drawerType.includes(COLLAPSED_DRAWER) ? "d-block" : "d-none";

    return (
        <AppBar className="app-main-header">

            <Toolbar className="app-toolbar" disableGutters={false}>

                <IconButton className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu"
                            onClick={onToggleCollapsedNav}>
                    <span className="menu-icon"/>
                </IconButton>

                <Link className="app-logo mr-2 d-none d-sm-block" to="/app/home">
                    <img src={require("assets/images/logo_navbar.svg")} alt="Jambo" title="Jambo"/>
                </Link>

                <Button 
                    onClick = {() => {

                        dispatch(openNetworkVisualizer(authUser.profile.projects[0]?.project.projectId || 334, "projects"));
                    }}    
                    variant="contained" className="jr-btn bg-secondary text-white ml-2">
                    Explorer
                </Button>
                

                <SearchModule />


                

    

                
                <div className="d-flex flex-row align-items-center ml-auto mr-1">
                    {/*<div className="pl-1 pr-1" style={{borderRight: "solid 1px gray"}}>
                        <div className={`badge badge-secondary badge-net text-white`} style={{marginBottom: "0px"}}>Kovan</div>
                    </div>*/}
                    {process.env.REACT_APP_CHAIN === "testnet" ?
                    <div className="pl-1 pr-1">
                        <div className={`badge badge-nobg badge-net text-white`} style={{marginBottom: "0px"}}>testnet</div>
                    </div>: null}
                </div> 
                


                <ul className="header-notifications list-inline">
                    {/*<li className="list-inline-item">
                        <Dropdown
                            className="quick-menu app-notification"
                            isOpen={apps}
                            toggle={onAppsSelect}>

                            <DropdownToggle
                                className="d-inline-block"
                                tag="span"
                                data-toggle="dropdown">
                  <span className="app-notification-menu">
                    <i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg"/>
                    <span>Apps</span>
                  </span>
                            </DropdownToggle>

                            <DropdownMenu>
                                {Apps()}
                            </DropdownMenu>
                        </Dropdown>
                    </li>*/}
                    {/*<li className="d-inline-block d-lg-none list-inline-item">
                        <Dropdown
                            className="quick-menu nav-searchbox"
                            isOpen={searchBox}
                            toggle={onSearchBoxSelect}>

                            <DropdownToggle
                                className="d-inline-block"
                                tag="span"
                                data-toggle="dropdown">
                                <IconButton className="icon-btn">
                                    <i className="zmdi zmdi-search zmdi-hc-fw"/>
                                </IconButton>
                            </DropdownToggle>

                            <DropdownMenu right className="p-0">
                                <SearchBox styleName="search-dropdown" placeholder=""
                                           onChange={updateSearchText}
                                           value={searchText}/>
                            </DropdownMenu>
                        </Dropdown>
                    </li>*/}
                    {/*<li className="list-inline-item">
                        <Dropdown
                            className="quick-menu"
                            isOpen={langSwitcher}
                            toggle={onLangSwitcherSelect}>

                            <DropdownToggle
                                className="d-inline-block"
                                tag="span"
                                data-toggle="dropdown">
                                <IconButton className="icon-btn">
                                    <i className={`flag flag-24 flag-${locale.icon}`}/>
                                </IconButton>
                            </DropdownToggle>

                            <DropdownMenu right className="w-50">
                                <LanguageSwitcher switchLanguage={onSwitchLanguage}
                                                  handleRequestClose={handleRequestClose}/>
                            </DropdownMenu>
                        </Dropdown>


                    </li>*/}
                    {/*<li className="list-inline-item app-tour">
                        <Button 
                            onClick = {() => {

                                dispatch(openNetworkVisualizer(authUser.profile.projects[0]?.project.projectId || 252, "projects"));
                            }}    
                            variant="contained" className="jr-btn bg-secondary text-white ml-2">
                            Explorer
                        </Button>
                    </li>*/}
                    <li className="list-inline-item app-tour">
                        <Dropdown
                            className="quick-menu"
                            isOpen={appNotification}
                            toggle={onAppNotificationSelect}>

                            <DropdownToggle
                                onClick={onNotificationsCollapse}
                                className="d-inline-block"
                                tag="span"
                                data-toggle="dropdown">
                                <IconButton className="icon-btn">
                                    <i className={`zmdi zmdi-notifications-none ${newNotifications}`}/>
                                </IconButton>
                            </DropdownToggle>

                            <DropdownMenu right>
                                <CardHeader styleName="align-items-center"
                                            heading={"Notifications"}/>
                                <AppNotification/>
                            </DropdownMenu>
                        </Dropdown>
                    </li>
                    
                    
                    {/*<li className="list-inline-item mail-tour">
                        <Dropdown
                            className="quick-menu"
                            isOpen={mailNotification}
                            toggle={onMailNotificationSelect}
                        >
                            <DropdownToggle
                                className="d-inline-block"
                                tag="span"
                                data-toggle="dropdown">

                                <IconButton className="icon-btn">
                                    <i className="zmdi zmdi-comment-alt-text zmdi-hc-fw"/>
                                </IconButton>
                            </DropdownToggle>


                            <DropdownMenu right>
                                <CardHeader styleName="align-items-center"
                                            heading={<IntlMessages id="mailNotification.title"/>}/>
                                <MailNotification/>
                            </DropdownMenu>
                        </Dropdown>
                    </li>*/}

                </ul>
                <UserInfo/>
                

                <div className="ellipse-shape"/>
            </Toolbar>
        </AppBar>
    );
};


export default withRouter(Index);
