import { defaultImages } from 'components/networkVisualizer/assets/variables/defaultImages'
import { cooperationsCountAdder } from 'components/networkVisualizer/modules/cooperationsCountAdder'
import usePrevious from 'components/networkVisualizer/modules/usePrevious'
import { GraphTypes } from 'components/networkVisualizer/networkVisualizerState/sharedTypes'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { animated, useSpring } from 'react-spring'
import { AppState } from 'store'
import { getItemRotation, getRadius } from '../../modules/graphHelpers'
import { ItemComponent } from './components/itemComponent/itemComponent'

export const itemSize = 3.8

export const springConfig = { mass: 1, tension: 160, friction: 20 }

const transformCreator = (rotation: number, radius: number) =>
  `rotate(${rotation}deg) translate3d(${radius}em, 0 ,0) rotate(-${rotation}deg)`

export interface ItemProps {
  id: number | string
  type: GraphTypes
  active: boolean
}

interface IsProps extends ItemProps {
  i: number
  outerConnectionsLength: number
  visible: boolean
  onHideComplete?: () => void
}

export const Item = React.memo(
  ({
    id,
    type,
    i,
    active,
    outerConnectionsLength,
    visible,
    onHideComplete,
  }: IsProps) => {
    const [hovered, setHover] = useState(false)

    const itemData = useSelector(
      (state: AppState) => state.networkVisualizerState.general.data[type][id]
    )

    const { itemRotation, x } = useMemo(() => {
      let itemRotation = +getItemRotation(
        i > 0 ? i - 1 : i,
        outerConnectionsLength
      ).toFixed(4)

      let radius = getRadius(itemSize, outerConnectionsLength)

      let x = i > 0 ? radius : 0

      return {
        itemRotation,
        x,
      }
    }, [i, outerConnectionsLength])

    const [springProps, setSpring] = useSpring(() => ({
      from: {
        transform: transformCreator(itemRotation, 0),
        opacity: i === 0 ? 1 : 0,
      },
      config: springConfig,
    }))

    const previousRotation = usePrevious(itemRotation)
    const previousIndex = usePrevious(i)

    useEffect(() => {
      if (!visible) {
        //@ts-ignore
        setSpring.current[0].start({
          to: {
            transform: transformCreator(itemRotation, 0),
            opacity: 0,
          },
          onRest: onHideComplete,
        })
      } else {
        if (previousIndex === 0) {
          //If the item was previously the center item, rotate it first prior to translating it otherwise it moves in a curve rather than a straight line (similar to the issue with setting the new center item, all stemming down to the rotates which anyway cancel each other out and the translate happens along the rotated axis). Since the 'immediate' prop had to be invoked but only on the rotation, the set calls had to be chained manually like so.
          //@ts-ignore
          setSpring.current[0].start({
            to: async (set) => {
              await set({
                to: {
                  transform: transformCreator(itemRotation, 0),
                  opacity: 1,
                },
                immediate: true,
              })
              await set({
                transform: transformCreator(itemRotation, x),
                opacity: 1,
              })
            },
          })
        } else {
          //@ts-ignore
          setSpring.current[0].start({
            to: {
              transform:
                i === 0
                  ? //If the item is now the main item and moving towards the center, let it maintain it's previous rotation to prevent it from rotating while translating which causes the node to move in a curve rather than a straight line. Setting the rotation immediately as done above does not work.
                    transformCreator(previousRotation, 0)
                  : transformCreator(itemRotation, x),
              opacity: 1,
            },
          })
        }
      }
    }, [
      visible,
      i,
      previousIndex,
      itemRotation,
      previousRotation,
      x,
      setSpring,
      onHideComplete,
    ])

    if (itemData === undefined) return null

    let { image, name, cooperationsCount, platformId } = itemData

    //Data is not structured well. Can not risk cooperationsCount value being null.
    let totalCooperations = cooperationsCount
      ? cooperationsCountAdder(cooperationsCount)
      : undefined

    return (
      <animated.div
        className={`item-wrapper ${active ? 'active' : ''} ${
          hovered ? 'hovered' : ''
        } ${i === 0 ? 'center-item' : ''}`}
        style={springProps}
        // css={itemWrapperStyles}
      >
        <ItemComponent
          {...{
            id,
            type,
            name,
            hovered,
            setHover,
            active,
            totalCooperations,
            platformId,
          }}
          tokenId={itemData.tokenId}
          isCenteredNode={i === 0}
          image={image || defaultImages[type]}
        />
      </animated.div>
    )
  }
)
