import React from 'react';
import { Avatar, Button } from '@material-ui/core';



const PageTitle = ({title, titleIcon, children}) => {


    return (

        <div className={`row justify-content-between mb-4 mt-4`}>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex flex-row justify-content-start align-items-center mb-4">
                {titleIcon && (<Avatar src={titleIcon} className="size-60 mr-2"/>)}
                <h1 className="jr-fs-xxxl jr-font-weight-medium align-items-center" style={{marginBottom: "0rem"}}>{title}</h1>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-end align-items-center">
                {children}
            </div>
        </div>
    )

}

export default PageTitle;