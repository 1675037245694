import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link, withRouter} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import logo from "assets/images/logo_trans.svg";
import {
    hideMessage,
    showAuthLoader,
    userFacebookSignIn,
    userGithubSignIn,
    userGoogleSignIn,
    userSignUp,
    userTwitterSignIn,
    sendCode,
    sendCodeSuccess,
    resetUserPassword,
    userSignOut
} from 'actions/Auth';
import { resetPassword, signOutUser } from 'sagas/Auth';
import DialogueLoader from 'components/Loader/DialogueLoader';
import DialogueLoaderTransparent from 'components/Loader/DialogueLoaderTransparent';
import { showLoaderCreateSuccess_ } from 'actions/IsFetching';
import { Divider } from '@material-ui/core';
import { SignOut } from 'aws-amplify-react';

const ForgotPassword = (props) => {

    const [name, setName] = useState("");
    const [errorName, setErrorName] = useState(false);
    const [helperName, setHelperName] = useState('');
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password_repeat, setPassword_repeat] = useState("");
    const [code, setCode] = useState("");


    const dispatch = useDispatch();
    const {loader, alertMessage, showMessage, authUser, destination,stage, loaderCreateResult} = useSelector(({auth}) => auth);
    // let stage=2;
    let timeout;
    useEffect(() => {
        if (showMessage) {
            timeout = setTimeout(() => {
                dispatch(hideMessage());
            }, 3000);
        }


        if (loaderCreateResult !== undefined) {
            dispatch(showLoaderCreateSuccess_({}));
            props.history.push('/app/signin');
        }

        // dispatch(resetCodeSuccess(1));
        return () => {
            clearTimeout(timeout);
        }

    }, [showMessage, loaderCreateResult]);

    const handleNext = (event) => {
        event.preventDefault();
        if (name !== '') {
            dispatch(showAuthLoader());
            dispatch(sendCode({name}));
            dispatch(userSignOut());
        } else {
            setErrorName(true);
            setHelperName("Please enter Username or eMail address");
            
        }
        
    }
    const handleName = (evt) => {
        setName(evt.target.value);
        if (evt.target.value.length > 0) {
            setErrorName(false);
            setHelperName("");
        }
    }
    

    return (
        
        <div
            className="app-login-container justify-content-center align-items-center">
                <div className="row justify-content-center mt-5 mb-4">
                    <Link className="logo-lg" to="/" title="Jambo">
                        <img src={logo} alt="jambo" title="jambo"/>
                    </Link>
                </div>
                {stage == 1 && (
                    <div className="app-login-main-content mb-5">
                        <DialogueLoaderTransparent />

                        <div className="app-login-content">
                        
                            <div className="app-login-header">
                                <h1>Reset Password</h1>
                            </div>

                            <div className="mb-4">
                                <p>Please enter your Username or e-Mail address</p>
                            </div>

                            <div className="app-login-form">
                            
                                <form>
                                    <TextField
                                        required
                                        type="text"
                                        label="Username / e-Mail"
                                        variant="outlined"
                                        error={errorName} 
                                        helperText={helperName}
                                        onChange={handleName}
                                        fullWidth
                                        value={name}
                                        margin="normal"
                                        className="mt-0 mb-4"
                                    />

                                    
                                    <div className="mt-1 mb-3 d-flex align-items-center justify-content-between">
                                        <Link to="/signin">
                                            Back to Login
                                        </Link>
                                        <Button type="submit" variant="contained" onClick={handleNext} color="primary">
                                            Get Reset Link
                                        </Button>
                                        
                                    </div>
                                    {/*<div className="app-social-block my-1 my-sm-3">
                                        <IntlMessages
                                            id="signIn.connectWith"/>
                                        <ul className="social-link">
                                            <li>
                                                <IconButton className="icon"
                                                            onClick={() => {
                                                                dispatch(showAuthLoader());
                                                                dispatch(userFacebookSignIn());
                                                            }}>
                                                    <i className="zmdi zmdi-facebook"/>
                                                </IconButton>
                                            </li>

                                            <li>
                                                <IconButton className="icon"
                                                            onClick={() => {
                                                                dispatch(showAuthLoader());
                                                                dispatch(userTwitterSignIn());
                                                            }}>
                                                    <i className="zmdi zmdi-twitter"/>
                                                </IconButton>
                                            </li>

                                            <li>
                                                <IconButton className="icon"
                                                            onClick={() => {
                                                                dispatch(showAuthLoader());
                                                                dispatch(userGoogleSignIn());

                                                            }}>
                                                    <i className="zmdi zmdi-google-plus"/>
                                                </IconButton>
                                            </li>

                                            <li>
                                                <IconButton className="icon"
                                                            onClick={() => {
                                                                dispatch(showAuthLoader());
                                                                dispatch(userGithubSignIn());
                                                            }}>
                                                    <i className="zmdi zmdi-github"/>
                                                </IconButton>
                                            </li>
                                        </ul>
                                                        </div>*/}

                                </form>
                            </div>
                        </div>
                    

                    </div>
                )}



                {stage == 2 && 
                    <div className="mb-5">
                        <DialogueLoaderTransparent />
                        <div className="app-login-content">

                            <div className="row mb-4">
                                <div className="col-12 d-block justify-content-center mb-3">
                                    <h1 className="d-block" style={{textAlign: "center", marginBottom: "40px"}}>{"Reset Link has been sent to"}</h1>
                                    <h2 className="text-muted d-block" style={{textAlign: "center"}}>{`${destination.CodeDeliveryDetails.Destination || "your registered eMail address"}`}</h2>
                                </div>
                                
                                <div className="col-12 d-block justify-content-center mb-5">
                                    
                                    <h4 className="text-muted d-block" style={{textAlign: "center"}}>{"We've sent a message to your registered e-Mail Address. Please follow the instructions to reset your password!"}</h4>
                                    
                                </div>

                                
                                
                                <div className="col-12 d-block justify-content-center mb-5">
                                <Divider />
                                    {/*<div className="row justify-content-center mb-3 mt-5">
                                        <h4 className="text-muted d-flex d-block" style={{textAlign: "center", marginBottom: "20px"}}>{"Haven't received anything? Just try again!"}</h4>
                                    </div>*/}
                                    <div className="row justify-content-center mb-3 mt-5">
                                        <Button variant="outlined" color="primary" onClick={()=> props.history.push('/signin')}>
                                            Go to Login
                                        </Button>
                                    </div>
                                    <div className="row"/>
                                        <h4 className="text-muted d-block mt-4" style={{textAlign: "center"}}>{"Your Utilify Team"}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                }




                {
                    loader &&
                    <div className="loader-view">
                        <CircularProgress/>
                    </div>
                }
                {showMessage && NotificationManager.error(alertMessage)}
                <NotificationContainer/>
        </div>
    )
};


export default withRouter(ForgotPassword);
