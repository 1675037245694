import {
    GET_ALL_NOTIFICATIONS_SUCCESS,
    NOTIFICATIONS_REFRESHED,
    NOTIFICATION_REFRESHED,
    UPDATE_NOTIFICATION_STATUS_SUCCESS
} from "constants/ActionTypes";

const INIT_STATE = {
    notificationList: '',
    notificationsRefreshed: false,
    notificationRefreshed: false
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_NOTIFICATIONS_SUCCESS: {
            return {
                ...state,
                notificationList: action.payload
            }
        }
        case UPDATE_NOTIFICATION_STATUS_SUCCESS: {
            return {
                ...state,
            }
        }
        case NOTIFICATIONS_REFRESHED: {
            return {
                ...state,
                notificationsRefreshed: action.payload
            }
        }
        case NOTIFICATION_REFRESHED: {
            return {
                ...state,
                notificationRefreshed: action.payload
            }
        }
        default:
            return state;
    }
}