import React from 'react'

interface IsProps {
  text: string
  left?: boolean
}

export const ToolTip = ({ text, left }: IsProps) => {
  return (
    <div className={`tool-tip ${left ? 'left' : 'top'}`}>
      <p className="tool-tip-text">{text}</p>
    </div>
  )
}
