import { Avatar } from '@material-ui/core'
import logoIcon from 'assets/images/logo_icon.png'
import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'store'
import { Hamburger } from './components/hamburger/hamburger'
import { SearchBar } from './components/searchBar/searchBar'

export const ToggleHeader = () => {
  const { initURL } = useSelector((state: AppState) => state.auth)

  return (
    <div className='navigation-toggle-wrapper'>
      <div className='navigation-header-container'>
        {initURL === '/explore' && window.innerWidth > 500 && (
          <Avatar src={logoIcon} className='user-avatar' />
        )}
        <Hamburger />
        <h2 className='navigation-title-text'>
          {window.innerWidth > 500 ? 'Explorer' : ''}
        </h2>
      </div>
      <SearchBar />
    </div>
  )
}
