import { categoryTypeToSingular } from 'components/networkVisualizer/modules/stringManipulators'
import { SearchFilter } from 'components/networkVisualizer/networkVisualizerState/store/navMenu/types'
import React from 'react'

interface IsProps {
  category: SearchFilter
  onClick: (category: SearchFilter) => void
  active: boolean
}

export const SearchFilterItem = ({ category, onClick, active }: IsProps) => (
  <div className='filter-item-wrapper'>
    <div
      onClick={() => onClick(category)}
      className={`filter-item-container${active ? ' active' : ''}`}
    >
      <p className='filter-item-text'>
        {category === 'all' ? 'All' : categoryTypeToSingular(category)}
      </p>
    </div>
  </div>
)
