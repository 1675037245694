import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import {NavLink, withRouter} from 'react-router-dom';
import { Collapse, Link, Tab, Tabs } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { setAssetType_, setTabValue_ } from 'actions';


const DocsNav = () => {


    
    
        return (
            <div className="row">
                <ul className="custom-navbar-nav d-flex justify-content-center">
                    <li className="d-flex header-nav-menu-item align-items-center justify-content-center">

                        <NavLink 

                            className="dashnav-item"
                            style={{ textDecoration: 'none', textAlign: "center" }}
                            
                            to={"/Docs/Overview"}><IntlMessages id="pages.overview"/>
                        </NavLink>

                    </li>
                    <li className="d-flex header-nav-menu-item align-items-center justify-content-center">
                        <NavLink
                            className="dashnav-item" 
                            style={{ textDecoration: 'none', textAlign: "center" }}

                            to={"/docs/platform"}
                            >Platform
                        </NavLink>
                    </li>
                    <li className="d-flex header-nav-menu-item align-items-center justify-content-center">
                        <NavLink 
                            className="dashnav-item" 
                            style={{ textDecoration: 'none', textAlign: "center" }}

                            to={"/docs/api"}>API
                        </NavLink>
                    </li>
                    <li className="d-flex header-nav-menu-item align-items-center justify-content-center">
                        <NavLink 
                            className="dashnav-item" 
                            style={{ textDecoration: 'none', textAlign: "center" }}
                            to={"/docs/sdk"}>SDK
                        </NavLink>
                    </li>

                </ul>
            </div>
        );
    }
;

export default withRouter(DocsNav);

