import {all, call, delay, fork, put, select, takeEvery} from "redux-saga/effects";


import { hideDashbaordLoader_, hideDialogueLoader_, hideIsFetching_, hideLocalFetching_, hideMainLoader_, hideScrollLoader_, hideTransparentLoader_, isLoadingMore_, showDashbaordLoader_, showDialogueLoader_, showFetchingSuccess_, showIsFetching_, showLoaderCreateSuccess_, showLoaderDeleteSuccess_, showLoaderUpdateSuccess_, showLocalFetching_, showMainLoader_, showScrollLoader_, showTransparentLoader_ } from "actions/IsFetching";
import { getAllChatUsers, getConversation, postMessage, updateAllChatUsers, updateMessageStatus } from "api";
import { conversationsRefreshed_, getAllChatUsersSuccess_, getConversationSuccess_, loadPrevMessagesSuccess_, loadPrevMessages_ } from "actions/Messages";
import { GET_ALL_CHAT_USERS, GET_CONVERSATION, LOAD_PREV_MESSAGES, SEND_TEXT_MESSAGE, UPDATE_ALL_CHAT_USERS, UPDATE_MESSAGE_STATUS } from "constants/ActionTypes";
import { formatChatHistory } from "util/formatChatData";
import { userSignOut } from "actions";
import { requestsRefreshed_ } from "actions/Request";


// Get JWToken from Local Storage

function* getAllChatUserByFilter({payload}) {
    const {JWToken, querryParams, refresh, noLoader} = payload;
    console.log("api querry: ", querryParams);
    try {
        if (!noLoader) {
            if (!refresh) {
                yield put(showMainLoader_());
            }
        }
        
        const apiResponse = yield call(getAllChatUsers, JWToken, querryParams);
        console.log("apiResponse chat users: ", apiResponse.data.conversations);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            
            yield put(getAllChatUsersSuccess_(apiResponse.data.conversations));     
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideMainLoader_());

    }

}

function* updateAllChatUserByFilter({payload}) {
    const {JWToken, userId, messageData, refresh, noLoader} = payload;
    try {
        if (!noLoader) {

        }
        const apiResponse = yield call(updateAllChatUsers, JWToken, userId, messageData);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            // yield put(getAllChatUsersSuccess_(''));     
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideMainLoader_());

    }

}

function* getConversationByUser({payload}) {
    const {JWToken, querryParams, refresh, noLoader, loadMore} = payload;
    const conversation = yield select(state => state.message.conversation)
    try {
        if (!noLoader) {
            if (!refresh) {
                yield put(showDialogueLoader_());
            }
        }

        if (loadMore) {
            yield put(showScrollLoader_());
            yield put(isLoadingMore_(true))
        }
        
        
        const apiResponse = yield call(getConversation, JWToken, querryParams);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse Wallet GET: ", apiResponse.data);
            let formattedMessages = yield call(formatChatHistory, apiResponse.data.messages)
            console.log("Formatted MEssages in Saga: ", formattedMessages);
            if (loadMore) {
                console.log("loadmore: ", loadMore, formattedMessages.formattedConv, );
                yield put(getConversationSuccess_([...formattedMessages.formattedConv, ...conversation]));  
            } else {
                yield put(getConversationSuccess_(formattedMessages.formattedConv)); 
            }
            
            
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // yield put(errorAction(error));
    } finally {
        yield delay(500);
        yield put(hideDialogueLoader_());
        yield put(hideScrollLoader_());
        yield put(isLoadingMore_(false))
    }

}

function* getPrevConversationByUser({payload}) {
    const {JWToken, querryParams, refresh, noLoader} = payload;
    try {
        if (!noLoader) {

        }
        if (!refresh) {
        yield put(showDialogueLoader_());
        }
        
        const apiResponse = yield call(getConversation, JWToken, querryParams);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse Wallet GET: ", apiResponse.data);
            let formattedMessages = yield call(formatChatHistory, apiResponse.data.messages)
            // console.log("Formatted MEssages in Saga: ", formattedMessages);
            yield put(loadPrevMessagesSuccess_(formattedMessages.formattedConv)); 
   
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // yield put(errorAction(error));
    } finally {
        yield put(hideDialogueLoader_());
    }

}

function* postNewMessageWithData({payload}) {
    const {JWToken, messageData, querryParams, startChat, refresh, noLoader} = payload;
    let fetchingResult = {};
    try {
        // yield put(showDialogueLoader_());

        if (startChat) {
            yield put(showDialogueLoader_());
        }
        const apiResponse = yield call(postMessage, JWToken, messageData);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            const refreshedMessages = yield call(getConversation, JWToken, querryParams);
            if (refreshedMessages.status>200) {
                // console.log("apiResponse_error: ", refreshedMessages);
                
            } else {
                // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
                // console.log("apiResponse Wallet GET: ", refreshedMessages.data);
                let formattedMessages = yield call(formatChatHistory, refreshedMessages.data.messages)
                // console.log("Formatted MEssages in Saga: ", formattedMessages);
                yield put(getConversationSuccess_(formattedMessages.formattedConv)); 
                if (startChat) {

                    const apiResponseChatUsers = yield call(getAllChatUsers, JWToken, querryParams);
        
                    if (apiResponseChatUsers.status>200) {
                        if (apiResponseChatUsers.status === 401) {
                            yield put(userSignOut());
                        }
                        // console.log("apiResponse_error: ", apiResponseChatUsers);
                        // yield put(errorAction(apiResponse.error));
                    } else {
                        // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
                        // console.log("apiResponse: ", apiResponseChatUsers);
                        yield put(getAllChatUsersSuccess_(apiResponseChatUsers.data.conversations));   
                        yield put(conversationsRefreshed_(true));  
                    }

                    
                }
            }
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // yield put(errorAction(error));
    } finally {
        yield put(hideDialogueLoader_());
    }

}

function* updateMessageByData({payload}) {
    const {JWToken, messageId, messageData, querryParams, refresh, noLoader} = payload;
    try {
        
        const apiResponse = yield call(updateMessageStatus, JWToken, messageId, messageData);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            const refreshedMessages = yield call(getConversation, JWToken, querryParams);
            if (refreshedMessages.status>200) {
                // console.log("apiResponse_error: ", apiResponse);
                
            } else {
                // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
                // console.log("apiResponse: ", apiResponse);
                yield put(getConversationSuccess_(apiResponse.error));
            }    
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideMainLoader_());
        yield put(hideDashbaordLoader_());
        yield put(hideTransparentLoader_());
    }

}


export function* readAllChatUsers() {
    yield takeEvery(GET_ALL_CHAT_USERS, 
        getAllChatUserByFilter);
}

export function* readConversation() {
    yield takeEvery(GET_CONVERSATION, 
        getConversationByUser);
}

export function* writeNewMessage() {
    yield takeEvery(SEND_TEXT_MESSAGE, 
        postNewMessageWithData);
}

export function* updateMessageRead() {
    yield takeEvery(UPDATE_MESSAGE_STATUS, 
        updateMessageByData);
}

export function* readPrevMessages() {
    yield takeEvery(LOAD_PREV_MESSAGES, 
        getPrevConversationByUser);
}

export function* updateChatUsersRead() {
    yield takeEvery(UPDATE_ALL_CHAT_USERS, 
        updateAllChatUserByFilter);
}


export default function* rootSaga() {
    yield all([fork(readAllChatUsers),
        fork(readConversation),
        fork(readPrevMessages),
        fork(writeNewMessage),
        fork(updateMessageRead),
        fork(updateChatUsersRead)
    ]);
}