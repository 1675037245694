import {all, call, fork, put, takeEvery} from "redux-saga/effects";

import {
    GET_ALL_EVENTS,
    GET_ALL_EVENTS_SUCCESS
} from "constants/ActionTypes";

import {
    getAllEvents_,
    getAllEventsSuccess_

} from "actions/Event";

import {
    getAllEvents, getAllNotifications
} from "../api";

import { Auth } from 'aws-amplify';
import { userSignOut } from "actions";

function* getAllEventsByFilter({payload}) {
    const {JWToken, querryParams} = payload;
    try {

        const apiResponse = yield call(getAllEvents, JWToken, querryParams);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(getAllEventsSuccess_(apiResponse.data.events));        
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    }

}



export function* readAllEvents() {
    yield takeEvery(GET_ALL_EVENTS, 
        getAllEventsByFilter);
}


export default function* rootSaga() {
    yield all([fork(readAllEvents)
    ]);
}