import { ShapePicker } from 'components/networkVisualizer/components/sharedComponents/itemShapes/itemShapes'
import { numberWithCommas } from 'components/networkVisualizer/modules/numberManipulators'
import { changeSelectedNode } from 'components/networkVisualizer/networkVisualizerState/store/graph/actions'
import {
  hideItemDetails,
  showItemDetails,
} from 'components/networkVisualizer/networkVisualizerState/store/itemDetails/actions'
import React, { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { ItemProps } from '../../item'
import { ActiveRing } from '../activeRing/activeRing'
import { CooperationsCountBadge } from '../cooperationsCountBadge/cooperationsCountBadge'
import { NameBadge } from '../nameBadge/nameBadge'
import { Popup } from '../popup/popup'

interface IsProps extends ItemProps {
  image: string
  name: string
  hovered: boolean
  setHover: (hovered: boolean) => void
  totalCooperations?: number
  tokenId?: string | number //Required for copying the link to asset
  platformId?: string | number
  isCenteredNode: boolean
}

/**
 * @param isCenteredNode To know if the node is the centered node for opening item details panel on click.
 */
export const ItemComponent = React.memo(
  ({
    id,
    type,
    image,
    name,
    hovered,
    setHover,
    active,
    totalCooperations,
    tokenId,
    platformId,
    isCenteredNode,
  }: IsProps) => {
    const dispatch = useDispatch()
    const hoverDelayRef = useRef<NodeJS.Timeout>()

    const onMouseEnter = useCallback(() => {
      if (hoverDelayRef.current) clearTimeout(hoverDelayRef.current)
      hoverDelayRef.current = setTimeout(() => setHover(true), 600)
    }, [])

    const onMouseLeave = useCallback(() => {
      if (hoverDelayRef.current) clearTimeout(hoverDelayRef.current)
      hoverDelayRef.current = setTimeout(() => setHover(false), 500)
    }, [])

    const viewDetails = useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation() // Prevent the backdrop catching the click event and closing all open side menus
        if (type !== 'wallets') {
          dispatch(showItemDetails(id, type, platformId))
        }
      },
      [id, type, platformId]
    )

    const copyLink = useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation() // Prevent the backdrop catching the click event and closing all open side menus

        /**
         * Michelle:
         * Hard code the domain name (ie. 'www.domainName.com') here if this isn't working properly. I wasn't sure what the final domain name was going to be so this reads the current domain from the browser.
         */
        let domain = window.location.hostname

        //Ensure the tokenId is used here if applicable and NOT the token referenceId
        let shareableUrl = `${domain}/explore/shared/${type}/${tokenId ?? id}${
          platformId ? '/' + platformId : ''
        }`

        try {
          //navigator can be undefined and will not work if not connected to a secure server (https) or if connected locally to an IP (102.168.8.102) rather than 'localhost'.
          navigator.clipboard.writeText(shareableUrl)
        } catch (e) {
          console.log('failed to copy to clipboard', e)
        }
      },
      [id, type, tokenId, platformId]
    )

    const onClick = useCallback(
      (e: React.MouseEvent) => {
        e.stopPropagation()
        if (hoverDelayRef.current) clearTimeout(hoverDelayRef.current) //Prevent the popup from being scheduled to show
        setHover(false) //Hide the popup if it is visible
        if (isCenteredNode) {
          viewDetails(e)
        } else {
          dispatch(hideItemDetails())
          dispatch(changeSelectedNode(id, type, platformId))
        }
      },
      [id, type, platformId, isCenteredNode, viewDetails]
    )

    useEffect(() => {
      return () => {
        if (hoverDelayRef.current) clearTimeout(hoverDelayRef.current)
      }
    })

    return (
      <div className={`item-container ${type} ${hovered ? 'hovered' : ''}`}>
        {type !== 'wallets' && (
          <Popup
            visible={hovered}
            {...{ id, type, onMouseEnter, onMouseLeave, viewDetails, copyLink }}
          />
        )}
        <div
          className='node-container'
          {...{ onClick, onMouseEnter, onMouseLeave }}
        >
          <ShapePicker
            {...{ id, type, image }}
            imageAlt={`${name} graph node`}
          />
        </div>
        {totalCooperations !== undefined && (
          <CooperationsCountBadge count={numberWithCommas(totalCooperations)} />
        )}
        <NameBadge {...{ name }} />
        <ActiveRing visible={active} />
      </div>
    )
  }
)
