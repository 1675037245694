import { featuredIcon } from 'components/networkVisualizer/assets/icons'
import { defaultImages } from 'components/networkVisualizer/assets/variables/defaultImages'
import { ShapePicker } from 'components/networkVisualizer/components/sharedComponents/itemShapes/itemShapes'
import { CategoryTypes } from 'components/networkVisualizer/networkVisualizerState/sharedTypes'
import React from 'react'

type AssetNames = {
  [key in CategoryTypes]: string
}

const assetNames: AssetNames = {
  tokens: 'Token Blueprint',
  resources: 'Resources',
  utilities: 'Utilities',
  projects: 'Projects',
}

interface IsProps {
  type: CategoryTypes
  id: string | number
  onPress: (
    id: number | string,
    type: CategoryTypes,
    platformId?: string | number
  ) => void
  active?: boolean
  name: string
  image?: string | null
  projectName?: string
  platformId?: string | number //Only used for tokens
  platformName?: string //Only used for tokens
  featured?: boolean //Adds the star
  featuredAnim?: boolean //Starts background anim
}

export const SearchResultItem = React.memo(
  ({
    type,
    id,
    name,
    image,
    onPress,
    active,
    projectName,
    platformId,
    platformName,
    featured,
    featuredAnim,
  }: IsProps) => {
    const onClick = () => onPress(id, type, platformId)

    let itemImage = image || defaultImages[type]

    return (
      <div
        className={`search-item-wrapper${active ? ' active' : ''}${
          featured ? ' featured' : ''
        }${featuredAnim ? ' featured-anim' : ''}`}
        onClick={onClick}
      >
        <div className='search-image-wrapper'>
          <ShapePicker
            {...{ id, type }}
            image={itemImage}
            imageAlt={`${name} search result`}
          />
        </div>
        <div className='body-container'>
          <div className='name-container'>
            {featured ? (
              <img
                src={featuredIcon}
                alt='Featured Star'
                className='featured-icon'
              />
            ) : null}
            <div className='name-text-container'>
              <p className='name-text'>{name}</p>
              {platformName ? (
                <p className='name-subtext'>{platformName}</p>
              ) : null}
            </div>
          </div>
          <p className={`type-text ${type}-color`}>
            {assetNames[type]}
            {projectName ? <span>{` (${projectName})`}</span> : null}
          </p>
        </div>
      </div>
    )
  }
)
