import { SearchAssetsResponse } from 'components/networkVisualizer/API/types/searchAssetsResponse'
import {
  Connections,
  Projects,
  Resources,
  SetVisualizerDataPayload,
  Tokens,
  Utilities,
} from 'components/networkVisualizer/networkVisualizerState/store/general/types'
import { tokenIdCreator } from '../../tokenIdCreator'

/**
 * Currently ONLY being used for formatting project data and it's connections.
 * Normalize data returned from search response array.
 * Returns the generalData payload along with the connections object.
 *
 * cooperationsCount is spelled as cooperationCounts on searchAssets API call.
 */

/**
 * Data that is returned from this output contains the general data to be merged with the existing along with the connections to be merged with the main item.
 */
interface ToGeneralDataOutput {
  data: SetVisualizerDataPayload
  connections: Connections
}

export function toGeneralData(
  response: SearchAssetsResponse
): ToGeneralDataOutput {
  if ('tokens' in response) {
    return tokensFormatter(response)
  } else if ('utilities' in response) {
    return utilitiesFormatter(response)
  } else if ('resources' in response) {
    return resourcesFormatter(response)
  } else if ('projects' in response) {
    return projectsFormatter(response)
  } else {
    // console.log(
    //   'searchAssets to general data format could not be complete. Check if corresponding key is in response.'
    // )
    return { data: {}, connections: {} }
  }
}

function tokensFormatter(
  response: Extract<SearchAssetsResponse, { tokens }>
): ToGeneralDataOutput {
  let tokens: Tokens = {}
  let connections: Connections = { tokens: [] }

  response.tokens.forEach((token) => {
    let {
      tokenId,
      name,
      nonFungible,
      supplyModel,
      totalSupply,
      circulatingSupply,
      meltValue,
      meltFeeRatio,
      transferable,
      platformId,
      // transferFeeSettings,
      appName,
      // cooperations,
      project,
      metadata,
      cooperationCounts,
    } = token

    let referenceId = tokenIdCreator(tokenId, platformId)

    connections.tokens.push(referenceId)

    tokens[referenceId] = {
      tokenId,
      name,
      image: metadata?.image ?? null,
      // connections,
      nonFungible,
      supplyModel,
      totalSupply,
      circulatingSupply,
      meltValue,
      meltFeeRatio: meltFeeRatio ?? undefined,
      transferable,
      // transferFee: transferFeeSettings.value ?? undefined,
      appName,
      // collaborationsCount,
      projectName: project?.name ?? undefined,
      cooperationsCount: cooperationCounts,
      platformId,
    }
  })

  return { data: { tokens }, connections }
}

function utilitiesFormatter(
  response: Extract<SearchAssetsResponse, { utilities }>
): ToGeneralDataOutput {
  let utilities: Utilities = {}
  let connections: Connections = { utilities: [] }

  response.utilities.forEach((utility) => {
    let {
      utilityId,
      name,
      avatarImage,
      project,
      type,
      cooperationCounts,
    } = utility

    connections.utilities.push(utilityId)

    utilities[utilityId] = {
      name,
      image: avatarImage?.thumbnail,
      // connections,
      projectName: project?.name ?? undefined,
      // expiry,
      type,
      // rating,
      cooperationsCount: cooperationCounts,
    }
  })

  return { data: { utilities }, connections }
}

function resourcesFormatter(
  response: Extract<SearchAssetsResponse, { resources }>
): ToGeneralDataOutput {
  let resources: Resources = {}
  let connections: Connections = { resources: [] }

  response.resources.forEach((resource) => {
    let {
      resourceId,
      name,
      avatarImage,
      project,
      category,
      modelType,
      platform,
      license,
      fileType,
      cooperationCounts,
    } = resource

    connections.resources.push(resourceId)

    resources[resourceId] = {
      name,
      image: avatarImage?.thumbnail ?? undefined,
      projectName: project?.name ?? undefined,
      category,
      modelType,
      platform,
      license,
      fileType,
      cooperationsCount: cooperationCounts,
    }
  })

  return { data: { resources }, connections }
}

function projectsFormatter(
  response: Extract<SearchAssetsResponse, { projects }>
): ToGeneralDataOutput {
  let projects: Projects = {}
  let connections: Connections = { projects: [] }

  response.projects.forEach((project) => {
    let { projectId, avatarImage, name, websiteUrl, assetCounts } = project

    connections.projects.push(projectId)

    projects[projectId] = {
      name,
      image: avatarImage?.thumbnail,
      websiteUrl,
      cooperationsCount: assetCounts,
    }
  })

  return { data: { projects }, connections }
}
