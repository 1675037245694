import {all, call, delay, fork, put, select, takeEvery} from "redux-saga/effects";

import {
    GET_ALL_WALLETS,
    GET_ALL_WALLETS_SUCCESS,
    GET_WALLET,
    GET_WALLET_SUCCESS,
    ADD_WALLET,
    ADD_WALLET_SUCCESS,
    DELETE_WALLET,
    DELETE_WALLET_SUCCESS, UPDATE_WALLET, GET_BALANCES, GET_BLUEPRINTS, UNLINK_WALLET, GET_WALLET_THUMB, GET_SUPPLY, GET_BLUEPRINT, GET_PLATFORMS, VERIFY_WALLET
} from "constants/ActionTypes";

import {
    getAllWallets_,
    getAllWalletsSuccess_,
    getWallet_,
    getWalletSuccess_,
    addWallet_,
    addWalletSuccess_,
    deleteWallet_,
    userWalletCreated_,
    projectWalletCreated_,
    deleteWalletSuccess_, updateWalletSuccess_, getBalancesSuccess_, getBlueprintsSuccess_, unLinkUserWalletSuccess_, getWalletThumbSuccess_, getSupplySuccess_, getUserBalancesSuccess_, loadMoreBlueprintsSuccess_, getUserWalletSuccess_, getAllUserWalletsSuccess_, addUserWalletSuccess_, setCachedList_, walletsRefreshed_, walletRefreshed_, getPlatformsSuccess_, verifyWalletSuccess_, linkWalletSuccess_

} from "actions/Wallet";

import {
    getAllWallets,
    getWallet,
    addWallet,
    deleteWallet,
    updateWallet, getBalances, getBlueprints, unLinkUserWallet, getSupply, getBlueprint, getPlatforms, verifyWallet
} from "../api";

import { Auth } from 'aws-amplify';
import { hideDashbaordLoader_, hideDialogueLoader_, hideIsFetching_, hideLocalFetching_, hideMainLoader_, hideScrollLoader_, hideTransparentLoader_, isLoadingMore_, showDashbaordLoader_, showDialogueLoader_, showFetchingSuccess_, showIsFetching_, showLoaderCreateSuccess_, showLoaderDeleteSuccess_, showLoaderUpdateSuccess_, showLocalFetching_, showMainLoader_, showScrollLoader_, showTransparentLoader_ } from "actions/IsFetching";
import { formatWalletThumbData } from "util/formatWalletThumbData";
import { setCurrentEnjinId_, setCurrentEthAddress_, setCurrentTokenId_, setCurrentWalletId_ } from "actions/EventListeners";
import { errorHandler, successHandler } from "util/messageHandler";
import { viewAlertMessage_ } from "actions/Alert";
import { getAssetSuccess_, userSignOut } from "actions";
import { getTokenBlueprintSuccess_ } from "actions/Token";
import { formatAssetData } from "util/formatAssetData";

// Get JWToken from Redux

function* getAddedWallet(JWToken, walletId) {
    
    const apiResponse_wallet = yield call(getWallet, JWToken, walletId);

    if (apiResponse_wallet.status>200) {
        if (apiResponse_wallet.status === 401) {
            yield put(userSignOut());
        }
        // console.log("apiResponse_error: ", apiResponse_tokens);
    } else {
        
        return apiResponse_wallet;
    }
}


function* getWalletsByFiter({payload}) {
    const {JWToken, querryParams, walletType, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                if (walletType === "project") {
                    yield put(showDashbaordLoader_());
                } else {
                    yield put(showMainLoader_())
                }
            }
        }
        
        const apiResponse = yield call(getAllWallets, JWToken, querryParams);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            console.log("apiResponse_error getWallets: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            if (walletType === "project") {
                yield put(getAllWalletsSuccess_(apiResponse.data));   
            } else {
                yield put(getAllUserWalletsSuccess_(apiResponse.data));   
            }
              
        }
    } catch (error) {
        console.log("catchError wallets: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideMainLoader_());
        yield put(hideDashbaordLoader_());
        yield put(hideTransparentLoader_());
    }
}

function* getWalletPlatforms({payload}) {
    const {JWToken, walletId, noLoader} = payload;
    try {
        if (!noLoader) {
            yield put(showDialogueLoader_());
        }
        
        const apiResponse = yield call(getPlatforms, JWToken, walletId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            console.log("apiResponse_error getWallets: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(getPlatformsSuccess_(apiResponse.data.platforms));   
              
        }
    } catch (error) {
        console.log("catchError wallets: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideDialogueLoader_());
    }
}


function* verifyWalletWithData({payload}) {
    const {JWToken, walletId, walletData, noLoader} = payload;
    try {
        if (!noLoader) {
            yield put(showDashbaordLoader_());
        }
        
        const apiResponse = yield call(verifyWallet, JWToken, walletId, walletData);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            console.log("apiResponse_error getWallets: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(linkWalletSuccess_())
            yield put(verifyWalletSuccess_(true));   
              
        }
    } catch (error) {
        console.log("catchError wallets: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideDashbaordLoader_());
    }
}

function* getWalletById({payload}) {
    const {JWToken, walletId, walletType, refresh, dialogue, noLoader, origin} = payload;
    
    try {
        if (!noLoader) {
            if (refresh === true) {
                // yield put(showTransparentLoader_());
                } else if (dialogue === true) {
                    yield put(showDialogueLoader_());
                } else {
                    if (walletType === "project") {
                        yield put(showDashbaordLoader_());
                    } else {
                        yield put(showMainLoader_())
                    }
            }
        }
        
        console.log("get wallet saga called with walletID: ", walletId, "from origin: ", origin);
        const apiResponse = yield call(getWallet, JWToken, walletId);
        console.log("apiResponse Wallet GET test: ", apiResponse.data);
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            console.log("apiResponse_error getWallet: ", apiResponse);

            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            

            if (walletType === "project") {
                yield put(getWalletSuccess_(apiResponse.data));
            } else {
                yield put(getUserWalletSuccess_(apiResponse.data));
            }
             
            yield put(setCurrentEnjinId_(apiResponse.data.enjin.identityId));
            yield put(setCurrentWalletId_(apiResponse.data.walletId))
            yield put(setCurrentEthAddress_(apiResponse.data.address));
   
        }
    } catch (error) {

        // yield put(errorAction(error));
    } finally {
        yield put(hideDashbaordLoader_());
        yield put(hideDialogueLoader_());
        yield put(hideMainLoader_());
        yield put(hideTransparentLoader_());
    }

}

function* getWalletThumbById({payload}) {
    const {JWToken, walletId, refresh, noLoader} = payload;
    let walletThumbData = {};
    try {
        if (!noLoader) {
            yield put(showDialogueLoader_());
        }
        
        const apiResponse = yield call(getWallet, JWToken, walletId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            console.log("apiResponse_error walletThumb: ", apiResponse);

            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(setCurrentEnjinId_(apiResponse.data.enjin.identityId));
            yield put(setCurrentEthAddress_(apiResponse.data.address))
            walletThumbData = yield call(formatWalletThumbData, apiResponse.data)
            yield put(getWalletThumbSuccess_(walletThumbData)); 
   
        }
    } catch (error) {
        console.log("catchError getWalletThumb: ", error);

        // yield put(errorAction(error));
    } finally {
        yield put(hideDialogueLoader_());
    }

}

function* getBalancesById({payload}) {
    const {JWToken, walletId, walletType, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                if (walletType === "project") {
                    yield put(showDashbaordLoader_());
                } else {
                    yield put(showMainLoader_())
                }
            }
        }
        
        
        const apiResponse = yield call(getBalances, JWToken, walletId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            console.log("apiResponse_error balances: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            if (walletType === "project") {
                yield put(getBalancesSuccess_(apiResponse.data.tokens));   
            } else {
                yield put(getUserBalancesSuccess_(apiResponse.data.tokens));   
            }
               
        }
    } catch (error) {
        console.log("catchError balances: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideMainLoader_());
        yield put(hideDashbaordLoader_());
        yield put(hideTransparentLoader_());
    }

}

function* getSupplyById({payload}) {
    const {JWToken, walletId, querryParams, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDashbaordLoader_());
            }
        }
        
        
        const apiResponse = yield call(getSupply, JWToken, walletId, querryParams);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(getSupplySuccess_(apiResponse.data));      
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideDashbaordLoader_());
        yield put(hideTransparentLoader_());
    }

}

function* getBlueprintById({payload}) {
    const {JWToken, walletId, querryParams, refresh, noLoader} = payload;
    let formattedAsset;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDashbaordLoader_());
            }
        }
        const apiResponse = yield call(getBlueprint, JWToken, walletId, querryParams);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            console.log("apiResponse_error blueprint: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            console.log("apiResponse blueprint: ", apiResponse.data);
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            // yield put(getTokenBlueprintSuccess_(apiResponse.data));
            // yield put(getBlueprintsSuccess_('')); 

            let assetData = apiResponse.data.tokens[0];
            let assetType = "tokens";
            console.log("formatted: ", assetData);
            formattedAsset = yield call(formatAssetData, assetData, assetType);
            
            yield put(getAssetSuccess_(formattedAsset)); 
            yield put(setCurrentTokenId_(apiResponse.data.tokenId));
            yield put(getTokenBlueprintSuccess_(apiResponse.data));  
        }

    } catch (error) {
        console.log("catchError blueprint: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideDashbaordLoader_());
    }

}

function* getBlueprintsById({payload}) {
    const {JWToken, walletId, projectId, querryParams, refresh, loadMore, getUncached, noLoader} = payload;
    const blueprints = yield select(state => state.wallet.blueprints)
    const cachedList = yield select(state => state.wallet.cachedList);

    try {
        if (!noLoader) {
            if (!refresh) {
                yield put(showDashbaordLoader_());
            } else {
                yield put(showTransparentLoader_());
            }
        }
        if (getUncached) {
            yield delay(2000);
        }

        if (loadMore) {
            yield put(showScrollLoader_());
            yield put(isLoadingMore_(true))
        }

        let freshData = true;
        if (projectId && cachedList.includes(projectId)) {
            freshData = true;
        } else {
            freshData = false;
            if (projectId) {
                yield put(setCachedList_([...cachedList, projectId]));
            }
        }
        querryParams.cached = freshData;
        const apiResponse = yield call(getBlueprints, JWToken, projectId, querryParams);
        

        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            console.log("apiResponse_error blueprints: ", apiResponse);
            yield put(hideIsFetching_());
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            console.log("apiResponse_blueprints: ", apiResponse);
            if (loadMore) {
                yield put(getBlueprintsSuccess_([...blueprints, ...apiResponse.data.blueprints])); 
            } else {
                yield put(getBlueprintsSuccess_(apiResponse.data.blueprints)); 
            }
            
            yield put(hideIsFetching_());       
        }
    } catch (error) {
        console.log("catchError blueprints: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideDashbaordLoader_());
        yield put(hideTransparentLoader_());
        yield put(hideScrollLoader_());
        if (loadMore) {
            yield put(isLoadingMore_(false))
        }
    }

}

function* addWalletByData({payload}) {
    const {JWToken, walletData, walletType, refresh, noLoader} = payload;
    try {

        if (!noLoader) {
            if (refresh) {
                yield put(showDashbaordLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        yield put(getPlatformsSuccess_(''));
        const apiResponse = yield call(addWallet, JWToken, walletData);
        console.log("what that: ", apiResponse);
        let fetchingResult = {};
        if (apiResponse.status>200) {
            console.log("status > 200: ", apiResponse.status);
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            // fetchingResult.error = apiResponse.data.error;
            // yield put(showLoaderCreateSuccess_(fetchingResult));
            // yield put(errorAction(apiResponse.error));
            yield put(addWalletSuccess_(apiResponse));
            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            console.log("status OK: ", apiResponse.status);
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            
            const walletId = apiResponse.data.walletId;
            const addedWallet = yield call(getWallet, JWToken, walletId);
            // yield put(setCurrentEnjinId_(addedWallet.data.enjin.identityId));
            // yield put(setCurrentWalletId_(walletId));
            console.log("apiResponse added wallet .. : ", apiResponse.data, walletId);
            
            // yield put(setCurrentEthAddress_(addedWallet.data.address))
            // console.log("added wallet: ", addedWallet);

            if (walletType === "project") {
                console.log("added wallet project: ", addedWallet.data )
                yield put(getAllWalletsSuccess_());
                yield put(getWalletSuccess_(addedWallet.data));
                yield put(addWalletSuccess_(addedWallet.data));  
                yield put(projectWalletCreated_(true));
            } else {
                yield put(getAllUserWalletsSuccess_());
                yield put(getUserWalletSuccess_(addedWallet.data));
                yield put(addUserWalletSuccess_(addedWallet.data)); 
                yield put(userWalletCreated_(true)); 
            }
            // yield put(walletsRefreshed_(true));
            fetchingResult.success = apiResponse.data;
            // yield put(showLoaderCreateSuccess_(fetchingResult));

            // alert message   
            const successMessage = yield call(successHandler,"Wallet", "", "created");
            yield put(viewAlertMessage_(successMessage)); 
            
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideDialogueLoader_());
        yield put(hideDashbaordLoader_());
    }

}

function* updateWalletByData({payload}) {
    const {JWToken, walletId, walletData, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            yield put(showTransparentLoader_());
        }
        
        const apiResponse = yield call(updateWallet, JWToken, walletId, walletData);
        let fetchingResult = {};
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            fetchingResult.error = apiResponse.data.error;
            yield put(showLoaderUpdateSuccess_(fetchingResult));
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse.data);
            yield put(walletRefreshed_(true));
            yield put(updateWalletSuccess_(apiResponse.data)); 
            fetchingResult.success = "Success!";
            // console.log("loaderupdateresultfromsaga", fetchingResult)
            yield put(showLoaderUpdateSuccess_(fetchingResult));  

            // alert message   
            const successMessage = yield call(successHandler,"Wallet", "", "updated");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
    }
}

function* unLinkUserWalletById({payload}) {
    const {JWToken, walletId, walletData, walletType, refresh, noLoader} = payload;
    let fetchingResult = {};
    try {
        if (!noLoader) {
            yield put(showTransparentLoader_());
        }
        const apiResponse = yield call(unLinkUserWallet, JWToken, walletId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse); 

            fetchingResult.error = apiResponse.data.error;
            // yield put(showLoaderUpdateSuccess_(fetchingResult));
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse.data);
            
            if (walletType === "project") {
                yield put(getBalancesSuccess_(''));
                yield put(getBlueprintsSuccess_(''));
            } else {
                yield put(getUserBalancesSuccess_(''));
            }
            
            yield put(walletRefreshed_(true));
            yield put(unLinkUserWalletSuccess_(apiResponse.data));
            fetchingResult.success = "Success!";
            // yield put(showLoaderUpdateSuccess_(fetchingResult));  
            
            // alert message   
            const successMessage = yield call(successHandler,"Wallet", "", "unlinked");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
    }

}

function* deleteWalletById({payload}) {
    const {JWToken, walletId, refresh, noLoader} = payload;
    let fetchingResult = {};
    try {
        if (!noLoader) {
            yield put(showTransparentLoader_());
        }

        const apiResponse = yield call(deleteWallet, JWToken, walletId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            

            fetchingResult.error = "Error";
            yield put(showLoaderDeleteSuccess_(fetchingResult));
            
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);

            
            yield put(walletsRefreshed_(true));
            yield put(deleteWalletSuccess_());        
            fetchingResult.success = "Success";
            yield put(showLoaderDeleteSuccess_(fetchingResult));  

            // alert message   
            const successMessage = yield call(successHandler,"Wallet", "", "deleted");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message

        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
    }

}

export function* readAllWallets() {
    yield takeEvery(GET_ALL_WALLETS, 
        getWalletsByFiter);
}

export function* readOneWalletThumb() {
    yield takeEvery(GET_WALLET_THUMB, 
        getWalletThumbById);
}

export function* readOneWallet() {
    yield takeEvery(GET_WALLET, 
        getWalletById);
}

export function* readWalletBalances() {
    yield takeEvery(GET_BALANCES, 
        getBalancesById);
}
export function* readWalletPlatforms() {
    yield takeEvery(GET_PLATFORMS, 
        getWalletPlatforms);
}


export function* readWalletSupply() {
    yield takeEvery(GET_SUPPLY, 
        getSupplyById);
}

export function* readWalletBlueprints() {
    yield takeEvery(GET_BLUEPRINTS, 
        getBlueprintsById);
}

export function* readWalletBlueprint() {
    yield takeEvery(GET_BLUEPRINT, 
        getBlueprintById);
}

export function* addOneWallet() {
    yield takeEvery(ADD_WALLET, 
        addWalletByData);
}

export function* verifyOneWallet() {
    yield takeEvery(VERIFY_WALLET, 
        verifyWalletWithData);
}


export function* unlinkOneWallet() {
    yield takeEvery(UNLINK_WALLET, 
        unLinkUserWalletById);
}

export function* updateOneWallet() {
    yield takeEvery(UPDATE_WALLET, 
        updateWalletByData);
}

export function* removeOneWallet() {
    yield takeEvery(DELETE_WALLET, 
        deleteWalletById);
}

export default function* rootSaga() {
    yield all([fork(readAllWallets),
        fork(readOneWallet),
        fork(readOneWalletThumb),
        fork(readWalletBalances),
        fork(readWalletPlatforms),
        fork(readWalletSupply),
        fork(readWalletBlueprints),
        fork(readWalletBlueprint),
        fork(addOneWallet),
        fork(verifyOneWallet),
        fork(unlinkOneWallet),
        fork(updateOneWallet),
        fork(removeOneWallet)
    ]);
}