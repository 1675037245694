import { combineReducers } from 'redux'
import { generalReducer } from './store/general/reducer'
import { graphReducer } from './store/graph/reducer'
import { itemDetailsReducer } from './store/itemDetails/reducer'
import { navMenuReducer } from './store/navMenu/reducer'
import { walletInputReducer } from './store/walletInput/reducer'

export const networkVisualizerReducer = combineReducers({
  general: generalReducer,
  graph: graphReducer,
  navMenu: navMenuReducer,
  itemDetails: itemDetailsReducer,
  walletInput: walletInputReducer,
})
