import { GetAssetDataResponse } from 'components/networkVisualizer/API/types/getAssetDataResponse'
import { SetVisualizerDataPayload } from 'components/networkVisualizer/networkVisualizerState/store/general/types'
import { projectFormatter } from './_projectFormatter'
import { resourceFormatter } from './_resourceFormatter'
import { tokenFormatter } from './_tokenFormatter'
import { utilityFormatter } from './_utilityFormatter'

/**
 * Normalize data returned from any of the individual item requests
 * eg. /tokens/{tokenId}
 */

export function toGeneralData(
  response: GetAssetDataResponse
): SetVisualizerDataPayload {
  if ('tokenId' in response) {
    return tokenFormatter(response)
  } else if ('utilityId' in response) {
    return utilityFormatter(response)
  } else if ('resourceId' in response) {
    return resourceFormatter(response)
  } else if ('projectId' in response) {
    return projectFormatter(response)
  } else {
    console.log('Item response could not be formatted. Check data formatter.')
    return {}
  }
}
