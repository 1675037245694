import { GRAPH_ASSET_ORDER } from 'components/networkVisualizer/components/networkGraph/components/graph/graph'
import { setVisualizerData } from 'components/networkVisualizer/networkVisualizerState/store/general/actions'
import { call, put, select } from 'redux-saga/effects'
import { AppState } from 'store'
import { CategoryTypes } from '../../../sharedTypes'
import {
  mergeVisualizerDataItemConnections,
  setVisualizerFetching,
} from '../../../store/general/actions'
import { CONNECTIONS_FETCH_LIMIT } from '../fetchAndSetData/fetchAndSetData'
import {
  FetchAndFormatConnections,
  fetchAndFormatConnections,
} from './_fetchAndFormatConnections'

const expectedConnectionsCountSelector = (
  state: AppState,
  id: string | number,
  type: CategoryTypes
) => state.networkVisualizerState.general.data[type][id].cooperationsCount

const currentConnectionsCountSelector = (
  state: AppState,
  id: string | number,
  type: CategoryTypes
) => state.networkVisualizerState.general.data[type][id].connections ?? {}

const filterSelector = (state: AppState) =>
  state.networkVisualizerState.graph.filter

/**
 * Can fetch connections for any of the asset types or projects. Used when changing the graph nodes.
 * Loops over all valid connections types of the item until it fetches enough connections to satisfy the limit.
 * Will fetch only VISIBLE connections as per the graph filter.
 * Will automatically offset any previously fetched connections.
 * API does not return list of multiple assets. Function checks if all assets of a given type have been fetched before moving on to the next asset type (if available).
 */

export function* fetchAndSetConnections(
  id: string | number,
  type: CategoryTypes,
  platformId?: string | number
) {
  yield put(setVisualizerFetching(true))

  const expectedConnectionsCount: ReturnType<typeof expectedConnectionsCountSelector> = yield select(
    (state: AppState) => expectedConnectionsCountSelector(state, id, type)
  )

  const currentConnectionsCount: ReturnType<typeof currentConnectionsCountSelector> = yield select(
    (state: AppState) => currentConnectionsCountSelector(state, id, type)
  )

  //Reads the filter state to ensure only relevant connections are fetched.
  const filterState: ReturnType<typeof filterSelector> = yield select(
    filterSelector
  )

  let expectedConnectionsKeys = Object.keys(expectedConnectionsCount) as Array<
    keyof typeof expectedConnectionsCount
  >

  let sortedExpectedConnectionKeys = expectedConnectionsKeys.sort(
    (a, b) => GRAPH_ASSET_ORDER.indexOf(a) - GRAPH_ASSET_ORDER.indexOf(b)
  )

  let fetchedConnectionsCount = 0

  //Reminder, you can't use forEach in a generator function. For loop instead.
  for (let expectedTypeKey of sortedExpectedConnectionKeys) {
    let currentCount = currentConnectionsCount[expectedTypeKey]?.length ?? 0
    let expectedCount = expectedConnectionsCount[expectedTypeKey]
    if (currentCount < expectedCount && filterState[expectedTypeKey]) {
      const { data, connections }: FetchAndFormatConnections = yield call(
        fetchAndFormatConnections,
        id,
        type,
        expectedTypeKey,
        currentCount,
        CONNECTIONS_FETCH_LIMIT,
        platformId
      )

      yield put(setVisualizerData(data))

      //Manual typings for key types so they show CategoryTypes[] and not default string[]
      let connectionKeys = Object.keys(connections) as Array<
        keyof typeof connections
      >

      let sortedConnectionKeys = connectionKeys.sort(
        (a, b) => GRAPH_ASSET_ORDER.indexOf(a) - GRAPH_ASSET_ORDER.indexOf(b)
      )

      //Even though formattedData.connections only returns one of the connection types (ie. tokens or assets or etc...) the loop is for future proofing if the formatter returns more than one type of connection at a time.
      for (let c_k of sortedConnectionKeys) {
        yield put(
          mergeVisualizerDataItemConnections({
            itemType: type,
            itemId: id,
            newConnectionsType: c_k,
            newConnections: connections[c_k],
          })
        )
      }

      fetchedConnectionsCount += Object.values(connections).reduce(
        (total: number, current: any[]) => current.length + total,
        0
      )

      if (fetchedConnectionsCount >= CONNECTIONS_FETCH_LIMIT) {
        //If the total fetched connections count satisfies the fetch limit, break out of the for loop.
        break
      }
    }
  }

  yield put(setVisualizerFetching(false))
}
