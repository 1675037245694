import React, {useEffect} from "react";
import AppLayouts from "./AppLayouts";
import Routes from "../../app/routes";
import {useSelector, useDispatch} from "react-redux";
import {getAuthUser, showAuthLoader} from "../../actions/Auth";





const AppLayout = () => {


    const horizontalNavPosition = useSelector(({settings}) => settings.horizontalNavPosition);
    const navigationStyle = useSelector(({settings}) => settings.navigationStyle);
    const {authUser, initURL} = useSelector(({auth}) => auth);
    
    
    const dispatch = useDispatch();

    useEffect(()=> {
        if (authUser.user === undefined && initURL !== "/explore") {
            dispatch(getAuthUser({showLoader:false}));
        }
    }, []);

    

    const onGetLayout = (layout) => {
        switch (layout) {
            case "inside_the_header":
                return "InsideHeaderNav";

            case "above_the_header":
                return "AboveHeaderNav";

            case "below_the_header":
                return "BelowHeaderNav";
            default:
                return "Vertical";
        }
    };

    if (authUser.profile === undefined) return null;

    
    const Layout = AppLayouts[navigationStyle === "vertical_navigation" ? "Vertical" : onGetLayout(horizontalNavPosition)];
    return (
        <Layout>
            
            <Routes/>
            
        </Layout>
    );
};

export default AppLayout;
