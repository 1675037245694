import { SetVisualizerDataPayload, VisualizerData } from '../types'

/**
 * Data updater required as API does not return consistent data. Data is only partially returned at times. Updater ensures that a valid value isn't overwritten by 'undefined' or 'null' if the new fetch is only partially complete.
 */

export function dataUpdater(
  stateData: VisualizerData,
  newData: SetVisualizerDataPayload
): VisualizerData {
  const data: VisualizerData = {
    ...{
      tokens: {},
      resources: {},
      projects: {},
      utilities: {},
    },
    ...stateData,
  }

  let newDataKeys = Object.keys(newData) as Array<keyof typeof newData>

  newDataKeys.forEach((categoryKey) => {
    let itemKeys = Object.keys(newData[categoryKey]!) as Array<string | number>

    itemKeys.forEach((itemKey) => {
      let prev = data[categoryKey][itemKey]
      let current = newData[categoryKey]![itemKey]

      //Remove any undefined or null properties so that they do not overwrite valid values that might already be set. This happens as connected items sometimes return undefined or null values.
      let propertyKeys = Object.keys(current) as Array<keyof typeof current>

      propertyKeys.forEach((propKey) => {
        if (current[propKey] === undefined || current[propKey] === null) {
          delete current[propKey]
        }
      })

      if (prev !== undefined) {
        data[categoryKey][itemKey] = {
          ...prev,
          ...current,
        }
      } else {
        data[categoryKey][itemKey] = newData[categoryKey]![itemKey]
      }
    })
  })

  return data
}
