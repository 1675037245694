import { CategoryTypes, GraphTypes } from '../../sharedTypes'
import {
  ChangeSelectedNode,
  ChangeVisibleConnectionsSet,
  CHANGE_SELECTED_NODE,
  CHANGE_VISIBLE_CONNECTIONS_SET,
  DisableGraphNextButton,
  DISABLE_GRAPH_NEXT_BUTTON,
  ResetGraphFilterState,
  ResetVisualizerGraphState,
  RESET_GRAPH_FILTER_STATE,
  RESET_VISUALIZER_GRAPH_STATE,
  SetGraphFilterState,
  SetSelectedNode,
  SetVisibleConnectionsSet,
  SetWalletFetchPage,
  SET_GRAPH_FILTER_STATE,
  SET_SELECTED_NODE,
  SET_VISIBLE_CONNECTIONS_SET,
  SET_WALLET_FETCH_PAGE,
  ToggleGraphFilterState,
  TOGGLE_GRAPH_FILTER_STATE,
} from './types'

export const setSelectedNode = (
  id: number | string,
  type: GraphTypes
): SetSelectedNode => ({
  type: SET_SELECTED_NODE,
  payload: { id, type },
})

export const setGraphFilterState = (
  payload: CategoryTypes
): SetGraphFilterState => ({
  type: SET_GRAPH_FILTER_STATE,
  payload,
})

export const resetGraphFilterState = (): ResetGraphFilterState => ({
  type: RESET_GRAPH_FILTER_STATE,
})

/* Manually set the graph connections set. Will not fetch more connections for pagination. */
export const setVisibleConnectionsSet = (
  set: number
): SetVisibleConnectionsSet => ({
  type: SET_VISIBLE_CONNECTIONS_SET,
  payload: set,
})

/**
 * Set the wallet fetch page. Used to keep track of page when fetching wallet connctions.
 * @param page
 */
export const setWalletFetchPage = (page: number): SetWalletFetchPage => ({
  type: SET_WALLET_FETCH_PAGE,
  payload: page,
})

/**
 * Disabled the next button manually. Only used when wallet is centered since there is no data on total connections. Ensure to reset this value when centering a new node.
 * @param disableNextButton
 */
export const disableGraphNextButton = (
  disableNextButton: boolean
): DisableGraphNextButton => ({
  type: DISABLE_GRAPH_NEXT_BUTTON,
  payload: disableNextButton,
})

export const resetVisualizerGraphState = (): ResetVisualizerGraphState => ({
  type: RESET_VISUALIZER_GRAPH_STATE,
})

/**
 *
 * Redux Saga Actions
 *
 */

export const changeSelectedNode = (
  id: number | string,
  type: GraphTypes,
  platformId?: string | number
): ChangeSelectedNode => ({
  type: CHANGE_SELECTED_NODE,
  payload: { id, type, platformId },
})

export const toggleGraphFilterState = (
  payload: CategoryTypes
): ToggleGraphFilterState => ({
  type: TOGGLE_GRAPH_FILTER_STATE,
  payload,
})

/* Call this when changing graph connections set. Will take care of pagination. */
export const changeVisibleConnectionsSet = (
  set: number
): ChangeVisibleConnectionsSet => ({
  type: CHANGE_VISIBLE_CONNECTIONS_SET,
  payload: set,
})
