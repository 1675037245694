import { ProjectsResponse } from 'components/networkVisualizer/API/types/searchAssetsResponse/projects'
import { ResourcesResponse } from 'components/networkVisualizer/API/types/searchAssetsResponse/resources'
import { TokensResponse } from 'components/networkVisualizer/API/types/searchAssetsResponse/tokens'
import { UtilitiesResponse } from 'components/networkVisualizer/API/types/searchAssetsResponse/utilities'
import { SearchResultsType } from 'components/networkVisualizer/networkVisualizerState/store/navMenu/types'
import { SagaReturnType } from 'redux-saga/effects'
import { API } from '../../../../API/api'
import { tokenIdCreator } from '../../tokenIdCreator'

export function toSearchResults(
  responses: (SagaReturnType<typeof API.searchAssets> | null)[]
): SearchResultsType {
  let results: SearchResultsType = []

  responses.forEach((response) => {
    if (response !== null) {
      let { data } = response
      //API data object constantly changing. This might not be stable.
      if ('tokens' in data) {
        results.push(...tokensFormatter(data))
      } else if ('utilities' in data) {
        results.push(...utilitiesFormatter(data))
      } else if ('resources' in data) {
        results.push(...resourcesFormatter(data))
      } else if ('projects' in data) {
        results.push(...projectsFormatter(data))
      } else {
        // console.log(
        //   'Item response could not be formatted for search results. Check searchAssetsFormatter helper.'
        // )
      }
    }
  })
  results.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
  return results
}

function tokensFormatter(response: TokensResponse): SearchResultsType {
  let { tokens } = response
  return tokens.map(
    ({ tokenId, metadata, name, project, platformId, platform }) => ({
      id: tokenIdCreator(tokenId, platformId),
      name,
      image: metadata?.image ?? null,
      type: 'tokens',
      projectName: project?.name,
      platformId,
      platformName: platform.name ?? undefined,
      tokenId,
    })
  )
}

function utilitiesFormatter(response: UtilitiesResponse): SearchResultsType {
  let { utilities } = response
  return utilities.map(({ utilityId, avatarImage, name, project }) => ({
    id: utilityId,
    name,
    image: avatarImage?.thumbnail ?? null,
    type: 'utilities',
    projectName: project?.name,
  }))
}

function resourcesFormatter(response: ResourcesResponse): SearchResultsType {
  let { resources } = response
  return resources.map(({ resourceId, avatarImage, name, project }) => ({
    id: resourceId,
    name,
    image: avatarImage?.thumbnail ?? null,
    type: 'resources',
    projectName: project?.name,
  }))
}

function projectsFormatter(response: ProjectsResponse): SearchResultsType {
  let { projects } = response
  return projects.map(({ projectId, avatarImage, name }) => ({
    id: projectId,
    name,
    image: avatarImage?.thumbnail ?? null,
    type: 'projects',
  }))
}
