
import React, {useEffect, useState} from "react";
import Pusher from 'pusher-js';
import { useDispatch, useSelector } from 'react-redux';
import {enjinLConnected_} from 'actions/EventListeners';
import { withRouter } from "react-router";
import { clearLinkingDialogue_, getBlueprintsSuccess_, getBlueprints_, getBlueprint_, getPlatforms_, getWalletSuccess_, getWalletThumbSuccess_, getWalletThumb_, getWallet_, linkWalletSuccess_, projectWalletCreated_, setCached_ } from "actions/Wallet";
import { refreshOrders_ } from "actions/Order";
import { clearLinkingDialogueEnjinApp_ } from "actions/EnjinApp";
import { viewAlertMessage_ } from "actions/Alert";
import { successHandler } from "util/messageHandler";
import { getTokenBlueprintSuccess_, getTokenBlueprint_ } from "actions/Token";

require('dotenv').config();



let pusherClient;

const PusherEventHandler = (props) => {

    const {currentEnjinId, currentEthAddress, currentWalletId, enjinListenerConnected, currentTokenId} = useSelector(({eventListeners}) => eventListeners);

    console.log("pusher handler - ", currentWalletId);

    const dispatch = useDispatch();
    let walletId = currentWalletId;
    let tokenId = currentTokenId;
    const JWToken = localStorage.getItem('idToken');

    const generateSuccessMessage = (type, name, operation) => {
        const message = successHandler(type, name, operation);
        dispatch(viewAlertMessage_(message)); 
    }

    useEffect(()=> {

        // console.log("pushersetup currentEthAddress: ", currentEthAddress, " - currentEnjId: ", currentEnjinId);

        if (enjinListenerConnected === false) {
            dispatch(enjinLConnected_(true));
            pusherClient = new Pusher("a826ad9293ce1ae1a036", {
                cluster: "mt1",
                encrypted: false
            });
        }

        if (currentWalletId && currentWalletId && !pusherClient.channel(`enjincloud.${process.env.REACT_APP_CHAIN == "mainnet" ? 'mainnet' : 'kovan'}.identity.${currentEnjinId}`)) {
            // PUSHER EVENTS

            const pusherChannelIdentity = pusherClient.subscribe(`enjincloud.${process.env.REACT_APP_CHAIN == "mainnet" ? 'mainnet' : 'kovan'}.identity.${currentEnjinId}`);
        
        
            pusherClient.channel(`enjincloud.${process.env.REACT_APP_CHAIN == "mainnet" ? 'mainnet' : 'kovan'}.identity.${currentEnjinId}`)
            pusherChannelIdentity.bind_global((event, data)=> {

                //filter for pusher events
                if(event.includes('pusher:')){
                    // console.log({event});
                }
            
                //filter for enjin events
                if(!event.includes('EnjinCloud\\Events\\')){
                    return;
                }
            
                //check if there is a status change in the distribution system
                const eventType = event.replace('EnjinCloud\\Events\\', '');
                switch (eventType) {
            
                    //Identities
                    case "IdentityLinked":
                        dispatch(linkWalletSuccess_());
                        /*dispatch(getBlueprints_({JWToken, walletId, querryParams:{
                            limit: 10,
                            page: 1,
                            cached: false,
                            overrideCaching: true
                        }, getUncached:true}));*/
                        
                        dispatch(getWallet_({JWToken, walletId: currentWalletId, walletType: "project", origin:"pusher events"}));
                        dispatch(getPlatforms_({JWToken, walletId: currentWalletId}));
                        dispatch(getWalletThumbSuccess_(''));
                        // dispatch(clearLinkingDialogue_(''));
                        dispatch(clearLinkingDialogueEnjinApp_(''));
                        break;
                    case "IdentityUnlinked":
                        dispatch(getWalletSuccess_(''));
                        dispatch(getWalletThumbSuccess_(''))
                        dispatch(clearLinkingDialogue_(''));
                        dispatch(clearLinkingDialogueEnjinApp_(''));

                        break;
                    case "IdentityUpdated":
                        break;
                    case "IdentityDeleted":
                        break;
                }
                // console.log("pushersetup IDENT: ", event, data);
            // this.setState({ chats: [...this.state.chats, data], test: '' });
            });

            //   pusherChannel.bind('IdentityLinked', data => {
            //       console.log("PUSHER: ", data);
            //     // this.setState({ chats: [...this.state.chats, data], test: '' });
            //   });
        }
            
        if (currentEthAddress && pusherClient.channel(`enjincloud.${process.env.REACT_APP_CHAIN == "mainnet" ? 'mainnet' : 'kovan'}.wallet.${currentEthAddress}`) === undefined ) {
            // console.log("walletLinkingData")
            const pusherChannelWalletAddress = pusherClient.subscribe(`enjincloud.${process.env.REACT_APP_CHAIN == "mainnet" ? 'mainnet' : 'kovan'}.wallet.${currentEthAddress.toLowerCase()}`);
        
            pusherChannelWalletAddress.bind_global((event, data)=> {

                //filter for pusher events
                if(event.includes('pusher:')){
                    // console.log({event});
                }
            
                //filter for enjin events
                if(!event.includes('EnjinCloud\\Events\\')){
                    return;
                }
            
                //check if there is a status change in the distribution system
                const eventType = event.replace('EnjinCloud\\Events\\', '');
                switch (eventType) {
            
                    //Identities
                    case "TokenCreated":
                        console.log("ebent: ", event, data);
                        dispatch(setCached_(false));
                        dispatch(getBlueprint_({JWToken, walletId: currentWalletId, querryParams:{
                            tokenId: data.token.id,
                            cached: false,
                            overrideCaching: true
                        }}));
                        // generateSuccessMessage("Token Blueprint", "", "created");
                        break;
                    case "TokenUpdated":
                        console.log("token updated event triggered");
                        // dispatch(setCached_(false));
                        /*dispatch(getBlueprints_({JWToken, walletId, querryParams:{
                            limit: 10,
                            page: 1,
                            cached: false,
                            overrideCaching: true
                        }, refresh:true}));*/
                        dispatch(getTokenBlueprint_({JWToken, tokenId, refresh:true, querryParams:{
                            cached:false,
                            overrideCaching:true
                        }, getUncached:true}));
                        // generateSuccessMessage("Token Blueprint", "", "created");
                        break;
                }
                // console.log("pushersetup ADDRESS: ", event, data);
            // this.setState({ chats: [...this.state.chats, data], test: '' });
            });

            //   pusherChannel.bind('IdentityLinked', data => {
            //       console.log("PUSHER: ", data);
            //     // this.setState({ chats: [...this.state.chats, data], test: '' });
            //   });
        }
    }, [currentEnjinId, currentEthAddress, currentWalletId])


    return null;

};


export default PusherEventHandler;
