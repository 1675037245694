import { fork, put, select, takeLatest, throttle } from 'redux-saga/effects'
import { AppState } from 'store'
import { changeSelectedNode } from './../graph/actions'
import { setWalletInputValue } from './actions'
import { CHANGE_WALLET_INPUT_VALUE, SUBMIT_WALLET_INPUT } from './types'

function* changeWalletInputValueWatcher() {
  yield takeLatest(CHANGE_WALLET_INPUT_VALUE, changeWalletInputValueFlow)
}

function* changeWalletInputValueFlow({
  type,
  payload,
}: {
  type: typeof CHANGE_WALLET_INPUT_VALUE
  payload: string | number
}) {
  yield put(setWalletInputValue(payload))
}

function* submitWalletInputWatcher() {
  yield throttle(500, SUBMIT_WALLET_INPUT, submitWalletInputFlow)
}

function* submitWalletInputFlow({
  type,
}: {
  type: typeof SUBMIT_WALLET_INPUT
}) {
  const walletId: string | number = yield select(
    (state: AppState) => state.networkVisualizerState.walletInput.walletId
  )

  // const walletData: Partial<VisualizerData> = {
  //   wallets: {
  //     [walletId]: { name: walletId.toString(), image: walletIcon },
  //   },
  // }

  // yield put(setVisualizerData(walletData))

  yield put(changeSelectedNode(walletId, 'wallets'))
}

export function* walletInputSagas() {
  yield fork(changeWalletInputValueWatcher)
  yield fork(submitWalletInputWatcher)
}
