export const formatCooperations = async(cooperationList, baseAsset) => {

    let mergedAssetsArray = await cooperationList.utilities.concat(cooperationList.resources);

        switch (baseAsset) {
            

            case "tokens": {
                let coopsSortedByAssets = [];
                coopsSortedByAssets = await mergedAssetsArray.reduce(function (r, a) {
                    r[a.tokenId] = r[a.tokenId] || [];
                    r[a.tokenId].push(a);
                    return r;
                }, Object.create(null));
                
                let sortedResults = await Object.entries(coopsSortedByAssets);
                return sortedResults;
            }
            break;

            case "utilities": {
                let coopsSortedByAssets = [];
                coopsSortedByAssets = mergedAssetsArray.reduce(function (r, a) {
                    r[a.utilityId] = r[a.utilityId] || [];
                    r[a.utilityId].push(a);
                    return r;
                }, Object.create(null));

                let sortedResults = await Object.entries(coopsSortedByAssets);
                return sortedResults;


            }
            break;
            case "resources": {
                let coopsSortedByAssets = [];
                coopsSortedByAssets = mergedAssetsArray.reduce(function (r, a) {
                    r[a.resourceId] = r[a.resourceId] || [];
                    r[a.resourceId].push(a);
                    return r;
                }, Object.create(null));

                let sortedResults = await Object.entries(coopsSortedByAssets);
                return sortedResults;

            }
            break;
            


        }

}

