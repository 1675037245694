import { hideItemDetails } from 'components/networkVisualizer/networkVisualizerState/store/itemDetails/actions'
import { hideVisualizerNavMenu } from 'components/networkVisualizer/networkVisualizerState/store/navMenu/actions'
import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'
import { ConnectionsNav } from './components/connectionsNav/connectionsNav'
import { Graph } from './components/graph/graph'

export const NetworkGraph = () => {
  const dispatch = useDispatch()

  const onBackdropPress = useCallback(() => {
    dispatch(hideItemDetails())
    dispatch(hideVisualizerNavMenu())
  }, [dispatch])

  //Prevent graph from opening until the visualizer open animation is complete. Makes for a smoother animation.
  const isOpening = useSelector(
    (state: AppState) => state.networkVisualizerState.general.isOpening
  )

  return (
    <div className={window.innerWidth > 500 ? `network-graph-wrapper` : `network-graph-wrapper-mobile`} onClick={onBackdropPress}>
      {!isOpening && <Graph />}
      {!isOpening && <ConnectionsNav />}
    </div>
  )
}
