import React from 'react';
import DialogueLoader from 'components/Loader/DialogueLoader';
import resourceIcon from 'assets/images/resource_icon.png';
import tokenIcon from 'assets/images/token_icon.png';
import utilityIcon from 'assets/images/utility_icon.png';
import utilityLinkIcon from 'assets/images/utility_link_icon.png';
import resourceLinkIcon from 'assets/images/resource_link_icon.png';
import questionIcon from 'assets/images/question_icon.png';
import { Button } from '@material-ui/core';



const HelpDialogueBody = ({
    type, handleClose, title, description
}) => {

    let icon
    let coopIcon
    switch (type) {
        case "resource": icon = resourceIcon;
        break;
        case "token": icon = tokenIcon;
        break;
        case "utility": icon = utilityIcon;
        break;
        case "utilityLink": icon = utilityLinkIcon;
        break;
        case "resourceLink": icon = resourceLinkIcon;
        break;
        default: icon = questionIcon
    }
    
    return (
        <div className="help-card">


            <DialogueLoader />

            
            <div className='d-flex justify-content-center card-img-top help-dialogue-img-parent pt-4 pb-4'>
                <img 
                    className='help-dialogue-img' 
                    src={icon} 
                    alt='{itemname}' 
                />   
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex flex-row justify-content-start align-items-center mb-4 mt-4">
                <h1 className="jr-fs-xxxl jr-font-weight-medium align-items-center text-muted" style={{marginBottom: "0rem", textAlign: "center"}}>{title}</h1>
            </div>
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 d-flex flex-row justify-content-start align-items-center mb-4 text-muted">
                <p>
                    {description}
                </p>
            </div>

            <div className="row mt-4 d-flex align-items-end justify-content-end flex-column pb-2">
                    <div className="col-8 d-flex justify-content-end align-items-end">
                        
                        <div className="d-inline">
                            <Button 
                                onClick = {handleClose}
                                variant="contained" className="jr-btn bg-indigo text-white mr-2">
                                Close
                            </Button>
                        </div>

                    </div>
                </div>
            
        </div>
    );
};

export default HelpDialogueBody;