import {
  currentConnectionsIndexSelector,
  totalExpectedSetsSelector,
} from 'components/networkVisualizer/components/networkGraph/components/connectionsNav/connectionsNav'
import { CategoryTypes } from 'components/networkVisualizer/networkVisualizerState/sharedTypes'
import { MAX_GRAPH_CONNECTIONS } from 'components/networkVisualizer/networkVisualizerState/store/graph/reducer'
import {
  all,
  call,
  fork,
  put,
  select,
  takeLatest,
  throttle,
} from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { AppState } from 'store'
import { fetchAndSetConnections } from '../../helpers/dataFetchers/fetchAndSetConnections/fetchAndSetConnections'
import { fetchAndSetData } from '../../helpers/dataFetchers/fetchAndSetData/fetchAndSetData'
import { fetchAndSetWalletConnections } from '../../helpers/dataFetchers/fetchAndSetWalletConnections/fetchAndSetWalletConnections'
import { tokenIdCreator } from '../../helpers/tokenIdCreator'
import { WALLET_FETCH_LIMIT } from './../../helpers/dataFetchers/fetchAndSetWalletConnections/fetchAndSetWalletConnections'
import {
  changeVisibleConnectionsSet,
  resetGraphFilterState,
  setGraphFilterState,
  setSelectedNode,
  setVisibleConnectionsSet,
} from './actions'
import {
  ChangeSelectedNodePayload,
  CHANGE_SELECTED_NODE,
  CHANGE_VISIBLE_CONNECTIONS_SET,
  TOGGLE_GRAPH_FILTER_STATE,
} from './types'

const tokenIdSelector = (state: AppState, id: string | number) =>
  state.networkVisualizerState.general.data.tokens[id].tokenId

type TokenIdSelector = ReturnType<typeof tokenIdSelector>

/**
 * Changes selected node and fetches and sets relevant data
 */
function* changeSelectedNodeWatcher() {
  yield throttle(1600, CHANGE_SELECTED_NODE, changeSelectedNodeFlow)
}

function* changeSelectedNodeFlow({
  type,
  payload,
}: {
  type: typeof CHANGE_SELECTED_NODE
  payload: ChangeSelectedNodePayload
}) {
  yield all([put(resetGraphFilterState()), put(setVisibleConnectionsSet(0))])

  if (payload.type === 'tokens') {
    //Token ID is not unique and has to be compiled locally. The ID passed for tokens is a reference ID to the object in redux and can not be used for fetching API data. The actual token ID must be selected from the redux object.
    const tokenId: TokenIdSelector = yield select((state: AppState) =>
      tokenIdSelector(state, payload.id)
    )

    const referenceId = yield call(tokenIdCreator, tokenId, payload.platformId)

    //Reference ID is used here because even the compiled token ID's are not unique when connected to a wallet (??). When a wallet is centered, 2 copies of the token are inserted to the redux store (id + platformId + tokenIndex & id + platformId).
    //The "id + platformId + tokenIndex" pattern is only used for tokens connected to wallets since these have additional duplicates (??) but shouldn't be referenced when changing nodes, instead opt to use the "normal" token.
    yield put(setSelectedNode(referenceId, payload.type))

    yield call(fetchAndSetData, tokenId, payload.type, payload.platformId)
  } else {
    yield put(setSelectedNode(payload.id, payload.type))
    yield call(fetchAndSetData, payload.id, payload.type, payload.platformId)
  }
}

/**
 * Toggle Filter and update visible connections index if necessary
 */
const selectedNodeSelector = (state: AppState) =>
  state.networkVisualizerState.graph.selectedNode

const itemDataSelector = createSelector(
  selectedNodeSelector,
  (state: AppState) => state.networkVisualizerState.general.data,
  ({ id, type }, data) => data[type][id]
)

const filterSelector = (state: AppState) =>
  state.networkVisualizerState.graph.filter

function* toggleGraphFilterWatcher() {
  yield takeLatest(TOGGLE_GRAPH_FILTER_STATE, toggleGraphFilterFlow)
}

function* toggleGraphFilterFlow({
  type,
  payload,
}: {
  type: typeof TOGGLE_GRAPH_FILTER_STATE
  payload: CategoryTypes
}) {
  const previousExpectedSets: ReturnType<typeof totalExpectedSetsSelector> = yield select(
    totalExpectedSetsSelector
  )

  //Ensure the filter is set after fetching previousExpectedSets
  yield put(setGraphFilterState(payload))

  const currentExpectedSets: ReturnType<typeof totalExpectedSetsSelector> = yield select(
    totalExpectedSetsSelector
  )
  const currentConnectionsIndex: number = yield select(
    currentConnectionsIndexSelector
  )

  //If the current set is different or the totalExpectedSets are different, trigger the changeVisibleConnectionsSet action. Even if the currentExpectedSets stays the same, the saga will take care of fetching relevant connections if required.
  if (currentConnectionsIndex + 1 > currentExpectedSets) {
    yield put(changeVisibleConnectionsSet(currentExpectedSets - 1))
  } else if (previousExpectedSets !== currentExpectedSets) {
    yield put(changeVisibleConnectionsSet(currentConnectionsIndex))
  }
}

/**
 * Change visible connections set and fetch additional connections if available.
 */
function* changeVisibleConnectionsSetWatcher() {
  yield throttle(
    1500,
    CHANGE_VISIBLE_CONNECTIONS_SET,
    changeVisibleConnectionsSetFlow
  )
}

const walletConnectionsSelector = (
  state: AppState,
  walletId: number | string
) => state.networkVisualizerState.general.data.wallets[walletId].connections

type WalletConnectionsSelector = ReturnType<typeof walletConnectionsSelector>

function* changeVisibleConnectionsSetFlow({
  type,
  payload,
}: {
  type: typeof CHANGE_VISIBLE_CONNECTIONS_SET
  payload: number
}) {
  let nextConnectionsSetIndex = payload

  const totalExpectedSets: ReturnType<typeof totalExpectedSetsSelector> = yield select(
    totalExpectedSetsSelector
  )

  const selectedNode: ReturnType<typeof selectedNodeSelector> = yield select(
    selectedNodeSelector
  )

  //The graph nav buttons do not fire if the next index is out of range however this is a secondary check for when this function is called from outside of the nav buttons(ie. toggleGraphFilterFlow).

  if (selectedNode.type === 'wallets') {
    const walletConnections: WalletConnectionsSelector = yield select(
      (state: AppState) => walletConnectionsSelector(state, selectedNode.id)
    )
    const totalWalletConnections = Object.values(walletConnections).reduce(
      (t, c) => (t += c.length),
      0
    )
    if (
      totalWalletConnections <=
      nextConnectionsSetIndex * WALLET_FETCH_LIMIT
    ) {
      yield fetchAndSetWalletConnections(selectedNode.id)
    }
    yield put(setVisibleConnectionsSet(payload))
  } else if (nextConnectionsSetIndex + 1 <= totalExpectedSets) {
    yield put(setVisibleConnectionsSet(payload))

    const filter: ReturnType<typeof filterSelector> = yield select(
      filterSelector
    )

    const {
      cooperationsCount,
      connections,
      platformId,
    }: ReturnType<typeof itemDataSelector> = yield select(itemDataSelector)

    let expectedTotalConnections = 0
    let currentTotalConnections = 0

    if (connections.projects?.length > 0) {
      expectedTotalConnections += 1
    }

    for (let k of Object.keys(cooperationsCount)) {
      //Only combine the assets that are visible.
      if (filter[k]) {
        expectedTotalConnections += cooperationsCount[k]

        if (connections[k]) {
          currentTotalConnections += connections[k].length
        }
      }
    }

    //Required total connections equals the current index(+1 to get a page) + 1 more set to ensure that the next set of nodes is always ready.
    let requiredTotalConnections =
      (nextConnectionsSetIndex + 2) * MAX_GRAPH_CONNECTIONS

    //Ensure there are connections to fetch and that more connections are required. Always fetches a few extra so that at minimum the next set of connections has already been fetched.
    if (
      currentTotalConnections < expectedTotalConnections &&
      currentTotalConnections <= requiredTotalConnections
    ) {
      if (selectedNode.type === 'tokens') {
        const tokenId: TokenIdSelector = yield select((state: AppState) =>
          tokenIdSelector(state, selectedNode.id)
        )

        yield call(
          fetchAndSetConnections,
          tokenId,
          selectedNode.type,
          platformId
        )
      } else {
        yield call(
          fetchAndSetConnections,
          selectedNode.id,
          selectedNode.type,
          platformId
        )
      }
    }
  }
}

export function* graphSagas() {
  yield fork(changeSelectedNodeWatcher)
  yield fork(toggleGraphFilterWatcher)
  yield fork(changeVisibleConnectionsSetWatcher)
}
