import {
    GET_REQUEST,
    GET_REQUEST_SUCCESS,
    GET_ALL_REQUESTS,
    GET_ALL_REQUESTS_SUCCESS,
    CREATE_REQUEST,
    CREATE_REQUEST_SUCCESS,
    UPDATE_REQUEST_STATUS,
    UPDATE_REQUEST_STATUS_SUCCESS,
    DELETE_REQUEST,
    DELETE_REQUEST_SUCCESS,
    LOAD_MORE_REQUESTS_SUCCESS,
    GET_ALL_REQUESTS_OVERVIEW_SUCCESS,
    GET_ALL_REQUESTS_SENT_SUCCESS,
    GET_ALL_REQUESTS_RECEIVED_SUCCESS,
    REQUESTS_REFRESHED,
    REQUEST_REFRESHED

} from 'constants/ActionTypes';

export const getRequest_ = (data) => {
    return {
        type: GET_REQUEST,
        payload: data
    };
};

export const getRequestSuccess_ = (data) => {
    return {
        type: GET_REQUEST_SUCCESS,
        payload: data
    };
};

export const getAllRequests_ = (data) => {
    return {
        type: GET_ALL_REQUESTS,
        payload: data
    };
};

export const getAllRequestsSuccess_ = (requests) => {
    return {
        type: GET_ALL_REQUESTS_SUCCESS,
        payload: requests
    };
};

export const getAllRequestsOverviewSuccess_ = (requests) => {
    return {
        type: GET_ALL_REQUESTS_OVERVIEW_SUCCESS,
        payload: requests
    };
};

export const getAllRequestsReceivedSuccess_ = (requests) => {
    return {
        type: GET_ALL_REQUESTS_RECEIVED_SUCCESS,
        payload: requests
    };
};

export const getAllRequestsSentSuccess_ = (requests) => {
    return {
        type: GET_ALL_REQUESTS_SENT_SUCCESS,
        payload: requests
    };
};


export const loadMoreRequestsSuccess_ = (requests) => {
    return {
        type: LOAD_MORE_REQUESTS_SUCCESS,
        payload: requests
    };
};

export const createRequest_ = (data) => {
    return {
        type: CREATE_REQUEST,
        payload: data
    };
};

export const createRequestSuccess_ = (requestId) => {
    return {
        type: CREATE_REQUEST_SUCCESS,
        payload: requestId
    };
};

export const updateRequestStatus_ = (data) => {
    return {
        type: UPDATE_REQUEST_STATUS,
        payload: data
    };
};

export const updateRequestStatusSuccess_ = () => {
    return {
        type: UPDATE_REQUEST_STATUS_SUCCESS
    };
};

export const deleteRequest_ = (requestId) => {
    return {
        type: DELETE_REQUEST,
        payload: requestId
    };
};

export const deleteRequestSuccess_ = () => {
    return {
        type: DELETE_REQUEST_SUCCESS
    };
};

export const requestsRefreshed_ = (data) => {
    return {
        type: REQUESTS_REFRESHED,
        payload: data
    };
};


export const requestRefreshed_ = (data) => {
    return {
        type: REQUEST_REFRESHED,
        payload: data
    };
};