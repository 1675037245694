import React, { useEffect, useState } from "react";
import {withRouter} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import Header from "./Header/index";
import SideBar from "containers/SideBar/index";
import Footer from "components/Footer";
import Tour from "components/Tour/index";
import {COLLAPSED_DRAWER, FIXED_DRAWER} from "constants/ActionTypes";
import ColorOption from "containers/Customizer/ColorOption";
import {isIOS, isMobile} from "react-device-detect";
import { getNextPage_ } from "actions/IsFetching";
import ScrollLoader from "components/Loader/ScrollLoader";
import { NetworkVisualizer } from "components/networkVisualizer/networkVisualizer";

import { Button, Collapse, fade, makeStyles } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import clsx from 'clsx';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { openHelpSection_ } from "actions";
import GetStarted from "app/routes/Help/GetStarted";
import { AppLoaderFull } from "components/Loader/AppLoaderFull";


const useStyles = makeStyles(theme => ({
    customizerBtn: {
      position: 'fixed',
      right: 46,
      top: 65,
      zIndex: 1201,
      backgroundColor: "#2daeee",
      color: theme.palette.common.white,
      width: 34,
      height: 34,
      minWidth: 10,
      borderRadius: '5px 5px 5px 5px',
      boxShadow: '0px 2px 5px rgba(72, 27, 169, 0.3)',
      '&:hover': {
        backgroundColor: fade(theme.palette.primary.main, 0.9),
        color: theme.palette.common.white,
      },
      [theme.breakpoints.up('lg')]: {
        width: 45,
        height: 34,
      },
      [theme.breakpoints.up('xl')]: {
        width: 45,
        height: 34,
      },
    },

    customizerOption: {
      width: 300,
      [theme.breakpoints.up('sm')]: {
        width: 380,
      },
    },
  }));

const Vertical = (props) => {

    const classes = useStyles();

    const dispatch = useDispatch()

    const {drawerType} = useSelector(({settings}) => settings);
    const {isLoadingMore, scrollLoader} = useSelector(({isFetching})=>isFetching);
    const {helpSectionOpen} = useSelector(({navigation})=>navigation);
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? "fixed-drawer" : drawerType.includes(COLLAPSED_DRAWER) ? "collapsible-drawer" : "mini-drawer";

    const [nextPage, setNextPage] = useState(1);

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
        document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
        document.body.classList.remove("ios-mobile-view-height");
    }

    useEffect(()=>{
        setNextPage(1);
        dispatch(openHelpSection_(false))
    }, [props.history.location.pathname])

    // console.log(props);
    const handleScroll = event => {
        const element = event.target;
        
        if (!scrollLoader && (Math.ceil(element.scrollHeight - element.scrollTop - 2) <= element.clientHeight)) {
            // console.log(element.scrollHeight - element.scrollTop - 1, element.clientHeight)
            dispatch(getNextPage_(nextPage + 1));
            setNextPage(nextPage + 1);
            // console.log("next page: ", nextPage);
        }
      };

    const handleOpenHelpSection = () => {
        document.getElementById("app-main-cn").scrollTop = 0;
        dispatch(openHelpSection_(!helpSectionOpen))
    }

    const handleOpen = () => {
        dispatch(openHelpSection_(!helpSectionOpen));
    }

    return (
        <div className={`app-container ${drawerStyle}`} >
            <div className="app-header">
                <Header/>
            </div>
            <Tour/>
            <Button
                className={clsx(classes.customizerBtn, 'Cmt-customizerBtn')}
                onClick={handleOpenHelpSection}
                data-tut="reactour__customizer">
                <HelpOutlineIcon />
            </Button>
            
            <SideBar/>
            <div className="app-main-container">
                {/*<div className="app-header">
                    <Header/>
                </div>*/}

                <main id="app-main-cn" className="app-main-content-wrapper" onScroll={handleScroll}>
                    <div  className="app-main-content" >
                        {<Collapse className='nav-collapse-item' in={helpSectionOpen} timeout="auto">
                            <GetStarted handleOpen={handleOpen} open={helpSectionOpen}/>
                        </Collapse>}
                        {props.children}
                    </div>
                    <ScrollLoader />
                    
                </main>
            </div>
            <NetworkVisualizer/>
            
            {/*<ColorOption/>*/}
        </div>
    );
};

export default withRouter(Vertical);
