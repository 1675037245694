import {all, call, delay, fork, put, select, takeEvery} from "redux-saga/effects";

import {
    GET_ALL_UTILITIES,
    GET_ALL_UTILITIES_SUCCESS,
    GET_UTILITY,
    GET_UTILITY_SUCCESS,
    CREATE_UTILITY,
    CREATE_UTILITY_SUCCESS,
    DELETE_UTILITY,
    DELETE_UTILITY_SUCCESS,
    UPDATE_UTILITY,
    UPDATE_UTILITY_SUCCESS
} from "constants/ActionTypes";

import {
    getAllUtilities_,
    getAllUtilitiesSuccess_,
    getUtility_,
    getUtilitySuccess_,
    createUtility_,
    createUtilitySuccess_,
    updateUtility_,
    updateUtilitySuccess_,
    deleteUtility_,
    deleteUtilitySuccess_,
    loadMoreUtilitiesSuccess_,
    utilitiesRefreshed_,
    utilityRefreshed_

} from "actions/Utility";

import {
    getAllUtilities,
    getUtility,
    createUtility,
    deleteUtility,
    updateUtility

} from "../api";

import { Auth } from 'aws-amplify';
import { hideDashbaordLoader_, hideDialogueLoader_, hideIsFetching_, hideMainLoader_, hideScrollLoader_, hideTransparentLoader_, isLoadingMore_, showDashbaordLoader_, showDialogueLoader_, showIsFetching_, showLoaderCreateSuccess_, showLoaderDeleteSuccess_, showLoaderUpdateSuccess_, showMainLoader_, showScrollLoader_, showTransparentLoader_ } from "actions/IsFetching";
import { updateImageSuccess_, updateImage_ } from "actions/Img";
import { createNewImage } from "./Img";
import { formatAssetData } from "util/formatAssetData";
import { getAssetSuccess_, userSignOut } from "actions";
import { errorHandler, successHandler } from "util/messageHandler";
import { viewAlertMessage_ } from "actions/Alert";


const CONTENT_TYPE_SUFFIX_MAPPINGS = {
    'image/jpg': 'jpg',
    'image/jpeg': 'jpg',
    'image/svg+xml': 'svg',
    'image/png': 'png',
};
const getSupportedContentTypes = () => {
    return Object.keys(CONTENT_TYPE_SUFFIX_MAPPINGS);
}
const isValidImageContentType = (contentType) => {
    return Object.keys(CONTENT_TYPE_SUFFIX_MAPPINGS).includes(contentType);
}
const getFileSuffixForContentType = (contentType) => {
    return CONTENT_TYPE_SUFFIX_MAPPINGS[contentType];
}

// Get JWToken from Local Storage


function* getAllUtilitiesByFilter({payload}) {
    
    const {JWToken, querryParams, refresh, loadMore, noLoader} = payload;
    const utilityList = yield select(state => state.utility.utilityList)

    if (loadMore) {
        yield put(showScrollLoader_());
        yield put(isLoadingMore_(true));
    }
    
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDashbaordLoader_());
            } 
        }
        const apiResponse = yield call(getAllUtilities, JWToken, querryParams);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 

            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            if (loadMore) {
                yield put(getAllUtilitiesSuccess_([...utilityList, ...apiResponse.data.utilities])); 
            } else {
                yield put(getAllUtilitiesSuccess_(apiResponse.data.utilities));
            }
            
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideDashbaordLoader_());
        yield put(hideTransparentLoader_());
        yield put(hideScrollLoader_());
        if (loadMore) {
            yield put(isLoadingMore_(false));
        }
    }
    
}



function* getUtilityById({payload}) {
    const {JWToken, utilityId, dashboard, refresh, noLoader} = payload;
    let formattedAsset;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                if (dashboard) {
                    yield put(showDashbaordLoader_());
                } else {
                    yield put(showMainLoader_());
                }
            }
        }
        
        const apiResponse = yield call(getUtility, JWToken, utilityId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            console.log("apiResponse_error UTILITY: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            let assetData = apiResponse.data;
            let assetType = "utilities";
            formattedAsset = yield call(formatAssetData, assetData, assetType);
            // console.log("FORMATTED: ", formattedAsset);
            yield put(getAssetSuccess_(formattedAsset)); 
            yield put(getUtilitySuccess_(apiResponse.data));        
        }
    } catch (error) {
        console.log("catchError: UTILITY: ", error);
        yield put(userSignOut());
    } finally {
        yield put(hideMainLoader_());
        yield put(hideDashbaordLoader_());
        yield put(hideTransparentLoader_());
    }

}

function* createUtilityWithData({payload}) {
    const {JWToken, utilityData, imgData, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDashbaordLoader_());
            } 
        }
        let fetchingResult = {};
        const apiResponse = yield call(createUtility, JWToken, utilityData);
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            fetchingResult.error = "Error";
            yield put(showLoaderCreateSuccess_(fetchingResult));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            if (imgData !== "") {
                // console.log("apiResponse: ", apiResponse.data);
                imgData.utilityId = apiResponse.data.utilityId;
                const downloadUrl = yield call(createNewImage, JWToken, imgData);
                yield put(updateImageSuccess_(downloadUrl));
            }
            yield delay(2500);
            yield put(utilitiesRefreshed_(true));
            fetchingResult.success = "Success";
            yield put(showLoaderCreateSuccess_(fetchingResult));
            yield put(createUtilitySuccess_(apiResponse.data.utilityId));

            // alert message   
            const successMessage = yield call(successHandler,"Utility", "", "created");
            yield put(viewAlertMessage_(successMessage)); 
                  
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideDashbaordLoader_());
    }

}

function* updateUtilityWithData({payload}) {
    const {JWToken, utilityId, utilityData, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDashbaordLoader_());
            } 
        }
        let fetchingResult = {};
        const apiResponse = yield call(updateUtility, JWToken, utilityId, utilityData);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            fetchingResult.error = "Update Error";
            yield put(showLoaderUpdateSuccess_(fetchingResult));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(utilityRefreshed_(true));
            fetchingResult.error = "Success";
            yield put(showLoaderUpdateSuccess_(fetchingResult));
            yield put(updateUtilitySuccess_());   
            
            /* alert message   
            const successMessage = yield call(successHandler,"Utility", "", "updated");
            yield put(viewAlertMessage_(successMessage)); */
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideDashbaordLoader_());
    }

}

function* deleteUtilityById({payload}) {
    const {JWToken, utilityId, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        let fetchingResult = {};
        const apiResponse = yield call(deleteUtility, JWToken, utilityId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            fetchingResult.error = "Error";
            yield put(showLoaderDeleteSuccess_(fetchingResult));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(utilitiesRefreshed_(true));
            fetchingResult.error = "Success";
            yield put(showLoaderDeleteSuccess_(fetchingResult));
            yield put(deleteUtilitySuccess_());   
            
            // alert message   
            const successMessage = yield call(successHandler,"Utility", "", "deleted");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideDialogueLoader_());
        yield put(hideTransparentLoader_());
    }

}

export function* readAllUtilities() {
    yield takeEvery(GET_ALL_UTILITIES, 
        getAllUtilitiesByFilter);
}

export function* readOneUtility() {
    yield takeEvery(GET_UTILITY, 
        getUtilityById);
}

export function* createOneUtility() {
    yield takeEvery(CREATE_UTILITY, 
        createUtilityWithData);
}

export function* updateOneUtility() {
    yield takeEvery(UPDATE_UTILITY, 
        updateUtilityWithData);
}

export function* removeOneUtility() {
    yield takeEvery(DELETE_UTILITY, 
        deleteUtilityById);
}

export default function* rootSaga() {
    yield fork(readAllUtilities)
    yield fork(readOneUtility)
    yield fork(createOneUtility)
    yield fork(removeOneUtility)
    yield fork(updateOneUtility)
}