import {
    GET_ALL_REQUESTS_SUCCESS,
    CREATE_REQUEST_SUCCESS,
    UPDATE_REQUEST_STATUS_SUCCESS,
    DELETE_REQUEST_SUCCESS,
    GET_REQUEST_SUCCESS,
    GET_ALL_REQUESTS_OVERVIEW_SUCCESS,
    GET_ALL_REQUESTS_RECEIVED_SUCCESS,
    GET_ALL_REQUESTS_SENT_SUCCESS,
    REQUESTS_REFRESHED,
    REQUEST_REFRESHED
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    requestList: '',
    requestListOverview: '',
    requestListReceived: '',
    requestListSent: '',
    selectedRequest: '',
    requestRefreshed: false,
    requestsRefreshed: false
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_REQUEST_SUCCESS: {
            return {
                ...state,
                selectedRequest: action.payload
            }
        }
        case GET_ALL_REQUESTS_SUCCESS: {
            return {
                ...state,
                requestList: action.payload
            }
        }
        case GET_ALL_REQUESTS_OVERVIEW_SUCCESS: {
            return {
                ...state,
                requestListOverview: action.payload
            }
        }
        case GET_ALL_REQUESTS_RECEIVED_SUCCESS: {
            return {
                ...state,
                requestListReceived: action.payload
            }
        }
        case GET_ALL_REQUESTS_SENT_SUCCESS: {
            return {
                ...state,
                requestListSent: action.payload
            }
        }
        case CREATE_REQUEST_SUCCESS: {
            return {
                ...state,
                loader: false
            }
        }
        case UPDATE_REQUEST_STATUS_SUCCESS: {
            return {
                ...state,
            }
        }
        case DELETE_REQUEST_SUCCESS: {
            return {
                ...state,
                loader: false
            }
        }
        case REQUESTS_REFRESHED: {
            return {
                ...state,
                requestsRefreshed: action.payload
            }
        }
        case REQUEST_REFRESHED: {
            return {
                ...state,
                requestRefreshed: action.payload
            }
        }
        default:
            return state;
    }
}