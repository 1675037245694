import { MAX_GRAPH_CONNECTIONS } from 'components/networkVisualizer/networkVisualizerState/store/graph/reducer'
import { call, put } from 'redux-saga/effects'
import { GraphTypes } from '../../../sharedTypes'
import { setVisualizerFetching } from '../../../store/general/actions'
import { fetchAndSetAssetData } from './_fetchAndSetAssetData'
import { fetchProjectData } from './_fetchAndSetProjectData'
import { fetchAndSetWalletData } from './_fetchAndSetWalletData'

export const CONNECTIONS_FETCH_LIMIT = MAX_GRAPH_CONNECTIONS * 2

/**
 * Use this when changing main graph node.
 * Used to fetch any assets. Utilizes the appropriate function depending on the asset type. In most cases, just call this function rather than the other 2 functions directly.
 *
 * For getting assets and their connections, multiple API calls are required. The getAssetData API call does NOT provide information about what items are connected to it.
 * A separate fetch to getCooperations is required to get the appropriate connections. This data needs to be normalized and added to the generalData while the connections (ie. {tokens: [id, id, id, etc...]}) must be extracted from the same response and merged with the getAssetData information.
 *
 */
export function* fetchAndSetData(
  id: string | number,
  type: GraphTypes,
  platformId?: string | number,
  blockchain?: string
) {
  yield put(setVisualizerFetching(true))
  try {
    if (type === 'projects') {
      yield call(fetchProjectData, id)
    } else if (type === 'wallets') {
      yield call(fetchAndSetWalletData, id, blockchain)
    } else {
      yield call(fetchAndSetAssetData, id, type, platformId)
    }
  } catch (e) {
    //<<<<<<<<Handle error gracefully>>>>>>>>>>>>>
    // console.log('fetch and set data error = ', e)
  } finally {
    yield put(setVisualizerFetching(false))
  }
}
