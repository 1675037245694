import {
    GET_ALL_UTILITIES,
    GET_ALL_UTILITIES_SUCCESS,
    GET_UTILITY,
    GET_UTILITY_SUCCESS,
    CREATE_UTILITY,
    CREATE_UTILITY_SUCCESS,
    UPDATE_UTILITY,
    UPDATE_UTILITY_SUCCESS,
    DELETE_UTILITY,
    DELETE_UTILITY_SUCCESS,
    LOAD_MORE_UTILITIES_SUCCESS,
    UTILITIY_REFRESHED,
    UTILITIES_REFRESHED

} from 'constants/ActionTypes';

export const getAllUtilities_ = (data) => {
    return {
        type: GET_ALL_UTILITIES,
        payload: data
    };
};

export const getAllUtilitiesSuccess_ = (utilities) => {
    return {
        type: GET_ALL_UTILITIES_SUCCESS,
        payload: utilities
    };
};

export const loadMoreUtilitiesSuccess_ = (utilities) => {
    return {
        type: LOAD_MORE_UTILITIES_SUCCESS,
        payload: utilities
    };
};

export const getUtility_ = (data) => {
    return {
        type: GET_UTILITY,
        payload: data
    };
};

export const getUtilitySuccess_ = (data) => {
    return {
        type: GET_UTILITY_SUCCESS,
        payload: data
    };
};

export const createUtility_ = (data) => {
    return {
        type: CREATE_UTILITY,
        payload: data
    };
};

export const createUtilitySuccess_ = (utilityId) => {
    return {
        type: CREATE_UTILITY_SUCCESS,
        payload: utilityId
    };
};

export const updateUtility_ = (data) => {
    return {
        type: UPDATE_UTILITY,
        payload: data
    };
};

export const updateUtilitySuccess_ = () => {
    return {
        type: UPDATE_UTILITY_SUCCESS
    };
};

export const deleteUtility_ = (utilityId) => {
    return {
        type: DELETE_UTILITY,
        payload: utilityId
    };
};

export const deleteUtilitySuccess_ = () => {
    return {
        type: DELETE_UTILITY_SUCCESS
    };
};

export const utilityRefreshed_ = (data) => {
    return {
        type: UTILITIY_REFRESHED,
        payload: data
    };
};

export const utilitiesRefreshed_ = (data) => {
    return {
        type: UTILITIES_REFRESHED,
        payload: data
    };
};
