import { ProjectResponse } from 'components/networkVisualizer/API/types/getAssetDataResponse/project'
import { Projects } from 'components/networkVisualizer/networkVisualizerState/store/general/types'

interface ProjectFormatterOutput {
  projects: Projects
}

export function projectFormatter(
  response: ProjectResponse
): ProjectFormatterOutput {
  let projects: Projects = {}

  let { projectId, avatarImage, name, websiteUrl, assetCounts } = response

  projects[projectId] = {
    name,
    image: avatarImage?.thumbnail,
    websiteUrl,
    cooperationsCount: assetCounts,
    //Is this the correct value?? Consider removing it if it's not accurate or not being used.
    // tokenCount: pinnedTokens?.length,
  }

  return {
    projects,
  }
}
