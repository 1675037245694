import { dataUpdater } from './modules/dataUpdater'
import {
  GeneralActions,
  GeneralState,
  MERGE_VISUALIZER_ITEM_CONNECTIONS,
  RESET_VISUALIZER_GENERAL_STATE,
  RESET_VISUALIZER_ITEM_CONNECTIONS,
  SET_NETWORK_VISUALIZER_OPENING_STATE,
  SET_VISUALIZER_DATA,
  SET_VISUALIZER_FETCHING,
  TOGGLE_FULL_SCREEN,
  TOGGLE_NETWORK_VISUALIZER,
} from './types'

const defaultData = {
  tokens: {},
  utilities: {},
  projects: {},
  resources: {},
  wallets: {},
}

const defaultState: GeneralState = {
  showVisualizer: false,
  isOpening: false,
  data: defaultData,
  fullScreen: false,
  fetching: false,
}

export const generalReducer = (
  state = defaultState,
  action: GeneralActions
): GeneralState => {
  switch (action.type) {
    case TOGGLE_NETWORK_VISUALIZER:
      return {
        ...state,
        showVisualizer: action.payload,
        fullScreen: action.payload ? false : state.fullScreen,
      }
    case SET_NETWORK_VISUALIZER_OPENING_STATE:
      return {
        ...state,
        isOpening: action.payload,
      }
    case SET_VISUALIZER_DATA:
      return {
        ...state,
        data: dataUpdater(state.data, action.payload),
      }
    case MERGE_VISUALIZER_ITEM_CONNECTIONS:
      let {
        itemType,
        itemId,
        newConnectionsType,
        newConnections,
      } = action.payload

      //If previous connection of newConnectionsType are undefined, use an empty array.
      let previousConnectionsOfType =
        state.data[itemType][itemId]?.connections?.[newConnectionsType] ?? []

      //Ensures there are no repeats. Is this required?
      let newConnectionsFiltered = newConnections.filter(
        (c) => previousConnectionsOfType.indexOf(c) < 0
      )
      return {
        ...state,
        data: {
          ...state.data,
          [itemType]: {
            ...state.data[itemType],
            [itemId]: {
              ...state.data[itemType][itemId],
              connections: {
                ...state.data[itemType][itemId].connections,
                [newConnectionsType]: [
                  ...previousConnectionsOfType,
                  ...newConnectionsFiltered,
                ],
              },
            },
          },
        },
      }
    case RESET_VISUALIZER_ITEM_CONNECTIONS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.itemType]: {
            ...state.data[action.payload.itemType],
            [action.payload.itemId]: {
              ...state.data[action.payload.itemType][action.payload.itemId],
              connections: {
                tokens: [],
              },
            },
          },
        },
      }
    case TOGGLE_FULL_SCREEN:
      return {
        ...state,
        fullScreen: action.payload || !state.fullScreen,
      }
    case SET_VISUALIZER_FETCHING:
      return {
        ...state,
        fetching: action.payload,
      }
    case RESET_VISUALIZER_GENERAL_STATE:
      return defaultState
    default:
      return state
  }
}
