// import './dev/wdyr'; // <--- first import
import React from 'react';
import {ConnectedRouter} from 'connected-react-router'
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

import configureStore, {history} from './store';
import App from './containers/App';

////// PUSHER
import Pusher from 'pusher-js';

/////// AMPLIFY-COGNITO
import Amplify, { Auth, API, Storage, PubSub } from 'aws-amplify';
import PusherEventHandler from 'containers/EventHandler/PusherEventHandler';
import UtilifyEventHandler from 'containers/EventHandler/UtilifyEventHandler';
import { NetworkVisualizer } from 'components/networkVisualizer/networkVisualizer';
import BrowserEventListener from 'containers/EventHandler/BrowserEventListener';

// import { MqttOverWSProvider } from "@aws-amplify/pubsub/lib/Providers";

if (process.env.REACT_APP_UTILIFY_API!=="https://d1kfyd722jt8ef.cloudfront.net/platform") {
    // console.log = () => {};
}


// multi tab trigger event on open
// window.localStorage.setItem('REQUESTING_SHARED_CREDENTIALS', Date.now().toString())
// window.localStorage.removeItem('REQUESTING_SHARED_CREDENTIALS')


Amplify.configure({
    Auth: {

        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: process.env.REACT_APP_UTILIFY_IDENTITY_POOL_ID,
        
        // REQUIRED - Amazon Cognito Region
        region: process.env.REACT_APP_UTILIFY_LOCATION,

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
        // Required only if it's different from Amazon Cognito Region
        // identityPoolRegion: 'XX-XXXX-X',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_UTILIFY_USER_POOL_ID,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.REACT_APP_UTILIFY_CLIENT_ID,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        // mandatorySignIn: false,

        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        // cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        //     domain: '.yourdomain.com',
        // OPTIONAL - Cookie path
        //    path: '/',
        // OPTIONAL - Cookie expiration in days
        //    expires: 365,
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        //    secure: true
        // },

        // OPTIONAL - customized storage object
        // storage: new MyStorage(),

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        // authenticationFlowType: 'USER_PASSWORD_AUTH',

        // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
        // clientMetadata: { myCustomKey: 'myCustomValue' },

        // OPTIONAL - Hosted UI configuration
        // oauth: {
        //     domain: 'https://utilify.auth.eu-central-1.amazoncognito.com',
        //     scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        //     redirectSignIn: 'http://localhost:3000/',
        //     redirectSignOut: 'http://localhost:3000/',
        //     responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    },

    API: {
        endpoints: [
            {
                name: "utilify-api-dev",
                // endpoint: "https://api.stage.utilify.io/platform"
                endpoint: process.env.REACT_APP_UTILIFY_API
            }

            // {
            //    name: "MyCustomCloudFrontApi",
            //    endpoint: "https://api.my-custom-cloudfront-domain.com",
    
            // }
        ]
    },

    /*Storage: {
        AWSS3: {
            bucket: 'utility-imgs-401439326364', //REQUIRED -  Amazon S3 bucket name
            // region: 'XX-XXXX-X', //OPTIONAL -  Amazon service region
        }
    }*/
});


/* Apply plugin with configuration
Amplify.addPluggable(new MqttOverWSProvider({
    aws_pubsub_region: "us-east-1",
    aws_pubsub_endpoint: `wss://afdhskcsl5z2v-ats.iot.us-east-1.amazonaws.com/mqtt`,
}));

Amplify.PubSub.subscribe('utilify/stage/test/events/project/73/request').subscribe({
    next: data => console.log('Message received', data),
    error: error => console.error(error),
    close: () => console.log('Done'),
});*/

// You can get the current config object
const currentConfig = Auth.configure();

// console.log("Load MainApp");

///////

export const store = configureStore();

const MainApp = () => 
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Switch>
                <Route path="/" component={App}/>
            </Switch>
        </ConnectedRouter>
        <PusherEventHandler />
        
        {/*<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< the Visualizer to a more approopriate place >>>>>>>>>>>>*/}
        {/*<BrowserEventListener />*/}
        {/*<UtilifyEventHandler />*/}
    </Provider>

export default MainApp;


/* 
    /////////////////////////////////// PUSHER CONNECTION DETAILS

    "notifications": {
        "pusher": {
          "key": "a826ad9293ce1ae1a036",
          "channels": {
            "app": "enjincloud.kovan.app.{id}",
            //// "identity": "enjincloud.kovan.identity.{id}",
            "token": "enjincloud.kovan.token.{id}",
            "user": "enjincloud.kovan.user.{id}",
            "wallet": "enjincloud.kovan.wallet.{ethAddress}"
          },
          "namespace": "EnjinCloud\\Events",
          "options": {
            "cluster": "mt1",
            "encrypted": true
          }
        }
      }


      /////////////////////////////   UTILIFY IOT CORE ENDPUNKT

      afdhskcsl5z2v-ats.iot.us-east-1.amazonaws.com




      /////////////////////////////   UTILIFY EVENT SYTSEM

      EventSystem Format:

        {sharedBaseTopic}/{eventCategory}/{subtopic}/{eventType}



        - 'sharedBaseTopic'
            - environment 'prod':
                - `utilify/{ENVIRONMENT}/{CHAIN}/`;
            - environment != 'prod'
                - `utilify/{CHAIN}/`;


        - 'ENVIRONMENT'
            - test
            - stage
            - prod

        - 'Chain'
            - test
            - main


        - 'eventCategory'
            - 'sytemEvents'
                - utilify platform internal system events (platform--> listener)
            - 'events'
                - platform public events (platform --> frontend / sdk(?))
            
        - 'subtopic'
            - public events:
                - '/project/{projectId}/
                - '/user/{userId}/
            - system events
                - none

        - 'eventType':
            system:
                - ProjectWalletCreated
                - ProjectWalletUpdated
                - ProjectWalletDeleted
            public:
                - UtilifyEvents:
                    - notification
                        - message: {"type": {type}}
                        - 'type':
                            - request
                            - requestResponse
                            - follower
                        - broadcastOn:
                            - '../events/user/{userId}/notification
                    - message
                        - broadcastOn:
                            - '../events/user/{userId}/message
                    - request
                        - broadcastOn:
                            - '../events/project/{projectId}/request
                    - requestResponse
                        - message: {"type": {type}}
                        - broadcastOn:
                            - '../events/project/{projectId}/requestResponse
                - ...tbd


*/