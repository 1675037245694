import {all, call, fork, put, takeEvery, takeLatest, delay, select} from "redux-saga/effects";

import {
    CREATE_TOKEN,
    GET_TOKEN_BLUEPRINT,
    GET_TOKEN_STATS,
    UPDATE_METADATA,
    UPDATE_URI,
    CREATE_TOKEN_SUCCESS,
    GET_TOKEN_BLUEPRINT_SUCCESS,
    GET_TOKEN_STATS_SUCCESS,
    CREATE_METADATA_SUCCESS,
    UPDATE_URI_SUCCESS,
    GET_ALL_TOKEN_BLUEPRINTS,
    GET_METADATA
    
} from "constants/ActionTypes";

import {
    createToken_,
    createTokenSuccess_,
    getTokenBlueprint_,
    getTokenBlueprintSuccess_,
    getTokenStats_,
    getTokenStatsSuccess_,
    createMetaData_,
    updateMetaDataSuccess_,
    updateUri_,
    updateUriSuccess_,
    getAllTokenBlueprintsSuccess_,
    getMetaDataSuccess_,
    tokenRefreshed_,
    tokensRefreshed_

} from "actions/Token";

import {
    createToken,
    getTokenBlueprint,
    getTokenStats,
    createMetadata,
    updateUri,
    getAllTokenBlueprints,
    getMetadataByUri,
    updateMetadata,
    getMetadata

} from "../api";

import { Auth } from 'aws-amplify';
import { createNewImage } from "./Img";
import { updateImageSuccess_ } from "actions/Img";
import { hideDashbaordLoader_, hideDialogueLoader_, hideIsFetching_, hideMainLoader_, hideScrollLoader_, hideTransparentLoader_, isLoadingMore_, showDashbaordLoader_, showDialogueLoader_, showIsFetching_, showLoaderCreateSuccess_, showLoaderUpdateSuccess_, showMainLoader_, showScrollLoader_, showTransparentLoader_ } from "actions/IsFetching";
import { formatAssetData } from "util/formatAssetData";
import { getAssetSuccess_, userSignOut } from "actions";
import { errorHandler, infoHandler, successHandler } from "util/messageHandler";
import { viewAlertMessage_ } from "actions/Alert";
import { setCurrentTokenId_ } from "actions/EventListeners";

// Get JWToken from Local Storage

function* createNewTokenWithData({payload}) {
    const {JWToken, tokenData, imgData, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        let fetchingResult = {};
        const apiResponse = yield call(createToken, JWToken, tokenData);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            fetchingResult.error = "Error";
            yield put(showLoaderCreateSuccess_(fetchingResult));
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 

            /*console.log("apiResponse: ", apiResponse.data);
            imgData.tokenId = apiResponse.data.tokenId;
            console.log("imgData in Saga (after): ", imgData);
            const downloadUrl = yield call(createNewImage, JWToken, imgData);
            yield put(updateImageSuccess_(downloadUrl));*/
            yield put(tokensRefreshed_(true));
            fetchingResult.success = "Success";
            yield put(showLoaderCreateSuccess_(fetchingResult));
            yield put(createTokenSuccess_()); 
            
            /* alert message   
            const successMessage = yield call(successHandler,"Create Request for Token Blueprint", "", "made");
            yield put(viewAlertMessage_(successMessage)); 
            // alert message   
            const infoMessage = yield call(infoHandler,"token created");
            yield put(viewAlertMessage_(infoMessage)); */
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideDialogueLoader_());
    }


}

function* getTokenBlueprintById({payload}) {
    const {JWToken, platform, tokenId, dashboard, refresh, querryParams, getUncached, noLoader} = payload;
    let formattedAsset;
    if (getUncached) {
        yield delay(3000);
    }
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                if (dashboard) {
                    yield put(showDashbaordLoader_());
                } else {
                    yield put(showMainLoader_());
                }
            }
        }
        
        
        const apiResponse = yield call(getTokenBlueprint, JWToken, platform, tokenId, querryParams);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            let assetData = apiResponse.data;
            let assetType = "tokens";
            console.log("formatted: ", assetData);
            formattedAsset = yield call(formatAssetData, assetData, assetType);
            
            yield put(getAssetSuccess_(formattedAsset)); 
            yield put(setCurrentTokenId_(apiResponse.data.tokenId));
            yield put(getTokenBlueprintSuccess_(apiResponse.data));        
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideMainLoader_());
        yield put(hideDashbaordLoader_());
        yield put(hideTransparentLoader_());
    }

}

function* getAllTokenBlueprintsByData({payload}) {
    
    const {JWToken, querryParams, loadMore, refresh, noLoader} = payload;
    const tokenList = yield select(state => state.token.tokenList)
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDashbaordLoader_());
            } 
        }

        if (loadMore) {
            yield put(showScrollLoader_());
            yield put(isLoadingMore_(true))
        }
        /*if (querryParams.name.length === 1) {
            yield delay(100);
        }*/
        const apiResponse = yield call(getAllTokenBlueprints, JWToken, querryParams);
        console.log("token blueprints called");
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse__ SAGA: ", apiResponse.data);
            /*let i;
            for (i=0; i<apiResponse.data.tokens.length; i++ ) {
                let tokenId = apiResponse.data.tokens[i].tokenId;
                console.log("tokenId: ", tokenId);
                let itemUri = apiResponse.data.tokens[i].itemUri.replace('{id}', tokenId);
                console.log("itemUri: ", itemUri);
                if (itemUri) {
                    let metaData = yield call(getMetadataByUri, itemUri);
                    console.log("METADATA: ", metaData);
                    apiResponse.data.tokens[i].metaData = metaData;
                    }
                } */
                if (loadMore) {
                    yield put(getAllTokenBlueprintsSuccess_([...tokenList, ...apiResponse.data.tokens])); 
                } else {
                    yield put(getAllTokenBlueprintsSuccess_(apiResponse.data.tokens));
                }
              
        }
    } catch (error) {
        // console.log("catchError: ", error);
    } finally {
        yield put(hideDashbaordLoader_());
        yield put(hideTransparentLoader_());
        yield put(hideScrollLoader_());
        if (loadMore) {
            yield put(isLoadingMore_(false))
        }
    }

}

function* getTokenStatsById({payload}) {
    const {JWToken, tokenId, tokenIndex, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        const apiResponse = yield call(getTokenStats, JWToken, tokenId, tokenIndex);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(getTokenStatsSuccess_(apiResponse.data));        
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideDialogueLoader_());
    }

}

function* updateMetaDataWithData({payload}) {
    const {JWToken, tokenId, metaData, refresh, noLoader} = payload;
    let fetchingResult = {};
    try {
        if (!noLoader) {
            yield put(showTransparentLoader_());
        }
        const apiResponse = yield call(updateMetadata, JWToken, tokenId, metaData);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            // fetchingResult.error = "error";
            // yield put(showLoaderUpdateSuccess_(fetchingResult));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 

            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apifetchingResult.success = "Success";
            // fetchingResult.success = "success";
            // yield put(showLoaderUpdateSuccess_(fetchingResult));
            // alert message   
            yield put(tokenRefreshed_(true));
            const successMessage = yield call(successHandler,"Metadata", "", "updated");
            yield put(viewAlertMessage_(successMessage)); 

            yield put(updateMetaDataSuccess_(apiResponse.data));        
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
    }

}

function* getMetadataWithData({payload}) {
    console.log("saga triggered");
    const {JWToken, platform, tokenId, refresh, noLoader} = payload;
    let fetchingResult = {};
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        // yield put(showTransparentLoader_())
        const apiResponse = yield call(getMetadata, JWToken, platform, tokenId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apifetchingResult.success = "Success";

            yield put(getMetaDataSuccess_(apiResponse.data));        
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideDialogueLoader_());
    }

}

function* updateTokenUriById({payload}) {
    const {JWToken, tokenId, itemUri, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        let fetchingResult = {};
        const apiResponse = yield call(updateUri, JWToken, tokenId, itemUri);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            

            fetchingResult.error = "Error";
            yield put(showLoaderUpdateSuccess_(fetchingResult));
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);

            
            yield put(tokensRefreshed_(true));
            // yield put(updateUriSuccess_()); 
            
            // alert message   
            const infoMessage = yield call(infoHandler, "uri updated");
            yield put(viewAlertMessage_(infoMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideDialogueLoader_());
        yield put(hideTransparentLoader_());
    }

}


export function* createOneToken() {
    yield takeEvery(CREATE_TOKEN, 
        createNewTokenWithData);
}

export function* readTokenBlueprint() {
    yield takeEvery(GET_TOKEN_BLUEPRINT, 
        getTokenBlueprintById);
}

export function* readAllTokenBlueprints() {
    yield takeEvery(GET_ALL_TOKEN_BLUEPRINTS, 
        getAllTokenBlueprintsByData);
}

export function* readTokenStats() {
    yield takeEvery(GET_TOKEN_STATS, 
        getTokenStatsById);
}

export function* updateOneMetaData() {
    yield takeEvery(UPDATE_METADATA, 
        updateMetaDataWithData);
}

export function* getOneMetaData() {
    yield takeEvery(GET_METADATA, 
        getMetadataWithData);
}

export function* refreshTokenUri() {
    yield takeEvery(UPDATE_URI, 
        updateTokenUriById);
}

export default function* rootSaga() {
    yield all([fork(createOneToken),
        fork(readTokenBlueprint),
        fork(readTokenStats),
        fork(updateOneMetaData),
        fork(refreshTokenUri),
        fork(getOneMetaData),
        fork(readAllTokenBlueprints)
    ]);
}