import { CooperationsCount } from 'components/networkVisualizer/API/types/sharedTypes'
import { CategoryTypes, SelectionData } from '../../sharedTypes'
import { GraphTypes } from './../../sharedTypes'

type ConnectionsArray = (string | number)[]

export type Connections = {
  [key in CategoryTypes]?: ConnectionsArray
}

interface ItemBaseDetails {
  name: string
  image?: string | null
  connections?: Connections
  cooperationsCount?: CooperationsCount
  tokenId?: string | number //Only applicable to tokens. Added here to help with TS.
  platformId?: string | number //Only applicable to tokens.
  platformName?: string //Only applicable to tokens.
}

interface AssetCommonDetails {
  projectName?: string
  expiry?: string | null
}

/**
 * Object ID example: "tokenId-platformId-tokenIndex"
 * Token ID's are not unique (There can be tokens of the same ID with different platform ID's as well as tokens of the same ID and same platformId but with different token Indexes) so the Object ID is a combination of tokens ID + platformId or ID + platformId + tokenIndex if coming from a wallet. Ensure the object ID is not used for fetching data relevant to the token. Instead use the id from the object.
 */
interface Token extends ItemBaseDetails, AssetCommonDetails {
  tokenId: string | number //Only applicable to tokens because the object key is a combination of the tokens ID + platformId. (ID + platformId + tokenIndex if connected to a wallet). This ID is the tokens actual ID to be used when fetching it's data.
  nonFungible?: boolean //Not provided with wallet response
  supplyModel?: string //Not provided with wallet response
  totalSupply?: string //Not provided with wallet response
  circulatingSupply?: string //Not provided with wallet response
  meltValue?: string //Not provided with wallet response
  meltFeeRatio?: number
  transferable?: string //Not provided with wallet response
  transferFee?: string
  rating?: number | string
  appName: string
}

interface Utility extends ItemBaseDetails, AssetCommonDetails {
  type?: string | null
  rating?: string | null
}

interface Resource extends ItemBaseDetails, AssetCommonDetails {
  category?: string
  modelType?: string
  platform?: string
  license?: string | null
  fileType?: string
}

interface Project extends ItemBaseDetails {
  websiteUrl?: string | null
  //This property isn't returned from the API anymore. Is it still in use?
  followersCount?: number
}

type Wallet = ItemBaseDetails

export interface Tokens {
  [key: string]: Token
}

export interface Utilities {
  [key: string]: Utility
}

export interface Projects {
  [key: string]: Project
}

export interface Resources {
  [key: string]: Resource
}

export interface Wallets {
  [key: string]: Wallet
}

export interface VisualizerData {
  tokens: Tokens
  utilities: Utilities
  projects: Projects
  resources: Resources
  wallets: Wallets
}

export interface GeneralState {
  showVisualizer: boolean
  isOpening: boolean
  data: VisualizerData
  fullScreen: boolean
  fetching: boolean
}

//saga action
export const OPEN_NETWORK_VISUALIZER = 'OPEN_NETWORK_VISUALIZER'

export interface OpenNetworkVisualizer {
  type: typeof OPEN_NETWORK_VISUALIZER
  payload: SelectionData
}

//saga action
export const RESET_NETWORK_VISUALIZER = 'RESET_NETWORK_VISUALIZER'

export interface ResetNetworkVisualizer {
  type: typeof RESET_NETWORK_VISUALIZER
}

//saga action
export const CLOSE_NETWORK_VISUALIZER = 'CLOSE_NETWORK_VISUALIZER'

export interface CloseNetworkVisualizer {
  type: typeof CLOSE_NETWORK_VISUALIZER
}

export const TOGGLE_NETWORK_VISUALIZER = 'TOGGLE_NETWORK_VISUALIZER'

export interface ToggleNetworkVisualizer {
  type: typeof TOGGLE_NETWORK_VISUALIZER
  payload: boolean
}

export const SET_NETWORK_VISUALIZER_OPENING_STATE =
  'SET_NETWORK_VISUALIZER_OPENING_STATE'

export interface SetNetworkVisualizerOpeningState {
  type: typeof SET_NETWORK_VISUALIZER_OPENING_STATE
  payload: boolean
}

export const SET_NETWORK_VISUALIZER_LOADING_STATE =
  'SET_NETWORK_VISUALIZER_LOADING_STATE'

export interface SetNetworkLoadingVisualizerLoading {
  type: typeof SET_NETWORK_VISUALIZER_LOADING_STATE
  payload: boolean
}

export const SET_VISUALIZER_DATA = 'SET_VISUALIZER_DATA'

export type SetVisualizerDataPayload = Partial<VisualizerData>

export interface SetVisualizerData {
  type: typeof SET_VISUALIZER_DATA
  payload: SetVisualizerDataPayload
}

export const RESET_VISUALIZER_GENERAL_STATE = 'RESET_VISUALIZER_GENERAL_STATE'

export interface ResetVisualizerGeneralState {
  type: typeof RESET_VISUALIZER_GENERAL_STATE
}

export const MERGE_VISUALIZER_ITEM_CONNECTIONS =
  'MERGE_VISUALIZER_ITEM_CONNECTIONS'

export interface MergeVisualizerItemConnectionsPayload {
  itemType: GraphTypes
  itemId: string | number
  newConnectionsType: CategoryTypes
  newConnections: ConnectionsArray
}

export const RESET_VISUALIZER_ITEM_CONNECTIONS =
  'RESET_VISUALIZER_ITEM_CONNECTIONS'

export interface ResetVisualizerItemConnectionsPayload {
  itemType: GraphTypes
  itemId: string | number
}

export interface ResetVisualizerItemConnections {
  type: typeof RESET_VISUALIZER_ITEM_CONNECTIONS
  payload: ResetVisualizerItemConnectionsPayload
}

export interface MergeVisualizerItemConnections {
  type: typeof MERGE_VISUALIZER_ITEM_CONNECTIONS
  payload: MergeVisualizerItemConnectionsPayload
}

export const TOGGLE_FULL_SCREEN = 'TOGGLE_FULL_SCREEN'

export interface ToggleFullScreen {
  type: typeof TOGGLE_FULL_SCREEN
  payload?: boolean
}

export const SET_VISUALIZER_FETCHING = 'SET_VISUALIZER_FETCHING'

export interface SetVisualizerFetching {
  type: typeof SET_VISUALIZER_FETCHING
  payload: boolean
}

export type GeneralActions =
  | OpenNetworkVisualizer
  | CloseNetworkVisualizer
  | ToggleNetworkVisualizer
  | SetNetworkVisualizerOpeningState
  | SetVisualizerData
  | ResetVisualizerGeneralState
  | MergeVisualizerItemConnections
  | ResetVisualizerItemConnections
  | ToggleFullScreen
  | SetNetworkLoadingVisualizerLoading
  | SetVisualizerFetching
