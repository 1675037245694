import { CLEAR_LINKING_DIALOGUE_ENJINAPP, DELETE_ENJINAPP, DELETE_ENJINAPP_SUCCESS, ENJIN_L_CONNECTED, ENJIN_L_CONNECTING, GET_ALL_ENJINAPPS, GET_ALL_ENJINAPPS_SUCCESS, GET_ENJINAPP, GET_ENJINAPP_SUCCESS, LINK_ENJINAPP, LINK_ENJINAPP_SUCCESS, SET_CURRENT_ENJINID, SET_CURRENT_ETHADDRESS, SET_CURRENT_TOKNEID, SET_CURRENT_WALLETID, SET_UTILIFY_EVENT_CHANNELS, UPDATE_ENJINAPP, UPDATE_ENJINAPP_SUCCESS, UTILIFY_L_CONNECTED, UTILIFY_L_CONNECTING } from "constants/ActionTypes";


export const enjinLConnecting_ = (data) => {
    return {
        type: ENJIN_L_CONNECTING,
        payload: data
    };
};

export const enjinLConnected_ = (data) => {
    return {
        type: ENJIN_L_CONNECTED,
        payload: data
    };
};

export const setCurrentEnjinId_ = (data) => {
    return {
        type: SET_CURRENT_ENJINID,
        payload: data
    };
};

export const setCurrentWalletId_ = (data) => {
    return {
        type: SET_CURRENT_WALLETID,
        payload: data
    };
};


export const setCurrentTokenId_ = (data) => {
    return {
        type: SET_CURRENT_TOKNEID,
        payload: data
    };
};

export const setCurrentEthAddress_ = (data) => {
    return {
        type: SET_CURRENT_ETHADDRESS,
        payload: data
    };
};

export const utilifyLConnecting_ = (data) => {
    return {
        type: UTILIFY_L_CONNECTING,
        payload: data
    };
};

export const utilifyLConnected_ = (data) => {
    return {
        type: UTILIFY_L_CONNECTED,
        payload: data
    };
};

export const setUtilifyEventChannels_ = (data) => {
    return {
        type: SET_UTILIFY_EVENT_CHANNELS,
        payload: data
    };
};



