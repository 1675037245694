export const formatWalletThumbData = (rawWalletData) => {
    let walletThumbData = {};

    let address;
    if (rawWalletData.address) {
        address = rawWalletData.address;
    } else {
        address = "No Address avaialible";
    }

    let name;
    if (rawWalletData.name) {
        name = rawWalletData.name;
    } else {
        name = "Wallet"
    }

    let avatarImage;
    if (rawWalletData.linkingType === "enjin") {
        avatarImage = "https://coincierge.de/wp-content/uploads/2019/10/ENJ_Logo.png";
    } else {
        avatarImage = "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Ethereum-icon-purple.svg/1200px-Ethereum-icon-purple.svg.png"
    }

    let statusStyle;
    if (rawWalletData.status === "linked") {
        statusStyle = "green";
    } else {
        statusStyle = "orange";
    } 

    let status = rawWalletData.status || "undefined"

    return walletThumbData = {
        address,
        name,
        avatarImage,
        status,
        statusStyle
    }
}