import {all, call, fork, put, select, takeEvery} from "redux-saga/effects";

import {
    GET_ALL_NOTIFICATIONS,
    GET_ALL_NOTIFICATIONS_SUCCESS,
    UPDATE_NOTIFICATION_STATUS,
    UPDATE_NOTIFICATION_STATUS_SUCCESS
} from "constants/ActionTypes";

import {
    getAllNotifications_,
    getAllNotificationsSuccess_,
    updateNotificationStatus_,
    updateNotificationStatusSuccess_,
    notificationsRefreshed_
} from "actions/Notification";

import {
    getAllNotifications,
    updateNotificationStatus
} from "../api";

import { Auth } from 'aws-amplify';
import { hideDialogueLoader_, hideMainLoader_, hideScrollLoader_, hideTransparentLoader_, isLoadingMore_, showDialogueLoader_, showLoaderUpdateSuccess_, showMainLoader_, showScrollLoader_, showTransparentLoader_ } from "actions/IsFetching";
import { userSignOut } from "actions";

// Get JWToken from Local Storage

function* getAllNotificationsByFilter({payload}) {
    const {JWToken, querryParams, refresh, loadMore, noLoader} = payload;
    const notificationList = yield select(state => state.notification.notificationList)
    if (!noLoader) {
        if (refresh) {
            yield put(showTransparentLoader_());
        } else {
            yield put(showDialogueLoader_());
        } 
    }

    if (loadMore) {
        yield put(showScrollLoader_());
        yield put(isLoadingMore_(true));
    }
    try {
        const apiResponse = yield call(getAllNotifications, JWToken, querryParams);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            if (loadMore) {
                yield put(getAllNotificationsSuccess_([...notificationList, ...apiResponse.data.notifications])); 
            } else {
                yield put(getAllNotificationsSuccess_(apiResponse.data.notifications));  
            }
                  
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {

        yield put(hideDialogueLoader_());
        yield put(hideTransparentLoader_());
        yield put(hideScrollLoader_());
        yield put(isLoadingMore_(false));
    }

}

function* updateNotificationStatusById({payload}) {
    const {JWToken, isRead} = payload;
    let fetchingResult = {};
    try {
        const apiResponse = yield call(updateNotificationStatus, JWToken, isRead);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            fetchingResult.error = "error";
            yield put(showLoaderUpdateSuccess_(fetchingResult));
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(notificationsRefreshed_(true));
            fetchingResult.success = "success";
            yield put(showLoaderUpdateSuccess_(fetchingResult));
            yield put(updateNotificationStatusSuccess_());    
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    }

}

export function* readAllNotifications() {
    yield takeEvery(GET_ALL_NOTIFICATIONS, 
        getAllNotificationsByFilter);
}

export function* updateOneNotificationStatus() {
    yield takeEvery(UPDATE_NOTIFICATION_STATUS, 
        updateNotificationStatusById);
}

export default function* rootSaga() {
    yield all([fork(readAllNotifications),
        fork(updateOneNotificationStatus)
    ]);
}