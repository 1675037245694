import { SvgIconTypeMap } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import React from 'react'

interface DisplayImageProps {
  image: string
  alt: string
}

export const DisplayImage = ({ image, alt }: DisplayImageProps) => (
  <div className='image-container'>
    <img src={image} alt={alt} />
  </div>
)

interface AssetNameAndTypeProps {
  name: string
  type: 'token' | 'utility' | 'resource' | 'project'
}

export const AssetNameAndType = ({ name, type }: AssetNameAndTypeProps) => (
  <>
    <p className={`type-text ${type}-color`}>{type}</p>
    <p className='name-text'>{name}</p>
  </>
)

interface ItemDetailProps {
  image: string | OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
  imageAlt: string
  iconColor?: string
  children: React.ReactNode
  visible?: boolean
}

export const ItemDetail = ({
  image,
  imageAlt,
  iconColor = '#ffffff',
  children,
  visible = true,
}: ItemDetailProps) => {
  if (!visible) return null

  let Image = image

  return (
    <div className='item-detail-wrapper'>
      <div className='item-icon-container'>
        {typeof image === 'string' ? (
          <img src={image} alt={imageAlt} />
        ) : (
          <Image className='item-detail-icon' htmlColor={iconColor} />
        )}
      </div>
      <div className='item-body-container'>{children}</div>
    </div>
  )
}
