import {
    GET_ALL_WALLETS,
    GET_ALL_WALLETS_SUCCESS,
    GET_WALLET,
    GET_WALLET_SUCCESS,
    ADD_WALLET,
    ADD_WALLET_SUCCESS,
    PROJECT_WALLET_CREATED,
    USER_WALLET_CREATED,
    DELETE_WALLET,
    GET_PLATFORMS_SUCCESS,
    DELETE_WALLET_SUCCESS, UPDATE_WALLET, UPDATE_WALLET_SUCCESS, GET_BALANCES, GET_BALANCES_SUCCESS, GET_BLUEPRINTS, GET_BLUEPRINTS_SUCCESS, UNLINK_WALLET_SUCCESS, UNLINK_WALLET, GET_WALLET_THUMB, GET_WALLET_THUMB_SUCCESS, CLEAR_LINKING_DIALOGUE, GET_SUPPLY, GET_SUPPLY_SUCCESS, GET_SELECTED_BLUEPRINTS_SUCCESS, GET_USER_BALANCES_SUCCESS, LOAD_MORE_WALLETS_SUCCESS, LOAD_MORE_SELECTED_BLUEPRINTS_SUCCESS, SET_CACHED, GET_USER_WALLET_SUCCESS, UNLINK_USER_WALLET_SUCCESS, UPDATE_USER_WALLET_SUCCESS, DELETE_USER_WALLET_SUCCESS, GET_ALL_USER_WALLETS_SUCCESS, ADD_USER_WALLET_SUCCESS, CLEAR_USER_LINKING_DIALOGUE, SET_CACHED_LIST, GET_BLUEPRINT, WALLETS_REFRESHED, WALLET_REFRESHED, VERIFY_WALLET, SET_LINKING_METHOD, LINK_WALLET_SUCCESS, GET_PLATFORMS, VERIFY_WALLET_SUCCESS

} from 'constants/ActionTypes';

export const getAllWallets_ = (data) => {
    return {
        type: GET_ALL_WALLETS,
        payload: data
    };
};

export const getAllWalletsSuccess_ = (wallets) => {
    return {
        type: GET_ALL_WALLETS_SUCCESS,
        payload: wallets
    };
};

export const loadMoreWalletsSuccess_ = (wallets) => {
    return {
        type: LOAD_MORE_WALLETS_SUCCESS,
        payload: wallets
    };
};

export const getWallet_ = (walletId) => {
    return {
        type: GET_WALLET,
        payload: walletId
    };
};

export const getWalletSuccess_ = (wallet) => {
    return {
        type: GET_WALLET_SUCCESS,
        payload: wallet
    };
};



export const getWalletThumb_ = (walletId) => {
    return {
        type: GET_WALLET_THUMB,
        payload: walletId
    };
};

export const getWalletThumbSuccess_ = (wallet) => {
    return {
        type: GET_WALLET_THUMB_SUCCESS,
        payload: wallet
    };
};

export const getBalances_ = (data) => {
    return {
        type: GET_BALANCES,
        payload: data
    };
};

export const getBalancesSuccess_ = (data) => {
    return {
        type: GET_BALANCES_SUCCESS,
        payload: data
    };
};



export const getSupply_ = (data) => {
    return {
        type: GET_SUPPLY,
        payload: data
    };
};

export const getSupplySuccess_ = (data) => {
    return {
        type: GET_SUPPLY_SUCCESS,
        payload: data
    };
};

export const getBlueprints_ = (data) => {
    return {
        type: GET_BLUEPRINTS,
        payload: data
    };
};

export const getBlueprint_ = (data) => {
    return {
        type: GET_BLUEPRINT,
        payload: data
    };
};

export const getBlueprintsSuccess_ = (data) => {
    return {
        type: GET_BLUEPRINTS_SUCCESS,
        payload: data
    };
};

export const loadMoreBlueprintsSuccess_ = (data) => {
    return {
        type: GET_BLUEPRINTS_SUCCESS,
        payload: data
    };
};

export const getSelectedBlueprintsSuccess_ = (data) => {
    return {
        type: GET_SELECTED_BLUEPRINTS_SUCCESS,
        payload: data
    };
};

export const loadMoreSelectedBlueprintsSuccess_ = (data) => {
    return {
        type: LOAD_MORE_SELECTED_BLUEPRINTS_SUCCESS,
        payload: data
    };
};

export const addWallet_ = (data) => {
    return {
        type: ADD_WALLET,
        payload: data
    };
};

export const addWalletSuccess_ = (data) => {
    return {
        type: ADD_WALLET_SUCCESS,
        payload: data
    };
};

export const linkWalletSuccess_ = () => {
    return {
        type: LINK_WALLET_SUCCESS
    };
};

export const setLinkingMethod_ = (data) => {
    return {
        type: SET_LINKING_METHOD,
        payload: data
    };
};

export const verifyWallet_ = (data) => {
    return {
        type: VERIFY_WALLET,
        payload: data
    };
};

export const verifyWalletSuccess_ = (data) => {
    return {
        type: VERIFY_WALLET_SUCCESS,
        payload: data
    };
};

export const getPlatforms_ = (data) => {
    return {
        type: GET_PLATFORMS,
        payload: data
    };
};

export const getPlatformsSuccess_ = (platforms) => {
    return {
        type: GET_PLATFORMS_SUCCESS,
        payload: platforms
    };
};

export const projectWalletCreated_ = (data) => {
    return {
        type: PROJECT_WALLET_CREATED,
        payload: data
    };
};

export const unLinkUserWallet_ = (data) => {
    return {
        type: UNLINK_WALLET,
        payload: data
    };
};

export const unLinkUserWalletSuccess_ = (data) => {
    return {
        type: UNLINK_WALLET_SUCCESS,
        payload: data
    };
};

export const updateWallet_ = (data) => {
    return {
        type: UPDATE_WALLET,
        payload: data
    };
};

export const updateWalletSuccess_ = (data) => {
    return {
        type: UPDATE_WALLET_SUCCESS,
        payload: data
    };
};

export const deleteWallet_ = (walletId) => {
    return {
        type: DELETE_WALLET,
        payload: walletId
    };
};

export const deleteWalletSuccess_ = () => {
    return {
        type: DELETE_WALLET_SUCCESS
    };
};

export const clearLinkingDialogue_ = () => {
    return {
        type: CLEAR_LINKING_DIALOGUE
    };
};


export const setCached_ = () => {
    return {
        type: SET_CACHED
    };
};

// USER WALLET

export const getUserWalletSuccess_ = (wallet) => {
    return {
        type: GET_USER_WALLET_SUCCESS,
        payload: wallet
    };
};

export const addUserWalletSuccess_ = (data) => {
    return {
        type: ADD_USER_WALLET_SUCCESS,
        payload: data
    };
};

export const userWalletCreated_ = (data) => {
    return {
        type: USER_WALLET_CREATED,
        payload: data
    };
};

export const getAllUserWalletsSuccess_ = (wallets) => {
    return {
        type: GET_ALL_USER_WALLETS_SUCCESS,
        payload: wallets
    };
};

export const getUserBalancesSuccess_ = (data) => {
    return {
        type: GET_USER_BALANCES_SUCCESS,
        payload: data
    };
};

export const deleteUserWalletSuccess_ = (data) => {
    return {
        type: DELETE_USER_WALLET_SUCCESS,
        payload: data
    };
};

export const unlinkUserWalletSuccess_ = (data) => {
    return {
        type: UNLINK_USER_WALLET_SUCCESS,
        payload: data
    };
};

export const updateUserWalletSuccess_ = (data) => {
    return {
        type: UPDATE_USER_WALLET_SUCCESS,
        payload: data
    };
};

export const clearUserLinkingDialogue_ = () => {
    return {
        type: CLEAR_USER_LINKING_DIALOGUE
    };
};

/// CACHING

export const setCachedList_ = (data) => {
    return {
        type: SET_CACHED_LIST,
        payload: data
    };
};

/// refresh flags

export const walletsRefreshed_ = (data) => {
    return {
        type: WALLETS_REFRESHED,
        payload: data
    };
};

export const walletRefreshed_ = (data) => {
    return {
        type: WALLET_REFRESHED,
        payload: data
    };
};




