import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'store'
import { noConnectionsSelector } from '../../cooperations'

/**
 Only show the fetching spinner when fetching is true AND there are no connections to display. This is to prevent the fetching overlay appearing when fetching more data.
 */
export const Fetching = () => {
  const fetching = useSelector(
    (state: AppState) => state.networkVisualizerState.itemDetails.fetching
  )

  const noConnections = useSelector(noConnectionsSelector)

  if (fetching && noConnections) {
    return (
      <div className='cooperations-fetching-wrapper'>
        <div className='fetching-container'>
          <div className='spinner'></div>
          <p className='fetching-text'>loading</p>
        </div>
      </div>
    )
  } else {
    return null
  }
}
