import {all, call, fork, put, takeEvery, takeLatest} from "redux-saga/effects";

import {
    GET_ALL_USERS,
    GET_USER,
    ADD_USER,
    UPDATE_USER,
    DELETE_USER,
    PIN_TOKEN_TO_PROFILE,
    PIN_RESOURCE_TO_PROFILE,
    PIN_UTILITY_TO_PROFILE,
    GET_USER_PROJECT_WATCHLIST,
    GET_USER_SETTINGS,
    UPDATE_USER_SETTINGS,
    GET_ALL_USERS_SUCCESS,
    GET_USER_SUCCESS,
    ADD_USER_SUCCESS,
    UPDATE_USER_SUCCESS,
    DELETE_USER_SUCCESS,
    PIN_TOKEN_TO_PROFILE_SUCCESS,
    PIN_RESOURCE_TO_PROFILE_SUCCESS,
    PIN_UTILITY_TO_PROFILE_SUCCESS,
    GET_USER_PROJECT_WATCHLIST_SUCCESS,
    GET_USER_SETTINGS_SUCCESS,
    UPDATE_USER_SETTINGS_SUCCESS,
    PIN_ASSET_TO_USER,
    UNPIN_ASSET_FROM_USER,
    
} from "constants/ActionTypes";

import {
    getAllUsers_,
    getAllUsersSuccess_,
    getUser_,
    getUserSuccess_,
    addUser_,
    addUserSuccess_,
    updateUser_,
    updateUserSuccess_,
    pinAssetToUserProfile_,
    pinAssetToUserProfileSuccess_,
    getUserProjectWatchlist_,
    getUserProjectWatchlistSuccess_,
    getUSerSettings_,
    getUSerSettingsSuccess_,
    updateUSerSettings_,
    updateUSerSettingsSuccess_,
    deleteUserSuccess_,
    pinnedToUserSuccess_,
    unpinnedFromUserSuccess_,
    userRefreshed_,
    usersRefreshed_

} from "actions/User";

import {
    getAllUsers,
    getUser,
    addUser,
    updateUser,
    pinAssetToUserProfile,
    unpinTokenFromUser,
    unpinUtilityFromUser,
    unpinResourceFromUser,
    getUserProjectWatchlist,
    getUserSettings,
    updateUserSettings,
    deleteUser

} from "../api";

import { Auth } from 'aws-amplify';
import { hideDashbaordLoader_, hideDialogueLoader_, hideIsFetching_, hideMainLoader_, hideTransparentLoader_, showDashbaordLoader_, showDialogueLoader_, showLoaderDeleteSuccess_, showLoaderUpdateSuccess_, showMainLoader_, showTransparentLoader_ } from "actions/IsFetching";
import { errorHandler, successHandler } from "util/messageHandler";
import { viewAlertMessage_ } from "actions/Alert";
import { showErrorPage_, userSignOut } from "actions";

// Get JWToken from Local Storage

function* getAllUsersByFilter({payload}) {
    const {JWToken, querryParams, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showMainLoader_());
            } 
        }
        const apiResponse = yield call(getAllUsers, JWToken, querryParams);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            // yield put(successAction(apiResponse.error));        
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideMainLoader_());
    }

}

function* getUserById({payload}) {
    const {JWToken, userId, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showMainLoader_());
            } 
        }
        const apiResponse = yield call(getUser, JWToken, userId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            if (apiResponse.status === 500) {
                yield put(showErrorPage_(true));
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse GET USER: ", apiResponse);
            yield put(getUserSuccess_(apiResponse));
            // yield put(successAction(apiResponse.error));        
        }
    } catch (error) {
        console.log("catchError user: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideMainLoader_());
        yield put(hideTransparentLoader_());
    }

}
function* updateUserById({payload}) {
    const {JWToken, userId, userData, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDashbaordLoader_());
            } 
        }
        const apiResponse = yield call(updateUser, JWToken, userId, userData);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 

            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse UPDATE USER: ", apiResponse);

            
            yield put(userRefreshed_(true));
            yield put(updateUserSuccess_(apiResponse));   
            
            // alert message   
            const successMessage = yield call(successHandler,"User data", "", "updated");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally{
        yield put(hideTransparentLoader_());
        yield put(hideDashbaordLoader_());
    }

}

function* addUserByData({payload}) {
    const {JWToken, userData, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        const apiResponse = yield call(addUser, JWToken, userData);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 

            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(usersRefreshed_(true));
            yield put(addUserSuccess_());
            // alert message   
            const successMessage = yield call(successHandler,"User", "", "created");
            yield put(viewAlertMessage_(successMessage)); 

                    
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideDialogueLoader_());
    }

}

function* deleteUserById({payload}) {
    const {JWToken, userId, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        const apiResponse = yield call(deleteUser, JWToken, userId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 

            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(usersRefreshed_(true));
            yield put(deleteUserSuccess_());  
            // alert message   
            const successMessage = yield call(successHandler,"User", "", "delete");
            yield put(viewAlertMessage_(successMessage)); 

                 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideDialogueLoader_());
        yield put(hideTransparentLoader_());
    }

}

function* pinAssetToUserProfileWithData({payload}) {
    const {JWToken, userId, assetData, refresh, noLoader} = payload;
    let fetchingResult = {};
    try {
        if (!noLoader) {
            yield put(showTransparentLoader_());
        }
        const apiResponse = yield call(pinAssetToUserProfile, JWToken, userId, assetData);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse); 

            
            yield put(userRefreshed_(true));
            yield put(pinnedToUserSuccess_(true));
            
            // alert message   
            const successMessage = yield call(successHandler,"Asset", "", "pinned");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
    }

}

function* unpinAssetFromUserProfileWithData({payload}) {
    const {JWToken, userId, assetType, assetId, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            yield put(showTransparentLoader_());
        }
        let fetchingResult;
        let apiResponse;
        switch (assetType) {
            case "tokens": 
                let tokenId = assetId;
                apiResponse = yield call(unpinTokenFromUser, JWToken, userId, tokenId);
            break;
            case "utilities": 
                let utilityId = assetId;
                apiResponse = yield call(unpinUtilityFromUser, JWToken, userId, utilityId);
            break;
            case "resources": 
                let resourceId = assetId;
                apiResponse = yield call(unpinResourceFromUser, JWToken, userId, resourceId);
            break;
            default: console.log("pin saga failed");
        }
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            

            
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse); 

             
            yield put(userRefreshed_(true));
            yield put(unpinnedFromUserSuccess_(true));  
            
            // alert message   
            const successMessage = yield call(successHandler,"Asset", "", "unpinned");
            yield put(viewAlertMessage_(successMessage));
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
    }

}



function* getUserWatchlistById({payload}) {
    const {JWToken, userId, querryParams, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showMainLoader_());
            } 
        }
        const apiResponse = yield call(getUserProjectWatchlist, JWToken, userId, querryParams);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(getUserProjectWatchlistSuccess_(apiResponse.data));        
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideMainLoader_());
    }

}

function* getUSerSettingsById({payload}) {
    const {JWToken, userId, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDashbaordLoader_());
            } 
        }
        const apiResponse = yield call(getUserSettings, JWToken, userId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(getUSerSettingsSuccess_(apiResponse.data));        
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideDashbaordLoader_());
    }

}

function* updateUserSettingsByData({payload}) {
    const {JWToken, userId, settingData, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDashbaordLoader_());
            } 
        }
        const apiResponse = yield call(updateUserSettings, JWToken, userId, settingData);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 

        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(userRefreshed_(true));
            yield put(updateUSerSettingsSuccess_());  
            // alert message   
            const successMessage = yield call(successHandler,"User Settings", "", "updated");
            yield put(viewAlertMessage_(successMessage)); 

                  
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideDashbaordLoader_());
    }

}


export function* readAllUsers() {
    yield takeEvery(GET_ALL_USERS, 
        getAllUsersByFilter);
}

export function* readOneUser() {
    yield takeLatest(GET_USER, 
        getUserById);
}

export function* updateOneUser() {
    yield takeEvery(UPDATE_USER, 
        updateUserById);
}

export function* addOneUser() {
    yield takeEvery(ADD_USER, 
        addUserByData);
}

export function* removeOneUser() {
    yield takeEvery(DELETE_USER, 
        deleteUserById);
}

export function* attachAssetToser() {
    yield takeEvery(PIN_ASSET_TO_USER, 
        pinAssetToUserProfileWithData);
}

export function* detachAssetFromUser() {
    yield takeEvery(UNPIN_ASSET_FROM_USER, 
        unpinAssetFromUserProfileWithData);
}


export function* readUserWatchlist() {
    yield takeEvery(GET_USER_PROJECT_WATCHLIST, 
        getUserWatchlistById);
}

export function* getUserSettingsForOneUser() {
    yield takeEvery(GET_USER_SETTINGS, 
        getUSerSettingsById);
}

export function* updateUSerSettingsForOneUser() {
    yield takeEvery(UPDATE_USER_SETTINGS, 
        updateUserSettingsByData);
}
export default function* rootSaga() {
    yield all([fork(readAllUsers),
        fork(readOneUser),
        fork(updateOneUser),
        fork(addOneUser),
        fork(removeOneUser),
        fork(attachAssetToser),
        fork(detachAssetFromUser),
        fork(readUserWatchlist),
        fork(getUserSettingsForOneUser),
        fork(updateUSerSettingsForOneUser)
    ]);
}