import { UtilityResponse } from 'components/networkVisualizer/API/types/getAssetDataResponse/utility'
import {
  Projects,
  Utilities,
} from 'components/networkVisualizer/networkVisualizerState/store/general/types'

interface UtilityFormatterOutput {
  utilities: Utilities
  projects: Projects
}

export function utilityFormatter(
  response: UtilityResponse
): UtilityFormatterOutput {
  let utilities: Utilities = {}

  let {
    utilityId,
    name,
    avatarImage,
    project,
    type,
    cooperationsCount,
  } = response

  let projects: Projects = project
    ? {
        [project.projectId]: {
          name: project?.name,
          image: project?.avatarImage?.thumbnail,
        },
      }
    : {}

  utilities[utilityId] = {
    name,
    image: avatarImage?.thumbnail ?? undefined,
    cooperationsCount,
    projectName: project?.name ?? undefined,
    type,
    //Empty array is assigned as it's used to determine whether the asset has a project or not
    connections: {
      projects: project ? [project?.projectId] : [],
    },
  }

  return {
    utilities,
    projects,
  }
}
