import {all, call, fork, put, takeEvery} from "redux-saga/effects";
/* import {
    auth,
    facebookAuthProvider,
    githubAuthProvider,
    googleAuthProvider,
    twitterAuthProvider
} from "../firebase/firebase"; */
import {

    SIGNIN_USER,
    SIGNOUT_USER,
    SIGNUP_USER,
    SEND_CODE,
    RESET_PASSWORD,
    GET_AUTH_USER,
    CONFIRM_USER,
    SIGNIN_GUEST
} from "constants/ActionTypes";
import {
    showAuthMessage,
    userSignInSuccess, 
    userSignInUnverified, 
    userSignOutSuccess, 
    userSignUpSuccess, 
    sendCodeSuccess, 
    resetPasswordSuccess,
    getAuthUserSuccess,
    guestSignInSuccess
} from "actions/Auth";



import {
    getUser
} from "../api";

import { Auth } from 'aws-amplify';
import { hideAppLoader_, hideDialogueLoader_, hideMainLoader_, showAppLoader_, showDialogueLoader_, showFetchingSuccess_, showLoaderCreateSuccess_, showMainLoader_ } from "actions/IsFetching";
import { getAllRequestsOverviewSuccess_, getAllRequestsSuccess_ } from "actions/Request";
import { getUserSuccess_ } from "actions/User";
import { getAllWalletsSuccess_, getBalancesSuccess_, getBlueprintsSuccess_, getWalletSuccess_, getWalletThumbSuccess_ } from "actions/Wallet";
import { getAllChatUsersSuccess_, getAllContactUsersSuccess_, getConversationSuccess_, loadPrevMessagesSuccess_, onUserSelectSuccess_, onUserSelect_, setActiveChatUsers_, setActiveConversation_ } from "actions/Messages";
import { getAllUtilitiesSuccess_ } from "actions/Utility";
import { getAllResourcesSuccess_ } from "actions/Resource";
import { getAllOrdersSuccess_ } from "actions/Order";
import { getAllTransactionsSuccess_ } from "actions/Transaction";
import { getAllProjectBlueprintsSuccess_, getProjectSuccess_ } from "actions/Project";

import axios from 'axios';
import { getAllNotificationsSuccess_ } from "actions/Notification";
import { getUserAccountSuccess_ } from "actions/Account";
import { getAllEnjinAppsSuccess_, getEnjinAppSuccess_, linkEnjinAppSuccess_ } from "actions/EnjinApp";
import { closeNetworkVisualizer } from "components/networkVisualizer/networkVisualizerState/store/general/actions";

// SingUp User with username, email & password
const aws_createUserWithEmailPasswordRequest = async (name, email, password) => {
    const response = {};
    try {
        const user = await Auth.signUp({
            username: name,
            password,
            attributes: {
                email,          // optional
                "custom:role": "user",
                // "custom:plan": "free"
                // phone_number,   // optional - E.164 number convention
                // other custom attributes 
            }
        });
        response.user = user;
        // console.log({ user });
    } catch (error) {
        response.error = error;
        // console.log('error signing up:', error);
    }
    return response;
}

///////

// GET Current Auth User

const aws_getCurrentAuthUser = async () => {
    const response = {};
    try {
        let user = await Auth.currentAuthenticatedUser();
        response.user = user;
    } catch (error) {
        response.error = error;
    }
    return response;
}

// refresh session

export const aws_refreshSession = () => {
    console.log("refresh session");
    const response = {};
    Auth.currentSession()
    .then(data => {
        console.log("data refreshsession: ", data);
        localStorage.setItem('idToken', data.idToken.jwtToken);
        //sessionStorage.setItem('idToken', data.idToken.jwtToken);
    })
    
    .catch(err => console.log("session error: ", err));
    return response;
}


/*const createUserWithEmailPasswordRequest = async (email, password) =>
    await auth.createUserWithEmailAndPassword(email, password)
        .then(authUser => authUser)
        .catch(error => error);*/



// SignIn User with username or email & password
const aws_signInUserWithEmailPasswordRequest = async (email, password) => {
    const response = {};
    try {
        const user = await Auth.signIn(email, password);
        response.user = user;

        // console.log( "SIGNIN-Response: ", user );
    } catch (error) {
        response.error = error;
        // console.log('error signing in', error);
        
    }
    return response;
}


/*
const signInUserWithEmailPasswordRequest = async (email, password) =>
    await auth.signInWithEmailAndPassword(email, password)
        .then(authUser => authUser)
        .catch(error => error);
*/
const aws_signOutRequest = async () => {
    try {
        await Auth.signOut();
    } catch (error) {
        // console.log('error signing out: ', error);
    }
}

const aws_confirmSignUp = async (name, code) => {
    try {
      await Auth.confirmSignUp(name, code);
    } catch (error) {
        // console.log('error confirming sign up', error);
    }
}

const aws_sendCodeRequest = async (name) => {
    return await Auth.forgotPassword(name)
        .then(user => user)
        .catch(error => error);
    }

const aws_resetPassword = async (name, code, new_password) => {
    const response = {};
    try {
        const user = await Auth.forgotPasswordSubmit(name, code, new_password);
        response.user = user;
        // console.log({ user });
    } catch (error) {
        response.error = error;
        // console.log('error reset password', error);
    }
    return response;
    
}


/*const signInUserWithGoogleRequest = async () =>
    await auth.signInWithPopup(googleAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);*/

/*const signInUserWithFacebookRequest = async () =>
    await auth.signInWithPopup(facebookAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);*/

/*const signInUserWithGithubRequest = async () =>
    await auth.signInWithPopup(githubAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);*/

/*const signInUserWithTwitterRequest = async () =>
    await auth.signInWithPopup(twitterAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);/*
//

/* function* createUserWithEmailPassword_({payload}) {
    const {email, password} = payload;
    try {
        const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userSignUpSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
} */

function* createUserWithEmailPassword({payload}) {
    const {name, email, password} = payload;
    try {
        yield put(showDialogueLoader_());
        const signUpUser = yield call(aws_createUserWithEmailPasswordRequest, name, email, password);
        if (signUpUser.error) {
            // console.log("ERROR: ", signUpUser.error.message);
            yield put(showAuthMessage(signUpUser.error.message));
            
        } else {
            // console.log("SUCCESS signUpUser: ", signUpUser);
            // localStorage.setItem('user_id', signUpUser.user.userSub);
            yield put(userSignUpSuccess(signUpUser.user));
            
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    } finally{
        yield put(hideDialogueLoader_());
    }
}

function* confirmUserWithUsernameAndCode({payload}) {
    const {name, code} = payload;
    try {
        yield put(showDialogueLoader_());
        console.log("confirm saga: ")
        const confirmUser = yield call(aws_confirmSignUp, name, code);
        if (confirmUser !== undefined && confirmUser.error) {
            console.log("ERROR: ", confirmUser.message);
            yield put(showAuthMessage("Error"));
            
        } else {
            console.log("SUCCESS signUpUser: ", confirmUser);
            // localStorage.setItem('user_id', signUpUser.user.userSub);
            yield put(resetPasswordSuccess());
            
        }
    } catch (error) {
        // console.log("ERROR: ", error);
        yield put(showAuthMessage(error.message));
    } finally {
        yield put(hideDialogueLoader_());
    }
}



/*function* signInUserWithGoogle() {
    try {
        const signUpUser = yield call(signInUserWithGoogleRequest);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('idToken', signUpUser.user.uid);
            yield put(userGoogleSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


function* signInUserWithFacebook() {
    try {
        const signUpUser = yield call(signInUserWithFacebookRequest);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('idToken', signUpUser.user.uid);
            yield put(userFacebookSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


function* signInUserWithGithub() {
    try {
        const signUpUser = yield call(signInUserWithGithubRequest);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('idToken', signUpUser.user.uid);
            yield put(userGithubSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


function* signInUserWithTwitter() {
    try {
        const signUpUser = yield call(signInUserWithTwitterRequest);
        if (signUpUser.message) {
            if (signUpUser.message.length > 100) {
                yield put(showAuthMessage('Your request has been canceled.'));
            } else {
                yield put(showAuthMessage(signUpUser.message));
            }
        } else {
            localStorage.setItem('idToken', signUpUser.user.uid);
            yield put(userTwitterSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
} */

/* function* signInUserWithEmailPassword({payload}) {
    const {email, password} = payload;
    try {
        const signInUser = yield call(aws_signInUserWithEmailPasswordRequest, email, password);
        if (signInUser.error) {
            if (signInUser.error.code !== "UserNotConfirmedException") {
                yield put(showAuthMessage(JSON.stringify(signInUser.error.message)));
            } else {
                yield put(userSignInSuccess(JSON.stringify(signInUser.user)));
            }
        } else {
            localStorage.setItem('user_id', signInUser.user.signInUserSession.accessToken.jwtToken);
            yield put(userSignInSuccess(JSON.stringify(signInUser.user)));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
} */

function* signInUserWithEmailPassword({payload}) {
    
    const {email, password} = payload;
    yield put(userSignOutSuccess(signOutUser));
    yield put(getProjectSuccess_(''));
    yield put(getAllRequestsSuccess_(''));
    yield put(getAllRequestsOverviewSuccess_(''));
    yield put(getUserSuccess_(''));
    yield put(getAllWalletsSuccess_(''));
    yield put(getAllChatUsersSuccess_(''));
    yield put(getAllContactUsersSuccess_(''));
    yield put(getAllProjectBlueprintsSuccess_(''));
    yield put(onUserSelectSuccess_(''));
    yield put(setActiveChatUsers_(''));
    yield put(onUserSelect_(''));
    yield put(setActiveConversation_(''));
    yield put(loadPrevMessagesSuccess_(''));
    yield put(getConversationSuccess_(''));
    yield put(getBlueprintsSuccess_(''));
    yield put(getAllUtilitiesSuccess_(''));
    yield put(getAllResourcesSuccess_(''));
    yield put(getWalletSuccess_(''));
    yield put(getWalletThumbSuccess_(''));
    yield put(getBalancesSuccess_(''));
    yield put(getAllOrdersSuccess_(''));
    yield put(getAllTransactionsSuccess_(''));
    yield put(getAllNotificationsSuccess_(''));
    yield put(getUserAccountSuccess_(''));
    yield put(getAllEnjinAppsSuccess_(''));
    yield put(getEnjinAppSuccess_(''));
    yield put(linkEnjinAppSuccess_(''));
    try {
        yield put(showAppLoader_())
        // yield call(aws_signOutRequest);
        // yield put(closeNetworkVisualizer());
        const loggedInUser = yield call(aws_signInUserWithEmailPasswordRequest, email, password);
        if (!loggedInUser.error) {
            localStorage.setItem('idToken', loggedInUser.user.signInUserSession.idToken.jwtToken);
            localStorage.setItem('userId', loggedInUser.user.username);
            // sessionStorage.setItem('idToken', loggedInUser.user.signInUserSession.idToken.jwtToken);
            // sessionStorage.setItem('userId', loggedInUser.user.username);

            const userProfile = yield call(getUser, loggedInUser.user.signInUserSession.idToken.jwtToken, loggedInUser.user.username);
            loggedInUser.profile = userProfile;

            axios.defaults.headers.common['Authorization'] = loggedInUser.user.signInUserSession.idToken.jwtToken;
            // FETCH user account from API 
            yield put(userSignInSuccess(loggedInUser));
        } else {
            switch (loggedInUser.error.code) {
                case("UserNotConfirmedException"):
                // console.log("LoggedInUser: ", loggedInUser);
                    yield put(userSignInUnverified(loggedInUser.error));
                    break;
                default:
                    yield put(showAuthMessage(JSON.stringify(loggedInUser.error.message)));
                } 
        }
        // GET USER ACCOUNT DATA & PASS TO GLOBAL STATE
        
    } catch (error) {
        yield put(showAuthMessage(error));
    } finally {
        yield put(hideAppLoader_());
    }
}

function* signInGuestWithEmailPassword() {
    let email = "";
    let password = "";
    try {
        yield put(showAppLoader_())
        const loggedInGuest = yield call(aws_signInUserWithEmailPasswordRequest, email, password);
        if (!loggedInGuest.error) {
            // localStorage.setItem('idToken_Guest', loggedInGuest.user.signInUserSession.idToken.jwtToken);
            // localStorage.setItem('userId_Guest', loggedInGuest.user.username);
            // sessionStorage.setItem('idToken', loggedInGuest.user.signInUserSession.idToken.jwtToken);
            // sessionStorage.setItem('userId', loggedInGuest.user.username);

            const userProfile = yield call(getUser, loggedInGuest.user.signInUserSession.idToken.jwtToken, loggedInGuest.user.username);
            loggedInGuest.profile = userProfile;

            axios.defaults.headers.common['Authorization'] = loggedInGuest.user.signInUserSession.idToken.jwtToken;
            // FETCH user account from API 
            yield put(guestSignInSuccess(loggedInGuest));
        } else {
            // console.log("loggedInGuest: ", loggedInGuest);
            yield put(showAuthMessage(JSON.stringify(loggedInGuest.error.message)));
        } 
        
        // GET USER ACCOUNT DATA & PASS TO GLOBAL STATE
        
    } catch (error) {
        yield put(showAuthMessage(error));
    } finally {
        yield put(hideAppLoader_());
    }
}

export function* signOut() {
    yield put(userSignOutSuccess(signOutUser));
    yield put(getProjectSuccess_(''));
    yield put(getAllRequestsSuccess_(''));
    yield put(getAllRequestsOverviewSuccess_(''));
    yield put(getUserSuccess_(''));
    yield put(getAllWalletsSuccess_(''));
    yield put(getAllChatUsersSuccess_(''));
    yield put(getAllContactUsersSuccess_(''));
    yield put(getAllProjectBlueprintsSuccess_(''));
    yield put(onUserSelectSuccess_(''));
    yield put(setActiveChatUsers_(''));
    yield put(onUserSelect_(''));
    yield put(setActiveConversation_(''));
    yield put(loadPrevMessagesSuccess_(''));
    yield put(getConversationSuccess_(''));
    yield put(getBlueprintsSuccess_(''));
    yield put(getAllUtilitiesSuccess_(''));
    yield put(getAllResourcesSuccess_(''));
    yield put(getWalletSuccess_(''));
    yield put(getWalletThumbSuccess_(''));
    yield put(getBalancesSuccess_(''));
    yield put(getAllOrdersSuccess_(''));
    yield put(getAllTransactionsSuccess_(''));
    yield put(getAllNotificationsSuccess_(''));
    yield put(getUserAccountSuccess_(''));
    yield put(getAllEnjinAppsSuccess_(''));
    yield put(getEnjinAppSuccess_(''));
    yield put(linkEnjinAppSuccess_(''));
    try {
        const signOutUser = yield call(aws_signOutRequest);
        if (signOutUser === undefined) {
            localStorage.removeItem('idToken');
            localStorage.removeItem('userId');
            //sessionStorage.removeItem('idToken');
            //window.localStorage.setItem('CREDENTIALS_FLUSH', Date.now().toString());
            //window.localStorage.removeItem('CREDENTIALS_FLUSH');
        } else {
            yield put(showAuthMessage(signOutUser.message));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* sendCodeWithUsername({payload}){
    const {name, inApp} = payload;
    let fetchingResult = {};
    try {
        yield put(showDialogueLoader_());

        const sendNewCode = yield call(aws_sendCodeRequest, name);
        // console.log("CODE SAGA: ", sendNewCode);
        if (sendNewCode.code !== undefined) {
        switch (sendNewCode.code) {
            case("LimitExceededException"):
                if (inApp) {
                    fetchingResult.error = sendNewCode.message;
                    yield put(showFetchingSuccess_(fetchingResult));
                } else {
                    yield put(showAuthMessage(sendNewCode.message));
                    // yield put(sendCodeSuccess(sendNewCode));
                }
                break;
            default:
                if (inApp) {
                    fetchingResult.error = sendNewCode.message;
                    yield put(showFetchingSuccess_(fetchingResult));
                } else {
                    yield put(showAuthMessage("Username or e-Mail not found"));
                    // yield put(sendCodeSuccess(sendNewCode));
                }
            }

        } else {

            // console.log("SENDNEWCODE: ", sendNewCode)
            if (inApp) {
                fetchingResult.success = "Your Reset Link has been sent to your registered e-Mail!";
                yield put(showFetchingSuccess_(fetchingResult));
            } else {
                yield put(sendCodeSuccess(sendNewCode));
            }
        }
    } catch (error) {
        if (inApp) {
            fetchingResult.error = error;
            yield put(showFetchingSuccess_(error));
        } else {
            yield put(showAuthMessage(error));
            yield put(sendCodeSuccess(error));
        }
        // console.log("Error_send_code: ", error);

    } finally {
        yield put(hideDialogueLoader_());
    }
}

function* resetPasswordWithCode({payload}){
    const {name, code, password} = payload;
    try {
        
        yield put(showDialogueLoader_());
        const resetPassword = yield call(aws_resetPassword, name, code, password);
        // console.log("RESETPASSWORD-RES: ", resetPassword);
        let fetchingResult = {};
        if (resetPassword.error) {
            fetchingResult.error = "Reset failed.";
            // yield put(showFetchingSuccess_(fetchingResult));
            yield put(showAuthMessage(resetPassword.error.message));
        } else {
            // yield console.log("Awesome: ", sendNewCode)
            // localStorage.setItem('user_id', sendNewCode.user.signInUserSession.accessToken.jwtToken);
            fetchingResult.success = "Reset success!";
            yield put(showLoaderCreateSuccess_(fetchingResult));
            yield put(resetPasswordSuccess(resetPassword.message));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
        // yield put(showAuthMessage(error));
    } finally {
        yield put(hideDialogueLoader_());
        
    }
}

function* getLoggedInUser({payload}){

    try {
        yield put(showAppLoader_());
        yield put(showMainLoader_());
        const loggedInUser = yield call(aws_getCurrentAuthUser);
        if (loggedInUser.error) {
            yield put(showAuthMessage(loggedInUser));
            yield call(signOut);
        } else {
            // yield call(aws_refreshSession);
            // console.log("loggedIn: ", loggedInUser);
            localStorage.setItem('idToken', loggedInUser.user.signInUserSession.idToken.jwtToken);
            localStorage.setItem('userId', loggedInUser.user.username);
            // sessionStorage.setItem('idToken', loggedInUser.user.signInUserSession.idToken.jwtToken);
            // sessionStorage.setItem('userId', loggedInUser.user.username);
            axios.defaults.headers.common['Authorization'] = loggedInUser.user.signInUserSession.idToken.jwtToken;
            const userProfile = yield call(getUser, loggedInUser.user.signInUserSession.idToken.jwtToken, loggedInUser.user.username);
            loggedInUser.profile = userProfile;
            yield put(getAuthUserSuccess(loggedInUser)); 
        }
    } catch (error) {
        yield put(showAuthMessage(error));
        
    } finally {
        yield put(hideAppLoader_());
        yield put(hideMainLoader_());
    }
}

function* refreshSession(){
    try {
        const loggedInUser = yield call(aws_getCurrentAuthUser);
        if (loggedInUser.error) {
            yield put(showAuthMessage(loggedInUser));
            yield call(signOut);
        } else {
            localStorage.setItem('idToken', loggedInUser.user.signInUserSession.idToken.jwtToken);
            localStorage.setItem('userId', loggedInUser.user.username);
            sessionStorage.setItem('idToken', loggedInUser.user.signInUserSession.idToken.jwtToken);
            sessionStorage.setItem('userId', loggedInUser.user.username);

            axios.defaults.headers.common['Authorization'] = loggedInUser.user.signInUserSession.idToken.jwtToken;
            
            const userProfile = yield call(getUser, loggedInUser.user.signInUserSession.idToken.jwtToken, loggedInUser.user.username);
            loggedInUser.profile = userProfile;
            yield put(getAuthUserSuccess(loggedInUser)); 
        }
    } catch (error) {
        yield put(showAuthMessage(error));
        // yield put(showAuthMessage(error));
    }
}


export function* createUserAccount() {
    yield takeEvery(SIGNUP_USER, 
        createUserWithEmailPassword);
}

export function* confirmUserAccount() {
    yield takeEvery(CONFIRM_USER, 
        confirmUserWithUsernameAndCode);
}

/*export function* signInWithGoogle() {
    yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
    yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
    yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
    yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
} */

export function* signInUser() {
    yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signInGuest() {
    yield takeEvery(SIGNIN_GUEST, signInGuestWithEmailPassword);
}

export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
}

export function* sendNewCode() {
    yield takeEvery(SEND_CODE, sendCodeWithUsername);
}

export function* resetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPasswordWithCode);
}

export function* getCurrentAuthUser() {
    yield takeEvery(GET_AUTH_USER, getLoggedInUser);
}

export default function* rootSaga() {
    yield all([fork(signInUser),
        fork(signInGuest),
        fork(createUserAccount),
        fork(confirmUserAccount),
        // fork(signInWithGoogle),
        // fork(signInWithFacebook),
        // fork(signInWithTwitter),
        // fork(signInWithGithub),
        fork(signOutUser),
        fork(sendNewCode),
        fork(resetPassword),
        fork(getCurrentAuthUser)
    ]);
}