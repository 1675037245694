import {
    ASSETS_REFRESHED,
    ASSET_REFRESHED,
    GET_ASSET_SUCCESS
} from "constants/ActionTypes";

const INIT_STATE = {
    asset: '',
    assetRefreshed: false,
    assetsRefreshed: false
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ASSET_SUCCESS: {
            return {
                ...state,
                asset: action.payload
            }
        }
        case ASSETS_REFRESHED: {
            return {
                ...state,
                assetsRefreshed: action.payload
            }
        }
        case ASSET_REFRESHED: {
            return {
                ...state,
                assetRefreshed: action.payload
            }
        }

        default:
            return state;
    }
}