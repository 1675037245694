import React from 'react';
import Avatar from '@material-ui/core/Avatar'
import tokenIcon from 'assets/images/token_icon.png';
import utilityIcon from 'assets/images/utility_icon.png';
import resourceIcon from 'assets/images/resource_icon.png';
import projectIcon from 'assets/images/project_icon.png';


const SearchResultCard = ({result}) => {

    

    let assetTypeIcon;
    if (result.type === "tokens") {
        assetTypeIcon = tokenIcon;
    } else if (result.type === "resources") {
        assetTypeIcon = resourceIcon;
    } else if (result.type === "projects") {
        assetTypeIcon = projectIcon;
    } else {
        assetTypeIcon = utilityIcon;
    }


    return (
        <li className="media">
            <div className="row justify-content-between">
                <div className="col-12 d-flex flex-row justify-content-start">
                    <Avatar
                    alt={"image"}
                    src={result.avatarImage || assetTypeIcon}
                    className="mr-2"
                    />
                    <div className="media-body align-self-center">
                        <p className="sub-heading mb-0" style={{fontSize: "16px", fontWeight: "500"}}>{`${result.name}`}</p>
                        <p className="sub-heading mb-0 d-inline" style={{color: result.color}}>{`${result.typeName} `}</p>
                        <span
                        className="meta-date"><small>{` (${result.projectName})`}</small></span>
                    </div>
                </div>
            </div>
            
        </li>
    );
};

export default SearchResultCard;
