import React, { useState } from "react";
import assetStructure from 'assets/images/help/asset_structure.png';
import HelpTitle from "components/PageLayout/HelpTitle";
import questionIcon from 'assets/images/question_icon.png';
import projectIcon from 'assets/images/project_icon.png';
import utilityIcon from 'assets/images/utility_icon.png';
import resourceIcon from 'assets/images/resource_icon.png';
import tokenIcon from 'assets/images/token_icon.png';
import defaultIcon from 'assets/images/default_icon.png';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Button, IconButton } from "@material-ui/core";
import HelpAlert from "./HelpAlert";
import IconExpandLess from '@material-ui/icons/ExpandLess';
import IconExpandMore from '@material-ui/icons/ExpandMore';
import HelpStep from "./HelpStep";

const getStartedDescription = "Follow these steps to create your first assets and engage in cooperations"
const createProjectDescription = "Go to - Home - and create a new Project. On Utilify Tokens, Utilities and Resources are created, managed and linked from within a project"
const createAssetsDescription = "Navigate to your project and use the project dashboard to create, manage and interconnect your assets. Click the info icon on the figure below to learn more about the different asset and cooperation types availible on Utilify."
const cooperateDescription = "Use the explorer to discover tokens, utilities and resources that complement your project and send coop requests to cross-link your assets"
const growNetworkDescription = "Engage in different types of cooperations to grow your asset network and benefit your users with exciting utilities across different projects"

const instruction = "Click the info icons to learn more about the assets and their connections"

const integrateAppDescription = "Access your cooperations via the public API or via the SDK to manage your token based utility access and token distribution"
const utilityDescription = "A utility is any use case or added value a token can provide to its holders. The most basic concept of a utility is access to virtual or non-virtual goods and services a provider may offer. A utility can be a game asset like a playable character, a piece of equipment, a weapon or consumable item. It can also be a discount on a membership or a single purchase inside an online store. It can be access to limited resources like digital artwork. Or it can be access to physical resources like a vending machine or the door to co-working spaces. Considering a token to be a key, utilities would then be the corresponding doors to whatever someone is willing to offer. So, whatever utility you want to manage via blockchain based token access, you can manage on the Utilify platform. Utilities can be private, enabling access to your project tokens only or make them public and accessible for cooperation requests by any token or projects who wish to connect with your utility.";
const resourceDescription = "Resources are images, artwork, mood boards, 2d-models, 3d-models and any material or data-sources that can be used by developers to create amazing utilities. Add Resources and attach them to a corresponding token to make them available for other projects wanting to create use cases for the token. Imagine a Game asset like a playable character, that defines your utility. You can attach its resources to the related token, which can be used to recreate your utility in other projects. Resources define the true character and nature of the token itself and help others to build new utilities that evolve from that initial creation by taking your resources as a starting point. By reducing the work required for others to create utilities in their own service from scratch, resources may bring down barriers for cross-game and cross-service implementations and cooperations. By establishing a utility link to a token, you can gain access to its resources.";
const tokenDescription = "Tokens are cryptographically secured entities. They can be either fungible or non-fungible (NFTs), thus interchangeable or non-interchangeable, unique, and scarce. They are used to create verifiable digital scarcity, as well as digital ownership, and the possibility of asset interoperability across multiple platforms, services and products. On the Utilify platform, tokens with token-related metadata can be created, managed, distributed and linked to utilities. They are the keys that enable their owner to access a variety of digital products and services. Add your Project Wallet on the project dashboard and start to manage your token blueprints and link them to utilities and resources.";
const utilityLink = "To make a utility accessible by a token, an asset link is required between the two. If both are originated in the same project they can be linked without requests. If you wish to link your token to an external utility  or vice versa, a cooperation request is required. Simply visit the profile page of the desired asset and make a request. The owner of the utility has the chance to accept or decline the request. Once a link or a coop is established, it will become visible in the explorer for all users. The same applies in reverse. A link originating from the utility owner will instantly be active but can be approved by the token owner to be a “confirmed” cooperation.";
const resourceLink = "To attach a resource to a token, an asset link is required between the two. If both are originated in the same project they can be linked without requests. If you wish to link your token to an external resource a cooperation request is required. Simply visit the profile page of the desired asset and make a request. The owner of the resource has the chance to accept or decline the request. Once a link or a coop is established, it will become visible in the explorer for all users.  The same applies in reverse. A link originating from the resource owner will instantly be active but can be approved by the token owner to be a “confirmed” cooperation.";

const GetStarted = ({handleOpen, open}) => {

    return (
        <div className="help-asset-structure">
            <HelpTitle title={"Get Started"} titleIcon={questionIcon}>

            </HelpTitle>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-8 mb-2">
                    <h6 style={{marginBottom:"0.25rem", marginLeft: "4.25rem", color: "#d6f9fefc"}}>{getStartedDescription}</h6>
                </div>
            </div>

            <HelpStep number="1" title="Create Project" titleIcons={[projectIcon]} description={createProjectDescription}>
            </HelpStep>

            <HelpStep number="2" title="Create Assets" titleIcons={[tokenIcon, utilityIcon, resourceIcon]} description={createAssetsDescription}>
            </HelpStep>
            
            <div className="row justify-content-center align-items-center help-img-wrapper">
                <div className="col-lg-6 col-md-6 col-sm-10 d-flex help-img-parent-asset-structure justify-content-center align-items-center">
                    <img className="help-img-asset-structure" src={assetStructure}/>
                    <div className="token-info-tag">
                        <IconButton>
                            <HelpAlert type={"token"} title={"Token"} description={tokenDescription}/>
                        </IconButton>
                    </div>
                    <div className="resourcelink-info-tag">
                        <IconButton>
                            <HelpAlert type={"resourceLink"} title={"Resource Link"} description={resourceLink}/>
                        </IconButton>
                    </div>
                    <div className="utilitylink-info-tag">
                        <IconButton>
                            <HelpAlert type={"utilityLink"} title={"Utility Link"} description={utilityLink} />
                        </IconButton>
                    </div>
                    <div className="utility-info-tag">
                        <IconButton>
                            <HelpAlert type={"utility"} title={"Utility"} description={utilityDescription}/>
                        </IconButton>
                    </div>
                    <div className="resource-info-tag">
                        <IconButton>
                            <HelpAlert type={"resource"} title={"Resource"} description={resourceDescription}/>
                        </IconButton>
                    </div>
                    
                </div>
                
            </div>

            <HelpStep number="3" title="Explore and Cooperate" description={cooperateDescription}>
            </HelpStep>

            <HelpStep number="4" title="Grow your Asset Network" description={growNetworkDescription}>
            </HelpStep>

            <HelpStep number="5" title="Integrate with your App" description={integrateAppDescription}>
            </HelpStep>

            <div className="row justify-content-end align-items-end">
                <Button disableRipple size="small" color="secondary" className="jr-btn mr-2" component="span" onClick={handleOpen}>
                    Close{' '} 
                    {!open && <IconExpandMore className='nav-arrow'/>}
                    {open && <IconExpandLess className='nav-arrow'/>}
                </Button>
                
            </div>

            
        </div>
        
        
    )
    
}

export default GetStarted;