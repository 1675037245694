import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';


const MainLoader = () => {

    const {mainLoader} = useSelector(({isFetching}) => isFetching);

    if (!mainLoader) return null

    return (
        <div className="row no-gutters loader-container-main justify-content-center align-items-center">
            <div className="col-2 d-flex loader-view-main justify-content-center align-items-center">
                <CircularProgress size={60} thickness={2}/>
            </div>
        </div> 
    )
}

export default MainLoader;