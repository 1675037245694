import { hideDialogueLoader_, showDialogueLoader_ } from "actions/IsFetching";
import { searchTxStringSuccess_ } from "actions/Transaction";
import { getAllTransactions } from "api";
import { SEARCH_TX_STRING } from "constants/ActionTypes";
import {all, call, fork, put, takeEvery, throttle, debounce, join} from "redux-saga/effects";
import { formatTxSearchResults } from "util/formatTxSearchResults";

let searchResults = {};

function* fetchTxHash(JWToken, querryData) {

    let querryParams = {
        page: querryData.page,
        limit: querryData.limit,
        txHash: querryData.txHash
    }
    
    const apiResponse_resources = yield call(getAllTransactions, JWToken, querryParams);

    // resources handling
    if (apiResponse_resources.status>200) {
        // console.log("apiResponse_error: ", apiResponse_resources);
    } else {
        return apiResponse_resources;
    }
    
    
}

function* fetchEnjinTxId(JWToken, querryData) {

    let querryParams = {
        page: querryData.page,
        limit: querryData.limit,
        enjinTxId: querryData.enjinTxId
    }
    
    const apiResponse_tokens = yield call(getAllTransactions, JWToken, querryParams);

    // tokens handling
    if (apiResponse_tokens.status>200) {
        // console.log("apiResponse_error: ", apiResponse_tokens);
    } else {
        return apiResponse_tokens;
    }
}


function* fetchAllTx(JWToken, querryData) {
    const apiResponse_txHash = yield fork(fetchTxHash, JWToken, querryData);
    const apiResponse_enjinTxId = yield fork(fetchEnjinTxId, JWToken, querryData);

    const results = yield join([apiResponse_txHash, apiResponse_enjinTxId]);
    return results;
}

function* searchTxsByString({payload}) {
    const {searchText, JWToken, querryData} = payload;
    // console.log(searchText);
    try {
        yield put(showDialogueLoader_())
        const fetchResults = yield call(fetchAllTx, JWToken, querryData);

        // console.log("Search RESULTS from Saga: ", fetchResults);

        // tokens handling
        if (fetchResults[0].status>200) {
            searchResults.txHashes = fetchResults[0].data;
            // console.log("apiResponse_error: ", searchResults.transactions);
        } else {
            searchResults.txHashes = fetchResults[0].txHashes.transactions;
            // console.log("apiResponse: ", searchResults.txHashes);
        }

        // resources handling
        if (fetchResults[1].status>200) {
            searchResults.enjinTxIds = fetchResults[1].data;
            // console.log("apiResponse_error: ", searchResults.transactions);
        } else {
            searchResults.enjinTxIds = fetchResults[1].data.transactions;
            // console.log("apiResponse: ", searchResults.enjinTxIds);
        }


        // console.log("SearchResults from Saga: ", searchResults);
        const formattedList = yield call(formatTxSearchResults, searchResults);
        yield put(searchTxStringSuccess_(formattedList));

    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideDialogueLoader_())
    }
}

export function* searchTxWatcher() {
    yield throttle(500, SEARCH_TX_STRING, 
        searchTxsByString);
}


export default function* rootSaga() {
    yield all([call(searchTxWatcher),
        
    ]);
}