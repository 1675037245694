import { CategoryTypes } from 'components/networkVisualizer/networkVisualizerState/sharedTypes'
import _ from 'lodash'
import React, { useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import AutoSizer from 'react-virtualized-auto-sizer'
// import { Scrollbars } from 'react-custom-scrollbars'
import { FixedSizeList, ListOnScrollProps } from 'react-window'
import { connectionsSelector } from '../../cooperations'
import { Item } from './components/item/item'

interface IsProps {
  activeCategory: CategoryTypes
  fetchMoreItems: () => void
  // connections?: Connections
}

export const List = ({ activeCategory, fetchMoreItems }: IsProps) => {
  const connections = useSelector(connectionsSelector)

  if (connections === undefined) return null

  if (connections.length === 0)
    return <EmptyPlaceholder type={activeCategory} />

  return (
    <TransitionGroup className={'list-wrapper'}>
      <CSSTransition key={activeCategory} timeout={250}>
        <ListBody
          ids={connections ?? []}
          type={activeCategory}
          {...{ fetchMoreItems }}
        />
      </CSSTransition>
    </TransitionGroup>
  )
}

interface ListBodyProps {
  ids: (string | number)[]
  type: CategoryTypes
  fetchMoreItems: () => void
}

const ListBody = ({ ids, type, fetchMoreItems }: ListBodyProps) => {
  const isMountedRef = useRef(false)
  const outerRef = useRef(null)

  const RenderItem = ({
    data,
    index,
    style,
  }: {
    data: typeof ids
    index: number
    style: any
  }) => {
    let id = data[index]
    return (
      <div {...{ style }} className=''>
        <Item key={id} {...{ id, type }} />
      </div>
    )
  }

  const onEndReached = useCallback(_.throttle(fetchMoreItems, 1500), [])

  const onScroll = useCallback(
    (e: ListOnScrollProps) => {
      if (!isMountedRef.current) {
        isMountedRef.current = true
      } else if (
        outerRef.current &&
        e.scrollOffset + outerRef.current.offsetHeight ===
          outerRef.current.scrollHeight
      ) {
        onEndReached()
      }
    },
    [onEndReached]
  )

  return (
    <AutoSizer className='scroll-wrapper'>
      {({ height, width }) => (
        <FixedSizeList
          itemCount={ids.length}
          itemData={ids}
          itemSize={itemSize}
          height={height}
          width={width}
          outerRef={outerRef}
          onScroll={onScroll}
        >
          {RenderItem}
        </FixedSizeList>
      )}
    </AutoSizer>
  )
}

let itemSize = 70

const VerticalTrack = () => <div className='track' />
const VerticalThumb = () => <div className='thumb' />

const EmptyPlaceholder = ({ type }: { type: string }) => (
  <div className='empty-placeholder-wrapper'>
    <p className=''>{`No ${type} connected`}</p>
  </div>
)
