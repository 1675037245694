import {
  closeNetworkVisualizer,
  resetNetworkVisualizer,
  setNetworkVisualizerOpeningState,
} from 'components/networkVisualizer/networkVisualizerState/store/general/actions'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { AppState } from 'store'
import { FetchingSpinner } from './components/fetchingSpinner/fetchingSpinner'
import { Filter } from './components/filter/filter'
import { FullScreenButton } from './components/fullScreenButton/fullScreenButton'
import { ItemDetails } from './components/itemDetails/itemDetails'
import { NavigationMenu } from './components/navigationMenu/navigationMenu'
import { NetworkGraph } from './components/networkGraph/networkGraph'
import { WalletInput } from './components/walletInput/walletInput'
import './networkVisualizer.scss'
import { GraphTypes } from './networkVisualizerState/sharedTypes'
import { changeSelectedNode } from './networkVisualizerState/store/graph/actions'

const Visualizer = React.memo(() => (
  <>
    <FullScreenButton />
    {/* <BackgroundStars /> */}
    <FetchingSpinner />
    <NetworkGraph />
    <NavigationMenu />
    <ItemDetails />
    <WalletInput />
    <Filter />
  </>
))

const NetworkVisualizerComp = ({
  nodeRef,
}: {
  nodeRef: React.MutableRefObject<null>
}) => {
  const dispatch = useDispatch()
  const isFullScreen = useSelector(
    (state: AppState) => state.networkVisualizerState.general.fullScreen
  )
  const initURL: string = useSelector((state: AppState) => state.auth.initURL)

  /**
   * First item in array is an empty string when splitting url.
   *
   *  "/explore/shared/tokens/123/1" => ['', 'explore', 'shared', 'tokens', '123', '1']
   */
  const path = initURL.split('/')

  const hideVisualizer = useCallback(() => {
    dispatch(closeNetworkVisualizer())
  }, [dispatch])

  useEffect(() => {
    if (path[2] === 'shared') {
      let assetType = path[3] as GraphTypes
      let assetId = path[4]
      let platformId = path[5]

      dispatch(changeSelectedNode(assetId, assetType, platformId))
    }

    //Reset the network visualizer on unmount
    return () => {
      dispatch(resetNetworkVisualizer())
    }
  }, [])

  //Full screen version if on /explore url
  let exploreScreen = path[1] === 'explore' ?? false

  return (
    <div ref={nodeRef} className='network-visualizer-parent-wrapper'>
      <div className='screen-backdrop' onClick={hideVisualizer} />
      <div
        className={`network-visualizer-container${
          exploreScreen ? '-home' : ''
        } ${isFullScreen ? 'home' : ''}`}
      >
        <div
          className={`visualizer-wrapper${exploreScreen ? '-home' : ''}`}
        ></div>
        <Visualizer />
      </div>
    </div>
  )
}

const NetworkVisualizerWrapper = () => {
  //Node Ref is to prevent CSSTransition from using the default FindDOMNode method which is deprecated in strict mode and causes a warning.
  const nodeRef = React.useRef(null)
  const dispatch = useDispatch()

  const isVisible = useSelector(
    (state: AppState) => state.networkVisualizerState.general.showVisualizer
  )

  const onEntered = () => {
    dispatch(setNetworkVisualizerOpeningState(false))
  }

  return (
    <CSSTransition
      in={isVisible}
      classNames={'network-visualizer-transition-wrapper-'}
      timeout={{ enter: 450, exit: 300 }}
      mountOnEnter
      unmountOnExit
      {...{ onEntered, nodeRef }}
    >
      <NetworkVisualizerComp nodeRef={nodeRef} />
    </CSSTransition>
  )
}

export const NetworkVisualizer = React.memo(NetworkVisualizerWrapper)
