import React from 'react'
import { NoResults } from './components/noResults/noResults'
import { Results } from './components/results/results'
import { SearchingLoader } from './components/searchingLoader/searchingLoader'

export const SearchResults = () => {
  return (
    <div className='search-results-wrapper'>
      {/* <div className="custom-scroll-window"> */}
      <NoResults />
      <SearchingLoader />
      <Results />
      {/* </div> */}
    </div>
  )
}
