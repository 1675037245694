import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { AppState } from 'store'

const visibilitySelector = createSelector(
  (state: AppState) => state.networkVisualizerState.navMenu.searchResults,
  (state: AppState) => state.networkVisualizerState.navMenu.searchValue,
  (state: AppState) => state.networkVisualizerState.navMenu.searching,
  (state: AppState) => state.networkVisualizerState.navMenu.isTyping,
  (searchResults, searchValue, searching, isTyping) =>
    searchValue.length > 0 &&
    searchResults.length < 1 &&
    !searching &&
    !isTyping
)

export const NoResults = () => {
  const visible = useSelector(visibilitySelector)

  if (!visible) return null

  return <Body />
}

const Body = () => {
  const searchValue = useSelector(
    (state: AppState) => state.networkVisualizerState.navMenu.searchValue
  )

  return (
    <div className="no-results-wrapper">
      <div className="text-container">
        <p className="no-results-text">{`Sorry, there were no results found for\n'${searchValue}'`}</p>
      </div>
    </div>
  )
}
