import React from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'store'
import { DetailPair } from './sharedComponents/detailPair/detailPair'

interface IsProps {
  id: string | number
}

export const UtilityDetails = ({ id }: IsProps) => {
  const data = useSelector(
    (state: AppState) => state.networkVisualizerState.general.data.utilities[id]
  )

  let { projectName, type } = data

  return (
    <>
      <DetailPair title='Project Name' value={projectName} />
      <DetailPair title='Type' value={typeFormatter(type)} />
    </>
  )
}

function typeFormatter(type: string | undefined | null) {
  if (type === 'gameItem') {
    return 'Game Item'
  } else if (typeof type === 'string') {
    return type.charAt(0).toUpperCase() + type.slice(1)
  } else {
    return 'Other'
  }
}
