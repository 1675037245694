export function getTan(connectionsLength: number) {
  return +Math.tan(Math.PI / connectionsLength).toFixed(2)
}

export function getRadius(itemSize: number, connectionsLength: number) {
  let tan = getTan(connectionsLength)
  const spaceBetween = getSpaceBetween(connectionsLength)
  const radius =
    Math.round(((0.5 * (1 + spaceBetween) * itemSize) / tan) * 100) / 100
  return radius
}

export function getItemRotation(i: number, connectionsLength: number): number {
  return (i * 360) / connectionsLength
}

export function getSpaceBetween(itemLength: number) {
  switch (true) {
    case itemLength <= 2:
      return window.innerWidth > 500 ? 6 : 4.5//6
    case itemLength <= 4:
      return window.innerWidth > 500 ? 5 : 3.5//5
    case itemLength <= 6:
      return window.innerWidth > 500 ? 3.5 : 2.5//3.5
    default:
      return window.innerWidth > 500 ? 1.5 : 0.7//1.5
  }
}
