import React from "react";
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import apispec from "assets/vendors/swagger/utilify-api-spec";


const Api = () => {

    apispec.servers = [{url: process.env.REACT_APP_PUBLIC_API, description: "utilify api"}]

    return (
        <div>
            <SwaggerUI spec={apispec} /> 
        </div>
        
        
    )
    
}

export default Api;