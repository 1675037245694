import { CategoryTypes } from '../../sharedTypes'

interface SearchResult {
  id: number | string
  type: CategoryTypes
  name: string
  image?: string | null
  projectName?: string
  platformId?: string | number //Only used for tokens
  platformName?: string //Only used for tokens
  tokenId?: string | number //Only used for tokens
}

export type SearchResultsType = SearchResult[]

type FeaturedProject = SearchResult

export type FeaturedProjects = FeaturedProject[]

export type SearchFilter = CategoryTypes | 'all'

export interface NavMenuState {
  visible: boolean
  searchFilter: SearchFilter
  searchValue: string
  isTyping: boolean
  searching: boolean
  searchResults: SearchResultsType
  showFeaturedProjects: boolean
  featuredProjectsLoading: boolean
  featuredProjects: FeaturedProjects
}

export const TOGGLE_NETWORK_VISUALIZER_NAV_MENU =
  'TOGGLE_NETWORK_VISUALIZER_NAV_MENU'

export interface ShowVisualizerNavMenu {
  type: typeof TOGGLE_NETWORK_VISUALIZER_NAV_MENU
}

export const HIDE_NETWORK_VISUALIZER_NAV_MENU =
  'HIDE_NETWORK_VISUALIZER_NAV_MENU'

export interface HideVisualizerNavMenu {
  type: typeof HIDE_NETWORK_VISUALIZER_NAV_MENU
}

export const SET_VISUALIZER_SEARCH_FILTER = 'SET_VISUALIZER_SEARCH_FILTER'

export interface SetVisualizerSearchFilter {
  type: typeof SET_VISUALIZER_SEARCH_FILTER
  payload: SearchFilter
}

//Used to set loading whilst the search query is processing.
export const SET_VISUALIZER_SEARCHING_STATUS = 'SET_VISUALIZER_SEARCHING_STATUS'

export interface SetSearchingStatus {
  type: typeof SET_VISUALIZER_SEARCHING_STATUS
  payload: boolean
}

export const SET_VISUALIZER_SEARCH_TYPING_STATUS =
  'SET_VISUALIZER_SEARCH_TYPING_STATUS'

export interface SetVisualizerSearchTypingStatus {
  type: typeof SET_VISUALIZER_SEARCH_TYPING_STATUS
  payload: boolean
}

export const SET_VISUALIZER_SEARCH_VALUE = 'SET_VISUALIZER_SEARCH_VALUE'

export interface SetVisualizerSearchValue {
  type: typeof SET_VISUALIZER_SEARCH_VALUE
  payload: string
}

export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS'

export interface SetSearchResults {
  type: typeof SET_SEARCH_RESULTS
  payload: SearchResultsType
}

export const RESET_VISUALIZER_NAV_MENU_STATE = 'RESET_VISUALIZER_NAV_MENU_STATE'

export interface ResetVisualizerNavMenuState {
  type: typeof RESET_VISUALIZER_NAV_MENU_STATE
}

export const SET_VISUALIZER_FEATURED_PROJECTS =
  'SET_VISUALIZER_FEATURED_PROJECTS'

export interface SetFeaturedProjects {
  type: typeof SET_VISUALIZER_FEATURED_PROJECTS
  payload: FeaturedProjects
}

export const SET_VISUALIZER_FEATURED_PROJECTS_VISIBILITY =
  'SET_VISUALIZER_FEATURED_PROJECTS_VISIBILITY'

export interface SetFeaturedProjectsVisibility {
  type: typeof SET_VISUALIZER_FEATURED_PROJECTS_VISIBILITY
  payload?: boolean
}

export type NavMenuActions =
  | ShowVisualizerNavMenu
  | HideVisualizerNavMenu
  | SetVisualizerSearchFilter
  | SetVisualizerSearchTypingStatus
  | SetSearchingStatus
  | ChangeVisualizerSearchValue
  | SetVisualizerSearchValue
  | SetSearchResults
  | ResetVisualizerNavMenuState
  | SetFeaturedProjects
  | SetFeaturedProjectsVisibility

/**
 *
 * Redux Saga Actions
 *
 */

export const CHANGE_VISUALIZER_SEARCH_FILTER = 'CHANGE_VISUALIZER_SEARCH_FILTER'

export interface ChangeVisualizerSearchFilter {
  type: typeof CHANGE_VISUALIZER_SEARCH_FILTER
  payload: SearchFilter
}

export const CHANGE_VISUALIZER_SEARCH_VALUE = 'CHANGE_VISUALIZER_SEARCH_VALUE'

export interface ChangeVisualizerSearchValue {
  type: typeof CHANGE_VISUALIZER_SEARCH_VALUE
  payload: string
}

export const FETCH_VISUALIZER_FEATURED_PROJECTS =
  'FETCH_VISUALIZER_FEATURED_PROJECTS'

export interface FetchVisualizerFeaturedProjects {
  type: typeof FETCH_VISUALIZER_FEATURED_PROJECTS
}
