import { signOut } from "sagas/Auth";

export const errorHandler = (error) => {
    let errorMessage = {};
    errorMessage.type = "error";
    if(!error.errorMessage || error.errorMessage === 'Unspecified') {
        if (error.message && error.message === "The incoming token has expired") {
            signOut();
        }
        errorMessage.message = "Something went wrong, please try again later";
        errorMessage.title = "Oops!"
    } else {
        errorMessage.message = error.errorMessage;
        errorMessage.title = error.error;
    }
    // console.log("Error: ", errorMessage);
    return errorMessage;
}

export const successHandler = (type, name, operation) => {
    let successMessage = {};
    successMessage.type = "success";
    switch(operation) {
        case "made":
            successMessage.title = `${type} ${operation}`;
            successMessage.message = `${type}${name ? ` ${name}` : ` `} has been ${operation}`;
        break;
        case "created":
            successMessage.title = `${type} ${operation}`;
            successMessage.message = `${type}${name ? ` ${name}` : ` `} has been ${operation}`;
        break;
        case "deleted":
            successMessage.title = `${type} ${operation}`;
            successMessage.message = `${type}${name ? ` ${name}` : ` `} has been ${operation}`;
        break;
        case "removed":
            successMessage.title = `${type} ${operation}`;
            successMessage.message = `${type}${name ? ` ${name}` : ` `} has been ${operation}`;
        break;
        case "updated":
            successMessage.title = `${type} ${operation}`;
            successMessage.message = `${type}${name ? ` ${name}` : ` `} has been ${operation}`;
        break;
        case "sent":
            successMessage.title = `${type} ${operation}`;
            successMessage.message = `${type} has been ${operation}`;
        break;
        case "accepted":
            successMessage.title = `${type} ${operation}`;
            successMessage.message = `${type} has been ${operation}`;

        break;
        case "placed":
            successMessage.title = `${type} ${operation}`;
            successMessage.message = `${type} has been ${operation}`;
        break;
        case "added":
            successMessage.title = `${type} ${operation}`;
            successMessage.message = `${type} has been ${operation} to your project`;
        break;
        case "pinned":
            successMessage.title = `${type} ${operation}`;
            successMessage.message = `${type} has been ${operation} to your profile`;
        break;
        case "unpinned":
            successMessage.title = `${type} ${operation}`;
            successMessage.message = `${type} has been ${operation} from your profile`;
        break;
        case "linked":
            successMessage.title = `${type} ${operation}`;
            successMessage.message = `${type} has been ${operation}`;
        break;
        case "unlinked":
            successMessage.title = `${type} ${operation}`;
            successMessage.message = `${type} has been ${operation}`;
        break;
        default: 
            successMessage.title = `Success!`;
            successMessage.message = `Your action was successful`;

    }
    // console.log("Success: ", successMessage);
    return successMessage;
}

export const infoHandler = (operation) => {
    let infoMessage = {};
    infoMessage.type = "warning";
    switch(operation) {
        case "uri updated":
            infoMessage.title = `Confirm URI update`;
            infoMessage.message = `Please confirm in your wallet`;
        break;
        case "token created":
            infoMessage.title = `Confirm Request`;
            infoMessage.message = `Please confirm in your wallet`;
        break;
        case "force send requested":
            infoMessage.title = `Confirm Request`;
            infoMessage.message = `Please confirm in your wallet`;
        break;
        case "check fields":
            infoMessage.title = `Check field inputs`;
            infoMessage.message = `Please try different values`;
        break;
        default: 
        infoMessage.title = `Success!`;
        infoMessage.message = `Your action was successful`;

    }
    // console.log("Success: ", infoMessage);
    return infoMessage;
}


/*{
    "error": "Unspecified",
    "errorCode": 0,
    "errorMessage": "Unspecified",
    "errorId": 1608629982985
}

{
    "error": "Bad Request",
    "errorCode": 0,
    "errorMessage": "insufficient sending supply for token 188000000000032a; available balance: 1, missing: 1"
}

{
    "message": "Internal server error"
  }*/

  