import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import {
    clearAuthUser,
    hideMessage,
    showAuthLoader,
    userFacebookSignIn,
    userGithubSignIn,
    userGoogleSignIn,
    userSignOutSuccess,
    userSignUp,
    userTwitterSignIn
} from 'actions/Auth';
import logo from "assets/images/logo_trans.svg";

const VerifyAccount = (props) => {

    const dispatch = useDispatch();
    const {authUser, alertMessage, showMessage} = useSelector(({auth}) => auth);
    let timeout;
    useEffect(() => {
        if (showMessage) {
            timeout = setTimeout(() => {
                dispatch(hideMessage());
            }, 3000);
        }
    if (authUser !== null) {
        dispatch(clearAuthUser());
    }
    return () => {
        clearTimeout(timeout);
    }

    }, [showMessage, props.history, dispatch]);

    let message = "Thanks for Signing Up & Welcome to Utilify! We've sent a verification link to your registered eMail address."

    return (
        <div
            className="app-login-container justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="row justify-content-center mt-5 mb-4">
                    <Link className="logo-lg" to="/" title="Jambo">
                        <img src={logo} alt="jambo" title="jambo"/>
                    </Link>
                </div>

            <div className="row justify-content-center mt-5 mb-5">

                <div className="col-12 d-flex justify-content-center mb-3">
                    <h1 className="d-flex d-block" style={{textAlign: "center"}}>{"Verify your Account"}</h1>
                </div>
                
                <div className="col-12 d-flex justify-content-center mb-5">
                    <h4 className="text-muted d-flex d-block" style={{textAlign: "center"}}>{message}</h4>
                </div>
                
                <div className="col-12 d-flex justify-content-center mb-2">
                    <div className="row justify-content-center mb-3">
                        <Button variant="contained" color="primary" onClick={()=> props.history.push("/signin")}>
                            Back to Login
                        </Button>
                    </div>
                </div>
            </div>
            

            {showMessage && NotificationManager.error(alertMessage)}
            <NotificationContainer/>
        </div>
    )
};


export default VerifyAccount;
