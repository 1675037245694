import React, { useState } from 'react';

import { Dialog, IconButton } from '@material-ui/core';

import HelpDialogueBody from './HelpDialogueBody';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const HelpAlert = ({
    type,
    title,
    description
}) => {

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false);
    };
    
    return (
        <div>
            <IconButton>
                <HelpOutlineIcon color="secondary" onClick={handleClickOpen}/>
            </IconButton>

            <Dialog
                open={open}
                onClose={handleClose}
                scroll={"body"}
                maxWidth={"md"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <HelpDialogueBody type={type} title={title} description={description} handleClose={handleClose}/>
            </Dialog>

            
            
            
        </div>
    );
};

export default HelpAlert;