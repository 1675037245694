import React from 'react'
import { CSSTransition } from 'react-transition-group'

interface IsProps {
  visible: boolean
}

const Ring = () => (
  <div className="active-ring-wrapper">
    <div className="outer-ring">
      {Array(15)
        .fill(null)
        .map((_, i) => (
          <span key={'outer-ring-teeth-' + i} className="ring-teeth" />
        ))}
    </div>
    <div className="inner-ring">
      {Array(15)
        .fill(null)
        .map((_, i) => (
          <span key={'inner-ring-teeth-' + i} className="ring-teeth" />
        ))}
    </div>
  </div>
)

export const ActiveRing = ({ visible }: IsProps) => {
  return (
    <CSSTransition
      in={visible}
      classNames="transition-"
      timeout={{
        enter: 450,
        exit: 250,
      }}
      unmountOnExit
      mountOnEnter
    >
      <Ring />
    </CSSTransition>
  )
}
