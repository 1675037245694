import React from "react";
import "swagger-ui-react/swagger-ui.css"
import { Link } from "react-router-dom";
import PageTitle from "components/PageLayout/PageTitle";


const Overview = () => {
    return (
        <div>
            <div className="mt-5 mb-5 ml-4">
                <PageTitle title={"Overview"}/>
            </div>
            
            <ul className="mt-5">
                
                <Link to="/Docs/Platform"><li><h2 className="text-muted">Platform</h2></li></Link>
                <Link to="/Docs/Api"><li><h2 className="text-muted">API</h2></li></Link>
                <Link to="/Docs/Sdk"><li><h2 className="text-muted">SDK</h2></li></Link>
            </ul>
        </div>
        
        
    )
    
}

export default Overview;