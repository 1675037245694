import {
    UPDATE_IMG_BY_DATA_SUCCESS, 
    SET_SELECTED_IMAGE,
    SET_SELECTED_BANNER,
    UPDATE_BANNER_BY_DATA_SUCCESS,
    UPLOAD_SUCCESS,
    DELETE_SUCCESS,
    DELETE_IMG_SUCCESS,
    IMG_REFRESHED,
    IMGS_REFRESHED
} from "constants/ActionTypes";

const INIT_STATE = {
    imgUrl: '',
    imgData: '',
    bannerData: '',
    bannerUrl: '',
    uploadSuccess: '',
    deleteImgSuccess: '',
    imgRefreshed: false,
    imgsRefreshed: false


};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPDATE_IMG_BY_DATA_SUCCESS: {
            return {
                ...state,
                imgUrl: action.payload
            }
        }
        case UPDATE_BANNER_BY_DATA_SUCCESS: {
            return {
                ...state,
                bannerUrl: action.payload
            }
        }
        case SET_SELECTED_IMAGE: {
            return {
                ...state,
                imgData: action.payload
            }
        }
        case UPLOAD_SUCCESS: {
            return {
                ...state,
                uploadSuccess: action.payload
            }
        }
        case DELETE_IMG_SUCCESS: {
            return {
                ...state,
                deleteImgSuccess: action.payload
            }
        }
        case SET_SELECTED_BANNER: {
            return {
                ...state,
                bannerData: action.payload
            }
        }
        case IMG_REFRESHED: {
            return {
                ...state,
                imgRefreshed: action.payload
            }
        }
        case IMGS_REFRESHED: {
            return {
                ...state,
                imgsRefreshed: action.payload
            }
        }
        default:
            return state;
    }
}