import {
  ToggleNetworkVisualizer,
  TOGGLE_NETWORK_VISUALIZER,
} from '../general/types'
import {
  ShowVisualizerNavMenu,
  TOGGLE_NETWORK_VISUALIZER_NAV_MENU,
} from '../navMenu/types'
import {
  HIDE_ITEM_DETAILS,
  ItemDetailsActions,
  ItemDetailsState,
  RESET_VISUALIZER_ITEM_DETAILS_STATE,
  SET_ITEM_DETAILS,
  SET_ITEM_DETAILS_COOPERATIONS,
  SET_ITEM_DETAILS_COOPERATIONS_TAB_INDEX,
  SET_ITEM_DETAILS_FETCHING,
} from './types'

const defaultState: ItemDetailsState = {
  visible: false,
  fetching: false,
  id: 0,
  type: 'tokens',
  platformId: undefined, //Don't know where else to store this, data returned from API is not reliable.
  cooperations: {},
  cooperationsTabIndex: 0,
}

type Actions =
  | ItemDetailsActions
  | ShowVisualizerNavMenu
  | ToggleNetworkVisualizer

export const itemDetailsReducer = (
  state = defaultState,
  action: Actions
): ItemDetailsState => {
  switch (action.type) {
    case TOGGLE_NETWORK_VISUALIZER:
      //Make sure the details comp is closed upon opening the visualizer.
      return {
        ...state,
        visible: action.payload ? false : state.visible,
      }
    case SET_ITEM_DETAILS:
      return {
        //Using default state here to reset the state when opening.
        ...defaultState,
        ...action.payload,
        visible: true,
      }
    case HIDE_ITEM_DETAILS:
      return {
        ...state,
        visible: false,
      }
    case SET_ITEM_DETAILS_FETCHING:
      return {
        ...state,
        fetching: action.payload,
      }
    case SET_ITEM_DETAILS_COOPERATIONS_TAB_INDEX:
      return {
        ...state,
        cooperationsTabIndex: action.payload,
      }
    case SET_ITEM_DETAILS_COOPERATIONS:
      let { connectionType, connections } = action.payload

      //If previous connection of connectionType are undefined, use an empty array.
      let previousConnectionsOfType = state.cooperations[connectionType] ?? []

      //Ensures there are no repeats. Unsure about API reliability so using as an extra check to avoid duplicates. Is this required?
      let newConnectionsFiltered = connections.filter(
        (c) => previousConnectionsOfType.indexOf(c) < 0
      )

      return {
        ...state,
        cooperations: {
          ...state.cooperations,
          [connectionType]: [
            ...previousConnectionsOfType,
            ...newConnectionsFiltered,
          ],
        },
      }
    case TOGGLE_NETWORK_VISUALIZER_NAV_MENU:
      //Close the details comp when the nav menu opens.
      return {
        ...state,
        visible: false,
      }
    case RESET_VISUALIZER_ITEM_DETAILS_STATE:
      return defaultState
    default:
      return state
  }
}
