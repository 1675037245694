import React from 'react'

interface IsProps {
  title: string
  value?: string | number | null
  link?: string
}

export const DetailPair = ({ title, value, link }: IsProps) => {
  if (value !== undefined && value !== null) {
    return (
      <div className='detail-pair-wrapper'>
        <div className='title-container'>
          <p className='detail-title detail-text'>{title}</p>
        </div>
        <div className='value-container'>
          {link ? (
            <a
              href={!/^https?:\/\//i.test(link) ? 'https://' + link : link} //Link should be properly parsed from the backend. This could easily break, check consistency of links supplied by backend.
              target='_blank'
              rel='noopener noreferrer'
              className='detail-value detail-text link'
            >
              {value}
            </a>
          ) : (
            <p className='detail-value detail-text'>{value}</p>
          )}
        </div>
      </div>
    )
  } else {
    return null
  }
}
