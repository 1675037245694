import {all, call, fork, put, select, takeEvery} from "redux-saga/effects";

import {
    GET_ALL_TRANSACTIONS,
    GET_ALL_TRANSACTIONS_SUCCESS,
    GET_TRANSACTION,
    GET_TRANSACTION_SUCCESS,
    
} from "constants/ActionTypes";

import {
    getAllTransactions_,
    getAllTransactionsSuccess_,
    getTransaction_,
    getTransactionSuccess_

} from "actions/Transaction";

import {
    getAllTransactions,
    getTransaction

} from "../api";

import { Auth } from 'aws-amplify';
import { hideDashbaordLoader_, hideScrollLoader_, hideTransparentLoader_, isLoadingMore_, showDashbaordLoader_, showScrollLoader_, showTransparentLoader_ } from "actions/IsFetching";
import { userSignOut } from "actions";

// Get JWToken from Local Storage

function* getAllTransactionsById({payload}) {
    const {JWToken, querryParams, refresh, loadMore, noLoader} = payload;
    const transactionList = yield select(state => state.transaction.transactionList);



    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDashbaordLoader_());
            } 
        }

        if (loadMore) {
            yield put(showScrollLoader_());
            yield put(isLoadingMore_(true));
        }

        const apiResponse = yield call(getAllTransactions, JWToken, querryParams);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            if (loadMore) {
                yield put(getAllTransactionsSuccess_([...transactionList, ...apiResponse.data.transactions])); 
            } else {
                yield put(getAllTransactionsSuccess_(apiResponse.data.transactions)); 
            }
            yield put(getAllTransactionsSuccess_(apiResponse.data.transactions));        
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideDashbaordLoader_());
        yield put(hideTransparentLoader_());
        yield put(hideScrollLoader_());
        yield put(isLoadingMore_(false));
    }

}

function* getTransactionById({payload}) {
    const {JWToken, transactionId, querryParams, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDashbaordLoader_());
            } 
        }

        const apiResponse = yield call(getTransaction, JWToken, transactionId, querryParams);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(getTransactionSuccess_(apiResponse.data));        
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideDashbaordLoader_());
        yield put(hideTransparentLoader_());
    }

}



export function* readAllTransactions() {
    yield takeEvery(GET_ALL_TRANSACTIONS, 
        getAllTransactionsById);
}

export function* readOneTransaction() {
    yield takeEvery(GET_TRANSACTION, 
        getTransactionById);
}

export default function* rootSaga() {
    yield all([fork(readAllTransactions),
        fork(readOneTransaction)
    ]);
}