import React from 'react'

export const CooperationsCountBadge = ({
  count,
}: {
  count: number | string
}) => (
  <div className='cooperations-count-badge'>
    <p className='count-text'>{count}</p>
  </div>
)
