import {
  ToggleNetworkVisualizer,
  TOGGLE_NETWORK_VISUALIZER,
} from '../general/types'
import { ShowItemDetails, SHOW_ITEM_DETAILS } from '../itemDetails/types'
import {
  HIDE_NETWORK_VISUALIZER_NAV_MENU,
  NavMenuActions,
  NavMenuState,
  RESET_VISUALIZER_NAV_MENU_STATE,
  SET_SEARCH_RESULTS,
  SET_VISUALIZER_FEATURED_PROJECTS,
  SET_VISUALIZER_FEATURED_PROJECTS_VISIBILITY,
  SET_VISUALIZER_SEARCHING_STATUS,
  SET_VISUALIZER_SEARCH_FILTER,
  SET_VISUALIZER_SEARCH_TYPING_STATUS,
  SET_VISUALIZER_SEARCH_VALUE,
  TOGGLE_NETWORK_VISUALIZER_NAV_MENU,
} from './types'

const defaultState: NavMenuState = {
  visible: false,
  searchFilter: 'all',
  searchValue: '',
  searching: false,
  isTyping: false,
  searchResults: [],
  showFeaturedProjects: true,
  featuredProjectsLoading: true,
  featuredProjects: [],
}

type Actions = NavMenuActions | ShowItemDetails | ToggleNetworkVisualizer

export const navMenuReducer = (
  state = defaultState,
  action: Actions
): NavMenuState => {
  switch (action.type) {
    case TOGGLE_NETWORK_VISUALIZER:
      //Make sure the nav menu is closed upon opening the visualizer.
      return {
        ...state,
        visible: action.payload ? false : state.visible,
      }
    case TOGGLE_NETWORK_VISUALIZER_NAV_MENU:
      return {
        ...state,
        visible: true,
      }
    case HIDE_NETWORK_VISUALIZER_NAV_MENU:
      return {
        ...state,
        visible: false,
      }
    case SHOW_ITEM_DETAILS:
      return {
        ...state,
        visible: false,
      }
    case SET_VISUALIZER_SEARCH_FILTER:
      return {
        ...state,
        searchFilter: action.payload,
      }
    case SET_VISUALIZER_SEARCHING_STATUS:
      //Automatically sets to false when search results are set.
      return {
        ...state,
        searching: action.payload,
      }
    case SET_VISUALIZER_SEARCH_TYPING_STATUS:
      return {
        ...state,
        isTyping: action.payload,
      }
    case SET_VISUALIZER_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      }
    case SET_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload,
        searching: false,
      }
    case SET_VISUALIZER_FEATURED_PROJECTS:
      return {
        ...state,
        featuredProjectsLoading: false,
        featuredProjects: action.payload,
      }
    case SET_VISUALIZER_FEATURED_PROJECTS_VISIBILITY:
      return {
        ...state,
        showFeaturedProjects: action.payload ?? !state.showFeaturedProjects,
      }
    case RESET_VISUALIZER_NAV_MENU_STATE:
      let { featuredProjects, ...resetState } = defaultState
      return {
        ...state,
        ...resetState,
      }
    default:
      return state
  }
}
