import {
    GET_ASSET_SUCCESS,
    ASSET_REFRESHED,
    ASSETS_REFRESHED
} from 'constants/ActionTypes'

export const getAssetSuccess_ = (data) => {
    return {
        type: GET_ASSET_SUCCESS,
        payload: data
    };
};

export const assetRefreshed_ = (data) => {
    return {
        type: ASSET_REFRESHED,
        payload: data
    };
};

export const assetsRefreshed_ = (data) => {
    return {
        type: ASSETS_REFRESHED,
        payload: data
    };
};


