import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Avatar from '@material-ui/core/Avatar'
import {useDispatch} from 'react-redux'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {userSignOut} from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import {getAuthUser} from "../../actions/Auth";
import unknownIcon from 'assets/images/unknown_icon.png';



const UserInfo = () => {

    const dispatch = useDispatch();

    const [anchorE1, setAnchorE1] = useState(null);
    const [open, setOpen] = useState(false);

    const {alertMessage, showMessage, authUser} = useSelector(({auth}) => auth);

    // console.log("auth: ", authUser);

    const handleClick = event => {
        setOpen(true);
        setAnchorE1(event.currentTarget);
    };

    const handleRequestClose = () => {
        setOpen(false);
    };

    return (
        <div className="user-profile d-flex flex-row align-items-center">
            {authUser.profile && (
                <Avatar
                    alt='...'
                    src={authUser.profile.avatarImage ? authUser.profile.avatarImage.thumbnail : unknownIcon}
                    className="user-avatar-custom "
                />)}
            
            {authUser.user && (
                <div className="user-detail">
                    <h4 className="user-name d-flex" onClick={handleClick}><span
                        className='text-truncate'>{authUser.user.username}</span> <i
                        className="zmdi zmdi-caret-down zmdi-hc-fw align-middle"/>
                    </h4>
                </div>
            )}
            <Menu className="user-info"
                  id="simple-menu"
                  anchorEl={anchorE1}
                  disableScrollLock={true}
                  open={open}
                  onClose={handleRequestClose}
                  PaperProps={{
                      style: {
                          minWidth: 120,
                          paddingTop: 0,
                          paddingBottom: 0,
                          marginTop: 8
                      }
                  }}
            >
                <Link to={`/app/profile/${authUser.profile.userId}`} style={{textDecoration: "none"}}><MenuItem onClick={handleRequestClose}>
                    <i className="zmdi zmdi-account zmdi-hc-fw mr-2"/>
                    <IntlMessages id="popup.profile"/>
                </MenuItem></Link>
                <Link to='/app/accountsettings' style={{textDecoration: "none"}}><MenuItem onClick={handleRequestClose}>
                    <i className="zmdi zmdi-settings zmdi-hc-fw mr-2"/>
                    <IntlMessages id="popup.account"/>
                </MenuItem></Link>
                <MenuItem onClick={() => {
                    handleRequestClose();
                    dispatch(userSignOut());
                }}>
                    <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>

                    <IntlMessages id="popup.logout"/>
                </MenuItem>
            </Menu>
        </div>
    );
};

export default UserInfo;


