import { CategoryTypes, GraphTypes } from '../../sharedTypes'
import {
  CloseNetworkVisualizer,
  CLOSE_NETWORK_VISUALIZER,
  MergeVisualizerItemConnections,
  MergeVisualizerItemConnectionsPayload,
  MERGE_VISUALIZER_ITEM_CONNECTIONS,
  OpenNetworkVisualizer,
  OPEN_NETWORK_VISUALIZER,
  ResetNetworkVisualizer,
  ResetVisualizerGeneralState,
  ResetVisualizerItemConnections,
  RESET_NETWORK_VISUALIZER,
  RESET_VISUALIZER_GENERAL_STATE,
  RESET_VISUALIZER_ITEM_CONNECTIONS,
  SetNetworkVisualizerOpeningState,
  SetVisualizerData,
  SetVisualizerDataPayload,
  SetVisualizerFetching,
  SET_NETWORK_VISUALIZER_OPENING_STATE,
  SET_VISUALIZER_DATA,
  SET_VISUALIZER_FETCHING,
  ToggleFullScreen,
  ToggleNetworkVisualizer,
  TOGGLE_FULL_SCREEN,
  TOGGLE_NETWORK_VISUALIZER,
} from './types'

//Saga
export const openNetworkVisualizer = (
  id: number | string,
  type: CategoryTypes
): OpenNetworkVisualizer => ({
  type: OPEN_NETWORK_VISUALIZER,
  payload: { id, type },
})

//Saga
export const resetNetworkVisualizer = (): ResetNetworkVisualizer => ({
  type: RESET_NETWORK_VISUALIZER,
})

//Saga
export const closeNetworkVisualizer = (): CloseNetworkVisualizer => ({
  type: CLOSE_NETWORK_VISUALIZER,
})

export const toggleNetworkVisualizer = (
  visible: boolean
): ToggleNetworkVisualizer => ({
  type: TOGGLE_NETWORK_VISUALIZER,
  payload: visible,
})

export const setNetworkVisualizerOpeningState = (
  opening: boolean
): SetNetworkVisualizerOpeningState => ({
  type: SET_NETWORK_VISUALIZER_OPENING_STATE,
  payload: opening,
})

export const setVisualizerData = (
  data: SetVisualizerDataPayload
): SetVisualizerData => ({
  type: SET_VISUALIZER_DATA,
  payload: data,
})

export const toggleFullScreen = (visible?: boolean): ToggleFullScreen => ({
  type: TOGGLE_FULL_SCREEN,
  payload: visible,
})

export const setVisualizerFetching = (
  payload: boolean
): SetVisualizerFetching => ({
  type: SET_VISUALIZER_FETCHING,
  payload,
})

export const mergeVisualizerDataItemConnections = (
  payload: MergeVisualizerItemConnectionsPayload
): MergeVisualizerItemConnections => ({
  type: MERGE_VISUALIZER_ITEM_CONNECTIONS,
  payload,
})

/**
 * Removes all connections from an item. Currently used for resetting wallet data on page change.
 * @param itemType
 * @param itemId
 */
export const resetVisualizerItemConnections = (
  itemType: GraphTypes,
  itemId: string | number
): ResetVisualizerItemConnections => ({
  type: RESET_VISUALIZER_ITEM_CONNECTIONS,
  payload: { itemType, itemId },
})

export const resetVisualizerGeneralState = (): ResetVisualizerGeneralState => ({
  type: RESET_VISUALIZER_GENERAL_STATE,
})
