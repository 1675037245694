import {
  ChangeWalletInputValue,
  CHANGE_WALLET_INPUT_VALUE,
  SetWalletInputValue,
  SET_WALLET_INPUT_VALUE,
  SubmitWalletInput,
  SUBMIT_WALLET_INPUT,
  ToggleWalletInputVisibility,
  TOGGLE_WALLET_INPUT_VISIBILITY,
} from './types'

/**
 * Use to set the wallet input value. Do not call directly, use `changeWalletInputValue` instead.
 * @param walletId string | number
 */
export const setWalletInputValue = (
  walletId: string | number
): SetWalletInputValue => ({
  type: SET_WALLET_INPUT_VALUE,
  payload: walletId,
})

/**
 * Use to set any of the wallet input visibility. If no value is passed, stateful value will be toggled.
 * @param walletId string | number
 */
export const toggleWalletInputVisibility = (
  visible?: boolean
): ToggleWalletInputVisibility => ({
  type: TOGGLE_WALLET_INPUT_VISIBILITY,
  payload: visible,
})

/**
 *
 * Redux Saga Actions
 *
 */

/**
 * Use change the wallet input value. Managed by redux saga.
 * @param walletId string | number
 */
export const changeWalletInputValue = (
  walletId: string | number
): ChangeWalletInputValue => ({
  type: CHANGE_WALLET_INPUT_VALUE,
  payload: walletId,
})

/**
 * Use on submit of the wallet input.
 */
export const submitWalletInput = (): SubmitWalletInput => ({
  type: SUBMIT_WALLET_INPUT,
})
