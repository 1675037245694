import {

    GET_ALL_USERS_SUCCESS,
    GET_USER_SUCCESS,
    ADD_USER_SUCCESS,
    UPDATE_USER_SUCCESS,
    DELETE_USER_SUCCESS,
    PIN_TOKEN_TO_PROFILE_SUCCESS,
    PIN_RESOURCE_TO_PROFILE_SUCCESS,
    PIN_UTILITY_TO_PROFILE_SUCCESS,
    GET_USER_PROJECT_WATCHLIST_SUCCESS,
    GET_USER_SETTINGS_SUCCESS,
    UPDATE_USER_SETTINGS_SUCCESS,
    PINNED_TO_USER_SUCCESS,
    UNPINNED_FROM_USER_SUCCESS,
    USER_REFRESHED,
    USERS_REFRESHED,
    
} from "constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    userList: [],
    userProfile: '',
    projectWatchList: [],
    userSettings:'',
    pinnedToUserSuccess: false,
    unpinnedFromUserSuccess: false,
    userRefreshed: false,
    usersRefreshed: false

};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_USERS_SUCCESS: {
            return {
                ...state,
                loader: false,
                userList: action.payload
            }
        }
        case GET_USER_SUCCESS: {
            return {
                ...state,
                userProfile: action.payload
            }
        }
        case ADD_USER_SUCCESS: {
            return {
                ...state,
                loader: false
            }
        }
        case UPDATE_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                userProfile: action.payload
            }
        }
        case DELETE_USER_SUCCESS: {
            return {
                ...state,
                loader: false
            }
        }
        case PIN_TOKEN_TO_PROFILE_SUCCESS: {
            return {
                ...state,
                loader: false,
            }
        }
        case PIN_RESOURCE_TO_PROFILE_SUCCESS: {
            return {
                ...state,
                loader: false
            }
        }
        case PIN_UTILITY_TO_PROFILE_SUCCESS: {
            return {
                ...state,
                loader: false
            }
        }
        case GET_USER_PROJECT_WATCHLIST_SUCCESS: {
            return {
                ...state,
                loader: false,
                projectWatchList: action.payload
            }
        }
        case GET_USER_SETTINGS_SUCCESS: {
            return {
                ...state,
                loader: false,
                userSettings: action.payload
            }
        }
        case UPDATE_USER_SETTINGS_SUCCESS: {
            return {
                ...state,
                loader: false
            }
        }
        case PINNED_TO_USER_SUCCESS: {
            return {
                ...state,
                pinnedToUserSuccess: action.payload
            }
        }
        case UNPINNED_FROM_USER_SUCCESS: {
            return {
                ...state,
                unpinnedFromUserSuccess: action.payload
            }
        }
        case USER_REFRESHED: {
            return {
                ...state,
                userRefreshed: action.payload
            }
        }
        case USERS_REFRESHED: {
            return {
                ...state,
                usersRefreshed: action.payload
            }
        }
        default:
            return state;
    }
}

