import {all, call, delay, fork, put, select, takeEvery} from "redux-saga/effects";

import {
    GET_ALL_REQUESTS,
    GET_ALL_REQUESTS_SUCCESS,
    CREATE_REQUEST,
    CREATE_REQUEST_SUCCESS,
    UPDATE_REQUEST_STATUS,
    UPDATE_REQUEST_STATUS_SUCCESS,
    DELETE_REQUEST,
    DELETE_REQUEST_SUCCESS,
    GET_REQUEST
    
} from "constants/ActionTypes";

import {
    getAllRequestsSuccess_,
    createRequest_,
    createRequestSuccess_,
    updateRequestStatus_,
    updateRequestStatusSuccess_,
    deleteRequest_,
    deleteRequestSuccess_,
    getRequestSuccess_,
    getAllRequestsOverviewSuccess_,
    getAllRequestsReceivedSuccess_,
    getAllRequestsSentSuccess_,
    requestRefreshed_,
    requestsRefreshed_

} from "actions/Request";

import {
    getAllRequests,
    createRequest,
    updateRequestStatus,
    deleteRequest,
    getRequest

} from "../api";

import { Auth } from 'aws-amplify';
import { hideDashbaordLoader_, hideDialogueLoader_, hideIsFetching_, hideLocalFetching_, hideScrollLoader_, hideTransparentLoader_, isLoadingMore_, showDashbaordLoader_, showDialogueLoader_, showFetchingSuccess_, showIsFetching_, showLocalFetching_, showScrollLoader_, showTransparentLoader_ } from "actions/IsFetching";
import { errorHandler, successHandler } from "util/messageHandler";
import { viewAlertMessage_ } from "actions/Alert";
import { userSignOut } from "actions";

// Get JWToken from Local Storage

function* getAllRequestsByFilter({payload}) {

    const {JWToken, querryParams, refresh, loadMore, page, noLoader} = payload;

    const requestListOverview = yield select(state => state.request.requestListOverview)
    const requestListReceived = yield select(state => state.request.requestListReceived)
    const requestListSent = yield select(state => state.request.requestListSent)

    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDashbaordLoader_());
            } 
        }
        if (!refresh && loadMore) {
            yield put(showScrollLoader_());
            yield put(isLoadingMore_(true));
        }
        const apiResponse = yield call(getAllRequests, JWToken, querryParams);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            yield put(hideIsFetching_())
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse.data);
            switch (page) {
                case "overview":
                    if (loadMore) {
                        yield put(getAllRequestsOverviewSuccess_([...requestListOverview, ...apiResponse.data.requests])); 
                    } else {
                        yield put(getAllRequestsOverviewSuccess_(apiResponse.data.requests)); 
                    }
                break;
                case "received":
                    if (loadMore) {
                        yield put(getAllRequestsReceivedSuccess_([...requestListReceived, ...apiResponse.data.requests])); 
                    } else {
                        yield put(getAllRequestsReceivedSuccess_(apiResponse.data.requests)); 
                    }
                break;
                case "sent":
                    if (loadMore) {
                        yield put(getAllRequestsSentSuccess_([...requestListSent, ...apiResponse.data.requests])); 
                    } else {
                        yield put(getAllRequestsSentSuccess_(apiResponse.data.requests)); 
                    }
                break;
            }
                
            

            /* const allRequests = apiResponse.data.requests;
            let allRequestsFull = [];


             for (let i=0; i<allRequests.length; i++) {
                let requestId = allRequests[i].requestId;
                let fullRequestObj = yield call(getRequest, JWToken, requestId);
                yield allRequestsFull.push(fullRequestObj.data);
            }        
            yield put(getAllRequestsSuccess_(allRequestsFull));*/
        }
    } catch (error) {
        // console.log("catchError: ", error);
        
        // yield put(errorAction(error));
    } finally {
        yield put(hideDashbaordLoader_());
        yield put(hideTransparentLoader_());
        yield put(hideScrollLoader_());
        yield put(isLoadingMore_(false));
    }

}

function* getRequestById({payload}) {
    const {JWToken, requestId, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        const apiResponse = yield call(getRequest, JWToken, requestId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(getRequestSuccess_(apiResponse.data));        
        }
    } catch (error) {
        // console.log("catchError: ", error);
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideDialogueLoader_());
    }

}

function* createNewRequestWithData({payload}) {
    const {JWToken, requestData, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        const apiResponse = yield call(createRequest, JWToken, requestData);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);

            yield put(showFetchingSuccess_("error"));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 

        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(requestRefreshed_(true));
            yield put(requestsRefreshed_(true));
            yield put(createRequestSuccess_());
            yield put(showFetchingSuccess_("success"));

            // alert message   
            const successMessage = yield call(successHandler,"Request", "", "sent");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        
        yield put(showFetchingSuccess_("error"));

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
    } finally {
        yield delay(2000)
        yield put(hideIsFetching_());
        yield put(hideTransparentLoader_());
        yield put(hideDialogueLoader_());
        
    }

}

function* updateRequestStatusById({payload}) {
    // console.log("UPDATE REQUEST STATUS")
    const {JWToken, requestId, requestData, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        const apiResponse = yield call(updateRequestStatus, JWToken, requestId, requestData);
        let fetchingResult = {};
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            
            // yield put(errorAction(apiResponse.error));
            fetchingResult.error = apiResponse.data.error;
            yield put(showFetchingSuccess_(fetchingResult));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 
        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(requestRefreshed_(true));
            yield put(requestsRefreshed_(true));
            yield put(updateRequestStatusSuccess_()); 
            fetchingResult.success = "Success!";
            yield put(showFetchingSuccess_(fetchingResult));    
            
            // alert message   
            const successMessage = yield call(successHandler,"Request", "", "updated");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideLocalFetching_());
        yield put(hideDialogueLoader_());
        yield put(hideTransparentLoader_());
    }

}

function* deleteRequestById({payload}) {
    const {JWToken, requestId, refresh, noLoader} = payload;
    try {
        if (!noLoader) {
            if (refresh) {
                yield put(showTransparentLoader_());
            } else {
                yield put(showDialogueLoader_());
            } 
        }
        const apiResponse = yield call(deleteRequest, JWToken, requestId);
        
        if (apiResponse.status>200) {
            if (apiResponse.status === 401) {
                yield put(userSignOut());
            }
            // console.log("apiResponse_error: ", apiResponse);
            // yield put(errorAction(apiResponse.error));

            // alert message
            const errorMessage = yield call(errorHandler, apiResponse.data);
            yield put(viewAlertMessage_(errorMessage)); 

        } else {
            // apiResponse.profileData = yield call(getUserAccount, loggedInUser.user.username, loggedInUser.user.signInUserSession.idToken.jwtToken); 
            // console.log("apiResponse: ", apiResponse);
            yield put(requestRefreshed_(true));
            yield put(requestsRefreshed_(true));
            yield put(deleteRequestSuccess_());   
            
            // alert message   
            const successMessage = yield call(successHandler,"Request", "", "deleted");
            yield put(viewAlertMessage_(successMessage)); 
        }
    } catch (error) {
        // console.log("catchError: ", error);

        // alert message
        const errorMessage = yield call(errorHandler, error);
        yield put(viewAlertMessage_(errorMessage)); 
        // yield put(errorAction(error));
    } finally {
        yield put(hideTransparentLoader_());
        yield put(hideDialogueLoader_());
    }

}

export function* readOneRequest() {
    yield takeEvery(GET_REQUEST, 
        getRequestById);
}

export function* readAllRequests() {
    yield takeEvery(GET_ALL_REQUESTS, 
        getAllRequestsByFilter);
}

export function* createOneRequest() {
    yield takeEvery(CREATE_REQUEST, 
        createNewRequestWithData);
}

export function* updateOneRequest() {
    yield takeEvery(UPDATE_REQUEST_STATUS, 
        updateRequestStatusById);
}

export function* removeOneRequest() {
    yield takeEvery(DELETE_REQUEST, 
        deleteRequestById);
}

export default function* rootSaga() {
    yield all([fork(readOneRequest),
        fork(readAllRequests),
        fork(createOneRequest),
        fork(updateOneRequest),
        fork(removeOneRequest)
    ]);
}