import { CooperationsCount } from '../API/types/sharedTypes'

export function cooperationsCountAdder(
  cooperationsCount: CooperationsCount
): number {
  return Object.values(cooperationsCount).reduce(
    (t: number, c: number) => t + c,
    0
  )
}
