import {
  hideVisualizerNavMenu,
  showVisualizerNavMenu,
} from 'components/networkVisualizer/networkVisualizerState/store/navMenu/actions'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

export const Hamburger = () => {
  const dispatch = useDispatch()

  const isOpen = useSelector(
    (state: AppState) => state.networkVisualizerState.navMenu.visible
  )

  const toggleNavMenu = () =>
    isOpen
      ? dispatch(hideVisualizerNavMenu())
      : dispatch(showVisualizerNavMenu())

  return (
    <button
      className={`hamburger hamburger--squeeze${isOpen ? ' is-active' : ''}`}
      type="button"
      onClick={toggleNavMenu}
    >
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </button>
  )
}
