import { updateMessageStatus_ } from 'actions/Messages';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { updateMessageRead } from 'sagas/Message';



export const formatChatHistory = async(conversationRaw) => {

    let userId = localStorage.getItem('userId')

    let type;
    let read;
    let messageAge;
    let hoursSinceCreated;
    
    
        let formattedConv = [];
        let newMessagesCount = '';
        let isReadArr = [];
        let conversationData;
        let sentAt;
        
        if (conversationRaw.length) {
            await conversationRaw.map((message, index) => {

                // type sent or received
                if (message.senderId === userId) {
                    type = "sent"
                } else {
                    type = "received"
                }

                
                // format Date
                hoursSinceCreated = (Date.now() - Date.parse(message.createdAt))/(1000*60*60);
                
                sentAt = moment().subtract(hoursSinceCreated, 'hours').format('MMMM Do YYYY, h:mm:ss a');

                // console.log("time [createdAt, hoursSince, sentAt] ", moment, message.createdAt, hoursSinceCreated, sentAt);

                formattedConv.push({
                        messageId: message.messageId,
                        messageType: "text",
                        senderId: message.senderId,
                        receiverId: message.receiverId,
                        subject: message.subject,
                        isRead: message.isRead,
                        createdAt:Date.parse(message.createdAt),
                        hoursSinceCreated: hoursSinceCreated,
                        updatedAt:message.updatedAt,
                        requestId: message.requestId,
                        displayName: message.displayName,
                        type: type,
                        message: message.messageText,
                        sentAt: moment().subtract(hoursSinceCreated, 'hours'),
                        request: message.request || null

                    })

                // Count isRead

            })}
            

            conversationData = {
                formattedConv,
                newMessagesCount
            }


            return conversationData;
}


