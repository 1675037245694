import React from 'react'
import { useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { AppState } from 'store'
import { FeaturedProjects } from './components/featuredProjects/featuredProjects'
import { SearchFilter } from './components/searchFilter/searchFilter'
import { SearchResults } from './components/searchResults/searchResults'
import { ToggleHeader } from './components/toggleHeader/toggleHeader'

const BodyItems = React.memo(() => (
  <div className='navigation-body-wrapper'>
    <FeaturedProjects />
    <SearchFilter />
    <SearchResults />
  </div>
))

const NavigationBody = () => {
  const isVisible = useSelector(
    (state: AppState) => state.networkVisualizerState.navMenu.visible
  )

  return (
    <CSSTransition
      in={isVisible}
      timeout={350}
      classNames={'slide-in-'}
      mountOnEnter
      unmountOnExit
    >
      <BodyItems />
    </CSSTransition>
  )
}

export const NavigationMenu = React.memo(() => {
  return (
    <div className='navigation-menu-wrapper'>
      <ToggleHeader />
      <NavigationBody />
    </div>
  )
})
